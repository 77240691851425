import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { UserService } from './service/user.service';
import { ResourceService } from './service/resource.service';
import { TableModule } from 'primeng/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ChartModule } from 'primeng/chart';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';


import { LiapiService } from './service/liapi.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { ScrollComponent } from './common/scroll/scroll.component';
import { BreadcrumbsComponent } from './common/breadcrumbs/breadcrumbs.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { ApikeysComponent } from './apikeys/apikeys.component';
import { ApisComponent } from './apis/apis.component';
import { CommerceapisComponent } from './commerceapis/commerceapis.component';
import { PricingComponent } from './pricing/pricing.component';
import { ReleaseComponent } from './releasenotes/releasenotes.component';
import { BannersComponent } from './banners/banners.component';
import { GettingComponent } from './gettingstarted/gettingstarted.component';
import { ApidescComponent } from './common/apidesc/apidesc.component';
import { ApiresponseComponent } from './common/apiresponse/apiresponse.component';
import { ApipricingComponent } from './common/apipricing/apipricing.component';
import { SdkComponent } from './common/sdk/sdk.component';
import { DemoheaderComponent } from './common/demoheader/demoheader.component';
import { GeocodeApiComponent } from './geocode/geocodeapi.component';
import { GeocodeDemoComponent } from './geocode/geocodedemo.component';
import { GeocodeGisComponent } from './geocode/geocodegis.component';
import { TypeaheadApiComponent } from './typeahead/typeaheadapi.component';
import { TypeaheadDemoComponent } from './typeahead/typeaheaddemo.component';
import { TypeaheadHowComponent } from './typeahead/typeaheadhow.component';
import { TypeaheadDriveComponent } from './typeahead/typeaheaddrive.component';
import { AutocompleteApiComponent } from './autocomplete/autocompleteapi.component';
import { AutocompleteDemoComponent } from './autocomplete/autocompletedemo.component';
import { MapsApiComponent } from './maps/mapsapi.component';
import { MapsDemoComponent } from './maps/mapsdemo.component';
import { ZonesApiComponent } from './zones/zonesapi.component';
import { ZonesDemoComponent } from './zones/zonesdemo.component';
import { RoutingApiComponent } from './routing/routingapi.component';
import { RoutingDemoComponent } from './routing/routingdemo.component';
import { StreetsApiComponent } from './streets/streetsapi.component';
import { StreetsDemoComponent } from './streets/streetsdemo.component';
import { PlacesApiComponent } from './places/placesapi.component';
import { PlacesDemoComponent } from './places/placesdemo.component';
import { RisksApiComponent } from './risks/risksapi.component';
import { RisksDemoComponent } from './risks/risksdemo.component';
import { PsapApiComponent } from './psap/psapapi.component';
import { PsapDemoComponent } from './psap/psapdemo.component';
import { DemographicsApiComponent } from './demographics/demographicsapi.component';
import { DemographicsDemoComponent } from './demographics/demographicsdemo.component';
import { LocaltaxApiComponent } from './localtax/localtaxapi.component';
import { LocaltaxDemoComponent } from './localtax/localtaxdemo.component';
import { TelecommApiComponent } from './telecomm/telecommapi.component';
import { TelecommDemoComponent } from './telecomm/telecommdemo.component';
import { TimezoneApiComponent } from './timezone/timezoneapi.component';
import { TimezoneDemoComponent } from './timezone/timezonedemo.component';
import { GeolocationApiComponent } from './geolocation/geolocationapi.component';
import { GeolocationDemoComponent } from './geolocation/geolocationdemo.component';
import { SchoolsApiComponent } from './schools/schoolsapi.component';
import { SchoolsDemoComponent } from './schools/schoolsdemo.component';
import { NeighborhoodsApiComponent } from './neighborhoods/neighborhoodsapi.component';
import { NeighborhoodsDemoComponent } from './neighborhoods/neighborhoodsdemo.component';
import { PropertyApiComponent } from './property/propertyapi.component';
import { PropertyDemoComponent } from './property/propertydemo.component';
import { AddressverificationApiComponent } from './addressverification/addressverificationapi.component';
import { AddressverificationDemoComponent } from './addressverification/addressverificationdemo.component';
import { EmailverificationApiComponent } from './emailverification/emailverificationapi.component';
import { EmailverificationDemoComponent } from './emailverification/emailverificationdemo.component';
import { AddressingApiComponent } from './addressingapi/addressingapi.component';
import { AddressesDemoComponent } from './addressingapi/addressesdemo.component';
import { FiledownloadComponent } from './common/sdk/filedownload.component';
import { AddproductComponent } from './addproduct/addproduct.component';
import { GeosearchComponent } from './geosearch/geosearch.component';
import { GeosearchService } from './geosearch/geosearch.service';
import { PhoneVerificationApiComponent } from './phoneverification/phoneverificationapi.component';
import { PhoneverificationDemoComponent } from './phoneverification/phoneverificationdemo.component';
import { AddressAutocompleteComponentDemoA } from './address-autcomplete-demos/address-autocomplete-demoA/address-autocomplete-demoA';
import { AddressAutocompleteDemoComponentB } from './address-autcomplete-demos/address-autocomplete-demoB/address-autocomplete-demoB';
import { AddressAutocompleteComponentC } from './address-autcomplete-demos/address-autocomplete-demoC/address-autocomplete-demoC';
import { AddressAutocompleteComponentD } from './address-autcomplete-demos/address-autocomplete-demoD/address-autocomplete-demoD';

const CONFIGURE_STATES = [
  { path: '', component: HomeComponent },
  { path: 'software-apis/dashboard', component: DashboardComponent },
  { path: 'software-apis/myaccount', component: MyaccountComponent },
  { path: 'software-apis/apikeys', component: ApikeysComponent },
  { path: 'apis', component: ApisComponent },
  { path: 'commerceapis', component: CommerceapisComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'apis/releasenotes', component: ReleaseComponent },
  { path: 'apis/banners', component: BannersComponent },
  { path: 'gettingstarted', component: GettingComponent },
  { path: 'apis/geocode', component: GeocodeApiComponent },
  { path: 'demos/geocode', component: GeocodeDemoComponent },
  { path: 'demos', component: GeocodeDemoComponent },
  { path: 'apis/geocode/gis', component: GeocodeGisComponent },
  { path: 'apis/typeahead', component: TypeaheadApiComponent },
  { path: 'apis/typeahead/find-out-how', component: TypeaheadHowComponent },
  { path: 'apis/typeahead/drive-ecommerce-conversion', component: TypeaheadDriveComponent },
  { path: 'demos/typeahead', component: TypeaheadDemoComponent },
  { path: 'apis/autocomplete', component: AutocompleteApiComponent },
  { path: 'demos/autocomplete', component: AutocompleteDemoComponent },
  { path: 'apis/maps', component: MapsApiComponent },
  { path: 'demos/maps', component: MapsDemoComponent },
  { path: 'apis/zones', component: ZonesApiComponent },
  { path: 'demos/zones', component: ZonesDemoComponent },
  { path: 'apis/routing', component: RoutingApiComponent },
  { path: 'demos/routing', component: RoutingDemoComponent },
  { path: 'apis/streets', component: StreetsApiComponent },
  { path: 'demos/streets', component: StreetsDemoComponent },
  { path: 'apis/places', component: PlacesApiComponent },
  { path: 'demos/places', component: PlacesDemoComponent },
  { path: 'apis/risks', component: RisksApiComponent },
  { path: 'demos/risks', component: RisksDemoComponent },
  { path: 'apis/911', component: PsapApiComponent },
  { path: 'demos/911', component: PsapDemoComponent },
  { path: 'apis/demographics', component: DemographicsApiComponent },
  { path: 'demos/demographics', component: DemographicsDemoComponent },
  { path: 'apis/localtax', component: LocaltaxApiComponent },
  { path: 'demos/localtax', component: LocaltaxDemoComponent },
  { path: 'apis/telecomm', component: TelecommApiComponent },
  { path: 'demos/telecomm', component: TelecommDemoComponent },
  { path: 'apis/timezone', component: TimezoneApiComponent },
  { path: 'demos/timezone', component: TimezoneDemoComponent },
  { path: 'apis/geolocation', component: GeolocationApiComponent },
  { path: 'demos/geolocation', component: GeolocationDemoComponent },
  { path: 'apis/schools', component: SchoolsApiComponent },
  { path: 'demos/schools', component: SchoolsDemoComponent },
  { path: 'apis/neighborhoods', component: NeighborhoodsApiComponent },
  { path: 'demos/neighborhoods', component: NeighborhoodsDemoComponent },
  { path: 'apis/property', component: PropertyApiComponent },
  { path: 'demos/property', component: PropertyDemoComponent },
  { path: 'apis/addressverification', component: AddressverificationApiComponent },
  { path: 'demos/addressverification', component: AddressverificationDemoComponent },
  { path: 'apis/emailverification', component: EmailverificationApiComponent },
  { path: 'demos/emailverification', component: EmailverificationDemoComponent },
  { path: 'apis/addresses', component: AddressingApiComponent },
  { path: 'demos/addresses', component: AddressesDemoComponent },
  { path: 'download', component: FiledownloadComponent },
  { path: 'subscribeproduct', component: AddproductComponent },
  { path: 'apis/phoneverification', component: PhoneVerificationApiComponent },
  { path: 'demos/phoneverification', component: PhoneverificationDemoComponent },
  // This is the WILDCARD CATCH-ALL route that is scoped to the entire application.
  // It will catch any request that is not matched by an earlier route definition.
  // Always make sure this is last route
  { path: '**', redirectTo: '/' }
];

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, ScrollComponent, BreadcrumbsComponent, HomeComponent, DashboardComponent, MyaccountComponent, ApikeysComponent, ApisComponent, CommerceapisComponent, PricingComponent, ReleaseComponent, BannersComponent, GettingComponent, ApidescComponent, ApiresponseComponent, ApipricingComponent, SdkComponent, DemoheaderComponent, GeocodeApiComponent, GeocodeDemoComponent, GeocodeGisComponent, TypeaheadApiComponent, TypeaheadDemoComponent, AutocompleteApiComponent, AutocompleteDemoComponent, TypeaheadHowComponent, TypeaheadDriveComponent, MapsApiComponent, MapsDemoComponent, ZonesApiComponent, ZonesDemoComponent, RoutingApiComponent, RoutingDemoComponent, StreetsApiComponent, StreetsDemoComponent, PlacesApiComponent, PlacesDemoComponent, RisksApiComponent, RisksDemoComponent, PsapApiComponent, PsapDemoComponent, DemographicsApiComponent, DemographicsDemoComponent, LocaltaxApiComponent, LocaltaxDemoComponent, TelecommApiComponent, TelecommDemoComponent, TimezoneApiComponent, TimezoneDemoComponent, GeolocationApiComponent, GeolocationDemoComponent, SchoolsApiComponent, SchoolsDemoComponent, NeighborhoodsApiComponent, NeighborhoodsDemoComponent, PropertyApiComponent, PropertyDemoComponent, AddressverificationApiComponent, AddressverificationDemoComponent, EmailverificationApiComponent, EmailverificationDemoComponent, GeosearchComponent, FiledownloadComponent, AddproductComponent, AddressingApiComponent, AddressesDemoComponent, PhoneVerificationApiComponent, PhoneverificationDemoComponent, AddressAutocompleteComponentDemoA, AddressAutocompleteDemoComponentB, AddressAutocompleteComponentC, AddressAutocompleteComponentD],
  imports: [BrowserModule, AppRoutingModule, FormsModule, AccordionModule, TabViewModule, DialogModule, PanelModule, ToastModule, SidebarModule, InputSwitchModule, HttpClientModule, RouterModule.forRoot(CONFIGURE_STATES, { relativeLinkResolution: 'legacy' }), TableModule, BrowserAnimationsModule, LeafletModule, ChartModule, AutoCompleteModule, MultiSelectModule, TagModule],
  providers: [MessageService, UserService, ResourceService, LiapiService, GeosearchService],
  bootstrap: [AppComponent]
})
export class AppModule { }
