import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';

@Component({
  selector: 'schools-demo',
  templateUrl: './schoolsdemo.component.html'
})
export class SchoolsDemoComponent implements OnInit {
  public searchedAddr = "";
  public searchAddress = "";
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public locationsArr = [];
  public newMarker;
  public resMarkers = [];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService) { };
  ngOnInit() {
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
  };
  showSearchForm = function () {
    this.searchedAddr = "";
  };
  schoolsDemoSearchAddress = function () {
    this.searchedAddr = this.searchAddress;
    var country = 'USA';
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    };
    if (this.resMarkers.length != 0) {
      for(let i=0; i<this.resMarkers.length; i++){
        this.map.removeLayer(this.resMarkers[i]);
      }
    }
    this.locationsArr = [];
    $('#geoPropertyDemoSearchError').hide();
    $('#geoPropertyschoolsError').hide();
    $('#geoPropertyschoolsResult').html('');
    if (this.searchAddress != '') {
      this.geoApisGetLocationGeocode(this.searchAddress, country);
    }
    else {
      $('#geoPropertyDemoSearchError').html('Address cannot be blank.');
      $('#geoPropertyDemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var lat = success.candidates[0].geometry.coordinates[1];
          var lng = success.candidates[0].geometry.coordinates[0];
          this.locationsArr.push([lat, lng]);
          this.map.setView(L.latLng(lat, lng), 14);
          this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
          this.sampleDemoGeoSchoolByAddress(address);
        }
        else {
          $('#geoPropertyschoolsError').hide();
          $('#geoPropertyschoolsResult').html('');
          $('#geoPropertyDemoSearchError').html('No information available for this address.');
          $('#geoPropertyDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeoSchoolByAddress = function (address) {
    $('#geoPropertyschoolsError').hide();
    $('#geoPropertyschoolsResult').html('');
    var tmpData = {
      address: address,
      dataType: 'json'
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApiGeoPropertySchoolByAddr', tmpData).subscribe(
      success => {
        if (success.school) {
          var tmpHtml = '<div class="accordion" id="accordiondemo">';
          var markerCount = 1;
          for (var i = 0; i < success.school.length; i++) {
            var latitude = success.school[i].geometry.coordinates[1];//latitude
            var longitude = success.school[i].geometry.coordinates[0];//longitude
            var markerName = markerCount + '. ' + success.school[i].name;
            var markerText = markerCount.toString();
            markerCount++;
            let resMarkerIcon = {
              icon: L.divIcon({
                iconSize: [56, 56],
                iconAnchor: [28, 56],
                popupAnchor: [0, -44],
                className: 'singlePoiIcon',
                html: markerText
              })
            };
            let resMarker = L.marker([latitude, longitude], resMarkerIcon).addTo(this.map);
            this.resMarkers.push(resMarker);
            this.locationsArr.push([latitude, longitude]);
            this.map.fitBounds(this.locationsArr);
            tmpHtml += '<div class="card">';
            tmpHtml += '<div class="card-header" id="schools_head' + i + '">';
            tmpHtml += '<h5 class="mb-0">';
            tmpHtml += '<button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#schools_content' + i + '" aria-controls="#schools_content' + i + '"';
            if (i != 0) {
              tmpHtml += ' aria-expanded="false"';
            }
            else {
              tmpHtml += ' aria-expanded="true"';
            }
            tmpHtml += '>' + markerName + '</button></h5></div>';
            tmpHtml += '<div id="schools_content' + i + '" class="collapse';
              if (i == 0) {
                tmpHtml += ' show';
              }
              tmpHtml += '" aria-labelledby="schools_head' + i + '" data-parent="#accordiondemo"><div class="card-body">';
            tmpHtml += '<table class="table table-condensed table-unstyled">';
            tmpHtml += '<tr><td colspan="2"><div class="resultKey">Address</div><div class="resultAccVal">';
            if (success.school[i].address.mainAddressLine) {
              tmpHtml += success.school[i].address.mainAddressLine + '<br/>';
            }
            if (success.school[i].address.areaName3) {
              tmpHtml += success.school[i].address.areaName3 + ', ';
            }
            if (success.school[i].address.areaName1) {
              tmpHtml += success.school[i].address.areaName1 + ', ';
            }
            if (success.school[i].address.postCode) {
              tmpHtml += success.school[i].address.postCode;
            }
            tmpHtml += '</div></td></tr>';
            tmpHtml += '<tr><td><div class="resultKey">Phone</div><div class="resultAccVal">';
            if (success.school[i].phone) {
              tmpHtml += success.school[i].phone;
            }
            else {
              tmpHtml += '-';
            }
            tmpHtml += '</div></td>';
            tmpHtml += '<td><div class="resultKey">Distance</div><div class="resultAccVal">' + parseFloat(success.school[i].distance.value).toFixed(2) + ' ' + success.school[i].distance.unit + '</div></td></tr>';
            tmpHtml += '<tr><td><div class="resultKey">School Type</div><div class="resultAccVal">';
            if (success.school[i].schoolTypeDesc) {
              tmpHtml += success.school[i].schoolTypeDesc;
            }
            if (success.school[i].schoolTypeDesc && success.school[i].schoolSubTypeDesc) {
              tmpHtml += ' - ';
            }
            if (success.school[i].schoolSubTypeDesc) {
              tmpHtml += success.school[i].schoolSubTypeDesc;
            }
            tmpHtml += '</div></td><td><div class="resultKey">Education Level</div><div class="resultAccVal">';
            if (success.school[i].educationLevelDesc) {
              tmpHtml += success.school[i].educationLevelDesc;
            }
            else {
              tmpHtml += '-';
            }
            tmpHtml += '</div></td></tr>';
            tmpHtml += '<tr><td><div class="resultKey">Lowest Grade</div><div class="resultAccVal">';
            if (success.school[i].lowestGrade) {
              tmpHtml += success.school[i].lowestGrade;
            }
            else {
              tmpHtml += '-';
            }
            tmpHtml += '</div></td>';
            tmpHtml += '<td><div class="resultKey">Highest Grade</div><div class="resultAccVal">';
            if (success.school[i].highestGrade) {
              tmpHtml += success.school[i].highestGrade;
            }
            else {
              tmpHtml += '-';
            }
            tmpHtml += '</div></td></tr>';
            tmpHtml += '<tr><td style="min-width:150px;"><div class="resultKey">Student Teacher Ratio</div><div class="resultAccVal">';
            if (success.school[i].studentTeacherRatio) {
              tmpHtml += success.school[i].studentTeacherRatio;
            }
            else {
              tmpHtml += '-';
            }
            tmpHtml += '</div></td>';
            tmpHtml += '<td><div class="resultKey">School Districts</div><div class="resultAccVal">';
            if (success.school[i].schoolDistricts.name) {
              tmpHtml += success.school[i].schoolDistricts.name;
            }
            else {
              tmpHtml += '-';
            }
            tmpHtml += '</div></td></tr>';
            tmpHtml += '</table>';
            tmpHtml += '</div></div></div>';
          }
          tmpHtml += '</div>';
          $('#geoPropertyschoolsResult').html(tmpHtml);
        }
        else {
          $('#geoPropertyschoolsError').html('No information available for this location.');
          $('#geoPropertyschoolsError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoPropertyschoolsError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoPropertyschoolsError').html(error.statusText);
        }
        $('#geoPropertyschoolsError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoPropertyschoolsError').hide();
        }
      }
    );
  };
}
