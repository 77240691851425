import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'banners',
  templateUrl: './banners.component.html'
})
export class BannersComponent implements OnInit {
  public showBanner: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { };
  ngOnInit() {
    window.scroll(0, 0);
  };

  public bannerNameArr: any = ['geocode', 'typeahead', 'maps', 'zones', 'routing', 'streets', 'places', 'risks', '911', 'demographics', 'localtax', 'telecomm', 'timezone', 'geolocation', 'schools', 'neighborhoods', 'property', 'addressverification', 'emailverification', 'addresses', 'phoneverification'];
  public bannerName: string = 'geocode';
  public autostartAtImage: number = 0;
  initGallery = function (autostartAtImage) {
    this.showBanner = true;
    this.autostartAtImage = autostartAtImage - 1;
    this.bannerName = this.bannerNameArr[this.autostartAtImage];
    var tmphtml = '<a href="/apis/' + this.bannerName + '" rel="noopener noreferrer" target="_blank"><img src="/assets/images/banners/' + this.bannerName + '.png" width="700"></a>';
    $('#gallery').html(tmphtml);
  };
  gotoprev = function () {
    if (this.autostartAtImage != 0) {
      this.autostartAtImage = this.autostartAtImage - 1;
      this.bannerName = this.bannerNameArr[this.autostartAtImage];
      var tmphtml = '<a href="/apis/' + this.bannerName + '" rel="noopener noreferrer" target="_blank"><img src="/assets/images/banners/' + this.bannerName + '.png" width="700"></a>';
      $('#gallery').html(tmphtml);
    }
  };
  gotonext = function () {
    var maxVal = this.bannerNameArr.length - 1;
    if (this.autostartAtImage < maxVal) {
      this.autostartAtImage = this.autostartAtImage + 1;
      this.bannerName = this.bannerNameArr[this.autostartAtImage];
      var tmphtml = '<a href="/apis/' + this.bannerName + '" rel="noopener noreferrer" target="_blank"><img src="/assets/images/banners/' + this.bannerName + '.png" width="700"></a>';
      $('#gallery').html(tmphtml);
    }
  };
}
