import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'geolocation-api',
  templateUrl: './geolocationapi.component.html'
})
export class GeolocationApiComponent implements OnInit {
  public wifiParamLink: any = '';
  public dsParamLink: any = '';
  public macaccessPointJson = '[{"cellType":"wifi", "mac":"00:22:75:10:d5:91", "ssid":"", "rssi":" -90", "speed":"500"}, {"cellType":"wifi", "mac":"00:22:75:10:d5:91", "ssid":"", "rssi":" -90"}]';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'mac address geolocation, geolocation wifi, free geolocation api, geolocation api free, ip address coordinates, wifi geolocation, geolocation devices, ip geolocation api, geolocator free, geocode ip address, wifi access point, lookup, geolocation' }
    );
    this.macaccessPointJson = JSON.stringify(JSON.parse(this.macaccessPointJson), undefined, 4);
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.setParamLink(success.docsURL);
        }
      );
    }
    else{
      this.setParamLink(docsURL);
    }
  };
  setParamLink = function (docsURL) {
    this.wifiParamLink = docsURL + 'Geolocation/WIFI/request_parameters.html';
    this.dsParamLink = docsURL + 'Geolocation/Carrier/Devicestatus/query_parameters.html';
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_byIpRes':
        this.get_byIpUri = "";
        this.get_byIpRes = null;
        this.get_byIpResStatus = "";
        this.get_byIpResType = "";
        break;
      case 'get_byMacRes':
        this.get_byMacUri = "";
        this.get_byMacRes = null;
        this.get_byMacResStatus = "";
        this.get_byMacResType = "";
        break;
    }
  };
  public geoLocationTryApiLocationByIpAddripAddress = '54.86.242.73';
  public geoLocationTryApiLocationByIpAddrDataType = 'json';
  public get_byIpUri = "";
  public get_byIpRes = null;
  public get_byIpResStatus = "";
  public get_byIpResType = "";
  tryApiGeoLocationLocationByIpAddr = function (btnId) {
    var ipAddress = this.geoLocationTryApiLocationByIpAddripAddress;
    var dataType = this.geoLocationTryApiLocationByIpAddrDataType;
    var tmpData = {
      ipAddress: ipAddress,
      dataType: dataType
    };
    if (ipAddress != '') {
      var methodName = '/tryApiGeoLocationLocationByIpAddr';
      let requestUri = this.liapiservice.getCompleteUri('geolocation', 'Location By IP Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_byIpUri = requestUri;
          this.get_byIpResType = dataType;
          if(this.get_byIpResStatus.includes('429')){
            this.get_byIpUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_byIpResStatus = '200 success';
            this.get_byIpRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_byIpResStatus = `${error.status} ${error.statusText}`;
              this.get_byIpRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoLocationTryApiLocationByMacmac = '00:22:75:10:d5:91';
  public geoLocationTryApiLocationByMacssid = '';
  public geoLocationTryApiLocationByMacrssi = '';
  public geoLocationTryApiLocationByMacspeed = '';
  public geoLocationTryApiLocationByMacDataType = 'json';
  public get_byMacUri = "";
  public get_byMacRes = null;
  public get_byMacResStatus = "";
  public get_byMacResType = "";
  tryApiGeoLocationLocationByMac = function (btnId) {
    var mac = this.geoLocationTryApiLocationByMacmac;
    var accessPoint = this.macaccessPointJson;
    var ssid = this.geoLocationTryApiLocationByMacssid;
    var rssi = this.geoLocationTryApiLocationByMacrssi;
    var speed = this.geoLocationTryApiLocationByMacspeed;
    var dataType = this.geoLocationTryApiLocationByMacDataType;
    var tmpData = {
      dataType: dataType
    };
    if(mac !='' || accessPoint !=''){
      if(mac != ''){
        tmpData['mac'] = mac;
      }
      if(accessPoint != ''){
        tmpData['accessPoint'] = accessPoint;
      }
      if(ssid != ''){
        tmpData['ssid'] = ssid;
      }
      if(rssi != ''){
        tmpData['rssi'] = rssi;
      }
      if(speed != ''){
        tmpData['speed'] = speed;
      }
      var methodName = '/tryApiGeoLocationLocationByMac';
      let requestUri = this.liapiservice.getCompleteUri('geolocation', 'Location By Wi-fi Access Point', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_byMacUri = requestUri;
          this.get_byMacResType = dataType;
          if(this.get_byMacResStatus.includes('429')){
            this.get_byMacUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_byMacResStatus = '200 success';
            this.get_byMacRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_byMacResStatus = `${error.status} ${error.statusText}`;
              this.get_byMacRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else{
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
}
