import { Component, Input, Output, EventEmitter } from "@angular/core";
import { LiapiService } from '../../service/liapi.service';
import { catchError, debounceTime, distinctUntilChanged, Observable, of, switchMap } from "rxjs";
import {v4 as uuidv4} from 'uuid';


@Component({
    selector: 'address-autocomplete-demoB',
    templateUrl: './address-autocomplete-demoB.html',
    styleUrls: ['./address-autocomplete-demoB.css']
})
export class AddressAutocompleteDemoComponentB {
    public searchText: string = null;
    public _showResults: boolean = false;
    public loading: boolean = false;
    public results : any = {};
    public resultLength: number;
    public selectedIndex: number = -1;
    public requestObj: any = null;
    public transactionId: string = null;
    public generateNewTransactionID: boolean = true;
    public minLength :number= 3;
    public maxCandidates :number= 10;
    public postCode: string;
    public city : string;
    public state : string;
    public searchRadius :number  = 5;
    public searchRadiusUnit : string = 'miles';
    public ipAddress :string= '';
    public postalVerified : string = "";
    public countryInfo = {
        "name": "UNITED KINGDOM",
        "code": "GBR",
        "label": "UNITED KINGDOM (GBR)"
      };
      public matchOnAddressNumber: string = 'false';
      public searchOnAddressNumber: string = 'N';
      public returnAdminAreasOnly: string = 'N';
      public autoDetectLocation: boolean = true;
      public isStateEmpty : boolean = false;
    
      constructor(private searchLocationsTmp: LiapiService){}
    
      onQueryUpdate =  () => {
        if(this.generateNewTransactionID){
            this.transactionId = uuidv4();
            this.generateNewTransactionID = false;
        }
        let requestData = new Map();
        requestData.set("maxCandidates", this.maxCandidates);
        requestData.set("searchOnAddressNumber", this.searchOnAddressNumber);
        requestData.set("returnAdminAreasOnly", this.returnAdminAreasOnly);
        requestData.set("autoDetectLocation", this.autoDetectLocation);
        requestData.set("searchRadius", this.searchRadius);
        requestData.set("searchRadiusUnit", this.searchRadiusUnit);        

        if(this.ipAddress != ''){
            requestData.set("ipAddress", this.ipAddress);
        }
        
        if(this.searchText !== "" && this.searchText.length >= this.minLength) {
            requestData.set('searchText', this.searchText)
            requestData.set('transactionId', this.transactionId);
            requestData.set('clientIdentifier', "ECommerceDemo")
            this.loading = true;
            if(this.requestObj != null){
                this.requestObj.unsubscribe();
            }
            this.requestObj = this.searchLocationsTmp.getAddressAutocompleteResponse(requestData).subscribe({
                next: (success :any) => {
                    this.loading = false;
                    if (success.location) {
                        if (success.location.length != 0) {
                            this.results = success;
                            this.resultLength = success.location.length;
                            this._showResults = true;
                            this.selectedIndex = 0;
                        }
                        else {
                            this._showResults = false;
                        }
                    }
                    else {
                        this._showResults = false;
                    }
                },
                error: error => {
                    this.loading = false
                    this._showResults = false;
                    if(error.status == '429'){
                        this.searchLocationsTmp.showPrimeInfo();
                    }
                }
            });
            
        }
        if (this.searchText.length < this.minLength) {
            this._showResults = false;
        }
    };
    onResultClicked =  (result : any) => {
        if (result.totalUnitCount > 1) {
            this.results.location = [];
            let locArr = [];
            let addrObj = result.address;
            let geometryObj = result.geometry;
            let arrCount = 0;
            for (let i = 0; i < result.ranges.length; i++) {
                for (let j = 0; j < result.ranges[i].units.length; j++) {
                    locArr[arrCount] = {};
                    locArr[arrCount].totalUnitCount = 1;
                    locArr[arrCount].geometry = Object.assign({}, geometryObj);
                    locArr[arrCount].address = Object.assign({}, addrObj);
                    locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                    if(result.ranges[i].units[j].postalVerified !=null){
                        locArr[arrCount].address.postalVerified = result.ranges[i].units[j].postalVerified;
                    }
                    var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                    locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                    arrCount++;
                }
            }
            this.results.location = locArr;
        }
        else {
            if (result.address.postalVerified != null ) {
                  this.postalVerified = result.address.postalVerified;
            }
            else if(result.ranges != null && result.ranges.length == 1 && result.ranges[0].units != null && result.ranges[0].units.length == 1 &&
              result.ranges[0].units[0].postalVerified != null){
                  this.postalVerified = result.ranges[0].units[0].postalVerified;
            }
            else{
                this.postalVerified = "Not Verfied";
            }

            this.searchText = result.address.mainAddressLine;
            if(result.address.postCode !=undefined && result.address.postCode != ""){
                this.postCode = result.address.postCode;
            }
            if(result.address.areaName3 !=undefined){
                this.city = result.address.areaName3;
            }
            if(result.address.areaName1 !=undefined && result.address.areaName1 != "" && result.address.areaName1 != null){
                
                this.state = result.address.areaName1;
                this.isStateEmpty = false;
            }
            else{
                this.isStateEmpty = true;
            }
            
            
        
            this._showResults = false;
            let requestData = new Map();
            requestData.set('selectedAddress', result.address.formattedAddress);
            requestData.set('country', this.countryInfo.code);
            requestData.set('transactionId', this.transactionId);
            this.searchLocationsTmp.selectAddress(requestData).subscribe((success) =>{
                this.transactionId = null;
                this.generateNewTransactionID = true;
            });
        }
    };
    onKeyDown =  (event:any) => {
        if (document.getElementById('geoitemcontainer')) {
            if (event.keyCode === 38) {
                event.preventDefault();
                var indexval = this.selectedIndex;
                if (this.selectedIndex > 1) {
                    if (this.addCSS(this.selectedIndex - 1)) {
                        this.selectedIndex = this.selectedIndex - 1;
                        this.removeCSS(indexval);
                        document.getElementById('geoitemcontainer').scrollTop -= 40;
                    }
                }
            }
            if (event.keyCode === 40) {
                event.preventDefault();
                var indexval = this.selectedIndex;
                if (this.selectedIndex < this.resultLength) {
                    if (this.addCSS(this.selectedIndex + 1)) {
                        this.selectedIndex = this.selectedIndex + 1;
                        this.removeCSS(indexval);
                        if (this.selectedIndex > 1) {
                            document.getElementById('geoitemcontainer').scrollTop += 40;
                        }
                    }
                }
            }
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById('geoitemcontainer').scrollTop = 0;
                var indexval = this.selectedIndex;
                if (indexval > 0) {
                    var result = this.results.location[indexval - 1];
                    if (result.totalUnitCount > 1) {
                        this.results.location = [];
                        var locArr = [];
                        var addrObj = result.address;
                        var geometryObj = result.geometry;
                        var arrCount = 0;
                        this.resultLength = result.totalUnitCount;
                        for (var i = 0; i < result.ranges.length; i++) {
                            for (var j = 0; j < result.ranges[i].units.length; j++) {
                                locArr[arrCount] = {};
                                locArr[arrCount].totalUnitCount = 1;
                                locArr[arrCount].address = Object.assign({}, addrObj);
                                locArr[arrCount].geometry = Object.assign({}, geometryObj);
                                locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                                var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                                locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                                this.removeCSS(this.selectedIndex);
                                this.selectedIndex = 0;
                                arrCount++;
                            }
                        }
                        this.results.location = locArr;
                    }
                    else {
                        this.removeCSS(this.selectedIndex);
                        this.selectedIndex = 0;
                        this.searchText = result.address.formattedAddress;
                        this._showResults = false;
                    }
                }
            }
            if (event.keyCode === 27) {
                this.removeCSS(this.selectedIndex);
                this.selectedIndex = 0;
                this._showResults = false;
                event.preventDefault();
            }
        }
    };
    removeCSS =  (index:any) => {
        if (index > 0) {
            var elementId = 'geoitem' + index;
            document.getElementById(elementId).className = "pb-geo-item";
        }
    };
    addCSS =  (index:any) => {
        var returnval = false;
        if (index > 0) {
            var elementId = 'geoitem' + index;
            document.getElementById(elementId).className = "pb-geo-item pb-geo-item-selected";
            return true;
        }
        return returnval;
    };
    countrySearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term =>
        this.searchLocationsTmp.searchTypeaheadCountry(term).pipe(
            
          catchError(() => {
            return of([]);
          })
        )
      )
    )
    countryFormatter = (x: { label: string }) => x.label;

    clearFields = ()=>{
        this.searchText = "";
        this.city = "";
        this.state = "";
        this.postCode = "";
        this._showResults = false;
        this.searchRadius = 5;
        this.searchRadiusUnit = 'miles';
        this.ipAddress = "";
        this.postalVerified = "";
        this.autoDetectLocation = true;
        this.isStateEmpty = false;
    }
    toogleIpCheckbox = function(){
        this.ipAddress = '';
      };
}
