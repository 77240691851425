import { Component, OnInit, Input } from '@angular/core';
import * as AppConfigs from '../../app.constants';


@Component({
    selector: 'api-pricing',
    templateUrl: './apipricing.component.html'
})
export class ApipricingComponent implements OnInit {
    public pricingTblArr:any = [];
    @Input() apiName: string;

    constructor() { };
    ngOnInit() {
        for (var i = 0; i < AppConfigs.apisMethodList.length; i++) {
            if (this.apiName == AppConfigs.apisMethodList[i].apiName) {
                this.pricingTblArr = AppConfigs.apisMethodList[i].methods;
            }
        }
    };
}
