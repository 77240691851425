import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import "chartjs-adapter-moment";
import { chartColors } from '../../assets/jsonData/chartColors';
import { transparentize } from "../../assets/jsonData/chartColors";
import { UserService } from '../service/user.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import { KeyValue } from '@angular/common';
declare const recurly: any;

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit, AfterViewChecked {
  doughnutData: any;
  public showpage: boolean = false;
  public welcomeMessageM1 = "It's great to have you onboard.";
  public welcomeMessageM2 = "If you have any question at all, feel free to contact us. Happy coding.";
  public welcomeMessageM3 = '';
  public isSuspendedMessageM3: boolean = false;
  public isDialReady: boolean = false;
  public isCancelledEntitlement: boolean = false;
  public apicalltimerange = 'minute';
  public apicallcharttype = 'area';
  public areachart = false;
  public linechart = false;
  public bargroupchart = false;
  public isNodata = false;
  public ischartloader = true;
  public istableloader = true;
  public xAxisTicks = 10;
  public yAxisTicks = 10;
  public usagetimerange = 'minute';
  public usageproduct = 'all';
  public apiproductstotalCalls = 0;
  public apiproductserrors = 0;
  public apiproductscreditUsages = 0;
  public areachartdata: any = {
    labels: [],
    datasets: []
  };
  // public bargroupchartdata: Array<PbdsDatavizBarGrouped> = [];
  public bargroupchartdata: any = [];
  public transactionMsg: any = '';
  public isOverage: boolean = false;
  public userEntitlement: any = [];
  public planName: string = '';
  public apiproducts: any = [];
  public filteredapiproducts: any = [];
  public isAscending = true;
  public userInfo: any = null;
  public filterList: any = '';
  public showPaymentModal: boolean = false;
  public docsURL: any = "";
  public isNeToUpIm: boolean = false;
  public isPaymentFormOpen: boolean = false;
  public isPaymentErr: boolean = false;
  public isPaymentSuccess: boolean = false;
  public paymentErrMsg: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public addressLine1: string = '';
  public addressLine2: string = '';
  public city: string = '';
  public usStateMap = { AL: "Alabama", AK: "Alaska", AZ: "Arizona", AR: "Arkansas", CA: "California", CO: "Colorado", CT: "Connecticut", DE: "Delaware", DC: "District of Columbia", FL: "Florida", GA: "Georgia", HI: "Hawaii", ID: "Idaho", IL: "Illinois", IN: "Indiana", IA: "Iowa", KS: "Kansas", KY: "Kentucky", LA: "Louisiana", ME: "Maine", MD: "Maryland", MA: "Massachusetts", MI: "Michigan", MN: "Minnesota", MS: "Mississippi", MO: "Missouri", MT: "Montana", NE: "Nebraska", NV: "Nevada", NH: "New Hampshire", NJ: "New Jersey", NM: "New Mexico", NY: "New York", NC: "North Carolina", ND: "North Dakota", OH: "Ohio", OK: "Oklahoma", OR: "Oregon", PA: "Pennsylvania", RI: "Rhode Island", SC: "South Carolina", SD: "South Dakota", TN: "Tennessee", TX: "Texas", UT: "Utah", VT: "Vermont", VA: "Virginia", WA: "Washington", WV: "West Virginia", WI: "Wisconsin", WY: "Wyoming" };
  public stateMap: any = ["Alabama"];
  public stateprovince: string = 'Alabama';
  public stateName: string = 'Alabama';
  public countryMap: any = { US: 'United States' };
  public countryCode: string = 'US';
  public postalCode: any = '';
  public showConfirmAddr: boolean = false;
  public verifiedAddrRes: any;
  public confirmAddrRadio: any = null;
  public billPeriod: string;
  public chartSettings: any;
  public chartColors = chartColors;

  public currentPlan :string;
  private creditCardAndFreePlansList = new Set(["LI_100K_12_Months", "LI_10K_Month_to_Month", "LI_25K_Annual", "LI_5K_Annual", "LI_5K_Month_to_Month", "LI_Free_1Month_Trial"]);
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private resourceService: ResourceService) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.chartSettings = {
      scales: {
        xAxes:
        {
          type: 'time',
          time: {
            tooltipFormat: "dddd Do"
          },
          ticks: {
            maxTicksLimit: this.xAxisTicks
          },
          grid: {
            display: false
          }
        },
        yAxes: {
          ticks: {
            maxTicksLimit: this.yAxisTicks
          },
          grid: {
            display: false
          }
        }
      },
      plugins: {
        legend: {
          align: 'center',
          position: 'bottom'
        },
        tooltip: {
          displayColors: true,
          boxWidth: 20,
          boxHeight: 20
        }
      },
      interaction: {
        mode: 'index',
        intersect: 'true'
      }
    };
    this.loadCountryMap();
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.docsURL = success.docsURL + "#Appendix/appendix_mapping_of_old_urls_with_precisely_urls.html";
        }
      );
    }
    else {
      this.docsURL = docsURL + "#Appendix/appendix_mapping_of_old_urls_with_precisely_urls.html";
    }
    this.userService.getUserDeatails().subscribe(
      success => {
        this.userInfo = success;
        for (let i = 0; i < this.userInfo.entitlements.length; i++) {
          if (this.userInfo.entitlements[i].product == 'LBS') {
            this.userEntitlement[0] = this.userInfo.entitlements[i];
            this.currentPlan = this.userInfo.entitlements[i].plan;
            if(this.userInfo.entitlements[i].status == 'CANCELLED'){
              this.isCancelledEntitlement = true;
            }
          }
        }
        if (this.userEntitlement.length == 0) {
          window.location.href = "/subscribeproduct";
          return;
        }
        else {
          this.showpage = true;
          let recurlyAccountId = this.userEntitlement[0].recurlyAccountId;
          if (recurlyAccountId) {
            let planId = this.userEntitlement[0].plan;
            if (planId == 'LI_5K_Month_to_Month' || planId == 'LI_100K_12_Months' || planId == 'LI_10K_Month_to_Month' || planId == 'LI_25K_Annual' || planId == 'LI_5K_Annual') {
              this.getUserAccountDetails();
              let recurlyConfig = localStorage.getItem("recurlyConfig");
              if (!recurlyConfig) {
                this.resourceService.getRecurlyConfig().subscribe(
                  success => {
                    localStorage.setItem("recurlyConfig", success.recurlyConfig);
                  }
                );
              }
            }
          }
          else {
            //alert("User without recurly account id.");
            document.location.href = '/signout';
          }
        }
        this.userService.overallUsage().subscribe(
          success => {
            let usedPer = (success.totalPointsUsed * 100) / success.quota;
            this.isDialReady = true;
            if (success.totalPointsUsed > success.quota) {
              this.isOverage = true;
              this.transactionMsg = (success.totalPointsUsed - success.quota).toFixed(2);
              let pointsUsed = success.totalPointsUsed;
              let pointsLeft = 0;
              this.doughnutData = {
                datasets: [
                  {
                    data: [pointsUsed, pointsLeft],
                    backgroundColor: ['#E5007E', '#F3F3F3'],
                    hoverBackgroundColor: ['#E5007E', '#F3F3F3']
                  }
                ]
              };
            }
            else {
              this.transactionMsg = (success.quota - success.totalPointsUsed).toFixed(2);
              let pointsUsed = success.totalPointsUsed;
              let pointsLeft = this.transactionMsg;
              this.doughnutData = {
                datasets: [
                  {
                    data: [pointsUsed, pointsLeft],
                    backgroundColor: ['#39006B', '#F3F3F3'],
                    hoverBackgroundColor: ['#39006B', '#F3F3F3']
                  }
                ]
              };
            }
            let todayTime = new Date().getTime();
            let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            let isoDate = new Date(this.userInfo.subscriptions.lbs.endDate);
            let isoDateStr = monthNames[isoDate.getMonth()] + " " + isoDate.getDate() + ", " + isoDate.getFullYear();
  
            let dateFrom;
            let dateTo;
            if(this.creditCardAndFreePlansList.has(this.currentPlan)){
              dateFrom = new Date(this.userInfo.subscriptions.lbs.currentQuotaPeriodStartDate);
              dateTo = new Date(this.userInfo.subscriptions.lbs.nextQuotaPeriodStartDate);
            }
            else{
              dateFrom = new Date(this.userInfo.subscriptions.lbs.startDate);
              if(this.userInfo.subscriptions.lbs.endDate != null){
                dateTo = new Date(this.userInfo.subscriptions.lbs.endDate);
              }
            }
            let Year = dateFrom.getFullYear();
            let months = dateFrom.getMonth();
            let day = dateFrom.getDate();
            let fromDateStr = day + "-" + monthNames[months] + "-" + Year;
            let toDateStr = '';
            if(dateTo){
              let dateOffset = 1;
              dateTo.setDate(dateTo.getDate() - dateOffset);
              let YearTo = dateTo.getFullYear();
              let monthsTo = dateTo.getMonth();
              let dayTo = dateTo.getDate();
              toDateStr = dayTo + "-" + monthNames[monthsTo] + "-" + YearTo;
            }
            else{
              toDateStr = 'N/A';
            }
            this.billPeriod = fromDateStr + " To " + toDateStr;
            for (let i = 0; i < this.userInfo.entitlements.length; i++) {
              if (this.userInfo.entitlements[i].product == 'LBS') {
                this.userEntitlement[0] = this.userInfo.entitlements[i];
                if (this.userInfo.entitlements[i].status == 'CANCELLED') {
                  this.welcomeMessageM1 = 'Your current subscription has cancelled.';
                  this.welcomeMessageM2 = 'To re-subscribe, please sign out and sign up.';
                  this.welcomeMessageM3 = '';
                }
                else if (this.userInfo.entitlements[i].status == 'SUSPENDED') {
                  this.welcomeMessageM1 = 'Your current subscription is suspended.';
                  this.welcomeMessageM2 = 'To re-enable your subscription, please make sure your past due payments are clear and update your credit card details.';
                  this.welcomeMessageM3 = '';
                  this.isSuspendedMessageM3 = true;
                }
                else {
                  this.welcomeMessageM1 = "It's great to have you onboard.";
                  this.welcomeMessageM2 = "If you have any question at all, feel free to contact us. Happy coding.";
                  this.welcomeMessageM3 = '';
                  if (this.userInfo.subscriptions.lbs.isPaid === false && this.userInfo.subscriptions.lbs.endDate) {
                    this.welcomeMessageM3 = 'Your Precisely API credits plan will expire on ' + isoDateStr + '.';
                  }
                  if (this.userInfo.subscriptions.lbs.endDate != "" && parseInt(this.userInfo.subscriptions.lbs.endDate) < todayTime) {
                    this.welcomeMessageM1 = 'Your current plan has expired.';
                    this.welcomeMessageM2 = 'To upgrade your Precisely API plan, just head over to the pricing page.';
                    this.welcomeMessageM3 = 'Your Precisely API credits plan has expired on ' + isoDateStr + '.';
                  } else if (usedPer > 50 && usedPer < 75) {
                    this.welcomeMessageM1 = 'Over half of your Precisely API credits are used.';
                    this.welcomeMessageM2 = 'To upgrade your Precisely API plan, just head over to the pricing page.';
                  } else if (usedPer > 75 && usedPer < 100) {
                    this.welcomeMessageM1 = "Uh-oh. You're almost out of Precisely API credits.";
                    this.welcomeMessageM2 = 'To upgrade your Precisely API plan, just head over to the pricing page.';
                  } else if (usedPer >= 100) {
                    this.welcomeMessageM1 = "Unfortunately, you're out of Precisely API credits.";
                    this.welcomeMessageM2 = 'To upgrade your Precisely API plan, just head over to the pricing page.';
                  }
                }
              }
            }
          },
          error => {
            if (error.error.error == 'Cookie tempered') {
              window.location.href = '/signout';
            }
          }
        );
        this.userService.getPlanDetails().subscribe(
          success => {
            this.planName = success.name;
          },
          error => {
            if (error.error.error == 'Cookie tempered') {
              window.location.href = '/signout';
            }
          }
        );
        let productsPayload = {
          developerId: this.userInfo.apimPlatformUserId,
          applicationID: this.userInfo.subscriptions.lbs.applicationId,
          product: 'lbs'
        };
        this.userService.getProducts(productsPayload).subscribe(
          success => {
            for (let i = 0; i < success.products.length; i++) {
              this.filterList += success.products[i].productID + '%27%2C%27';
              let tmpObj = {
                productID: success.products[i].productID,
                productName: success.products[i].productName === 'Typeahead' ? "Address Autocomplete" : success.products[i].productName,
                totalCalls: 0,
                errors: 0,
                creditUsages: 0
              }
              if(success.products[i].productName === 'Address Autocomplete Advanced')
              tmpObj.productName = "Address Autocomplete Enterprise"

              this.apiproducts.push(tmpObj);
            }
            this.apiproducts.sort(this.compareApiProducts);
            this.filterList = this.filterList.substr(0, this.filterList.length - 9);
            this.updateCreditUsageTable();
            this.updateApiCallChart();
          },
          error => {
            if (error.error.error == 'Cookie tempered') {
              window.location.href = '/signout';
            }
          }
        );
      },
      error => {
        window.location.href = "/";
      }
    );
  };
  ngAfterViewChecked() {
    let gaugeNum = $('.gauge-number').html();
    if (gaugeNum) {
      if (!gaugeNum.includes('%')) {
        gaugeNum += '%';
        $('.gauge-number').html(gaugeNum);
      }
    }
  };
  compareApiProducts = function (a, b) {
    const productNameA = a.productName.toUpperCase();
    const productNameB = b.productName.toUpperCase();
    let comparison = 0;
    if (productNameA > productNameB) {
      comparison = 1;
    } else if (productNameA < productNameB) {
      comparison = -1;
    }
    return comparison;
  };
  updateCreditUsageTable = function () {
    this.istableloader = true;
    var timeUnit = this.usagetimerange;
    var fromDate = new Date().getTime();
    if (timeUnit == 'minute') {
      fromDate -= 86400000;
      var coeff = 1000 * 60 * 60;
      fromDate = Math.round(fromDate / coeff) * coeff;
    } else if (timeUnit == 'week') {
      fromDate -= 86400000 * 6;
    } else if (timeUnit == 'month') {
      fromDate -= 86400000 * 30;
    }
    if (typeof this.userInfo.subscriptions.lbs !== 'undefined' && this.userInfo.subscriptions.lbs.startDate > fromDate) {
      fromDate = this.userInfo.subscriptions.lbs.startDate;
    }
    let apitrafPayload = {
      usageFromDate: fromDate,
      userId: this.userInfo.email,
      timeUnit: timeUnit,
      product: 'lbs',
      filterList: this.filterList
    };
    this.userService.apiTraffic(apitrafPayload).subscribe(
      success => {
        this.istableloader = false;
        for (let j = 0; j < this.apiproducts.length; j++) {
          this.apiproducts[j].totalCalls = 0;
          this.apiproducts[j].errors = 0;
          this.apiproducts[j].creditUsages = 0;
        }
        this.apiproductstotalCalls = 0;
        this.apiproductserrors = 0;
        this.apiproductscreditUsages = 0;
        for (let i = 0; i < success.dimensions.length; i++) {
          let prodIndex;
          for (let j = 0; j < this.apiproducts.length; j++) {
            if (success.dimensions[i].name == this.apiproducts[j].productID) {
              prodIndex = j;
            }
          }
          let message_count = 0;
          let error_count = 0;
          for (var j = 0; j < success.dimensions[i].metrics.length; j++) {
            if (success.dimensions[i].metrics[j].name == 'sum(message_count)') {
              for (var k = 0; k < success.dimensions[i].metrics[j].values.length; k++) {
                if (success.dimensions[i].metrics[j].values[k].value > 0) {
                  message_count += parseFloat(success.dimensions[i].metrics[j].values[k].value)
                }
              }
            }
            if (success.dimensions[i].metrics[j].name == 'sum(is_error)') {
              for (var k = 0; k < success.dimensions[i].metrics[j].values.length; k++) {
                if (success.dimensions[i].metrics[j].values[k].value > 0) {
                  error_count += parseFloat(success.dimensions[i].metrics[j].values[k].value)
                }
              }
            }
          }
          if (prodIndex != undefined) {
            this.apiproducts[prodIndex].totalCalls = message_count;
            this.apiproducts[prodIndex].errors = error_count;
            this.apiproductstotalCalls += message_count;
            this.apiproductserrors += error_count;
          }
        }
        this.userService.overallUsage().subscribe(
          success => {
            for (let i = 0; i < success.usageDetails.length; i++) {
              for (let j = 0; j < this.apiproducts.length; j++) {
                if (success.usageDetails[i].productID == this.apiproducts[j].productID.toLowerCase()) {
                  this.apiproducts[j].creditUsages = (success.usageDetails[i].pointsUsage * 100) / success.quota;
                  this.apiproductscreditUsages += this.apiproducts[j].creditUsages;
                  this.apiproducts[j].creditUsages = this.apiproducts[j].creditUsages.toFixed(2);
                }
              }
            }
            this.apiproductscreditUsages = this.apiproductscreditUsages.toFixed(2);
            this.filterCreditUsageTable();
          },
          error => {
            if (error.error.error == 'Cookie tempered') {
              window.location.href = '/signout';
            }
          }
        );
      },
      error => {
        if (error.error.error == 'Cookie tempered') {
          window.location.href = '/signout';
        }
      }
    );
  };
  filterCreditUsageTable = function () {
    this.filteredapiproducts = [];
    if (this.usageproduct == 'all') {
      this.filteredapiproducts = [...this.apiproducts];
      this.sortUsageTable(false);
    }
    else {
      for (let j = 0; j < this.apiproducts.length; j++) {
        if (this.usageproduct == this.apiproducts[j].productID) {
          this.filteredapiproducts[0] = this.apiproducts[j];
        }
      }
    }
  };
  addDisplayFormatDateKey = () => {
    this.chartSettings.scales.xAxes.time.displayFormats = {};
    this.chartSettings.scales.xAxes.time.displayFormats["millisecond"] = "dddd Do";
    this.chartSettings.scales.xAxes.time.displayFormats["second"] = "dddd Do";
    this.chartSettings.scales.xAxes.time.displayFormats["minute"] = "dddd Do";
    this.chartSettings.scales.xAxes.time.displayFormats["hour"] = "dddd Do";
    this.chartSettings.scales.xAxes.time.displayFormats["day"] = "dddd Do";
    this.chartSettings.scales.xAxes.time.displayFormats["week"] = "dddd Do";
    this.chartSettings.scales.xAxes.time.displayFormats["month"] = "dddd Do";
    this.chartSettings.scales.xAxes.time.displayFormats["quarter"] = "dddd Do";
    this.chartSettings.scales.xAxes.time.displayFormats["year"] = "dddd Do";

  }
  deleteDisplayFormatDateKey = () => {
    delete this.chartSettings.scales.xAxes.time.displayFormats;
  }
  updateApiCallChart = function () {
    this.ischartloader = true;
    this.areachart = false;
    this.linechart = false;
    this.bargroupchart = false;
    let timeUnit = this.apicalltimerange;
    let datesArr = [];
    let fromDate = new Date().getTime();
    this.areachartdata = {
      labels: [],
      datasets: []
    }
      ;
    if (timeUnit == 'minute') {
      fromDate -= 86400000;
      let coeff = 1000 * 60 * 60;
      fromDate = Math.round(fromDate / coeff) * coeff;
      this.deleteDisplayFormatDateKey();
      let tmpDateArr = new Date();
      let startTimeArr = tmpDateArr.getTime() - (tmpDateArr.getMinutes() * 60 * 1000) - (tmpDateArr.getSeconds() * 1000) - tmpDateArr.getMilliseconds();
      this.chartSettings.scales.xAxes.time.tooltipFormat = "MMMM Do - h a";
      this.chartSettings.scales.xAxes.ticks.maxTicksLimit = 24;
      for (let i = 0; i < 24; i++) {
        datesArr.push(startTimeArr - (i * 3600000));
      }
    } else if (timeUnit == 'week') {
      fromDate -= 86400000 * 6;
      this.addDisplayFormatDateKey();
      this.chartSettings.scales.xAxes.ticks.maxTicksLimit = 6;
      let tmpDateArr = new Date();
      tmpDateArr.setUTCHours(0, 0, 0, 0);
      let startTimeArr = tmpDateArr.getTime();
      this.chartSettings.scales.xAxes.time.tooltipFormat = "dddd Do";
      for (let i = 0; i < 7; i++) {
        datesArr.push(startTimeArr - (i * 86400000));
      }
    } else if (timeUnit == 'month') {
      fromDate -= 86400000 * 30;
      this.deleteDisplayFormatDateKey();
      this.chartSettings.scales.xAxes.ticks.maxTicksLimit = 12;
      let tmpDateArr = new Date();
      tmpDateArr.setUTCHours(0, 0, 0, 0);
      let startTimeArr = tmpDateArr.getTime();
      this.chartSettings.scales.xAxes.time.tooltipFormat = "MMMM Do";
      for (let i = 0; i < 30; i++) {
        datesArr.push(startTimeArr - (i * 86400000));
      }
    }
    datesArr.sort();
    if (typeof this.userInfo.subscriptions.lbs !== 'undefined' && this.userInfo.subscriptions.lbs.startDate > fromDate) {
      fromDate = this.userInfo.subscriptions.lbs.startDate;
    }
    let apitrafPayload = {
      usageFromDate: fromDate,
      userId: this.userInfo.email,
      timeUnit: timeUnit,
      product: 'lbs',
      filterList: this.filterList
    };
    this.userService.apiTraffic(apitrafPayload).subscribe(
      success => {
        this.ischartloader = false;
        if (success.dimensions.length != 0) {
          this.isNodata = false;
          let seriesArr = [];
          for (let i = 0; i < success.dimensions.length; i++) {
            seriesArr[i] = { label: "", borderColor: "", backgroundColor: "", tension: 0.4, fill: true };
            for (let l = 0; l < this.apiproducts.length; l++) {
              if (success.dimensions[i].name == this.apiproducts[l].productID) {
                seriesArr[i].label = this.apiproducts[l].productName;
                seriesArr[i].borderColor = this.chartColors[i];
                seriesArr[i].backgroundColor = transparentize(this.chartColors[i], 0.5);
              }
            }
          }
          let dataLine = {}
          dataLine['labels'] = [];
          dataLine['datasets'] = [];
          for (let i = 0; i < seriesArr.length; i++) {
            seriesArr[i].data = new Array(datesArr.length).fill(0);
          }
          for (let i = 0; i < datesArr.length; i++) {
            dataLine['labels'][i] = (new Date(datesArr[i])).toISOString()
          }
          for (let i = 0; i < success.dimensions.length; i++) {
            for (var j = 0; j < success.dimensions[i].metrics.length; j++) {
              if (success.dimensions[i].metrics[j].name == 'sum(message_count)') {
                for (var k = 0; k < success.dimensions[i].metrics[j].values.length; k++) {
                  if (datesArr.includes(success.dimensions[i].metrics[j].values[k].timestamp)) {
                    let dateIndex = datesArr.indexOf(success.dimensions[i].metrics[j].values[k].timestamp);
                    seriesArr[i].data[dateIndex] += parseFloat(success.dimensions[i].metrics[j].values[k].value);
                  }
                  else {
                    if (timeUnit == 'minute') {
                      let currTimestamp = success.dimensions[i].metrics[j].values[k].timestamp;
                      for (let x = datesArr.length - 1; x >= 0; x--) {
                        if (datesArr[x] < currTimestamp) {
                          seriesArr[i].data[x] += parseFloat(success.dimensions[i].metrics[j].values[k].value);
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          seriesArr.sort(this.compareseriesArr);
          dataLine['datasets'] = seriesArr;
          this.areachartdata = dataLine;
          let dataBarGrp = [];
          for (let i = 0; i < dataLine['labels'].length; i++) {
            let tmpbargrpobj = { key: dataLine['labels'][i] };
            for (let j = 0; j < dataLine['datasets'].length; j++) {
              tmpbargrpobj[dataLine['datasets'][j].label] = dataLine['datasets'][j].data[i];
            }
            dataBarGrp.push(tmpbargrpobj);
          }
          this.bargroupchartdata = dataBarGrp;
          this.switchApiCallChart();

        }
        else {
          this.isNodata = true;
        }
      },
      error => {
        if (error.error.error == 'Cookie tempered') {
          window.location.href = '/signout';
        }
      }
    );
  };
  switchApiCallChart = function () {
    if (this.apicallcharttype == 'area') {
      this.areachart = true;
      this.linechart = false;
      this.bargroupchart = false;
      this.chartSettings["interaction"] = {};
      this.chartSettings["interaction"]["intersect"] = "index";
      this.chartSettings["interaction"]["mode"] = "index";
      for (let index = 0; index < this.areachartdata.datasets.length; index++) {
        this.areachartdata.datasets[index].fill = true;
      }
    }
    if (this.apicallcharttype == 'line') {
      this.areachart = false;
      this.linechart = true;
      this.bargroupchart = false;
      this.chartSettings["interaction"] = {};
      this.chartSettings["interaction"]["intersect"] = "index";
      this.chartSettings["interaction"]["mode"] = "index";
      for (let index = 0; index < this.areachartdata.datasets.length; index++) {
        this.areachartdata.datasets[index].fill = false;
      }
    }
    if (this.apicallcharttype == 'bargroup') {
      this.areachart = false;
      this.linechart = false;
      this.bargroupchart = true;
      delete this.chartSettings.interaction;

    }
  };
  compareseriesArr = function (a, b) {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    let comparison = 0;
    if (labelA > labelB) {
      comparison = 1;
    } else if (labelA < labelB) {
      comparison = -1;
    }
    return comparison;
  };
  sortUsageTable = function (toggleOpt) {
    if (this.isAscending) {
      if (toggleOpt) {
        this.isAscending = false;
        this.filteredapiproducts.sort(this.compareRevApiProducts);
      }
      else {
        this.filteredapiproducts.sort(this.compareApiProducts);
      }
    }
    else {
      if (toggleOpt) {
        this.isAscending = true;
        this.filteredapiproducts.sort(this.compareApiProducts);
      }
      else {
        this.filteredapiproducts.sort(this.compareRevApiProducts);
      }
    }
  };
  compareRevApiProducts = function (a, b) {
    const productNameA = a.productName.toUpperCase();
    const productNameB = b.productName.toUpperCase();
    let comparison = 0;
    if (productNameA < productNameB) {
      comparison = 1;
    } else if (productNameA > productNameB) {
      comparison = -1;
    }
    return comparison;
  };
  loadCountryMap = function () {
    this.resourceService.getCountries().subscribe(
      success => {
        this.countryMap = success;
      }
    );
  };
  loadStateMap = function (countryCode) {
    this.resourceService.getStates(countryCode).subscribe(
      success => {
        this.stateMap = success.states;
        this.stateName = this.stateprovince;
      }
    );
  };
  getUserAccountDetails = function () {
    this.userService.getUserAccountDetailsById().subscribe(
      success => {
        if (success.data[0].billing_info == null) {
          if (success.data[0].has_past_due_invoice) {
            this.isNeToUpIm = true;
          }
          this.loadStateMap(this.countryCode);
          this.showPaymentModal = true;
        }
      },
      error => {
        if (error.error.error == 'Cookie tempered') {
          window.location.href = '/signout';
        }
      }
    );
  };
  openPaymentForm = function () {
    this.isPaymentFormOpen = true;
    $('#recurly-form').show();
    let cardEleHtml = $('#recurly-elements').html();
    if (cardEleHtml == '') {
      recurly.configure({
        publicKey: localStorage.getItem("recurlyConfig"),
        required: ['cvv']
      });
      const elements = recurly.Elements();
      const cardElement = elements.CardElement({
        inputType: 'mobileSelect',
        style: {
          fontSize: '1em',
          padding: '10px 12px',
          height: '40px',
          placeholder: {
            color: 'gray !important',
            fontWeight: 'bold',
            content: {
              number: 'Card number',
              cvv: 'CVV'
            }
          },
          invalid: {
            fontColor: 'red'
          }
        }
      });
      cardElement.attach('#recurly-elements');
      let fields = {
        'first_name': 'First Name',
        'last_name': 'Last Name',
        'address1': 'Address',
        'address2': 'Address 2',
        'city': 'City',
        'state': 'State',
        'postal_code': 'Postal Code',
        'number': 'Credit Card Number',
        'month': 'Credit Card Month',
        'year': 'Credit Card Year',
        'cvv': 'CVV'
      };
      let that = this;
      document.querySelector('#recurly-form').addEventListener('submit', function (event) {
        const form = this;
        event.preventDefault();
        if (that.confirmAddrRadio == null && that.countryCode == 'US') {
          that.paymentErrMsg = 'Please select a corrected address.';
          that.isPaymentErr = true;
          document.getElementById("submitPaymentBtn").removeAttribute("disabled");
          document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
          return false;
        }
        document.getElementById("submitPaymentBtn").setAttribute("disabled", "disabled");
        document.getElementById("reEnterAddrBtn").setAttribute("disabled", "disabled");
        that.paymentErrMsg = '';
        that.isPaymentErr = false;
        recurly.token(elements, form, function (err, token) {
          if (err) {
            let failedMsgArr = [];
            //if(err.message === "There was an error validating your request." || err.message === "Cvv must be three digits"){​
            if (err.name === 'api-error' || err.name === 'validation') {
              for (let i = 0; i < err.details.length; i++) {
                failedMsgArr.push(`${fields[err.details[i].field]}​ ${err.details[i].messages[0]}​`);
              }
            }
            else {
              failedMsgArr.push(err.message);
            }
            for (let i = 0; i < failedMsgArr.length; i++) {
              if (i !== 0) {
                if (i === failedMsgArr.length - 1) {
                  that.paymentErrMsg += ' and ';
                }
                else {
                  that.paymentErrMsg += ', ';
                }
              }
              that.paymentErrMsg += failedMsgArr[i];
            }
            that.paymentErrMsg += '.';
            that.isPaymentErr = true;
            document.getElementById("submitPaymentBtn").removeAttribute("disabled");
            document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
          }
          else {
            that.userService.updatePaymentInfo(token.id).subscribe(
              success => {
                that.isPaymentSuccess = true;
                that.isPaymentErr = false;
                setTimeout(() => {
                  document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
                  that.showPaymentModal = false;
                }, 3000);
              },
              error => {
                if (error.error.error == 'Cookie tempered') {
                  window.location.href = '/signout';
                  return;
                }
                document.getElementById("submitPaymentBtn").removeAttribute("disabled");
                document.getElementById("reEnterAddrBtn").removeAttribute("disabled");
                that.paymentErrMsg = 'Failed to update. Please try again.';
                if (error.error) {
                  that.paymentErrMsg = error.error.error.message;
                }
                that.isPaymentErr = true;
              }
            );
          }
        });
      });
    }
  };
  closePaymentForm = function () {
    this.isPaymentFormOpen = false;
    this.isPaymentErr = false;
    this.isPaymentSuccess = false;
    $('#recurly-form').hide();
    this.showConfirmAddr = false;
    this.confirmAddrRadio = null;
    $('#validateAddressBtn').show();
    $('#cancelPaymentBtn').show();
    $('#submitPaymentBtn').hide();
    $('#reEnterAddrBtn').hide();
  };
  validateAddress = function () {
    let errArr = [];
    if (this.firstName == '') {
      errArr.push("First Name​ can't be blank​");
    }
    if (this.lastName == '') {
      errArr.push("Last Name​ can't be blank​");
    }
    if (this.addressLine1 == '') {
      errArr.push("Address​ can't be blank​");
    }
    if (this.postalCode == '') {
      errArr.push("Postal Code can't be blank​");
    }
    else {
      let usZipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/; //regex for USA zip only
      if (!usZipRegex.test(this.postalCode)) {
        errArr.push("Postal Code​ is invalid​​");
      }
    }
    if (this.city == '') {
      errArr.push("City​ can't be blank​");
    }
    if (errArr.length) {
      this.showConfirmAddr = false;
      this.paymentErrMsg = "";
      for (let i = 0; i < errArr.length; i++) {
        if (i !== 0) {
          if (i === errArr.length - 1) {
            this.paymentErrMsg += ' and ';
          }
          else {
            this.paymentErrMsg += ', ';
          }
        }
        this.paymentErrMsg += errArr[i];
      }
      this.paymentErrMsg += "."
      this.isPaymentErr = true;
    }
    else {
      this.isPaymentErr = false;
      this.showConfirmAddr = false;
      document.getElementById("validateAddressBtn").setAttribute("disabled", "disabled");
      document.getElementById("cancelPaymentBtn").setAttribute("disabled", "disabled");
      let addrPayload = {
        "AddressLine1": this.addressLine1,
        "AddressLine2": this.addressLine2,
        "City": this.city,
        "Country": "USA",
        "StateProvince": this.stateprovince,
        "PostalCode": this.postalCode
      };
      this.userService.verifyAddress(addrPayload).subscribe(
        success => {
          if (success.Output.length > 0) {
            if (success.Output.length == 1) {
              let addConfidence = success.Output[0].Confidence;
              if (addConfidence < 80) {
                this.paymentErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
                this.isPaymentErr = true;
                document.getElementById("validateAddressBtn").removeAttribute("disabled");
                document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
              }
              else {
                $('#validateAddressBtn').hide();
                $('#cancelPaymentBtn').hide();
                $('#submitPaymentBtn').show();
                $('#reEnterAddrBtn').show();
                document.getElementById("validateAddressBtn").removeAttribute("disabled");
                document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
                this.verifiedAddrRes = success.Output;
                this.showConfirmAddr = true;
              }
            }
            else {
              $('#validateAddressBtn').hide();
              $('#cancelPaymentBtn').hide();
              $('#submitPaymentBtn').show();
              $('#reEnterAddrBtn').show();
              document.getElementById("validateAddressBtn").removeAttribute("disabled");
              document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
              this.verifiedAddrRes = success.Output;
              this.showConfirmAddr = true;
            }
          }
          else {
            this.paymentErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
            this.isPaymentErr = true;
            document.getElementById("validateAddressBtn").removeAttribute("disabled");
            document.getElementById("cancelPaymentBtn").removeAttribute("disabled");
          }
        }
      );
    }
  };
  selectConfirmedAddress = function () {
    let address = this.verifiedAddrRes[this.confirmAddrRadio];
    this.addressLine1 = address.AddressLine1;
    this.addressLine2 = address.AddressLine2;
    this.city = address.City;
    this.stateprovince = this.usStateMap[address.StateProvince];
    this.postalCode = address.PostalCode;
  };
  resetAddressFields = function () {
    if (this.countryCode == 'US') {
      this.confirmAddrRadio = null;
      $('#validateAddressBtn').show();
      $('#cancelPaymentBtn').show();
      $('#submitPaymentBtn').hide();
      $('#reEnterAddrBtn').hide();
    }
  };
  resetCountry = function () {
    if (this.countryCode == 'US') {
      this.resetAddressFields();
    }
    else {
      this.showConfirmAddr = false;
      this.confirmAddrRadio = null;
      $('#validateAddressBtn').hide();
      $('#cancelPaymentBtn').hide();
      $('#submitPaymentBtn').show();
      $('#reEnterAddrBtn').show();
    }
    this.loadStateMap(this.countryCode);
  };
  valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.value.localeCompare(b.value);
  };
}
