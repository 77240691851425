import { Component, OnInit, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../service/user.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';

@Component({
  selector: 'home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {
  public docsURL: any = '';
  public showpage:boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private resourceService: ResourceService) {
  };
  ngAfterViewInit() {
    let twenContainer = $('.twentytwenty-container').html();
    if(twenContainer){
      let initPos = $('.twentytwenty-container').css('width');
      $('.twentytwenty-handle').css('left', initPos);
      $('.twentytwenty-before').css('clip', 'rect(0px, '+initPos+', 240px, 0px)');
      $('.twentytwenty-after').css('clip', 'rect(0px, '+initPos+', 240px, '+initPos+')');
    }
  };
  ngOnInit() {
    window.scroll(0,0);
    this.userService.getUserDeatails().subscribe(
        success => {
            if(this.activatedRoute.snapshot.url.length === 0){
                window.location.href = '/software-apis/dashboard';
            }
        },
        error => {
            this.showpage = true;
        }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.docsURL = success.docsURL + "index.html";
        }
      );
    }
    else{
      this.docsURL = docsURL + "index.html";
    }
  };
  handlemousemove = function(e){
    if($('.twentytwenty-container').hasClass('active')){
      $('.twentytwenty-handle').offset({left: e.clientX - 21});
      let updatedPos = $('.twentytwenty-handle').css('left');
      let initPos = $('.twentytwenty-container').css('width');
      $('.twentytwenty-before').css('clip', 'rect(0px, '+updatedPos+', 240px, 0px)');
      $('.twentytwenty-after').css('clip', 'rect(0px, '+initPos+', 240px, '+updatedPos+')');
    }
  };
  handlemousedown = function(){
    $('.twentytwenty-container').addClass('active');
  };
  handlemouseup = function(){
    $('.twentytwenty-container').removeClass('active');
  };
}
