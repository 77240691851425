import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ResourceService } from '../../service/resource.service';
import { UserService } from '../../service/user.service';
declare var require: any;


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    public displayName: string = '';
    public displayEmail: string = '';
    public showSignInLink: boolean = true;
    public gitHubURL: any = '';
    public docsURL: any = '';
    public sidebarVisible: boolean = false;
    constructor(private userService: UserService, private resourceService: ResourceService) { };

    ngOnInit() {
        let userDetails = null;
        localStorage.setItem("clientIdentifier", "unauth");
        this.userService.getUserDeatails().subscribe(
            success => {
                userDetails = success;
                this.showSignInLink = false;
                this.displayName = userDetails.firstName + " " + userDetails.lastName;
                this.displayEmail = userDetails.email;
                localStorage.setItem("clientIdentifier", userDetails.email);
            },
            error => {
                this.displayName = 'Sign In';
            }
        );
        let gitHubURL = JSON.parse(`[{"val": "${localStorage.getItem("gitHubURL")}"}]`)[0].val;
        if(gitHubURL == "null"){
            this.resourceService.getLiGitHubUrl().subscribe(
                success => {
                    this.gitHubURL = success.ligithubURL;
                    localStorage.setItem("gitHubURL", success.ligithubURL);
                }
            );
        }
        else{
            this.gitHubURL = gitHubURL;
        }
        let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
        if(docsURL == "null" || docsURL.includes("index.html")){
            this.resourceService.getDocsUrl().subscribe(
                success => {
                    this.docsURL = success.docsURL + "index.html";
                    localStorage.setItem("docsURL", success.docsURL);
                }
            );
        }
        else{
            this.docsURL = docsURL + "index.html";
        }
    };
}
