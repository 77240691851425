import { Component, OnInit } from "@angular/core";
import { LiapiService } from '../../service/liapi.service';
import { catchError, debounceTime, distinctUntilChanged, Observable, of, switchMap } from "rxjs";
import { v4 as uuidv4 } from 'uuid';
@Component({
    selector: 'address-autocomplete-demoA',
    templateUrl: './address-autocomplete-demoA.html',
    styleUrls: ['./address-autocomplete-demoA.css']
})
export class AddressAutocompleteComponentDemoA implements OnInit {
    public searchText: string = "";
    public _showResults: boolean = false;
    public loading: boolean = false;
    public results : any = {};
    public resultLength!: number;
    public selectedIndex: number = -1;
    public requestObj: any = null;
    public transactionId: string = "";
    public generateNewTransactionID: boolean = true;
    public minLength: number = 3;
    public maxCandidates: number = 10
    public postCode: string = "";
    public city : string = "";
    public state : string = "";
    public postalVerified : string = "";
    public countryInfo = {"name":"", "code": "", "label":""};
	public matchOnAddressNumber: string = 'false';
    public searchOnAddressNumber: string = 'N';
    public returnAdminAreasOnly: string = 'N';
    public isPostCodeEmpty: boolean = false;
    public isStateEmpty : boolean = false;
    public filteredCountries: any[];
    public countries: any[];
    constructor(private searchLocationsTmp: LiapiService){}
    ngOnInit(){

        this.getCountryFromIp();
        this.searchLocationsTmp.getTypeaheadCountries().subscribe(
          success => {
            this.countries = success;
          }
        );
    }

    onQueryUpdate =  () => {
        if(this.generateNewTransactionID){
            this.transactionId = uuidv4();
            this.generateNewTransactionID = false;
        }
        let requestData = new Map();
        requestData.set("maxCandidates", this.maxCandidates);
        requestData.set("country", this.countryInfo.code);
        requestData.set("searchOnAddressNumber", this.searchOnAddressNumber);
        requestData.set("returnAdminAreasOnly", this.returnAdminAreasOnly);
        if(this.searchText !== "" && this.searchText.length >= this.minLength) {
            requestData.set('searchText', this.searchText)
            requestData.set('transactionId', this.transactionId);
            requestData.set('clientIdentifier', "ECommerceDemo")
            this.loading = true;
            if(this.requestObj != null){
                this.requestObj.unsubscribe();
            }
            this.requestObj = this.searchLocationsTmp.getAddressAutocompleteResponse(requestData).subscribe(
                (success :any) => {
                    this.loading = false;
                    if (success.location) {
                        if (success.location.length != 0) {
                            this.results = success;
                            this.resultLength = success.location.length;
                            this._showResults = true;
                            this.selectedIndex = 0;
                        }
                        else {
                            this._showResults = false;
                        }
                    }
                    else {
                        this._showResults = false;
                    }
                },
                error => {
                    this.loading = false
                    this._showResults = false;
                    if(error.status == '429'){
                        this.searchLocationsTmp.showPrimeInfo();
                    }
                }
            );

        }
        if (this.searchText.length < this.minLength) {
            this._showResults = false;
        }
    };
    onResultClicked =  (result : any) => {
        if (result.totalUnitCount > 1) {
            this.results.location = [];
            let locArr :any= [];
            let addrObj = result.address;
            let geometryObj = result.geometry;
            let arrCount = 0;
            for (let i = 0; i < result.ranges.length; i++) {
                for (let j = 0; j < result.ranges[i].units.length; j++) {
                    locArr[arrCount] = {};
                    locArr[arrCount].totalUnitCount = 1;
                    locArr[arrCount].geometry = Object.assign({}, geometryObj);
                    locArr[arrCount].address = Object.assign({}, addrObj);
                    locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                    if(result.ranges[i].units[j].postalVerified !=null){
                        locArr[arrCount].address.postalVerified = result.ranges[i].units[j].postalVerified;
                    }
                    var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                    locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                    arrCount++;
                }
            }
            this.results.location = locArr;
        }
        else {
            if (result.address.postalVerified != null) {
                this.postalVerified = result.address.postalVerified;
            }
            else if (result.ranges != null && result.ranges.length == 1 && result.ranges[0].units != null && result.ranges[0].units.length == 1 &&
                result.ranges[0].units[0].postalVerified != null) {
                this.postalVerified = result.ranges[0].units[0].postalVerified;
            }
            else {
                this.postalVerified = "Not Verfied";
            }
            this.searchText = result.address.mainAddressLine;
            if(result.address.postCode !=undefined && result.address.postCode != ""){
                this.postCode = result.address.postCode;
            }
            else{
                this.isPostCodeEmpty = true;
            }
            if(result.address.areaName3 !=undefined){
                this.city = result.address.areaName3;
            }
            if(result.address.areaName1 !=undefined && result.address.areaName1 != ""){
                this.state = result.address.areaName1;
            }
            else{
                this.isStateEmpty = true;
            }
            this._showResults = false;
            let requestData = new Map();
            requestData.set('selectedAddress', result.address.formattedAddress);
            requestData.set('country', this.countryInfo.code);
            requestData.set('transactionId', this.transactionId);
            this.searchLocationsTmp.selectAddress(requestData).subscribe((success) =>{
                this.transactionId = "";
                this.generateNewTransactionID = true;
            });
        }
    };
    onKeyDown =  (event:any) => {
        let elememt = document.getElementById('geoitemcontainer')
        if (elememt) {
            if (event.keyCode === 38) {
                event.preventDefault();
                var indexval = this.selectedIndex;
                if (this.selectedIndex > 1) {
                    if (this.addCSS(this.selectedIndex - 1)) {
                        this.selectedIndex = this.selectedIndex - 1;
                        this.removeCSS(indexval);
                        elememt.scrollTop -= 40;
                    }
                }
            }
            if (event.keyCode === 40) {
                event.preventDefault();
                var indexval = this.selectedIndex;
                if (this.selectedIndex < this.resultLength) {
                    if (this.addCSS(this.selectedIndex + 1)) {
                        this.selectedIndex = this.selectedIndex + 1;
                        this.removeCSS(indexval);
                        if (this.selectedIndex > 1) {
                            elememt.scrollTop += 40;
                        }
                    }
                }
            }
            if (event.keyCode === 13) {
                event.preventDefault();
                elememt.scrollTop = 0;
                var indexval = this.selectedIndex;
                if (indexval > 0) {
                    var result = this.results.location[indexval - 1];
                    if (result.totalUnitCount > 1) {
                        this.results.location = [];
                        var locArr :any= [];
                        var addrObj = result.address;
                        var geometryObj = result.geometry;
                        var arrCount = 0;
                        this.resultLength = result.totalUnitCount;
                        for (var i = 0; i < result.ranges.length; i++) {
                            for (var j = 0; j < result.ranges[i].units.length; j++) {
                                locArr[arrCount] = {};
                                locArr[arrCount].totalUnitCount = 1;
                                locArr[arrCount].address = Object.assign({}, addrObj);
                                locArr[arrCount].geometry = Object.assign({}, geometryObj);
                                locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                                var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                                locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                                this.removeCSS(this.selectedIndex);
                                this.selectedIndex = 0;
                                arrCount++;
                            }
                        }
                        this.results.location = locArr;
                    }
                    else {
                        this.removeCSS(this.selectedIndex);
                        this.selectedIndex = 0;
                        this.searchText = result.address.formattedAddress;
                        this._showResults = false;
                    }
                }
            }
            if (event.keyCode === 27) {
                this.removeCSS(this.selectedIndex);
                this.selectedIndex = 0;
                this._showResults = false;
                event.preventDefault();
            }
        }
    };
    removeCSS =  (index:any) => {
        if (index > 0) {
            var elementId = 'geoitem' + index;
            let element = document.getElementById(elementId);
            if(element != null){
                element.className = "pb-geo-item";
            }

        }
    };
    addCSS =  (index:any) => {
        var returnval = false;
        if (index > 0) {
            var elementId = 'geoitem' + index;
            let element = document.getElementById(elementId);
            if(element != null){
                element.className = "pb-geo-item pb-geo-item-selected";
            }
            return true;
        }
        return returnval;
    };

    filterCountry = function (event) {
      let filtered: any[] = [];
      let query = event.query;
      for (let i = 0; i < this.countries.length; i++) {
        let country = this.countries[i];
        if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
          filtered.push(country);
        }
      }
      this.filteredCountries = filtered;
    };

    getCountryFromIp = ()=>{
        this.searchLocationsTmp.geoApiSampleDemoGetCall('/getCountryFromIPAddress', {}).subscribe({
            next: (success) =>{
                let countryCode = success.ipInfo.place.country.code;
                this.searchForCountry(countryCode);
            },
            error: (error) =>{
                this.countryInfo = {
                    "name": "UNITED STATES OF AMERICA",
                    "code": "USA",
                    "label": "UNITED STATES OF AMERICA (USA)"
                  };
            }
        })

    }
    checkForCountry = ($event)=>{
        if($event.code === "GBR"){
            this.isStateEmpty = true;
        }
        else if($event.code!= undefined){
            this.isStateEmpty = false;
        }
    }
    searchForCountry = (countryCode: string) => {
        this.searchLocationsTmp.searchTypeaheadCountry(countryCode).subscribe({
            next: (success) =>{
                for(let countryInfo of success){
                    if(countryCode === countryInfo.code){
                        this.countryInfo =countryInfo;
                        break;
                    }
                }
            }
        })
    }

    clearFields = ()=>{
        this.searchText = "";
        this.city = "";
        this.state = "";
        this.postCode = "";
        this._showResults = false;
        this.isStateEmpty = false;
        this.isPostCodeEmpty = false;
        this.postalVerified = "";
        this.getCountryFromIp();

    }
}
