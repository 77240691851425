import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'autocomplete-demo',
  templateUrl: './autocompletedemo.component.html',
  styleUrls:['./autocompleteapi.component.css']
})
export class AutocompleteDemoComponent implements OnInit {
  public activetab = 'typeahead';
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { };
  ngOnInit() {};
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
  };
}
