import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResourceService } from '../service/resource.service';
import * as L from "leaflet";
declare const Tangram: any;

@Component({
  selector: 'maps-demo',
  templateUrl: './mapsdemo.component.html'
})
export class MapsDemoComponent implements OnInit {
  public maptype = 'raster';
  public mapthemeRadio = 'bronze';
  public mapFullURL = '';
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private resourceService: ResourceService) { };
  ngOnInit() {
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.mapFullURL = mapFullURL;
    this.initializeMapOptions();
  };
  private initializeMapOptions() {
    this.mapOptions = {
      center: L.latLng(40.780541431860314, -74.00390625),
      zoom: 12
    };
    this.mapLayers = [L.tileLayer(this.mapFullURL + this.mapthemeRadio, { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
  };
  setmaptype = function (maptype) {
    this.maptype = maptype;
    if (this.maptype == 'raster') {
      this.mapLayers = [L.tileLayer(this.mapFullURL + this.mapthemeRadio, { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
    }
    else {
      var mvtURL = 'yaml';
      let mvtEnv = localStorage.getItem("mvtEnv");
      if(!mvtEnv){
        this.resourceService.getMvtEnv().subscribe(
          success => {
            localStorage.setItem("mvtEnv", success.mvtEnv);
            this.loadMvtMap(success.mvtEnv, mvtURL);
          }
        );
      }
      else{
        this.loadMvtMap(mvtEnv, mvtURL);
      }
    }
  };
  loadTheme = function () {
    if (this.maptype == 'raster') {
      this.mapLayers = [L.tileLayer(this.mapFullURL + this.mapthemeRadio, { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
    }
    else {
      var mvtURL = 'yaml';
      let mvtEnv = localStorage.getItem("mvtEnv");
      if(!mvtEnv){
        this.resourceService.getMvtEnv().subscribe(
          success => {
            localStorage.setItem("mvtEnv", success.mvtEnv);
            this.loadMvtMap(success.mvtEnv, mvtURL);
          }
        );
      }
      else{
        this.loadMvtMap(mvtEnv, mvtURL);
      }
    }
  };
  loadMvtMap = function(mvtEnv, mvtURL){
    if (mvtEnv == "qa") {
      mvtURL += '/qa';
    }
    mvtURL += '/' + this.mapthemeRadio + '.yaml';
    this.mapLayers = [Tangram.leafletLayer({ scene: mvtURL })];
  };
  sampleDemoGeoMapShareLocation = function () {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        var center = L.latLng(latitude, longitude);
        that.map.panTo(center);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
}
