import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';

@Component({
  selector: 'neighborhoods-demo',
  templateUrl: './neighborhoodsdemo.component.html'
})
export class NeighborhoodsDemoComponent implements OnInit {
  public latitude = "";
  public longitude = "";
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService) { };
  ngOnInit() {
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
    var that = this;
    this.map.on('click', (e: L.LeafletMouseEvent) => {
        //$('#neiLatitude').val(e.latlng.lat);
        //$('#neiLongitude').val(e.latlng.lng);
        that.latitude = e.latlng.lat.toString();
        that.longitude = e.latlng.lng.toString();
        that.neighborhoodsSearch();
    });
  };
  sampleDemoGeoMapShareLocation = function () {
    var that = this;
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            that.latitude = position.coords.latitude;
            that.longitude = position.coords.longitude;
            that.neighborhoodsSearch();
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  neighborhoodsSearch = function () {
    $('#neighborhoodsError').hide();
    $('#neighborhoodsResult').html('');
    if (this.newMarker != undefined) {
        this.map.removeLayer(this.newMarker);
    };
    var lat = this.latitude;
    var lng = this.longitude;
    if (lat != '' && lng != '' && !isNaN(lat) && !isNaN(lng)) {
        $('.geoApisDemoMapLoader').show();
        this.map.setView(L.latLng(lat, lng), 14);
        this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
        var tmpData = {
            latitude: lat,
            longitude: lng,
            dataType: 'json'
        };
        this.liapiservice.geoApiSampleDemoGetCall('/tryApiGeoEnrichPlaceByLoc', tmpData).subscribe(
            success => {
                if (success.location) {
                    var tmpHtml = '<h2 class="zeromargintop">Place Details</h2>';
                    tmpHtml += '<table class="table table-condensed table-unstyled">';
                    tmpHtml += '<tr><td><div class="resultKey">Level</div><div class="resultVal">' + success.location[0].place.levelName + '</div></td>';
                    tmpHtml += '<td><div class="resultKey">Name</div><div class="resultVal">' + success.location[0].place.name[0].value + '</div></td></tr>';
                    tmpHtml += '</table>';
                    $('#neighborhoodsResult').html(tmpHtml);
                }
                else {
                    $('#neighborhoodsError').html('No information available for this location.');
                    $('#neighborhoodsError').show();
                }
                $('.geoApisDemoMapLoader').hide();
            },
            error => {
              if (error.status == 401) {
                window.location.href = "/signin";
              }
              if (error.error) {
                $('#neighborhoodsError').html(error.error.errors[0].errorDescription);
              }
              else {
                $('#neighborhoodsError').html(error.statusText);
              }
              $('#neighborhoodsError').show();
              $('.geoApisDemoMapLoader').hide();
              if (error.status == '429') {
                this.liapiservice.showPrimeInfo();
                $('#neighborhoodsError').hide();
              }
            }
        );
    }
    else{
        $('#neighborhoodsError').html('Latitude and Longitude cannot be blank and must be numbers.');
        $('#neighborhoodsError').show();
    }
  };
}
