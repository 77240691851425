import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'phoneverification-api',
  templateUrl: './phoneverificationapi.component.html'
})
export class PhoneVerificationApiComponent implements OnInit {
  public phoneVerificationParamLink: any = '';
  public validatePhoneJson = '{"Input": {"Row": [{"PhoneNumber": "+1(507)2523440","Country": "US"}, {"PhoneNumber": "+919650541511","Country": "IN"}]}}';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'phone number verification service, phone verification api, phone number verification api, phone verification, phone service provider, phone carrier, phone network, phone number checker' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.phoneVerificationParamLink = success.docsURL + 'PhoneVerification/query_param.html';
        }
      );
    }
    else {
      this.phoneVerificationParamLink = docsURL + 'PhoneVerification/query_param.html';
    }
    this.formatJsonInputs();
  };
  clearResult = function (result) {
    switch (result) {
      case 'post_validatePhoneRes':
        this.post_validatePhoneUri = "";
        this.post_validatePhoneRes = null;
        this.post_validatePhoneResStatus = "";
        this.post_validatePhoneResType = "";
        break;
    }
  };
  public phoneVerificationTryApiphoneNumber = '5594126044';
  public phoneVerificationTryApiinclueNetworkInfo = 'Y';
  public phoneVerificationTryApiDataType = 'json';
  public post_validatePhoneUri = "";
  public post_validatePhoneRes = null;
  public post_validatePhoneResStatus = "";
  public post_validatePhoneResType = "";
  tryApiPostValidatePhone = function (btnId) {
    var postData = this.validatePhoneJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiPostValidatePhone';
      let requestUri = this.liapiservice.getCompleteUriPost('phoneverification', 'Post Validate Phone Number');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_validatePhoneUri = requestUri;
          this.post_validatePhoneResType = 'json';
          if(this.post_validatePhoneResStatus.includes('429')){
            this.post_validatePhoneUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_validatePhoneResStatus = '200 success';
            this.post_validatePhoneRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_validatePhoneResStatus = `${error.status} ${error.statusText}`;
              this.post_validatePhoneRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  formatJsonInputs = function () {
    this.validatePhoneJson = JSON.stringify(JSON.parse(this.validatePhoneJson), undefined, 4);
  };
}
