import { Component, OnInit } from '@angular/core';
import $ from 'jquery';

@Component({
    selector: 'app-scroll',
    templateUrl: './scroll.component.html'
})
export class ScrollComponent implements OnInit {
    public showscrollbtn: boolean = false;
    constructor() { };
    ngOnInit() {
        let that = this;
        $(window).on("scroll", function () {
            if (this.pageYOffset >= 100) {
                that.showscrollbtn = true;
            }
            else {
                that.showscrollbtn = false;
            }
        });
    };
    scrollToTop = function () {
        window.scrollTo(0, 0);
    };
}
