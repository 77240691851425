import { Component, OnInit, Input } from '@angular/core';
import * as AppConfigs from '../../app.constants';


@Component({
    selector: 'api-description',
    templateUrl: './apidesc.component.html'
})
export class ApidescComponent implements OnInit {
    public apiDisplayName = "";
    public strongDesc = "";
    public apiDesc = "";
    public demoUrl = "";
    public methodUrl = AppConfigs.BASE_API_PATH;
    public endPointsTblArr:any = [];
    public sdkUrl = "";
    @Input() apiName: string;

    constructor() { };
    ngOnInit() {
        this.sdkUrl = "apis/"+this.apiName+"#sdksectionid";
        for (var i = 0; i < AppConfigs.apisMethodList.length; i++) {
            if (this.apiName == AppConfigs.apisMethodList[i].apiName) {
                this.strongDesc = AppConfigs.apisMethodList[i].apiStrongDesc;
                this.apiDesc = AppConfigs.apisMethodList[i].apiDesc;
                this.apiDisplayName = AppConfigs.apisMethodList[i].displayName;
                this.methodUrl += AppConfigs.apisMethodList[i].apiUrl;
                this.endPointsTblArr = AppConfigs.apisMethodList[i].methods;
            }
        }
        this.demoUrl = this.apiName;
    };
}
