import { Component, OnInit, Input } from '@angular/core';
import { ResourceService } from '../../service/resource.service';
import { Event } from '@angular/router';

@Component({
    selector: 'api-sdk',
    templateUrl: './sdk.component.html'
})
export class SdkComponent implements OnInit {
    public gitHubURL: any = "";
    public webHelpUrl: any = "";
    public downloadableURL: any = "";
    @Input() apiName: string;

    constructor(private resourceService: ResourceService) { };
    ngOnInit() {
        let gitHubURL = JSON.parse(`[{"val": "${localStorage.getItem("gitHubURL")}"}]`)[0].val;
        if(gitHubURL == "null"){
            this.resourceService.getLiGitHubUrl().subscribe(
                success => {
                    this.gitHubURL = success.ligithubURL;
                }
            );
        }
        else{
            this.gitHubURL = gitHubURL;
        }
        let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
        if(docsURL == "null"){
            this.resourceService.getDocsUrl().subscribe(
                success => {
                    this.webHelpUrl = success.docsURL;
                    this.setWebHelpUrl();
                }
            );
        }
        else{
            this.webHelpUrl = docsURL;
            this.setWebHelpUrl();
        }
        let downloadableURL = localStorage.getItem("downloadableURL");
        if(!downloadableURL || downloadableURL == "https://learnpages-assets-qa.s3.amazonaws.com/downloads/9.5/"){
            this.resourceService.getDownloadUrl().subscribe(
                success => {
                    this.downloadableURL = success.downloadableURL;
                    localStorage.setItem("downloadableURL", success.downloadableURL);
                }
            );
        }
        else{
            this.downloadableURL = downloadableURL;
        }
    };
    downloadFile = function (event, fileName) {
        event.preventDefault();
        let downloadUrl = this.downloadableURL + fileName;
        let a = document.createElement('A') as HTMLAnchorElement;
        a.href = decodeURI(encodeURI(downloadUrl));
        a.download = decodeURI(encodeURI(fileName));
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    setWebHelpUrl = function () {
        switch (this.apiName) {
            case "geocode":
                this.webHelpUrl += 'Geocode/geocode_intro.html';
                break;
            case "typeahead":
                this.webHelpUrl += 'AddressAutocomplete/addressautocomplete_intro.html';
                break;
            case "maps":
                this.webHelpUrl += 'Maps/maps_intro.html';
                break;
            case "zones":
                this.webHelpUrl += 'Zones/zones_intro.html';
                break;
            case "routing":
                this.webHelpUrl += 'Routing/routing_intro.html';
                break;
            case "streets":
                this.webHelpUrl += 'Streets/streets_intro.html';
                break;
            case "places":
                this.webHelpUrl += 'Places/places_intro.html';
                break;
            case "risks":
                this.webHelpUrl += 'Risks/risks_intro.html';
                break;
            case "911":
                this.webHelpUrl += '911/911_intro.html';
                break;
            case "demographics":
                this.webHelpUrl += 'Demographics/intro.html';
                break;
            case "localtax":
                this.webHelpUrl += 'LocalTax/localtax_intro.html';
                break;
            case "telecomm":
                this.webHelpUrl += 'TelecommInfo/telecomm_intro.html';
                break;
            case "timezone":
                this.webHelpUrl += 'TimeZone/timezone_intro.html';
                break;
            case "geolocation":
                this.webHelpUrl += 'Geolocation/geolocation_intro.html';
                break;
            case "schools":
                this.webHelpUrl += 'Schools/introduction.html';
                break;
            case "neighborhoods":
                this.webHelpUrl += 'Neighborhoods/places_intro.html';
                break;
            case "property":
                this.webHelpUrl += 'PropertyInformation/introduction.html';
                break;
            case 'addressverification':
                this.webHelpUrl += 'AddressVerification/Validate_Mailing_Address/AddressVerification_description.html';
                break;
            case 'emailverification':
                this.webHelpUrl += 'EmailVerification/validate_emailaddress/ValidateEmailAddress_intro.html';
                break;
            case 'addresses':
                this.webHelpUrl += 'Addresses/addresses.html';
                break;
            case 'phoneverification':
                this.webHelpUrl += 'PhoneVerificationv2/phoneverificationv2.html';
                break;
        }
    };
}
