import { Component, OnInit, Input } from '@angular/core';
import * as AppConfigs from '../../app.constants';

@Component({
    selector: 'app-demo-header',
    templateUrl: './demoheader.component.html'
})
export class DemoheaderComponent implements OnInit {
    public apiDisplayName = "";
    public sidebarVisible: boolean = false;
    @Input() apiName: string;

    constructor() { };
    ngOnInit() {
        for (var i = 0; i < AppConfigs.apisMethodList.length; i++) {
            if (this.apiName == AppConfigs.apisMethodList[i].apiName) {
                this.apiDisplayName = AppConfigs.apisMethodList[i].displayName;
            }
        }
    };
    toggleSidebar = function(){
        this.sidebarVisible = !this.sidebarVisible;
    }
}
