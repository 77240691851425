import { Component, OnInit } from "@angular/core";
import { LiapiService } from '../../service/liapi.service';
import { catchError, debounceTime, distinctUntilChanged, Observable, of, switchMap } from "rxjs";
import { v4 as uuidv4 } from 'uuid';
@Component({
    selector: 'address-autocomplete-demoD',
    templateUrl: './address-autocomplete-demoD.html',
    styleUrls: ['./address-autocomplete-demoD.css']
})
export class AddressAutocompleteComponentD implements OnInit {
    public minLength: number = 3;
    public maxCandidates: number = 10;
    public postCode: string = "";
    public city : string = "";
    public state : string = "";
    public county : string = "";
    public street : string = "";
    public building : string = "";
    public premiseNo : string = "";
    public subBuilding : string = "";
    public poBox : string = "";
    public requestObj: any = null;
    public results: any = {};
    public resultLength: number;
    public showResults: Map<String,Boolean>;
    public selectedIndex: number = -1;
    public postalVerified : string = "";
    public countryInfo = {"name":"", "code": "", "label":""};
	public matchOnAddressNumber: string = 'false';
    public searchOnAddressNumber: string = 'N';
    public returnAdminAreasOnly: string = 'N';
    public transactionIds :Map<String, String>;
    public generateNewTransactionId: Map<String,Boolean>;
    public showLoader : Map<String,Boolean>;
    public isStateEmpty : boolean = false;
    public filteredCountries: any[];
    public countries: any[];
    constructor(private searchLocationsTmp: LiapiService){
        this.transactionIds = new Map<String,String>();
        this.generateNewTransactionId = new Map<String,Boolean>();
        this.generateNewTransactionId.set("postcode", true);
        this.generateNewTransactionId.set("city", true);
        this.generateNewTransactionId.set("street", true);
        
        this.showResults = new Map<String,Boolean>();
        this.showResults.set("postcode", false);
        this.showResults.set("city", false);
        this.showResults.set("street", false);
        
        this.showLoader = new Map<String, Boolean>();
        this.showLoader.set("postcode", false);
        this.showLoader.set("city", false);
        this.showLoader.set("street", false);
    }

    ngOnInit() {
        this.getCountryFromIp();
        this.searchLocationsTmp.getTypeaheadCountries().subscribe(
            success => {
              this.countries = success;
            }
          );
    }
    
    onQueryUpdate = (searchType: string) => {
        let requiredTransactionId = this.getRequiredTransactionId(searchType);
        let requestData = new Map();
        requestData.set("maxCandidates", this.maxCandidates);
        requestData.set("country", this.countryInfo.code);
        requestData.set("searchOnAddressNumber", this.searchOnAddressNumber);
        
        if(searchType === 'postcode' || searchType ==='city'){
            requestData.set("returnAdminAreasOnly", "Y");    
        }
        else{
            requestData.set("returnAdminAreasOnly", this.returnAdminAreasOnly);
        }
        if(this.postCode != "" && (searchType === 'street' || searchType ==='city')){
            requestData.set("postCode", this.postCode)
        }
        if(this.city != "" && (searchType === 'street' || searchType === 'postcode')){
            requestData.set("areaName3", this.city)
        }
        if(this.state != ""){
            requestData.set("areaName1", this.state)
        }
        let searchText = this.mapSearchTextBasedOnSearchType(searchType);
        
        if(searchText != '' && searchText.length > 2){
            this.showLoader.set(searchType, true);
            requestData.set('searchText', searchText)
            requestData.set('transactionId', requiredTransactionId);
            requestData.set('clientIdentifier', "ECommerceDemo")
            if(this.requestObj != null){
              this.requestObj.unsubscribe();
            }
            this.requestObj = this.searchLocationsTmp.getAddressAutocompleteResponse(requestData).subscribe({
              next: (success: any) =>{
                if(success != null && success.location != null && success.location.length >0){
                    this.results = success;
                    this.showResults.set(searchType, true);
                    this.selectedIndex = 0;
                    this.resultLength =success.location.length;
                    this.showLoader.set(searchType, false);
                }
                this.showLoader.set(searchType, false);
              },
              error: (error: any) =>{ 
                  this.showResults.set(searchType, false);
                  this.showLoader.set(searchType, false); 
                  if(error.status == '429'){
                      this.searchLocationsTmp.showPrimeInfo();
                  }
                }
            })

            
        }
        
    }
    
    filterCountry = function (event) {
        let filtered: any[] = [];
        let query = event.query;
        for (let i = 0; i < this.countries.length; i++) {
          let country = this.countries[i];
          if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
            filtered.push(country);
          }
        }
        this.filteredCountries = filtered;
      };

    checkForCountry = ($event)=>{
        if($event.code === "GBR"){
            this.isStateEmpty = true;
        }
        else if($event.code!= undefined){
            this.isStateEmpty = false;
        }
    }
    getRequiredTransactionId = (searchType: string) => {

       if(this.generateNewTransactionId.get(searchType)){
           this.transactionIds.set(searchType, uuidv4());
           this.generateNewTransactionId.set(searchType, false);
       }
       return this.transactionIds.get(searchType);
    }

    onResultClicked = (result: any, searchType:string) =>{
      if(result.totalUnitCount > 1){
        this.results.location = [];
            let locArr :any= [];
            let addrObj = result.address;
            let geometryObj = result.geometry;
            let arrCount = 0;
            for (let i = 0; i < result.ranges.length; i++) {
                for (let j = 0; j < result.ranges[i].units.length; j++) {
                    locArr[arrCount] = {};
                    locArr[arrCount].totalUnitCount = 1;
                    locArr[arrCount].geometry = Object.assign({}, geometryObj);
                    locArr[arrCount].address = Object.assign({}, addrObj);
                    locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                    if(result.ranges[i].units[j].postalVerified !=null){
                        locArr[arrCount].address.postalVerified = result.ranges[i].units[j].postalVerified;
                    }
                    var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                    locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                    arrCount++;
                }
            }
            this.results.location = locArr;
      }
      else{
          this.showResults.set(searchType, false);
          if (result.address.postalVerified != null && searchType === 'street') {
                this.postalVerified = result.address.postalVerified;
          }
          else if(result.ranges != null && result.ranges.length == 1 && result.ranges[0].units != null && result.ranges[0].units.length == 1 &&
            result.ranges[0].units[0].postalVerified != null && searchType === 'street'){
                this.postalVerified = result.ranges[0].units[0].postalVerified;
          }
          else if(searchType === 'street'){
              this.postalVerified = "Not Verfied";
          }

        let requestData = new Map();
        if(searchType === 'postcode'){
            this.postCode = result.address.postCode;
            this.city = result.address.areaName3;
            this.state = result.address.areaName1;
            requestData.set('selectedAddress', result.address.postCode);
        
        }
        else if(searchType === 'city'){
            this.city = result.address.areaName3;
            this.state = result.address.areaName1;
            this.postCode = result.address.postCode
            requestData.set('selectedAddress', result.address.areaName3);
        
        }
        else if(searchType === 'street'){
            this.city = result.address.areaName3;
            this.state = result.address.areaName1;
            this.postCode = result.address.postCode
            this.street = result.address.mainAddressLine;
            requestData.set('selectedAddress', result.address.formattedAddress);
        
        }
        requestData.set('country', this.countryInfo.code);
        requestData.set('transactionId', this.transactionIds.get(searchType));
        this.searchLocationsTmp.selectAddress(requestData).subscribe({
          next: (success)=>{
            let currentState = this.generateNewTransactionId.get(searchType);
            this.generateNewTransactionId.set(searchType, !currentState);
          }
        })
      }
    }

    onKeyDown =  (event:any, searchType:string) => {
      let elememt = document.getElementById('geoitemcontainer') 
      if (elememt) {
          if (event.keyCode === 38) {
              event.preventDefault();
              var indexval = this.selectedIndex;
              if (this.selectedIndex > 1) {
                  if (this.addCSS(this.selectedIndex - 1)) {
                      this.selectedIndex = this.selectedIndex - 1;
                      this.removeCSS(indexval);
                      elememt.scrollTop -= 40;
                  }
              }
          }
          if (event.keyCode === 40) {
              event.preventDefault();
              var indexval = this.selectedIndex;
              if (this.selectedIndex < this.resultLength) {
                  if (this.addCSS(this.selectedIndex + 1)) {
                      this.selectedIndex = this.selectedIndex + 1;
                      this.removeCSS(indexval);
                      if (this.selectedIndex > 1) {
                          elememt.scrollTop += 40;
                      }
                  }
              }
          }
          if (event.keyCode === 13) {
              event.preventDefault();
              elememt.scrollTop = 0;
              var indexval = this.selectedIndex;
              if (indexval > 0) {
                  var result = this.results.location[indexval - 1];
                  if (result.totalUnitCount > 1) {
                      this.results.location = [];
                      var locArr :any= [];
                      var addrObj = result.address;
                      var geometryObj = result.geometry;
                      var arrCount = 0;
                      this.resultLength = result.totalUnitCount;
                      for (var i = 0; i < result.ranges.length; i++) {
                          for (var j = 0; j < result.ranges[i].units.length; j++) {
                              locArr[arrCount] = {};
                              locArr[arrCount].totalUnitCount = 1;
                              locArr[arrCount].address = Object.assign({}, addrObj);
                              locArr[arrCount].geometry = Object.assign({}, geometryObj);
                              locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                              var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                              locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                              this.removeCSS(this.selectedIndex);
                              this.selectedIndex = 0;
                              arrCount++;
                          }
                      }
                      this.results.location = locArr;
                  }
                  else {
                      this.removeCSS(this.selectedIndex);
                      this.selectedIndex = 0;
                      this.street = result.address.mainAddressLine;
                      this.showResults.set(searchType, false);
                  }
              }
          }
          if (event.keyCode === 27) {
              this.removeCSS(this.selectedIndex);
              this.selectedIndex = 0;
              this.showResults.set(searchType, false);
              event.preventDefault();
          }
      }
  };
  removeCSS =  (index:any) => {
      if (index > 0) {
          var elementId = 'geoitem' + index;
          let element = document.getElementById(elementId);
          if(element != null){
              element.className = "pb-geo-item";
          }
          
      }
  };
  addCSS =  (index:any) => {
      var returnval = false;
      if (index > 0) {
          var elementId = 'geoitem' + index;
          let element = document.getElementById(elementId);
          if(element != null){
              element.className = "pb-geo-item pb-geo-item-selected";
          }
          return true;
      }
      return returnval;
  };

  onFocusIn = (searchType: string)=> {
    for(let [key, value] of this.showResults){
        if(key != searchType){
            this.showResults.set(searchType, false);
        }
    }
  }
  mapSearchTextBasedOnSearchType = (searchType: string) => {
      switch(searchType){
          case "postcode":
              return this.postCode;
              break;
          case "city":
              return this.city;
              break;
          case "street":
              return this.street;
              break;
          default:
              return "";            
      }
  }
  getCountryFromIp = ()=>{
    this.searchLocationsTmp.geoApiSampleDemoGetCall('/getCountryFromIPAddress', {}).subscribe({
        next: (success) =>{
            let countryCode = success.ipInfo.place.country.code;
            this.searchForCountry(countryCode);
        },
        error: (error) =>{
            this.countryInfo = {
                "name": "UNITED STATES OF AMERICA",
                "code": "USA",
                "label": "UNITED STATES OF AMERICA (USA)"
              };
        }
    })

}
searchForCountry = (countryCode: string) => {
    this.searchLocationsTmp.searchTypeaheadCountry(countryCode).subscribe({
        next: (success) =>{
            for(let countryInfo of success){
                if(countryCode === countryInfo.code){
                    this.countryInfo =countryInfo;
                    break;
                }
            }
        }
    })
}

  clearFields = ()=>{
      this.postCode = "";
      this.city = "";
      this.state = "";
      this.street = "";
      this.postCode = "";
      this.postalVerified = "";
      this.isStateEmpty = false;
      
      this.showResults.set("postcode", false);
      this.showResults.set("city", false);
      this.showResults.set("street", false);
      this.showLoader.set("postcode", false);
      this.showLoader.set("city", false);
      this.showLoader.set("street", false);
      this.getCountryFromIp();
  }

}
