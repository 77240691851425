import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import { finalize } from 'rxjs';

@Component({
  selector: 'geocode-api',
  templateUrl: './geocodeapi.component.html'
})
export class GeocodeApiComponent implements OnInit {
  public forpreParamLink = '';
  public revpreParamLink = '';
  public pidParamLink = '';
  public klParamLink = '';
  public forpreaddrhan = 'multi';
  public forprecountry = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public forpre_fallbackGeo = true;
  public forpre_fallbackPostal = true;
  public forpre_removeAccentMarks = false;
  //public findDPV = false;
  public forpreaddrtype = 'multi';
  public foradvaddrhan = 'multi';
  public foradvcountry = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public foradv_fallbackGeo = true;
  public foradv_fallbackPostal = true;
  public foradv_removeAccentMarks = false;
  public foradvaddrtype = 'multi';
  public forbasaddrhan = 'multi';
  public forbascountry = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public forbas_fallbackGeo = true;
  public forbas_fallbackPostal = true;
  public forbas_removeAccentMarks = false;
  public forbasaddrtype = 'multi';
  public revprecountry: any = '';
  public revadvcountry: any = '';
  public revbascountry: any = '';
  public singleAddressPreJson = '{"type": "ADDRESS","preferences": {"maxReturnedCandidates":1,"returnAllCandidateInfo":"true","preferredDictionaryOrders":[],"fallbackToGeographic":"true","fallbackToPostal":"true","streetOffset":7,"streetOffsetUnits":"METERS","cornerOffset":7,"cornerOffsetUnits":"METERS","clientLocale":"en_US","clientCoordSysName":"EPSG:4326","matchMode":"STANDARD","mustMatchFields":{"matchOnAddressNumber":"false","matchOnPostCode1":"false","matchOnPostCode2":"false","matchOnAreaName1":"false","matchOnAreaName2":"false","matchOnAreaName3":"false","matchOnAreaName4":"false","matchOnAllStreetFields":"false","matchOnStreetName":"false","matchOnStreetType":"false","matchOnStreetDirectional":"false","matchOnPlaceName":"false","matchOnInputFields":"false"},"customPreferences": {"FALLBACK_TO_WORLD": "true", "REMOVE_ACCENT_MARKS": "false"},"returnFieldsDescriptor":{"returnAllCustomFields":"false","returnMatchDescriptor":"false","returnStreetAddressFields":"false","returnUnitInformation":"false","returnedCustomFieldKeys":["PB_KEY"]}},"addresses": [{"mainAddressLine": "1 Global View, Troy, NY, 12180","country": "USA"},{"mainAddressLine": "2935 Broadbridge Ave, Stratford, CT, 06614","country": "USA"}]}';
  public multiAddressPreJson = '{"type": "ADDRESS","preferences": {"maxReturnedCandidates":1,"returnAllCandidateInfo":"true","preferredDictionaryOrders":[],"fallbackToGeographic":"true","fallbackToPostal":"true","streetOffset":7,"streetOffsetUnits":"METERS","cornerOffset":7,"cornerOffsetUnits":"METERS","clientLocale":"en_US","clientCoordSysName":"EPSG:4326","matchMode":"STANDARD","mustMatchFields":{"matchOnAddressNumber":"false","matchOnPostCode1":"false","matchOnPostCode2":"false","matchOnAreaName1":"false","matchOnAreaName2":"false","matchOnAreaName3":"false","matchOnAreaName4":"false","matchOnAllStreetFields":"false","matchOnStreetName":"false","matchOnStreetType":"false","matchOnStreetDirectional":"false","matchOnPlaceName":"false","matchOnInputFields":"false"},"customPreferences": {"FALLBACK_TO_WORLD": "true", "REMOVE_ACCENT_MARKS": "false"},"returnFieldsDescriptor":{"returnAllCustomFields":"false","returnMatchDescriptor":"false","returnStreetAddressFields":"false","returnUnitInformation":"false","returnedCustomFieldKeys":["PB_KEY"]}},"addresses": [{"mainAddressLine": "1 Global View","country": "USA","addressLastLine":"","placeName":"Pitney Bowes","areaName1":"NY","areaName2":"","areaName3":"Troy","areaName4":"","postCode1":"12180","postCode2":"","addressNumber":"","streetName":"","unitType":"","unitValue":""},{"placeName":"Empire State Building","mainAddressLine": "","country": "USA","addressLastLine":"","areaName1":"","areaName2":"","areaName3":"Manhattan","areaName4":"","postCode1":"10118","postCode2":"","addressNumber":"350, 5th Ave","streetName":"","unitType":"","unitValue":""}]}';
  public singleAddressAdvJson = '{"type": "ADDRESS","preferences": {"maxReturnedCandidates":1,"returnAllCandidateInfo":"true","preferredDictionaryOrders":[],"fallbackToGeographic":"true","fallbackToPostal":"true","streetOffset":7,"streetOffsetUnits":"METERS","cornerOffset":7,"cornerOffsetUnits":"METERS","clientLocale":"en_US","clientCoordSysName":"EPSG:4326","matchMode":"STANDARD","mustMatchFields":{"matchOnAddressNumber":"false","matchOnPostCode1":"false","matchOnPostCode2":"false","matchOnAreaName1":"false","matchOnAreaName2":"false","matchOnAreaName3":"false","matchOnAreaName4":"false","matchOnAllStreetFields":"false","matchOnStreetName":"false","matchOnStreetType":"false","matchOnStreetDirectional":"false","matchOnPlaceName":"false","matchOnInputFields":"false"},"customPreferences": {"FALLBACK_TO_WORLD": "true", "REMOVE_ACCENT_MARKS": "false"},"returnFieldsDescriptor":{"returnAllCustomFields":"false","returnMatchDescriptor":"false","returnStreetAddressFields":"false","returnUnitInformation":"false","returnedCustomFieldKeys":[""]}},"addresses": [{"mainAddressLine": "1 Global View, Troy, NY, 12180","country": "USA"},{"mainAddressLine": "2935 Broadbridge Ave, Stratford, CT, 06614","country": "USA"}]}';
  public multiAddressAdvJson = '{"type": "ADDRESS","preferences": {"maxReturnedCandidates":1,"returnAllCandidateInfo":"true","preferredDictionaryOrders":[],"fallbackToGeographic":"true","fallbackToPostal":"true","streetOffset":7,"streetOffsetUnits":"METERS","cornerOffset":7,"cornerOffsetUnits":"METERS","clientLocale":"en_US","clientCoordSysName":"EPSG:4326","matchMode":"STANDARD","mustMatchFields":{"matchOnAddressNumber":"false","matchOnPostCode1":"false","matchOnPostCode2":"false","matchOnAreaName1":"false","matchOnAreaName2":"false","matchOnAreaName3":"false","matchOnAreaName4":"false","matchOnAllStreetFields":"false","matchOnStreetName":"false","matchOnStreetType":"false","matchOnStreetDirectional":"false","matchOnPlaceName":"false","matchOnInputFields":"false"},"customPreferences": {"FALLBACK_TO_WORLD": "true", "REMOVE_ACCENT_MARKS": "false"},"returnFieldsDescriptor":{"returnAllCustomFields":"false","returnMatchDescriptor":"false","returnStreetAddressFields":"false","returnUnitInformation":"false","returnedCustomFieldKeys":[""]}},"addresses": [{"mainAddressLine": "1 Global View","country": "USA","addressLastLine":"","placeName":"Pitney Bowes","areaName1":"NY","areaName2":"","areaName3":"Troy","areaName4":"","postCode1":"12180","postCode2":"","addressNumber":"","streetName":"","unitType":"","unitValue":""},{"placeName":"Empire State Building","mainAddressLine": "","country": "USA","addressLastLine":"","areaName1":"","areaName2":"","areaName3":"Manhattan","areaName4":"","postCode1":"10118","postCode2":"","addressNumber":"350, 5th Ave","streetName":"","unitType":"","unitValue":""}]}';
  public singleAddressBasJson = '{"type": "ADDRESS","preferences": {"maxReturnedCandidates":1,"returnAllCandidateInfo":"true","preferredDictionaryOrders":[],"fallbackToGeographic":"true","fallbackToPostal":"true","streetOffset":7,"streetOffsetUnits":"METERS","cornerOffset":7,"cornerOffsetUnits":"METERS","clientLocale":"en_US","clientCoordSysName":"EPSG:4326","matchMode":"STANDARD","mustMatchFields":{"matchOnAddressNumber":"false","matchOnPostCode1":"false","matchOnPostCode2":"false","matchOnAreaName1":"false","matchOnAreaName2":"false","matchOnAreaName3":"false","matchOnAreaName4":"false","matchOnAllStreetFields":"false","matchOnStreetName":"false","matchOnStreetType":"false","matchOnStreetDirectional":"false","matchOnPlaceName":"false","matchOnInputFields":"false"},"customPreferences": {"REMOVE_ACCENT_MARKS": "false"},"returnFieldsDescriptor":{"returnAllCustomFields":"false","returnMatchDescriptor":"false","returnStreetAddressFields":"false","returnUnitInformation":"false","returnedCustomFieldKeys":[""]}},"addresses": [{"mainAddressLine": "Empire State Building, 350 5th Ave, New York, USA"},{"mainAddressLine": "39 Sycamore Rd,Stafford 08050, USA"}]}';
  public multiAddressBasJson = '{"type": "ADDRESS","preferences": {"maxReturnedCandidates":1,"returnAllCandidateInfo":"true","preferredDictionaryOrders":[],"fallbackToGeographic":"true","fallbackToPostal":"true","streetOffset":7,"streetOffsetUnits":"METERS","cornerOffset":7,"cornerOffsetUnits":"METERS","clientLocale":"en_US","clientCoordSysName":"EPSG:4326","matchMode":"STANDARD","mustMatchFields":{"matchOnAddressNumber":"false","matchOnPostCode1":"false","matchOnPostCode2":"false","matchOnAreaName1":"false","matchOnAreaName2":"false","matchOnAreaName3":"false","matchOnAreaName4":"false","matchOnAllStreetFields":"false","matchOnStreetName":"false","matchOnStreetType":"false","matchOnStreetDirectional":"false","matchOnPlaceName":"false","matchOnInputFields":"false"},"customPreferences": {"REMOVE_ACCENT_MARKS": "false"},"returnFieldsDescriptor":{"returnAllCustomFields":"false","returnMatchDescriptor":"false","returnStreetAddressFields":"false","returnUnitInformation":"false","returnedCustomFieldKeys":[""]}},"addresses": [{"mainAddressLine": "1 Global View","country": "USA","addressLastLine":"","placeName":"Pitney Bowes","areaName1":"NY","areaName2":"","areaName3":"Troy","areaName4":"","postCode1":"12180","postCode2":"","addressNumber":"","streetName":"","unitType":"","unitValue":""},{"placeName":"Empire State Building","mainAddressLine": "","country": "USA","addressLastLine":"","areaName1":"","areaName2":"","areaName3":"Manhattan","areaName4":"","postCode1":"10118","postCode2":"","addressNumber":"350, 5th Ave","streetName":"","unitType":"","unitValue":""}]}';
  public revpreJson = '{"preferences": {"returnAllCandidateInfo": true,"fallbackToGeographic": true,"fallbackToPostal": true,"maxReturnedCandidates": 1,"distance": 150,"streetOffset": 7,"cornerOffset": 7,"matchMode": "UNSPECIFIED","clientLocale": "en_US","clientCoordSysName": "epsg:4326","distanceUnits": "METERS","streetOffsetUnits": "METERS","cornerOffsetUnits": "METERS","mustMatchFields": {"matchOnAddressNumber": false,"matchOnPostCode1": false,"matchOnPostCode2": false,"matchOnAreaName1": false,"matchOnAreaName2": false,"matchOnAreaName3": false,"matchOnAreaName4": false,"matchOnAllStreetFields": false,"matchOnStreetName": false,"matchOnStreetType": false,"matchOnStreetDirectional": false,"matchOnPlaceName": false,"matchOnInputFields": false},"returnFieldsDescriptor": {"returnAllCustomFields": false,"returnMatchDescriptor": false,"returnStreetAddressFields": false,"returnUnitInformation": false},"customPreferences": {}},"points": [{"country": "AUS","geometry": {"type": "point","coordinates": [151.196036,-33.879637],"crs": {"type": "name","properties": {"name": "EPSG:4326"}}}}]}';
  public revadvJson = '{"preferences": {"returnAllCandidateInfo": true,"fallbackToGeographic": true,"fallbackToPostal": true,"maxReturnedCandidates": 1,"distance": 150,"streetOffset": 7,"cornerOffset": 7,"matchMode": "UNSPECIFIED","clientLocale": "en_US","clientCoordSysName": "epsg:4326","distanceUnits": "METERS","streetOffsetUnits": "METERS","cornerOffsetUnits": "METERS","mustMatchFields": {"matchOnAddressNumber": false,"matchOnPostCode1": false,"matchOnPostCode2": false,"matchOnAreaName1": false,"matchOnAreaName2": false,"matchOnAreaName3": false,"matchOnAreaName4": false,"matchOnAllStreetFields": false,"matchOnStreetName": false,"matchOnStreetType": false,"matchOnStreetDirectional": false,"matchOnPlaceName": false,"matchOnInputFields": false},"returnFieldsDescriptor": {"returnAllCustomFields": false,"returnMatchDescriptor": false,"returnStreetAddressFields": false,"returnUnitInformation": false},"customPreferences": {}},"points": [{"country": "AUS","geometry": {"type": "point","coordinates": [151.196036,-33.879637],"crs": {"type": "name","properties": {"name": "EPSG:4326"}}}}]}';
  public revbasJson = '{"preferences": {"returnAllCandidateInfo": true,"fallbackToGeographic": true,"fallbackToPostal": true,"maxReturnedCandidates": 1,"distance": 150,"streetOffset": 7,"cornerOffset": 7,"matchMode": "UNSPECIFIED","clientLocale": "en_US","clientCoordSysName": "epsg:4326","distanceUnits": "METERS","streetOffsetUnits": "METERS","cornerOffsetUnits": "METERS","mustMatchFields": {"matchOnAddressNumber": false,"matchOnPostCode1": false,"matchOnPostCode2": false,"matchOnAreaName1": false,"matchOnAreaName2": false,"matchOnAreaName3": false,"matchOnAreaName4": false,"matchOnAllStreetFields": false,"matchOnStreetName": false,"matchOnStreetType": false,"matchOnStreetDirectional": false,"matchOnPlaceName": false,"matchOnInputFields": false},"returnFieldsDescriptor": {"returnAllCustomFields": false,"returnMatchDescriptor": false,"returnStreetAddressFields": false,"returnUnitInformation": false},"customPreferences": {}},"points": [{"country": "AUS","geometry": {"type": "point","coordinates": [151.196036,-33.879637],"crs": {"type": "name","properties": {"name": "EPSG:4326"}}}}]}';
  public pbkeyJson= '{"addresses":[{"mainAddressLine":"143 TABERNACLE RD","addressLastLine":"","areaName1":"SC","areaName2":"ORANGEBURG COUNTY","areaName3":"EUTAWVILLE","postCode1":"29048","postCode2":"","country":"USA","addressNumber":"","streetName":"","unitType":"","unitValue":"","objectId":"3"},{"mainAddressLine":"4225 RUBY FOREST BLVD","addressLastLine":"","areaName1":"GA","areaName2":"GWINNETT COUNTY","areaName3":"SUWANEE","postCode1":"30024","postCode2":"","country":"USA","addressNumber":"","streetName":"","unitType":"","unitValue":"","objectId":"3"}]}';
  public keylookupJson = '{"type" : "PB_KEY","preferences": {"maxReturnedCandidates": 10},"keys": [{"objectId" : "1","country" : "USA","value" : "P00003PZ0BUF"},{"objectId" : "2","country" : "USA","value" :"P00003PZZOIE"}]}';
  countries: any[];
  filteredCountries: any[];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'precisely api, precisely geocoding, geocode api, geocodes list, geocode, geocode lookup, reverse geocoding api, geocode example, address to lat long batch, geocoder, geocode address, geo codes lookup, geo code list, geocoder ruby, best geocoding api, geocoder gem, address to coordinates, address geocoding, latitude, longitude, geocoding service, deliverability, mailability, serviceability, point level geocoding, rooftop geocoding, street geocoding, building level geocoding, premise geocoding, parcel geocoding' }
    );
    this.singleAddressPreJson = JSON.stringify(JSON.parse(this.singleAddressPreJson), undefined, 4);
    this.multiAddressPreJson = JSON.stringify(JSON.parse(this.multiAddressPreJson), undefined, 4);
    this.singleAddressAdvJson = JSON.stringify(JSON.parse(this.singleAddressAdvJson), undefined, 4);
    this.multiAddressAdvJson = JSON.stringify(JSON.parse(this.multiAddressAdvJson), undefined, 4);
    this.singleAddressBasJson = JSON.stringify(JSON.parse(this.singleAddressBasJson), undefined, 4);
    this.multiAddressBasJson = JSON.stringify(JSON.parse(this.multiAddressBasJson), undefined, 4);
    this.formatJsonInputs();
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.setParamLink(success.docsURL);
        }
      );
    }
    else{
      this.setParamLink(docsURL);
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  setParamLink = function (docsURL) {
    this.forpreParamLink = docsURL + 'Geocode/Geocode/LI_Geo_GET_queryparams.html';
    this.revpreParamLink = docsURL + 'Geocode/ReverseGeocode/LI_revGeo_GET_queryparams.html';
    this.pidParamLink = docsURL + 'Geocode/PreciselyID/byPreciselyID/query_param.html';
    this.klParamLink = docsURL + 'Geocode/query_parameters.html';
  };
  formatJsonInputs = function () {
    this.revpreJson = JSON.stringify(JSON.parse(this.revpreJson), undefined, 4);
    this.revadvJson = JSON.stringify(JSON.parse(this.revadvJson), undefined, 4);
    this.revbasJson = JSON.stringify(JSON.parse(this.revbasJson), undefined, 4);
    this.pbkeyJson = JSON.stringify(JSON.parse(this.pbkeyJson), undefined, 4);
    this.keylookupJson = JSON.stringify(JSON.parse(this.keylookupJson), undefined, 4);
  };
  clearResult = function (result) {
    switch (result){
      case 'get_forpreRes':
        this.get_forpreUri = "";
        this.get_forpreRes = null;
        this.get_forpreResStatus = "";
        this.get_forpreResType = "";
        break;
      case 'post_forpreRes':
        this.post_forpreUri = "";
        this.post_forpreRes = null;
        this.post_forpreResStatus = "";
        this.post_forpreResType = "";
        break;
      case 'get_foradvRes':
        this.get_foradvUri = "";
        this.get_foradvRes = null;
        this.get_foradvResStatus = "";
        this.get_foradvResType = "";
        break;
      case 'post_foradvRes':
        this.post_foradvUri = "";
        this.post_foradvRes = null;
        this.post_foradvResStatus = "";
        this.post_foradvResType = "";
        break;
      case 'get_forbasRes':
        this.get_forbasUri = "";
        this.get_forbasRes = null;
        this.get_forbasResStatus = "";
        this.get_forbasResType = "";
        break;
      case 'post_forbasRes':
        this.post_forbasUri = "";
        this.post_forbasRes = null;
        this.post_forbasResStatus = "";
        this.post_forbasResType = "";
        break;
      case 'get_revpreRes':
        this.get_revpreUri = "";
        this.get_revpreRes = null;
        this.get_revpreResStatus = "";
        this.get_revpreResType = "";
        break;
      case 'post_revpreRes':
        this.post_revpreUri = "";
        this.post_revpreRes = null;
        this.post_revpreResStatus = "";
        this.post_revpreResType = "";
        break;
      case 'get_revadvRes':
        this.get_revadvUri = "";
        this.get_revadvRes = null;
        this.get_revadvResStatus = "";
        this.get_revadvResType = "";
        break;
      case 'post_revadvRes':
        this.post_revadvUri = "";
        this.post_revadvRes = null;
        this.post_revadvResStatus = "";
        this.post_revadvResType = "";
        break;
      case 'get_revbasRes':
        this.get_revbasUri = "";
        this.get_revbasRes = null;
        this.get_revbasResStatus = "";
        this.get_revbasResType = "";
        break;
      case 'post_revbasRes':
        this.post_revbasUri = "";
        this.post_revbasRes = null;
        this.post_revbasResStatus = "";
        this.post_revbasResType = "";
        break;
      case 'get_pbkeyRes':
        this.get_pbkeyUri = "";
        this.get_pbkeyRes = null;
        this.get_pbkeyResStatus = "";
        this.get_pbkeyResType = "";
        break;
      case 'post_pbkeyRes':
        this.post_pbkeyUri = "";
        this.post_pbkeyRes = null;
        this.post_pbkeyResStatus = "";
        this.post_pbkeyResType = "";
        break;
      case 'get_keylookRes':
        this.get_keylookUri = "";
        this.get_keylookRes = null;
        this.get_keylookResStatus = "";
        this.get_keylookResType = "";
        break;
      case 'post_keylookRes':
        this.post_keylookUri = "";
        this.post_keylookRes = null;
        this.post_keylookResStatus = "";
        this.post_keylookResType = "";
        break;
    }
  };
  toogleOptionalContainer = function (optConHan, optCon) {
    var domEle = $('#' + optConHan);
    var domEleNext = $('#' + optCon);
    if (domEleNext.prop('style')['display'] == 'none') {
      domEleNext.show(200);
      domEle.removeClass('plus');
      domEle.addClass('minus');
    }
    else {
      domEleNext.hide(200);
      domEle.removeClass('minus');
      domEle.addClass('plus');
    }
  };
  clearOptionalFields = function (nbsOtpPrmFrm) {
    switch(nbsOtpPrmFrm){
      case 'forpreOptPrmFrm':
        this.geoCodeTryApiGetForPrematchMode = '';
        this.geoCodeTryApiGetForPremaxCands = '';
        this.geoCodeTryApiGetForPrestreetOffset = '';
        this.geoCodeTryApiGetForPrestreetOffsetUnits = '';
        this.geoCodeTryApiGetForPrecornerOffset = '';
        this.geoCodeTryApiGetForPrecornerOffsetUnits = '';
        break;
      case 'foradvOptPrmFrm':
        this.geoCodeTryApiGetForAdvmatchMode = '';
        this.geoCodeTryApiGetForAdvmaxCands = '';
        this.geoCodeTryApiGetForAdvstreetOffset = '';
        this.geoCodeTryApiGetForAdvstreetOffsetUnits = '';
        this.geoCodeTryApiGetForAdvcornerOffset = '';
        this.geoCodeTryApiGetForAdvcornerOffsetUnits = '';
        break;
      case 'forbasOptPrmFrm':
        this.geoCodeTryApiGetForBasmatchMode = '';
        this.geoCodeTryApiGetForBasmaxCands = '';
        this.geoCodeTryApiGetForBasstreetOffset = '';
        this.geoCodeTryApiGetForBasstreetOffsetUnits = '';
        this.geoCodeTryApiGetForBascornerOffset = '';
        this.geoCodeTryApiGetForBascornerOffsetUnits = '';
        break;
      case 'revpreOptPrmFrm':
        this.geoCodeTryApiGetRevPrecoordSysName = '';
        this.geoCodeTryApiGetRevPredistance = '';
        this.geoCodeTryApiGetRevPredistanceUnits = '';
        break;
      case 'revadvOptPrmFrm':
        this.geoCodeTryApiGetRevAdvcoordSysName = '';
        this.geoCodeTryApiGetRevAdvdistance = '';
        this.geoCodeTryApiGetRevAdvdistanceUnits = '';
        break;
      case 'revbasOptPrmFrm':
        this.geoCodeTryApiGetRevBascoordSysName = '';
        this.geoCodeTryApiGetRevBasdistance = '';
        this.geoCodeTryApiGetRevBasdistanceUnits = '';
        break;
    }
  };
  toogleforpreaddr = function () {
    if (this.forpreaddrhan == 'single') {
      this.forpreaddrhan = 'multi';
    }
    else {
      this.forpreaddrhan = 'single';
    }
  };
  setforpreaddrtype = function (addrtype) {
    this.forpreaddrtype = addrtype;
  };
  toogleforadvaddr = function () {
    if (this.foradvaddrhan == 'single') {
      this.foradvaddrhan = 'multi';
    }
    else {
      this.foradvaddrhan = 'single';
    }
  };
  setforadvaddrtype = function (addrtype) {
    this.foradvaddrtype = addrtype;
  };
  toogleforbasaddr = function () {
    if (this.forbasaddrhan == 'single') {
      this.forbasaddrhan = 'multi';
    }
    else {
      this.forbasaddrhan = 'single';
    }
  };
  setforbasaddrtype = function (addrtype) {
    this.forbasaddrtype = addrtype;
  };
  public geoCodeTryApiGetForPreDataType = 'json';
  public geoCodeTryApiGetForPremainAddress = '4750 Walnut St., Boulder CO, 80301';
  public geoCodeTryApiGetForPreplaceName = '';
  public geoCodeTryApiGetForPrelastLine = '';
  public geoCodeTryApiGetForPreareaName1 = '';
  public geoCodeTryApiGetForPreareaName2 = '';
  public geoCodeTryApiGetForPreareaName3 = '';
  public geoCodeTryApiGetForPreareaName4 = '';
  public geoCodeTryApiGetForPrepostalCode = '';
  public geoCodeTryApiGetForPrematchMode = 'Standard';
  public geoCodeTryApiGetForPremaxCands = 1;
  public geoCodeTryApiGetForPrestreetOffset = 7;
  public geoCodeTryApiGetForPrestreetOffsetUnits = 'METERS';
  public geoCodeTryApiGetForPrecornerOffset = 7;
  public geoCodeTryApiGetForPrecornerOffsetUnits = 'METERS';
  public get_forpreUri = "";
  public get_forpreRes = null;
  public get_forpreResStatus = "";
  public get_forpreResType = "";
  tryApigeoCodeGetForPre = function(btnId){
    var dataType = this.geoCodeTryApiGetForPreDataType;
    var tmpData = {
      dataType: dataType
    };
    if (this.forprecountry != '') {
      if (this.forprecountry.code) {
        tmpData['country'] = this.forprecountry.code;
      }
      else {
        tmpData['country'] = this.forprecountry;
      }
    }
    var mainAddress = this.geoCodeTryApiGetForPremainAddress;
    if(mainAddress != ''){
      tmpData['mainAddress'] = mainAddress;
    }
    if(this.forpreaddrhan == 'single'){
      var placeName = this.geoCodeTryApiGetForPreplaceName;
      if(placeName != ''){
        tmpData['placeName'] = placeName;
      }
      var lastLine = this.geoCodeTryApiGetForPrelastLine;
      if(lastLine != ''){
        tmpData['lastLine'] = lastLine;
      }
      var areaName1 = this.geoCodeTryApiGetForPreareaName1;
      if(areaName1 != ''){
        tmpData['areaName1'] = areaName1;
      }
      var areaName2 = this.geoCodeTryApiGetForPreareaName2;
      if(areaName2 != ''){
        tmpData['areaName2'] = areaName2;
      }
      var areaName3 = this.geoCodeTryApiGetForPreareaName3;
      if(areaName3 != ''){
        tmpData['areaName3'] = areaName3;
      }
      var areaName4 = this.geoCodeTryApiGetForPreareaName4;
      if(areaName4 != ''){
        tmpData['areaName4'] = areaName4;
      }
      var postalCode = this.geoCodeTryApiGetForPrepostalCode;
      if(postalCode != ''){
        tmpData['postalCode'] = postalCode;
      }
    }
    var matchMode = this.geoCodeTryApiGetForPrematchMode;
    if(matchMode != ''){
      tmpData['matchMode'] = matchMode;
    }
    tmpData['fallbackGeo'] = this.forpre_fallbackGeo;
    tmpData['fallbackPostal'] = this.forpre_fallbackPostal;
    var maxCands:any = this.geoCodeTryApiGetForPremaxCands;
    if(maxCands != ''){
      tmpData['maxCands'] = maxCands;
    }
    var streetOffset:any = this.geoCodeTryApiGetForPrestreetOffset;
    if(streetOffset != ''){
      tmpData['streetOffset'] = streetOffset;
    }
    var streetOffsetUnits = this.geoCodeTryApiGetForPrestreetOffsetUnits;
    if(streetOffsetUnits != ''){
      tmpData['streetOffsetUnits'] = streetOffsetUnits;
    }
    var cornerOffset:any = this.geoCodeTryApiGetForPrecornerOffset;
    if(cornerOffset != ''){
      tmpData['cornerOffset'] = cornerOffset;
    }
    var cornerOffsetUnits = this.geoCodeTryApiGetForPrecornerOffsetUnits;
    if(cornerOffsetUnits != ''){
      tmpData['cornerOffsetUnits'] = cornerOffsetUnits;
    }
    tmpData['removeAccentMarks'] = this.forpre_removeAccentMarks;
    //tmpData['findDPV'] = this.findDPV;

    if(!isNaN(maxCands) && !isNaN(streetOffset) &&  !isNaN(cornerOffset)){
      var methodName = '/tryApigeoCodeGetForPre';
      let requestUri = this.liapiservice.getCompleteUri('geocode', 'Get Forward Geocode Premium', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_forpreUri = requestUri;
          this.get_forpreResType = dataType;
          if(this.get_forpreResStatus.includes('429')){
            this.get_forpreUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.get_forpreResStatus = '200 success';
          this.get_forpreRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_forpreResStatus = `${error.status} ${error.statusText}`;
            this.get_forpreRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
    }
    else{
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_forpreUri = "";
  public post_forpreRes = null;
  public post_forpreResStatus = "";
  public post_forpreResType = "";
  tryApigeoCodePostForPre = function(btnId){
    var postData = '';
    if (this.forpreaddrtype === 'single') {
      postData = this.singleAddressPreJson;
    }
    else if (this.forpreaddrtype === 'multi') {
      postData = this.multiAddressPreJson;
    }
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else{
      var methodName = '/tryApigeoCodePostForPre';
      let requestUri = this.liapiservice.getCompleteUriPost('geocode', 'Post Forward Geocode Premium');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_forpreUri = requestUri;
          this.post_forpreResType = 'json';
          if(this.post_forpreResStatus.includes('429')){
            this.post_forpreUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.post_forpreResStatus = '200 success';
          this.post_forpreRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.post_forpreResStatus = `${error.status} ${error.statusText}`;
            this.post_forpreRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
    }
  };
  public geoCodeTryApiGetForAdvDataType = 'json';
  public geoCodeTryApiGetForAdvmainAddress = '4750 Walnut St., Boulder CO, 80301';
  public geoCodeTryApiGetForAdvplaceName = '';
  public geoCodeTryApiGetForAdvlastLine = '';
  public geoCodeTryApiGetForAdvareaName1 = '';
  public geoCodeTryApiGetForAdvareaName2 = '';
  public geoCodeTryApiGetForAdvareaName3 = '';
  public geoCodeTryApiGetForAdvareaName4 = '';
  public geoCodeTryApiGetForAdvpostalCode = '';
  public geoCodeTryApiGetForAdvmatchMode = 'Standard';
  public geoCodeTryApiGetForAdvmaxCands = 1;
  public geoCodeTryApiGetForAdvstreetOffset = 7;
  public geoCodeTryApiGetForAdvstreetOffsetUnits = 'METERS';
  public geoCodeTryApiGetForAdvcornerOffset = 7;
  public geoCodeTryApiGetForAdvcornerOffsetUnits = 'METERS';
  public get_foradvUri = "";
  public get_foradvRes = null;
  public get_foradvResStatus = "";
  public get_foradvResType = "";
  tryApigeoCodeGetForAdv = function(btnId){
    var dataType = this.geoCodeTryApiGetForAdvDataType;
    var tmpData = {
      dataType: dataType
    };
    if (this.foradvcountry != '') {
      if (this.foradvcountry.code) {
        tmpData['country'] = this.foradvcountry.code;
      }
      else {
        tmpData['country'] = this.foradvcountry;
      }
    }
    var mainAddress = this.geoCodeTryApiGetForAdvmainAddress;
    if(mainAddress != ''){
      tmpData['mainAddress'] = mainAddress;
    }
    if(this.foradvaddrhan == 'single'){
      var placeName = this.geoCodeTryApiGetForAdvplaceName;
      if(placeName != ''){
        tmpData['placeName'] = placeName;
      }
      var lastLine = this.geoCodeTryApiGetForAdvlastLine;
      if(lastLine != ''){
        tmpData['lastLine'] = lastLine;
      }
      var areaName1 = this.geoCodeTryApiGetForAdvareaName1;
      if(areaName1 != ''){
        tmpData['areaName1'] = areaName1;
      }
      var areaName2 = this.geoCodeTryApiGetForAdvareaName2;
      if(areaName2 != ''){
        tmpData['areaName2'] = areaName2;
      }
      var areaName3 = this.geoCodeTryApiGetForAdvareaName3;
      if(areaName3 != ''){
        tmpData['areaName3'] = areaName3;
      }
      var areaName4 = this.geoCodeTryApiGetForAdvareaName4;
      if(areaName4 != ''){
        tmpData['areaName4'] = areaName4;
      }
      var postalCode = this.geoCodeTryApiGetForAdvpostalCode;
      if(postalCode != ''){
        tmpData['postalCode'] = postalCode;
      }
    }
    var matchMode = this.geoCodeTryApiGetForAdvmatchMode;
    if(matchMode != ''){
      tmpData['matchMode'] = matchMode;
    }
    tmpData['fallbackGeo'] = this.foradv_fallbackGeo;
    tmpData['fallbackPostal'] = this.foradv_fallbackPostal;
    var maxCands:any = this.geoCodeTryApiGetForAdvmaxCands;
    if(maxCands != ''){
      tmpData['maxCands'] = maxCands;
    }
    var streetOffset:any = this.geoCodeTryApiGetForAdvstreetOffset;
    if(streetOffset != ''){
      tmpData['streetOffset'] = streetOffset;
    }
    var streetOffsetUnits = this.geoCodeTryApiGetForAdvstreetOffsetUnits;
    if(streetOffsetUnits != ''){
      tmpData['streetOffsetUnits'] = streetOffsetUnits;
    }
    var cornerOffset:any = this.geoCodeTryApiGetForAdvcornerOffset;
    if(cornerOffset != ''){
      tmpData['cornerOffset'] = cornerOffset;
    }
    var cornerOffsetUnits = this.geoCodeTryApiGetForAdvcornerOffsetUnits;
    if(cornerOffsetUnits != ''){
      tmpData['cornerOffsetUnits'] = cornerOffsetUnits;
    }
    tmpData['removeAccentMarks'] = this.foradv_removeAccentMarks;
    if(!isNaN(maxCands) && !isNaN(streetOffset) &&  !isNaN(cornerOffset)){
      var methodName = '/tryApigeoCodeGetForAdv';
      let requestUri = this.liapiservice.getCompleteUri('geocode', 'Get Forward Geocode Advanced', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_foradvUri = requestUri;
          this.get_foradvResType = dataType;
          if(this.get_foradvResStatus.includes('429')){
            this.get_foradvUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.get_foradvResStatus = '200 success';
          this.get_foradvRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_foradvResStatus = `${error.status} ${error.statusText}`;
            this.get_foradvRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
    }
    else{
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_foradvUri = "";
  public post_foradvRes = null;
  public post_foradvResStatus = "";
  public post_foradvResType = "";
  tryApigeoCodePostForAdv = function(btnId){
    var postData = '';
    if (this.foradvaddrtype === 'single') {
      postData = this.singleAddressAdvJson;
    }
    else if (this.foradvaddrtype === 'multi') {
      postData = this.multiAddressAdvJson;
    }
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else{
      var methodName = '/tryApigeoCodePostForAdv';
      let requestUri = this.liapiservice.getCompleteUriPost('geocode', 'Post Forward Geocode Advanced');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_foradvUri = requestUri;
          this.post_foradvResType = 'json';
          if(this.post_foradvResStatus.includes('429')){
            this.post_foradvUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.post_foradvResStatus = '200 success';
          this.post_foradvRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.post_foradvResStatus = `${error.status} ${error.statusText}`;
            this.post_foradvRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
    }
  };
  public geoCodeTryApiGetForBasDataType = 'json';
  public geoCodeTryApiGetForBasmainAddress = '4750 Walnut St., Boulder CO, 80301';
  public geoCodeTryApiGetForBasplaceName = '';
  public geoCodeTryApiGetForBaslastLine = '';
  public geoCodeTryApiGetForBasareaName1 = '';
  public geoCodeTryApiGetForBasareaName2 = '';
  public geoCodeTryApiGetForBasareaName3 = '';
  public geoCodeTryApiGetForBasareaName4 = '';
  public geoCodeTryApiGetForBaspostalCode = '';
  public geoCodeTryApiGetForBasmatchMode = 'Standard';
  public geoCodeTryApiGetForBasmaxCands = 1;
  public geoCodeTryApiGetForBasstreetOffset = 7;
  public geoCodeTryApiGetForBasstreetOffsetUnits = 'METERS';
  public geoCodeTryApiGetForBascornerOffset = 7;
  public geoCodeTryApiGetForBascornerOffsetUnits = 'METERS';
  public get_forbasUri = "";
  public get_forbasRes = null;
  public get_forbasResStatus = "";
  public get_forbasResType = "";
  tryApigeoCodeGetForBas = function(btnId){
    var dataType = this.geoCodeTryApiGetForBasDataType;
    var tmpData = {
      dataType: dataType
    };
    if (this.forbascountry != '') {
      if (this.forbascountry.code) {
        tmpData['country'] = this.forbascountry.code;
      }
      else {
        tmpData['country'] = this.forbascountry;
      }
    }
    var mainAddress = this.geoCodeTryApiGetForBasmainAddress;
    if(mainAddress != ''){
      tmpData['mainAddress'] = mainAddress;
    }
    if(this.forbasaddrhan == 'single'){
      var placeName = this.geoCodeTryApiGetForBasplaceName;
      if(placeName != ''){
        tmpData['placeName'] = placeName;
      }
      var lastLine = this.geoCodeTryApiGetForBaslastLine;
      if(lastLine != ''){
        tmpData['lastLine'] = lastLine;
      }
      var areaName1 = this.geoCodeTryApiGetForBasareaName1;
      if(areaName1 != ''){
        tmpData['areaName1'] = areaName1;
      }
      var areaName2 = this.geoCodeTryApiGetForBasareaName2;
      if(areaName2 != ''){
        tmpData['areaName2'] = areaName2;
      }
      var areaName3 = this.geoCodeTryApiGetForBasareaName3;
      if(areaName3 != ''){
        tmpData['areaName3'] = areaName3;
      }
      var areaName4 = this.geoCodeTryApiGetForBasareaName4;
      if(areaName4 != ''){
        tmpData['areaName4'] = areaName4;
      }
      var postalCode = this.geoCodeTryApiGetForBaspostalCode;
      if(postalCode != ''){
        tmpData['postalCode'] = postalCode;
      }
    }
    var matchMode = this.geoCodeTryApiGetForBasmatchMode;
    if(matchMode != ''){
      tmpData['matchMode'] = matchMode;
    }
    tmpData['fallbackGeo'] = this.forbas_fallbackGeo;
    tmpData['fallbackPostal'] = this.forbas_fallbackPostal;
    var maxCands:any = this.geoCodeTryApiGetForBasmaxCands;
    if(maxCands != ''){
      tmpData['maxCands'] = maxCands;
    }
    var streetOffset:any = this.geoCodeTryApiGetForBasstreetOffset;
    if(streetOffset != ''){
      tmpData['streetOffset'] = streetOffset;
    }
    var streetOffsetUnits = this.geoCodeTryApiGetForBasstreetOffsetUnits;
    if(streetOffsetUnits != ''){
      tmpData['streetOffsetUnits'] = streetOffsetUnits;
    }
    var cornerOffset:any = this.geoCodeTryApiGetForBascornerOffset;
    if(cornerOffset != ''){
      tmpData['cornerOffset'] = cornerOffset;
    }
    var cornerOffsetUnits = this.geoCodeTryApiGetForBascornerOffsetUnits;
    if(cornerOffsetUnits != ''){
      tmpData['cornerOffsetUnits'] = cornerOffsetUnits;
    }
    tmpData['removeAccentMarks'] = this.forbas_removeAccentMarks;
    if(!isNaN(maxCands) && !isNaN(streetOffset) &&  !isNaN(cornerOffset)){
      var methodName = '/tryApigeoCodeGetForBas';
      let requestUri = this.liapiservice.getCompleteUri('geocode', 'Get Forward Geocode Basic', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_forbasUri = requestUri;
          this.get_forbasResType = dataType;
          if(this.get_forbasResStatus.includes('429')){
            this.get_forbasUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.get_forbasResStatus = '200 success';
          this.get_forbasRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_forbasResStatus = `${error.status} ${error.statusText}`;
            this.get_forbasRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
    }
    else{
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_forbasUri = "";
  public post_forbasRes = null;
  public post_forbasResStatus = "";
  public post_forbasResType = "";
  tryApigeoCodePostForBas = function(btnId){
    var postData = '';
    if (this.forbasaddrtype === 'single') {
      postData = this.singleAddressBasJson;
    }
    else if (this.forbasaddrtype === 'multi') {
      postData = this.multiAddressBasJson;
    }
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else{
      var methodName = '/tryApigeoCodePostForBas';
      let requestUri = this.liapiservice.getCompleteUriPost('geocode', 'Post Forward Geocode Basic');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_forbasUri = requestUri;
          this.post_forbasResType = 'json';
          if(this.post_forbasResStatus.includes('429')){
            this.post_forbasUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.post_forbasResStatus = '200 success';
          this.post_forbasRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.post_forbasResStatus = `${error.status} ${error.statusText}`;
            this.post_forbasRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
    }
  };
  public geoCodeTryApiGetRevPrelongitude = -105.240976;
  public geoCodeTryApiGetRevPrelatitude = 40.018301;
  public geoCodeTryApiGetRevPreDataType = 'json';
  public geoCodeTryApiGetRevPrecoordSysName = 'EPSG:4326';
  public geoCodeTryApiGetRevPredistance = 150;
  public geoCodeTryApiGetRevPredistanceUnits = 'METERS';
  public get_revpreUri = "";
  public get_revpreRes = null;
  public get_revpreResStatus = "";
  public get_revpreResType = "";
  tryApigeoCodeGetRevPre = function(btnId){
    var x:any = this.geoCodeTryApiGetRevPrelongitude;
    var y:any = this.geoCodeTryApiGetRevPrelatitude;
    var dataType = this.geoCodeTryApiGetRevPreDataType;
    var tmpData = {
      x: x,
      y: y,
      dataType: dataType
    };
    if (this.revprecountry != '') {
      if (this.revprecountry.code) {
        tmpData['country'] = this.revprecountry.code;
      }
      else {
        tmpData['country'] = this.revprecountry;
      }
    }
    var coordSysName = this.geoCodeTryApiGetRevPrecoordSysName;
    if(coordSysName != ''){
      tmpData['coordSysName'] = coordSysName;
    }
    var distance:any = this.geoCodeTryApiGetRevPredistance;
    if(distance != ''){
      tmpData['distance'] = distance;
    }
    var distanceUnits = this.geoCodeTryApiGetRevPredistanceUnits;
    if(distanceUnits != ''){
      tmpData['distanceUnits'] = distanceUnits;
    }
    if( x != '' && y != ''){
      if(!isNaN(x) && !isNaN(y) && !isNaN(distance)){
        var methodName = '/tryApigeoCodeGetRevPre';
        let requestUri = this.liapiservice.getCompleteUri('geocode', 'Get Reverse Geocode Premium', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_revpreUri = requestUri;
            this.get_revpreResType = dataType;
            if(this.get_revpreResStatus.includes('429')){
              this.get_revpreUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
        )).subscribe(
          success => {
            this.get_revpreResStatus = '200 success';
            this.get_revpreRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_revpreResStatus = `${error.status} ${error.statusText}`;
              this.get_revpreRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
      }
      else{
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else{
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_revpreUri = "";
  public post_revpreRes = null;
  public post_revpreResStatus = "";
  public post_revpreResType = "";
  tryApigeoCodePostRevPre = function(btnId){
    var postData = this.revpreJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else{
      var methodName = '/tryApigeoCodePostRevPre';
      let requestUri = this.liapiservice.getCompleteUriPost('geocode', 'Post Reverse Geocode Premium');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_revpreUri = requestUri;
          this.post_revpreResType = 'json';
          if(this.post_revpreResStatus.includes('429')){
            this.post_revpreUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.post_revpreResStatus = '200 success';
          this.post_revpreRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.post_revpreResStatus = `${error.status} ${error.statusText}`;
            this.post_revpreRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
    }
  };
  public geoCodeTryApiGetRevAdvlongitude = -105.240976;
  public geoCodeTryApiGetRevAdvlatitude = 40.018301;
  public geoCodeTryApiGetRevAdvDataType = 'json';
  public geoCodeTryApiGetRevAdvcoordSysName = 'EPSG:4326';
  public geoCodeTryApiGetRevAdvdistance = 150;
  public geoCodeTryApiGetRevAdvdistanceUnits = 'METERS';
  public get_revadvUri = "";
  public get_revadvRes = null;
  public get_revadvResStatus = "";
  public get_revadvResType = "";
  tryApigeoCodeGetRevAdv = function(btnId){
    var x:any = this.geoCodeTryApiGetRevAdvlongitude;
    var y:any = this.geoCodeTryApiGetRevAdvlatitude;
    var dataType = this.geoCodeTryApiGetRevAdvDataType;
    var tmpData = {
      x: x,
      y: y,
      dataType: dataType
    };
    if (this.revadvcountry != '') {
      if (this.revadvcountry.code) {
        tmpData['country'] = this.revadvcountry.code;
      }
      else {
        tmpData['country'] = this.revadvcountry;
      }
    }
    var coordSysName = this.geoCodeTryApiGetRevAdvcoordSysName;
    if(coordSysName != ''){
      tmpData['coordSysName'] = coordSysName;
    }
    var distance:any = this.geoCodeTryApiGetRevAdvdistance;
    if(distance != ''){
      tmpData['distance'] = distance;
    }
    var distanceUnits = this.geoCodeTryApiGetRevAdvdistanceUnits;
    if(distanceUnits != ''){
      tmpData['distanceUnits'] = distanceUnits;
    }
    if( x != '' && y != ''){
      if(!isNaN(x) && !isNaN(y) && !isNaN(distance)){
        var methodName = '/tryApigeoCodeGetRevAdv';
        let requestUri = this.liapiservice.getCompleteUri('geocode', 'Get Reverse Geocode Advanced', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_revadvUri = requestUri;
            this.get_revadvResType = dataType;
            if(this.get_revadvResStatus.includes('429')){
              this.get_revadvUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
        )).subscribe(
          success => {
            this.get_revadvResStatus = '200 success';
            this.get_revadvRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_revadvResStatus = `${error.status} ${error.statusText}`;
              this.get_revadvRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
      }
      else{
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else{
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_revadvUri = "";
  public post_revadvRes = null;
  public post_revadvResStatus = "";
  public post_revadvResType = "";
  tryApigeoCodePostRevAdv = function(btnId){
    var postData = this.revadvJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else{
      var methodName = '/tryApigeoCodePostRevAdv';
      let requestUri = this.liapiservice.getCompleteUriPost('geocode', 'Post Reverse Geocode Advanced');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_revadvUri = requestUri;
          this.post_revadvResType = 'json';
          if(this.post_revadvResStatus.includes('429')){
            this.post_revadvUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.post_revadvResStatus = '200 success';
          this.post_revadvRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.post_revadvResStatus = `${error.status} ${error.statusText}`;
            this.post_revadvRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
    }
  };
  public geoCodeTryApiGetRevBaslongitude = -105.240976;
  public geoCodeTryApiGetRevBaslatitude = 40.018301;
  public geoCodeTryApiGetRevBasDataType = 'json';
  public geoCodeTryApiGetRevBascoordSysName = 'EPSG:4326';
  public geoCodeTryApiGetRevBasdistance = 150;
  public geoCodeTryApiGetRevBasdistanceUnits = 'METERS';
  public get_revbasUri = "";
  public get_revbasRes = null;
  public get_revbasResStatus = "";
  public get_revbasResType = "";
  tryApigeoCodeGetRevBas = function(btnId){
    var x:any = this.geoCodeTryApiGetRevBaslongitude;
    var y:any = this.geoCodeTryApiGetRevBaslatitude;
    var dataType = this.geoCodeTryApiGetRevBasDataType;
    var tmpData = {
      x: x,
      y: y,
      dataType: dataType
    };
    if (this.revbascountry != '') {
      if (this.revbascountry.code) {
        tmpData['country'] = this.revbascountry.code;
      }
      else {
        tmpData['country'] = this.revbascountry;
      }
    }
    var coordSysName = this.geoCodeTryApiGetRevBascoordSysName;
    if(coordSysName != ''){
      tmpData['coordSysName'] = coordSysName;
    }
    var distance:any = this.geoCodeTryApiGetRevBasdistance;
    if(distance != ''){
      tmpData['distance'] = distance;
    }
    var distanceUnits = this.geoCodeTryApiGetRevBasdistanceUnits;
    if(distanceUnits != ''){
      tmpData['distanceUnits'] = distanceUnits;
    }
    if( x != '' && y != ''){
      if(!isNaN(x) && !isNaN(y) && !isNaN(distance)){
        var methodName = '/tryApigeoCodeGetRevBas';
        let requestUri = this.liapiservice.getCompleteUri('geocode', 'Get Reverse Geocode Basic', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_revbasUri = requestUri;
            this.get_revbasResType = dataType;
            if(this.get_revbasResStatus.includes('429')){
              this.get_revbasUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
        )).subscribe(
          success => {
            this.get_revbasResStatus = '200 success';
            this.get_revbasRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_revbasResStatus = `${error.status} ${error.statusText}`;
              this.get_revbasRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
      }
      else{
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else{
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_revbasUri = "";
  public post_revbasRes = null;
  public post_revbasResStatus = "";
  public post_revbasResType = "";
  tryApigeoCodePostRevBas = function(btnId){
    var postData = this.revbasJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else{
      var methodName = '/tryApigeoCodePostRevBas';
      let requestUri = this.liapiservice.getCompleteUriPost('geocode', 'Post Reverse Geocode Basic');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_revbasUri = requestUri;
          this.post_revbasResType = 'json';
          if(this.post_revbasResStatus.includes('429')){
            this.post_revbasUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.post_revbasResStatus = '200 success';
          this.post_revbasRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.post_revbasResStatus = `${error.status} ${error.statusText}`;
            this.post_revbasRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
    }
  };
  public geoCodeTryApiGetPBKeyaddress = '4750 Walnut St., Boulder CO, 80301';
  public geoCodeTryApiGetPBKeycountry = 'USA';
  public geoCodeTryApiGetPBKeyDataType = 'json';
  public get_pbkeyUri = "";
  public get_pbkeyRes = null;
  public get_pbkeyResStatus = "";
  public get_pbkeyResType = "";
  tryApigeoCodeGetPBKey = function(btnId){
    var address = this.geoCodeTryApiGetPBKeyaddress;
    var country = this.geoCodeTryApiGetPBKeycountry;
    var dataType = this.geoCodeTryApiGetPBKeyDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if(country != ''){
      tmpData['country'] = country;
    }
    if(address != ''){
      var methodName = '/tryApigeoCodeGetPBKey';
      let requestUri = this.liapiservice.getCompleteUri('geocode', 'Get PreciselyID By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_pbkeyUri = requestUri;
          this.get_pbkeyResType = dataType;
          if(this.get_pbkeyResStatus.includes('429')){
            this.get_pbkeyUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.get_pbkeyResStatus = '200 success';
          this.get_pbkeyRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_pbkeyResStatus = `${error.status} ${error.statusText}`;
            this.get_pbkeyRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
    }
    else{
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_pbkeyUri = "";
  public post_pbkeyRes = null;
  public post_pbkeyResStatus = "";
  public post_pbkeyResType = "";
  tryApigeoCodePostPBKey = function(btnId){
    var postData = this.pbkeyJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else{
      var methodName = '/tryApigeoCodePostPBKey';
      let requestUri = this.liapiservice.getCompleteUriPost('geocode', 'Post PreciselyID By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_pbkeyUri = requestUri;
          this.post_pbkeyResType = 'json';
          if(this.post_pbkeyResStatus.includes('429')){
            this.post_pbkeyUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.post_pbkeyResStatus = '200 success';
          this.post_pbkeyRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.post_pbkeyResStatus = `${error.status} ${error.statusText}`;
            this.post_pbkeyRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
    }
  };
  public geoCodeTryApiGetKeyLookupkey = 'P00003PZ0BUF';
  public geoCodeTryApiGetKeyLookuptype = 'PB_Key';
  public geoCodeTryApiGetKeyLookupcountry = '';
  public geoCodeTryApiGetKeyLookupDataType = 'json';
  public get_keylookUri = "";
  public get_keylookRes = null;
  public get_keylookResStatus = "";
  public get_keylookResType = "";
  tryApigeoCodeGetKeyLookup = function(btnId){
    var key = this.geoCodeTryApiGetKeyLookupkey;
    var type = this.geoCodeTryApiGetKeyLookuptype;
    var country = this.geoCodeTryApiGetKeyLookupcountry;
    var dataType = this.geoCodeTryApiGetKeyLookupDataType;
    var tmpData = {
      key: key,
      type: type,
      dataType: dataType
    };
    if(country != ''){
      tmpData['country'] = country;
    }
    if(key != '' && type != ''){
      var methodName = '/tryApigeoCodeGetKeyLookup';
      let requestUri = this.liapiservice.getCompleteUri('geocode', 'Get Key Lookup', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_keylookUri = requestUri;
          this.get_keylookResType = dataType;
          if(this.get_keylookResStatus.includes('429')){
            this.get_keylookUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.get_keylookResStatus = '200 success';
          this.get_keylookRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_keylookResStatus = `${error.status} ${error.statusText}`;
            this.get_keylookRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
    }
    else{
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_keylookUri = "";
  public post_keylookRes = null;
  public post_keylookResStatus = "";
  public post_keylookResType = "";
  tryApigeoCodePostKeyLookup = function(btnId){
    var postData = this.keylookupJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else{
      var methodName = '/tryApigeoCodePostKeyLookup';
      let requestUri = this.liapiservice.getCompleteUriPost('geocode', 'Post Key Lookup');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_keylookUri = requestUri;
          this.post_keylookResType = 'json';
          if(this.post_keylookResStatus.includes('429')){
            this.post_keylookUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.post_keylookResStatus = '200 success';
          this.post_keylookRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.post_keylookResStatus = `${error.status} ${error.statusText}`;
            this.post_keylookRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
    }
  };
}
