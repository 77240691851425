import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ResourceService } from '../service/resource.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'geocode-gis',
  templateUrl: './geocodegis.component.html'
})
export class GeocodeGisComponent implements OnInit {
  public downloadableURL: any = "";
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private resourceService: ResourceService, private metaTagService: Meta) {
  };
  ngOnInit() {
    window.scroll(0,0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'arcgis geocoding api' }
    );
    let downloadableURL = localStorage.getItem("downloadableURL");
    if(!downloadableURL || downloadableURL == "https://learnpages-assets-qa.s3.amazonaws.com/downloads/9.5/"){
        this.resourceService.getDownloadUrl().subscribe(
            success => {
                this.downloadableURL = success.downloadableURL;
            }
        );
    }
    else{
        this.downloadableURL = downloadableURL;
    }
  };
  downloadFile = function (event, fileName) {
    event.preventDefault();
    let downloadUrl = this.downloadableURL + fileName;
    let a = document.createElement('A') as HTMLAnchorElement;
    a.href = decodeURI(encodeURI(downloadUrl));
    a.download = decodeURI(encodeURI(fileName));
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
}
