import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import $ from 'jquery';
import * as L from "leaflet";

@Component({
  selector: 'places-demo',
  templateUrl: './placesdemo.component.html'
})
export class PlacesDemoComponent implements OnInit {
  public activetab = "byaddr";
  public poisearchedaddr = '';
  public poiaddrType = '';
  public poiareaType = '';
  public poiboundType = '';
  public poiautoType = '';
  public searchText = '';
  public poiaddrName = '';
  public poiareaName = '';
  public poiboundName = '';
  public poiareaZip = '';
  public poiautoZip = '';
  public poiareaCity = 'Troy';
  public poiautoCity = '';
  public poiareaState = 'NY';
  public poiautoState = '';
  public addrMatchMode = 'Specific';
  public areaMatchMode = 'Specific';
  public boundMatchMode = 'Specific';
  public autoMatchMode = 'Relaxed';
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public autoMarker;
  public resMarkers = [];
  public polygon;
  public byAddrCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public byAreaCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public autoCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public byAddrCountryCode = 'USA';
  public autoCountryCode = 'USA';
  public byaddrres = [];
  public byaddrloc = [];
  public byareares = [];
  public byboundaryres = [];
  public geometryCoord = '';
  public autocompleteres = [];
  public typeIconArr = [{ name: 'car rental', iconClass: 'pbpoi-comm_rental_car' }, { name: 'local and suburban trnsit', iconClass: 'pbpoi-ptran_public_transit' }, { name: 'airport', iconClass: 'pbpoi-ent_airport' }, { name: 'drug store', iconClass: 'pbpoi-hcare_pharmacy' }, { name: 'restaurant', iconClass: 'pbpoi-ret_food' }, { name: 'gas station', iconClass: 'pbpoi-comm_gas_station' }, { name: 'hospital', iconClass: 'pbpoi-hos_hospital' }, { name: 'school', iconClass: 'pbpoi-edu_school_university' }, { name: 'park', iconClass: 'pbpoi-par_park' }, { name: 'cinema', iconClass: 'pbpoi-ent_cinema' }, { name: 'shopping center', iconClass: 'pbpoi-ret_shopping_center' }, { name: 'police', iconClass: 'pbpoi-gov_police_station' }, { name: 'fire station', iconClass: 'pbpoi-gov_fire_station' }, { name: 'tourist attraction', iconClass: 'pbpoi-tour_tourist_attractions' }, { name: 'bank', iconClass: 'pbpoi-busi_bank' }, { name: 'atm', iconClass: 'pbpoi-busi_ATM' }];
  countries: any[];
  filteredCountries: any[];
  pois: any[];
  filteredPoi: any[];
  filteredSearch: any[];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService) { };
  ngOnInit() {
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    this.liapiservice.getPoiTypes().subscribe(
      success => {
        this.pois = success;
      }
    );
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  ngDoCheck() {
    if (this.byAddrCountry.code) {
      this.byAddrCountryCode = this.byAddrCountry.code;
    }
    else {
      this.byAddrCountryCode = this.byAddrCountry.toString();
    }
    if (this.autoCountry.code) {
      this.autoCountryCode = this.autoCountry.code;
    }
    else {
      this.autoCountryCode = this.autoCountry.toString();
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  filterPoi = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.pois.length; i++) {
      let poi = this.pois[i];
      if (poi.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(poi);
      }
    }
    this.filteredPoi = filtered;
  };
  filterPoiAuto = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    this.liapiservice.poiAutocomplete(query, this.autoMatchMode, this.poiautoZip, this.autoCountryCode, this.poiautoCity, this.poiautoState, this.poiautoType).subscribe(
      success => {
        filtered = success;
        this.filteredSearch = filtered;
      }
    );
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
    let that = this;
    this.map.on('click', (e: L.LeafletMouseEvent) => {
      that.mapclickHandler(e.latlng);
    });
  };
  mapclickHandler = function (latlng) {
    if (this.activetab == 'byaddr') {
      this.poibylocation(latlng.lat, latlng.lng);
    }
  };
  sampleDemoGeoMapShareLocation = function () {
    let that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude;
        let lng = position.coords.longitude;
        that.poibylocation(lat, lng);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    this.removeMarkerLayers();
    if (this.activetab == 'byaddr') {
      setTimeout(() => {
        $('.pb-geo-input-text').val(this.poisearchedaddr);
      }, 100);
      if (this.newMarker != undefined) {
        this.map.addLayer(this.newMarker);
      }
      if (this.byaddrres.length != 0) {
        this.renderpoiresponse(this.byaddrres);
      }
    }
    else if (this.activetab == 'byarea') {
      if (this.byareares.length != 0) {
        this.renderpoiresponse(this.byareares);
      }
    }
    else if (this.activetab == 'byboundary') {
      if (this.byboundaryres.length != 0) {
        if (this.geometryCoord != '') {
          this.renderpoiresponse(this.byboundaryres);
          let geometryCoordArr = JSON.parse(this.geometryCoord);
          let locationsArr = [];
          for (let i = 0; i < geometryCoordArr.length; i++) {
            locationsArr.push([geometryCoordArr[i][1], geometryCoordArr[i][0]]);
          }
          let geometry: any = {
            type: "Polygon",
            coordinates: [geometryCoordArr]
          }
          this.polygon = L.geoJSON(geometry as any).addTo(this.map);
          this.map.fitBounds(locationsArr);
        }
      }
    }
    else if (this.activetab == 'autocomplete') {
      if (this.autocompleteres.length != 0) {
        this.renderautocompleteresponse();
      }
    }
  };
  removeMarkerLayers = function () {
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    if (this.autoMarker != undefined) {
      this.map.removeLayer(this.autoMarker);
    }
    if (this.resMarkers.length != 0) {
      for (let i = 0; i < this.resMarkers.length; i++) {
        this.map.removeLayer(this.resMarkers[i]);
      }
    }
    this.resMarkers = [];
    if (this.polygon != undefined) {
      this.map.removeLayer(this.polygon);
    };
  };
  poibylocation = function (lat, lng) {
    $('.geoApisDemoMapLoader').show();
    $('#geoEnrichPoisByAddrError').hide();
    $('#geoEnrichPoisByAddrResult').html('');
    this.byaddrloc = [[lat, lng]];
    this.removeMarkerLayers();
    this.map.setView(L.latLng(lat, lng), 14);
    this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
    let tmpData = {
      dataType: 'json',
      x: lng,
      y: lat
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApigeoCodeGetRevPre', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          let tmpAddr = "";
          if (success.candidates[0].address.mainAddressLine) {
            tmpAddr += success.candidates[0].address.mainAddressLine;
          }
          if (success.candidates[0].address.mainAddressLine && success.candidates[0].address.addressLastLine) {
            tmpAddr += ', ';
          }
          if (success.candidates[0].address.addressLastLine) {
            tmpAddr += success.candidates[0].address.addressLastLine;
          }
          this.liapiservice.getCountryObj(success.candidates[0].address.country).subscribe(
            success => {
              this.byAddrCountry = success;
            }
          );
          this.poisearchedaddr = tmpAddr;
          $('.pb-geo-input-text').val(tmpAddr);
          this.sampleDemoPlacesByLocation(lat, lng);
        }
        else {
          this.byaddrres = [];
          this.poisearchedaddr = '';
          $('.pb-geo-input-text').val('');
          $('#geoEnrichPoisByAddrError').html('No information available for this location.');
          $('#geoEnrichPoisByAddrError').show();
          $('.geoApisDemoMapLoader').hide();
        }

      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  poibyaddress = function () {
    this.poisearchedaddr = $('.pb-geo-input-text').val().toString();
    let country = '';
    if (this.byAddrCountry != '') {
      if (this.byAddrCountry.code) {
        country = this.byAddrCountry.code;
      }
      else {
        country = this.byAddrCountry;
      }
    }
    this.removeMarkerLayers();
    this.byaddrloc = [];
    $('#geoEnrichPoisByAddrError').hide();
    $('#geoEnrichPoisByAddrResult').html('');
    if (this.poisearchedaddr != '') {
      this.geoApisGetLocationGeocode(this.poisearchedaddr, country);
    }
    else {
      this.byaddrres = [];
      $('#geoEnrichPoisByAddrError').html('Address cannot be blank.');
      $('#geoEnrichPoisByAddrError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    let tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          let lat = success.candidates[0].geometry.coordinates[1];
          let lng = success.candidates[0].geometry.coordinates[0];
          this.map.setView(L.latLng(lat, lng), 14);
          this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
          this.byaddrloc.push([lat, lng]);
          this.sampleDemoPlacesByLocation(lat, lng);
        }
        else {
          this.byaddrres = [];
          $('#geoEnrichPoisByAddrError').html('No information available for this address.');
          $('#geoEnrichPoisByAddrError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoPlacesByLocation = function (lat, lng) {
    this.byaddrres = [];
    let tmpData = {
      latitude: lat,
      longitude: lng
    };
    if (this.poiaddrType != '') {
      if (this.poiaddrType.label) {
        tmpData['type'] = this.poiaddrType.label;
      }
      else {
        tmpData['type'] = this.poiaddrType;
      }
    }
    if (this.poiaddrName != '') {
      tmpData['name'] = this.poiaddrName;
    }
    tmpData['matchMode'] = this.addrMatchMode;
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoEnrichPOIByLoc', tmpData).subscribe(
      success => {
        if (success.poi) {
          this.byaddrres = success.poi;
          this.renderpoiresponse(this.byaddrres);
        }
        else {
          $('#geoEnrichPoisByAddrError').html('No information available for this address/location.');
          $('#geoEnrichPoisByAddrError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoEnrichPoisByAddrError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoEnrichPoisByAddrError').html(error.statusText);
        }
        $('#geoEnrichPoisByAddrError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoEnrichPoisByAddrError').hide();
        }
      }
    );
  };
  renderpoiresponse = function (resArr) {
    let eleId;
    let geoplaceType: any;
    let categoryClass = '';
    if (this.activetab == 'byaddr') {
      eleId = 'geoEnrichPoisByAddrResult';
      geoplaceType = this.poiaddrType;
    }
    else if (this.activetab == 'byarea') {
      eleId = 'geoEnrichPoisByAreaResult';
      geoplaceType = this.poiareaType;
    }
    else if (this.activetab == 'byboundary') {
      eleId = 'geoEnrichPoisByBoundaryResult';
      geoplaceType = this.poiboundType;
    }
    if (geoplaceType != '') {
      if (geoplaceType.label) {
        categoryClass = geoplaceType.label;
      }
      else {
        categoryClass = geoplaceType;
      }
    }
    let tmpHtml = '<div class="accordion" id="' + eleId + '_accordiondemo">';
    this.renderpoiresponsemarker(resArr[0].geometry.coordinates[1], resArr[0].geometry.coordinates[0]);
    for (let i = 0; i < resArr.length; i++) {
      let latitude = resArr[i].geometry.coordinates[1];//latitude
      let longitude = resArr[i].geometry.coordinates[0];//longitude
      tmpHtml += '<div class="card">';
      tmpHtml += '<div class="card-header" id="ahj_head' + i + '">';
      tmpHtml += '<h5 class="mb-0">';
      tmpHtml += '<button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#ahj_content' + i + '" aria-controls="#ahj_content' + i + '"';
      if (i != 0) {
        tmpHtml += ' aria-expanded="false"';
      }
      else {
        tmpHtml += ' aria-expanded="true"';
      }
      tmpHtml += ' latitude="' + latitude + '" longitude="' + longitude + '"><span class="accordion-marker-icon';
      if(categoryClass == ''){
        categoryClass = resArr[i].poiClassification.category.subClass.toLowerCase();
      }
      for (let j = 0; j < this.typeIconArr.length; j++) {
        let typeName = this.typeIconArr[j]['name'];
        if (categoryClass.includes(typeName)) {
          tmpHtml += ' ' + this.typeIconArr[j].iconClass;
          break;
        }
      }
      tmpHtml += '"></span> ' + resArr[i].name + '</button></h5></div>';
      tmpHtml += '<div id="ahj_content' + i + '" class="collapse';
      if (i == 0) {
        tmpHtml += ' show';
      }
      tmpHtml += '" aria-labelledby="ahj_head' + i + '" data-parent="#' + eleId + '_accordiondemo"><div class="card-body">';
      tmpHtml += '<table class="table table-condensed table-unstyled">';
      tmpHtml += '<tr><td colspan="2"><div class="resultKey">Address</div><div class="resultAccVal">';
      if (resArr[i].contactDetails.address.mainAddressLine) {
        tmpHtml += resArr[i].contactDetails.address.mainAddressLine + '<br/>';
      }
      tmpHtml += resArr[i].contactDetails.address.addressLastLine + '<br/>';
      tmpHtml += resArr[i].contactDetails.address.country + '</div></td></tr>';
      tmpHtml += '<tr><td><div class="resultKey">Phone</div><div class="resultAccVal">';
      if (resArr[i].contactDetails.phone) {
        tmpHtml += resArr[i].contactDetails.phone;
      }
      else {
        tmpHtml += '-';
      }
      tmpHtml += '</div></td>';
      tmpHtml += '<td><div class="resultKey">Distance</div><div class="resultAccVal">';
      if (resArr[i].distance) {
        tmpHtml += resArr[i].distance.value + ' ' + resArr[i].distance.unit;
      }
      else {
        tmpHtml += '-';
      }
      tmpHtml += '</div></td></tr>';
      tmpHtml += '<tr><td><div class="resultKey">Latitude</div><div class="resultAccVal">' + resArr[i].geometry.coordinates[1] + '</div></td>';
      tmpHtml += '<td><div class="resultKey">Longitude</div><div class="resultAccVal">' + resArr[i].geometry.coordinates[0] + '</div></td></tr>';
      tmpHtml += '</table>';
      tmpHtml += '</div></div></div>';
    }
    tmpHtml += '</div>';
    $('#' + eleId).html(tmpHtml);
    let that = this;
    $('#' + eleId + '_accordiondemo .card .card-header h5 button').on('click', function () {
      let lat = $(this).attr('latitude');
      let lng = $(this).attr('longitude');
      that.renderpoiresponsemarker(lat, lng);
    });
  };
  renderpoiresponsemarker = function (lat, lng) {
    let resArr = [];
    let geoplaceType: any;
    let categoryClass = '';
    if (this.activetab == 'byaddr') {
      resArr = this.byaddrres;
      geoplaceType = this.poiaddrType;
    }
    else if (this.activetab == 'byarea') {
      resArr = this.byareares;
      geoplaceType = this.poiareaType;
    }
    else if (this.activetab == 'byboundary') {
      resArr = this.byboundaryres;
      geoplaceType = this.poiboundType;
    }
    if (geoplaceType != '') {
      if (geoplaceType.label) {
        categoryClass = geoplaceType.label;
      }
      else {
        categoryClass = geoplaceType;
      }
    }
    let locationsArr = [];
    if (this.resMarkers.length != 0) {
      for (let i = 0; i < this.resMarkers.length; i++) {
        this.map.removeLayer(this.resMarkers[i]);
      }
    }
    this.resMarkers = [];
    let matchString = '';
    let clusterCount = 1;
    for (let i = 0; i < resArr.length; i++) {
      let latitude = resArr[i].geometry.coordinates[1];//latitude
      let longitude = resArr[i].geometry.coordinates[0];//longitude
      if (matchString == '') {
        matchString = latitude + '_' + longitude;
      }
      else {
        if (matchString != latitude + '_' + longitude) {
          matchString = latitude + '_' + longitude;
          clusterCount = 1;
        }
        else {
          clusterCount++;
        }
      }
      let iconClass = 'placePoiMarker';
      if (latitude == lat && longitude == lng) {
        iconClass += ' active';
      }
      if (clusterCount == 1) {
        if(categoryClass == ''){
          categoryClass = resArr[i].poiClassification.category.subClass.toLowerCase();
        }
        for (let j = 0; j < this.typeIconArr.length; j++) {
          let typeName = this.typeIconArr[j]['name'];
          if (categoryClass.includes(typeName)) {
            iconClass += ' ' + this.typeIconArr[j].iconClass;
            break;
          }
        }
      }
      let markerText = '';
      if (clusterCount > 1) {
        markerText = clusterCount.toString();
      }
      let resMarkerIcon = {
        icon: L.divIcon({
          iconSize: [28, 28],
          iconAnchor: [14, 14],
          className: iconClass,
          html: markerText
        })
      };
      let resMarker = L.marker([latitude, longitude], resMarkerIcon).addTo(this.map);
      this.resMarkers.push(resMarker);
      locationsArr.push([latitude, longitude]);
    }
    this.map.fitBounds(locationsArr);
  };
  poibyarea = function () {
    this.byareares = [];
    let country = '';
    if (this.byAreaCountry != '') {
      if (this.byAreaCountry.code) {
        country = this.byAreaCountry.code;
      }
      else {
        country = this.byAreaCountry;
      }
    }
    this.removeMarkerLayers();
    $('#geoEnrichPoisByAreaError').hide();
    $('#geoEnrichPoisByAreaResult').html('');
    if (country != '') {
      if (this.poiareaCity != '' || this.poiareaZip != '') {
        let tmpData = {
          country: country
        };
        if (this.poiareaState != '') {
          tmpData['state'] = this.poiareaState;
        }
        if (this.poiareaCity != '') {
          tmpData['city'] = this.poiareaCity;
        }
        if (this.poiareaZip != '') {
          tmpData['zip'] = this.poiareaZip;
        }
        if (this.poiareaType != '') {
          if (this.poiareaType.label) {
            tmpData['type'] = this.poiareaType.label;
          }
          else {
            tmpData['type'] = this.poiareaType;
          }
        }
        if (this.poiareaName != '') {
          tmpData['name'] = this.poiareaName;
        }
        tmpData['matchMode'] = this.areaMatchMode;
        $('.geoApisDemoMapLoader').show();
        this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoEnrichPOIByArea', tmpData).subscribe(
          success => {
            if (success.poi) {
              this.byareares = success.poi;
              this.renderpoiresponse(this.byareares);
            }
            else {
              $('#geoEnrichPoisByAreaError').html('No information available for this address/location.');
              $('#geoEnrichPoisByAreaError').show();
            }
            $('.geoApisDemoMapLoader').hide();
          },
          error => {
            if (error.status == 401) {
              window.location.href = "/signin";
            }
            if (error.error) {
              $('#geoEnrichPoisByAreaError').html(error.error.errors[0].errorDescription);
            }
            else {
              $('#geoEnrichPoisByAreaError').html(error.statusText);
            }
            $('#geoEnrichPoisByAreaError').show();
            $('.geoApisDemoMapLoader').hide();
            if (error.status == '429') {
              this.liapiservice.showPrimeInfo();
              $('#geoEnrichPoisByAreaError').hide();
            }
          }
        );
      }
      else {
        $('#geoEnrichPoisByAreaError').html('Please provide either city or zip.');
        $('#geoEnrichPoisByAreaError').show();
      }
    }
    else {
      $('#geoEnrichPoisByAreaError').html('Country is missing.');
      $('#geoEnrichPoisByAreaError').show();
    }
  };
  poibyboundary = function () {
    this.byboundaryres = [];
    this.removeMarkerLayers();
    $('#geoEnrichPoisByBoundaryError').hide();
    $('#geoEnrichPoisByBoundaryResult').html('');
    let postData = {};
    if (this.poiboundName != '') {
      postData["name"] = this.poiboundName;
    }
    postData['matchMode'] = this.boundMatchMode;
    postData['specificMatchOn'] = 'ANY'
    if (this.poiboundType != '') {
      if (this.poiboundType.label) {
        postData["type"] = this.poiboundType.label;
      }
      else {
        postData["type"] = this.poiboundType;
      }
    }
    postData["geometry"] = {
      type: "Polygon",
      crs: {
        type: "name",
        properties: {
          name: "epsg:4326"
        }
      },
      coordinates: [[]]
    };
    this.geometryCoord = $('#geoEnrichDemoBoundaryGeometry').val().toString();
    if (this.geometryCoord != '') {
      postData["geometry"].coordinates = JSON.parse(this.geometryCoord);
    }
    if (this.geometryCoord != '') {
      let geometryCoordArr = JSON.parse(this.geometryCoord);
      let locationsArr = [];
      for (let i = 0; i < geometryCoordArr.length; i++) {
        locationsArr.push([geometryCoordArr[i][1], geometryCoordArr[i][0]]);
      }
      let geometry: any = {
        type: "Polygon",
        coordinates: [geometryCoordArr]
      }
      this.polygon = L.geoJSON(geometry as any).addTo(this.map);
      this.map.fitBounds(locationsArr);
      $('.geoApisDemoMapLoader').show();
      let tmpData = {
        data: JSON.stringify(postData)
      };
      this.liapiservice.geoApiSampleDemoPostCall('/tryApiGeoEnrichPOIByBoundary', tmpData).subscribe(
        success => {
          let result = success;
          if (result.poi) {
            this.byboundaryres = result.poi;
            this.renderpoiresponse(this.byboundaryres);
          }
          else {
            $('#geoEnrichPoisByBoundaryError').html('No information available for this address/location.');
            $('#geoEnrichPoisByBoundaryError').show();
          }
          $('.geoApisDemoMapLoader').hide();
        },
        error => {
          if (error.status == 401) {
            window.location.href = "/signin";
          }
          if (error.error) {
            let errorObj = JSON.parse(error.error);
            $('#geoEnrichPoisByBoundaryError').html(errorObj.errors[0].errorDescription);
          }
          else {
            $('#geoEnrichPoisByBoundaryError').html(error.statusText);
          }
          $('#geoEnrichPoisByBoundaryError').show();
          $('.geoApisDemoMapLoader').hide();
          if (error.status == '429') {
            this.liapiservice.showPrimeInfo();
            $('#geoEnrichPoisByBoundaryError').hide();
          }
        }
      );
    }
    else {
      $('#geoEnrichPoisByBoundaryError').html('Geometry Coordinates are missing.');
      $('#geoEnrichPoisByBoundaryError').show();
    }
  };
  poibyid = function (poiId) {
    this.autocompleteres = [];
    $('.geoApisDemoMapLoader').show();
    $('#geoEnrichPoisAutocompleteError').hide();
    $('#geoEnrichPoisAutocompleteResult').html('');
    this.removeMarkerLayers();
    let tmpData = {
      dataType: 'json',
      id: poiId
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApiGeoEnrichPOIById', tmpData).subscribe(
      success => {
        this.autocompleteres = success;
        this.renderautocompleteresponse();
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoEnrichPoisAutocompleteError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoEnrichPoisAutocompleteError').html(error.statusText);
        }
        $('#geoEnrichPoisAutocompleteError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoEnrichPoisAutocompleteError').hide();
        }
      }
    );
  };
  renderautocompleteresponse = function () {
    this.removeMarkerLayers();
    let lat = this.autocompleteres.geometry.coordinates[1];
    let lng = this.autocompleteres.geometry.coordinates[0];
    this.map.setView(L.latLng(lat, lng), 14);
    this.autoMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
    var tmpHtml = '<h2 class="zeromargintop">' + this.autocompleteres.name + '</h2><table class="table table-condensed table-unstyled">';
    tmpHtml += '<tr><td><div class="resultKey">Address</div><div class="resultVal">';
    if (this.autocompleteres.contactDetails.address.mainAddressLine) {
      tmpHtml += this.autocompleteres.contactDetails.address.mainAddressLine + '<br/>';
    }
    tmpHtml += this.autocompleteres.contactDetails.address.addressLastLine + '<br/>';
    tmpHtml += this.autocompleteres.contactDetails.address.country + '</div></td></tr>';
    tmpHtml += '<tr><td colspan="2"><div class="resultKey">Phone</div><div class="resultVal">';
    if (this.autocompleteres.contactDetails.phone) {
      tmpHtml += this.autocompleteres.contactDetails.phone;
    }
    else {
      tmpHtml += '-';
    }
    tmpHtml += '</div></td></tr>';
    tmpHtml += '<tr><td><div class="resultKey">Latitude</div><div class="resultVal">' + lat + '</div></td>';
    tmpHtml += '<td><div class="resultKey">Longitude</div><div class="resultVal">' + lng + '</div></td></tr>';
    tmpHtml += '</table>';
    $('#geoEnrichPoisAutocompleteResult').html(tmpHtml);
  };
}
