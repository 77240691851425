import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResourceService } from '../../service/resource.service';

@Component({
    selector: 'api-filedownload',
    template: '<div>Downloading...</div>'
})
export class FiledownloadComponent implements OnInit {
    public queryParams;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private resourceService: ResourceService) {
        this.queryParams = this.activatedRoute.queryParams;
    };
    ngOnInit() {
        let downloadableURL = localStorage.getItem("downloadableURL");
        if(!downloadableURL || downloadableURL == "https://learnpages-assets-qa.s3.amazonaws.com/downloads/9.5/"){
            this.resourceService.getDownloadUrl().subscribe(
                success => {
                    this.downloadFile(success.downloadableURL);
                }
            );
        }
        else{
            this.downloadFile(downloadableURL);
        }
    };
    downloadFile = function (downloadableURL) {
        let queryVal = this.queryParams.getValue();
        if (Object.keys(queryVal).length) {
            let fileName = Object.keys(queryVal)[0];
            let downloadUrl = downloadableURL + fileName;
            let a = document.createElement('A') as HTMLAnchorElement;
            a.href = decodeURI(encodeURI(downloadUrl));
            a.download = decodeURI(encodeURI(fileName));
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(() => {
                window.close();
            }, 3000);
        }
    };
}
