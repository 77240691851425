import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';

@Component({
  selector: 'property-demo',
  templateUrl: './propertydemo.component.html'
})
export class PropertyDemoComponent implements OnInit {
  public searchedAddr = "";
  public searchAddress = "";
  public activetab = "property";
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public geometry: any = null;
  public polygon;
  public locationsArr = [];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService) { };
  ngOnInit() {
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    if (this.activetab == 'parcel' && this.geometry != null) {
      this.polygon = L.geoJSON(this.geometry as any).addTo(this.map);
      this.map.fitBounds(this.locationsArr);
    }
    else{
      if (this.polygon != undefined) {
        this.map.removeLayer(this.polygon);
      };
    }
  };
  showSearchForm = function () {
    this.searchedAddr = "";
  };
  geoPropertyDemoSearchAddress = function () {
    this.searchedAddr = this.searchAddress;
    var country = 'USA';
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    };
    if (this.polygon != undefined) {
      this.map.removeLayer(this.polygon);
    };
    this.locationsArr = [];
    $('#geoPropertyDemoSearchError').hide();
    $('#geoPropertypropertyError').hide();
    $('#geoPropertypropertyResult').html('');
    $('#geoPropertyparcelError').hide();
    $('#geoPropertyparcelResult').html('');
    if (this.searchAddress != '') {
      this.geoApisGetLocationGeocode(this.searchAddress, country);
    }
    else {
      $('#geoPropertyDemoSearchError').html('Address cannot be blank.');
      $('#geoPropertyDemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var lat = success.candidates[0].geometry.coordinates[1];
          var lng = success.candidates[0].geometry.coordinates[0];
          this.locationsArr.push([lat, lng]);
          this.map.setView(L.latLng(lat, lng), 14);
          this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
          this.sampleDemoGeoPropertyByAddress(address);
          this.sampleDemoGeoParcelByAddress(address);
        }
        else {
          $('#geoPropertypropertyError').hide();
          $('#geoPropertypropertyResult').html('');
          $('#geoPropertyparcelError').hide();
          $('#geoPropertyparcelResult').html('');
          $('#geoPropertyDemoSearchError').html('No information available for this address.');
          $('#geoPropertyDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeoPropertyByAddress = function (address) {
    $('#geoPropertypropertyError').hide();
    $('#geoPropertypropertyResult').html('');
    var tmpData = {
      address: address
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoPropertyByAddress', tmpData).subscribe(
      success => {
        if (success.propertyAttributes) {
          var tmpHtml = '<h2 class="zeromargintop">Property Attributes</h2>';
          tmpHtml += '<table class="table table-condensed table-unstyled my-0"><tr>';
          tmpHtml += '<td><div class="resultKey">APN</div><div class="resultVal">';
          if(success.propertyAttributes.propApn){
            tmpHtml += success.propertyAttributes.propApn;
          }
          else{
            tmpHtml += '-';
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td style="width:50%;"><div class="resultKey">Subdivision</div><div class="resultVal">';
          if(success.propertyAttributes.subdivision){
            tmpHtml += success.propertyAttributes.subdivision;
          }
          else{
            tmpHtml += '-';
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">PBKey</div><div class="resultVal">';
          if(success.propertyAttributes.pbKey){
            tmpHtml += success.propertyAttributes.pbKey;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Land Use</div><div class="resultVal">';
          if(success.propertyAttributes.landUse){
            tmpHtml += success.propertyAttributes.landUse.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Property Type</div><div class="resultVal">';
          if(success.propertyAttributes.propType){
            tmpHtml += success.propertyAttributes.propType;
          }
          else{
            tmpHtml += '-';
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Property Area</div><div class="resultVal">';
          if(success.propertyAttributes.propSqFt){
            tmpHtml += this.formatPropAmount(success.propertyAttributes.propSqFt);
          }
          else{
            tmpHtml += '-';
          }
          tmpHtml += ' sqft.</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">CA Exemptions</div><div class="resultVal">';
          if(success.propertyAttributes.caExemptions){
            tmpHtml += success.propertyAttributes.caExemptions.code;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Construction</div><div class="resultVal">';
          if(success.propertyAttributes.construction){
            tmpHtml += success.propertyAttributes.construction.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Owners</div><div class="resultVal">';
          if(success.propertyAttributes.owners){
            for(let x=0; x<success.propertyAttributes.owners.length; x++){
              tmpHtml += '<div>';
              if(success.propertyAttributes.owners[x].firstName){
                tmpHtml += success.propertyAttributes.owners[x].firstName + ' ';
              }
              if(success.propertyAttributes.owners[x].lastName){
                tmpHtml += success.propertyAttributes.owners[x].lastName;
              }
              tmpHtml += '</div>';
            }
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Absentee Owner</div><div class="resultVal">';
          if(success.propertyAttributes.absenteeOwner){
            tmpHtml += success.propertyAttributes.absenteeOwner.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Appraised Value</div><div class="resultVal">';
          if(success.propertyAttributes.appraisedValue){
            tmpHtml += '$' + this.formatPropAmount(success.propertyAttributes.appraisedValue);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Improved Appraised Value</div><div class="resultVal">';
          if(success.propertyAttributes.appraisedValueImp){
            tmpHtml += '$' + this.formatPropAmount(success.propertyAttributes.appraisedValueImp);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Assessed Value</div><div class="resultVal">';
          if(success.propertyAttributes.assessedValue){
            tmpHtml += '$' + this.formatPropAmount(success.propertyAttributes.assessedValue);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Improved Assessed Value</div><div class="resultVal">';
          if(success.propertyAttributes.assessedValueImp){
            tmpHtml += '$' + this.formatPropAmount(success.propertyAttributes.assessedValueImp);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Basement Type</div><div class="resultVal">';
          if(success.propertyAttributes.basementType){
            tmpHtml += success.propertyAttributes.basementType.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Basement Area</div><div class="resultVal">';
          if(success.propertyAttributes.basementSqFt){
            tmpHtml += this.formatPropAmount(success.propertyAttributes.basementSqFt) + ' sqft.';
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Baths</div><div class="resultVal">';
          if(success.propertyAttributes.baths){
            tmpHtml += this.formatPropAmount(success.propertyAttributes.baths);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Bedrooms</div><div class="resultVal">';
          if(success.propertyAttributes.bedrooms){
            tmpHtml += success.propertyAttributes.bedrooms;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Building Condition</div><div class="resultVal">';
          if(success.propertyAttributes.buildgCondition){
            tmpHtml += success.propertyAttributes.buildgCondition.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Building Count</div><div class="resultVal">';
          if(success.propertyAttributes.buildgCount){
            tmpHtml += success.propertyAttributes.buildgCount;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Building Features</div><div class="resultVal">';
          if(success.propertyAttributes.buildgFeaturesSqft){
            let buildgFeaturesSqft = success.propertyAttributes.buildgFeaturesSqft;
            for(let x=0; x<buildgFeaturesSqft.length; x++){
              tmpHtml += '<div>' + buildgFeaturesSqft[x].description + ' - ';
              tmpHtml += this.formatPropAmount(buildgFeaturesSqft[x].featureAreaSqft) + ' sqft.</div>';
            }
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Building Area';
          if(success.propertyAttributes.buildgSqSource){
            tmpHtml += ' (' + success.propertyAttributes.buildgSqSource.value + ')';
          }
          tmpHtml += '</div><div class="resultVal">';
          if(success.propertyAttributes.buildgSqFt){
            tmpHtml += this.formatPropAmount(success.propertyAttributes.buildgSqFt) + ' sqft.';
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Building Style</div><div class="resultVal">';
          if(success.propertyAttributes.buildgStyle){
            tmpHtml += success.propertyAttributes.buildgStyle.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Building Type</div><div class="resultVal">';
          if(success.propertyAttributes.buildgType){
            tmpHtml += success.propertyAttributes.buildgType.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Building Unit</div><div class="resultVal">';
          if(success.propertyAttributes.buildgUnitNo){
            tmpHtml += success.propertyAttributes.buildgUnitNo;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Built Year</div><div class="resultVal">';
          if(success.propertyAttributes.builtYear){
            tmpHtml += success.propertyAttributes.builtYear;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Business Name</div><div class="resultVal">';
          if(success.propertyAttributes.businessName){
            tmpHtml += success.propertyAttributes.businessName;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Cooling Type</div><div class="resultVal">';
          if(success.propertyAttributes.coolingType){
            tmpHtml += success.propertyAttributes.coolingType.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Effective Built Year</div><div class="resultVal">';
          if(success.propertyAttributes.effectiveBuiltYear){
            tmpHtml += success.propertyAttributes.effectiveBuiltYear;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Exterior Walls</div><div class="resultVal">';
          if(success.propertyAttributes.exteriorWalls){
            tmpHtml += success.propertyAttributes.exteriorWalls.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">FirePlace</div><div class="resultVal">';
          if(success.propertyAttributes.firePlace){
            tmpHtml += success.propertyAttributes.firePlace;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Foundation</div><div class="resultVal">';
          if(success.propertyAttributes.foundation){
            tmpHtml += success.propertyAttributes.foundation.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Garage Type</div><div class="resultVal">';
          if(success.propertyAttributes.garageType){
            tmpHtml += success.propertyAttributes.garageType.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Heating Type</div><div class="resultVal">';
          if(success.propertyAttributes.heatingType){
            tmpHtml += success.propertyAttributes.heatingType.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Last Updated</div><div class="resultVal">';
          if(success.propertyAttributes.lastUpdated){
            tmpHtml += this.formatPropDate(success.propertyAttributes.lastUpdated);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Living Area</div><div class="resultVal">';
          if(success.propertyAttributes.livingSqft){
            tmpHtml += this.formatPropAmount(success.propertyAttributes.livingSqft) + ' sqft.';
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Marketed Value</div><div class="resultVal">';
          if(success.propertyAttributes.marketValue){
            tmpHtml += '$' + this.formatPropAmount(success.propertyAttributes.marketValue);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Improved Marketed Value</div><div class="resultVal">';
          if(success.propertyAttributes.marketValueImp){
            tmpHtml += '$' + this.formatPropAmount(success.propertyAttributes.marketValueImp);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Mobile Home</div><div class="resultVal">';
          if(success.propertyAttributes.mobileHome){
            tmpHtml += success.propertyAttributes.mobileHome;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Parking Area</div><div class="resultVal">';
          if(success.propertyAttributes.parkingSqFt){
            tmpHtml += this.formatPropAmount(success.propertyAttributes.parkingSqFt) + ' sqft.';
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Pool</div><div class="resultVal">';
          if(success.propertyAttributes.pool){
            tmpHtml += success.propertyAttributes.pool;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Prior Sale Date</div><div class="resultVal">';
          if(success.propertyAttributes.priorSaleDate){
            tmpHtml += this.formatPropDate(success.propertyAttributes.priorSaleDate);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Prior Sales Price</div><div class="resultVal">';
          if(success.propertyAttributes.priorSalesPrice){
            tmpHtml += '$' + this.formatPropAmount(success.propertyAttributes.priorSalesPrice);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Replacement Cost</div><div class="resultVal">';
          if(success.propertyAttributes.replacementCost){
            tmpHtml += '$' + this.formatPropAmount(success.propertyAttributes.replacementCost);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Roof Cover Type</div><div class="resultVal">';
          if(success.propertyAttributes.roofCoverType){
            tmpHtml += success.propertyAttributes.roofCoverType.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Roof Frame Type</div><div class="resultVal">';
          if(success.propertyAttributes.roofFrameType){
            tmpHtml += success.propertyAttributes.roofFrameType.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Roof Shape Type</div><div class="resultVal">';
          if(success.propertyAttributes.roofShapeType){
            tmpHtml += success.propertyAttributes.roofShapeType.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Sales Date</div><div class="resultVal">';
          if(success.propertyAttributes.salesDate){
            tmpHtml += this.formatPropDate(success.propertyAttributes.salesDate);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Stories</div><div class="resultVal">';
          if(success.propertyAttributes.stories){
            if(success.propertyAttributes.stories.description){
              tmpHtml += success.propertyAttributes.stories.description;
            }
            else{
              tmpHtml += success.propertyAttributes.stories.value;
            }
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Tax Address</div><div class="resultVal">';
          if(success.propertyAttributes.taxAddress){
            tmpHtml += success.propertyAttributes.taxAddress;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td><div class="resultKey">Tax Amount</div><div class="resultVal">';
          if(success.propertyAttributes.taxAmount){
            tmpHtml += '$' + this.formatPropAmount(success.propertyAttributes.taxAmount);
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '<td><div class="resultKey">Tax Exemptions</div><div class="resultVal">';
          if(success.propertyAttributes.taxExemptions){
            for(var x=0; x<success.propertyAttributes.taxExemptions.length; x++){
              tmpHtml += success.propertyAttributes.taxExemptions[x].value;
              if(x < success.propertyAttributes.taxExemptions.length -1){
                tmpHtml += ', ';
              }
            }
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr><tr>';
          tmpHtml += '<td colspan="2"><div class="resultKey">Vacancy</div><div class="resultVal">';
          if(success.propertyAttributes.vacancy){
            tmpHtml += success.propertyAttributes.vacancy.value;
          }
          else{
            tmpHtml += '-'
          }
          tmpHtml += '</div></td>';
          tmpHtml += '</tr></table>';
          $('#geoPropertypropertyResult').html(tmpHtml);
        }
        else {
          $('#geoPropertypropertyError').html('No information available for this location.');
          $('#geoPropertypropertyError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoPropertypropertyError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoPropertypropertyError').html(error.statusText);
        }
        $('#geoPropertypropertyError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoPropertypropertyError').hide();
        }
      }
    );
  };
  formatPropDate = function(dateStr){
    var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthArr[parseInt(dateStr.slice(4, 6)) - 1] + ' ' + dateStr.slice(0, 4) + ', ' + dateStr.slice(6, 8);
  };
  formatPropAmount = function(amountStr){
    var tmpStrArr = amountStr.split('.');
    amountStr = tmpStrArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (tmpStrArr[1]) {
      amountStr += '.' + tmpStrArr[1];
    }
    return amountStr;
  };
  sampleDemoGeoParcelByAddress = function (address) {
    $('#geoPropertyparcelError').hide();
    $('#geoPropertyparcelResult').html('');
    var tmpData = {
      address: address
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApiGeoParcelByAddr', tmpData).subscribe(
      success => {
        if (success.parcelList) {
          var tmpHtml = '<h2 class="zeromargintop">Parcel Boundary</h2><table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">APN</div><div class="resultVal">' + success.parcelApn + '</div></td><td><div class="resultKey">County FIPS</div><div class="resultVal">' + success.countyfips + '</div></td></tr>';
          tmpHtml += '</table>';
          $('#geoPropertyparcelResult').html(tmpHtml);
          this.geometry = success.geometry;
          for(let i=0; i<this.geometry.coordinates.length; i++){
            for(let j=0; j<this.geometry.coordinates[i].length; j++){
              this.locationsArr.push([this.geometry.coordinates[i][j][1], this.geometry.coordinates[i][j][0]]);
            }
          }
          if (this.activetab == 'parcel') {
            this.polygon = L.geoJSON(this.geometry as any).addTo(this.map);
            this.map.fitBounds(this.locationsArr);
          }
        }
        else {
          $('#geoPropertyparcelError').html('No information available for this location.');
          $('#geoPropertyparcelError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoPropertyparcelError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoPropertyparcelError').html(error.statusText);
        }
        $('#geoPropertyparcelError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoPropertyparcelError').hide();
        }
      }
    );
  };
}
