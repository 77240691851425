import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';
import $ from 'jquery';

@Component({
  selector: 'schools-api',
  templateUrl: './schoolsapi.component.html'
})
export class SchoolsApiComponent implements OnInit {
  public nbsParamLink: any = '';
  public nbsRadio: any = 'searchRadius';
  public nbsSearchRadius: any = '3218.688';
  public nbsSearchRadiusUnit: any = 'meters';
  public nbsTravelTime = '';
  public nbsTravelTimeUnit = '';
  public nbsTravelDistance = '';
  public nbsTravelDistanceUnit = '';
  public nbsTravelMode = '';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'U.S. schools, nearby schools' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.nbsParamLink = success.docsURL + 'Schools/School_ByAddress/query_param.html';
        }
      );
    }
    else {
      this.nbsParamLink = docsURL + 'Schools/School_ByAddress/query_param.html';
    }
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_schoolByAddrRes':
        this.get_schoolByAddrUri = "";
        this.get_schoolByAddrRes = null;
        this.get_schoolByAddrResStatus = "";
        this.get_schoolByAddrResType = "";
        break;
    }
  };
  toogleOptionalContainer = function (optConHan, optCon) {
    var domEle = $('#' + optConHan);
    var domEleNext = $('#' + optCon);
    if (domEleNext.prop('style')['display'] == 'none') {
      domEleNext.show(200);
      domEle.removeClass('plus');
      domEle.addClass('minus');
    }
    else {
      domEleNext.hide(200);
      domEle.removeClass('minus');
      domEle.addClass('plus');
    }
  };
  clearOptionalFields = function (nbsOtpPrmFrm) {
    this.nbsTravelMode = '';
    this.geoPropertyTryApiSchoolByAddrgender = '';
    this.geoPropertyTryApiSchoolByAddrdistrictSchoolsOnly = '';
    this.geoPropertyTryApiSchoolByAddrmaxCandidates = '';
  };
  changeNbsRadio = function () {
    if (this.nbsRadio == 'searchRadius') {
      this.nbsSearchRadius = '3218.688';
      this.nbsSearchRadiusUnit = 'meters';
      this.nbsTravelTime = '';
      this.nbsTravelTimeUnit = '';
      this.nbsTravelDistance = '';
      this.nbsTravelDistanceUnit = '';
      this.nbsTravelMode = '';
    }
    if (this.nbsRadio == 'travelTime') {
      this.nbsSearchRadius = '';
      this.nbsSearchRadiusUnit = '';
      this.nbsTravelTime = '15';
      this.nbsTravelTimeUnit = 'minutes';
      this.nbsTravelDistance = '';
      this.nbsTravelDistanceUnit = '';
      this.nbsTravelMode = 'driving';
    }
    if (this.nbsRadio == 'travelDistance') {
      this.nbsSearchRadius = '';
      this.nbsSearchRadiusUnit = '';
      this.nbsTravelTime = '';
      this.nbsTravelTimeUnit = '';
      this.nbsTravelDistance = '10560';
      this.nbsTravelDistanceUnit = 'feet';
      this.nbsTravelMode = 'driving';
    }
  };
  public geoPropertyTryApiSchoolByAddraddress = '4750 Walnut St, Boulder, CO 80301';
  public geoPropertyTryApiSchoolByAddredLevel = '';
  public geoPropertyTryApiSchoolByAddrschoolType = '';
  public geoPropertyTryApiSchoolByAddrschoolSubType = '';
  public geoPropertyTryApiSchoolByAddrgender = '';
  public geoPropertyTryApiSchoolByAddrassignedSchoolsOnly = 'Y';
  public geoPropertyTryApiSchoolByAddrdistrictSchoolsOnly = 'N';
  public geoPropertyTryApiSchoolByAddrmaxCandidates = 10;
  public geoPropertyTryApiSchoolByAddrDataType = 'json';
  public get_schoolByAddrUri = "";
  public get_schoolByAddrRes = null;
  public get_schoolByAddrResStatus = "";
  public get_schoolByAddrResType = "";
  tryApiGeoPropertySchoolByAddr = function (btnId) {
    var address = this.geoPropertyTryApiSchoolByAddraddress;
    var edLevel = this.geoPropertyTryApiSchoolByAddredLevel;
    var schoolType = this.geoPropertyTryApiSchoolByAddrschoolType;
    var schoolSubType = this.geoPropertyTryApiSchoolByAddrschoolSubType;
    var gender = this.geoPropertyTryApiSchoolByAddrgender;
    var assignedSchoolsOnly = this.geoPropertyTryApiSchoolByAddrassignedSchoolsOnly;
    var districtSchoolsOnly = this.geoPropertyTryApiSchoolByAddrdistrictSchoolsOnly;
    var maxCandidates = this.geoPropertyTryApiSchoolByAddrmaxCandidates;
    var dataType = this.geoPropertyTryApiSchoolByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (edLevel != '') {
      tmpData['edLevel'] = edLevel;
    }
    if (schoolType != '') {
      tmpData['schoolType'] = schoolType;
    }
    if (schoolSubType != '') {
      tmpData['schoolSubType'] = schoolSubType;
    }
    if (this.nbsSearchRadius != '') {
      tmpData['searchRadius'] = this.nbsSearchRadius;
    }
    if (this.nbsSearchRadiusUnit != '') {
      tmpData['searchRadiusUnit'] = this.nbsSearchRadiusUnit;
    }
    if (this.nbsTravelTime != '') {
      tmpData['travelTime'] = this.nbsTravelTime;
    }
    if (this.nbsTravelTimeUnit != '') {
      tmpData['travelTimeUnit'] = this.nbsTravelTimeUnit;
    }
    if (this.nbsTravelDistance != '') {
      tmpData['travelDistance'] = this.nbsTravelDistance;
    }
    if (this.nbsTravelDistanceUnit != '') {
      tmpData['travelDistanceUnit'] = this.nbsTravelDistanceUnit;
    }
    if (this.nbsTravelMode != '') {
      tmpData['travelMode'] = this.nbsTravelMode;
    }
    if (gender != '') {
      tmpData['gender'] = gender;
    }
    if (assignedSchoolsOnly != '') {
      tmpData['assignedSchoolsOnly'] = assignedSchoolsOnly;
    }
    if (districtSchoolsOnly != '') {
      tmpData['districtSchoolsOnly'] = districtSchoolsOnly;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (address != '') {
      var methodName = '/tryApiGeoPropertySchoolByAddr';
      let requestUri = this.liapiservice.getCompleteUri('schools', 'Get Nearby Schools By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_schoolByAddrUri = requestUri;
          this.get_schoolByAddrResType = dataType;
          if(this.get_schoolByAddrResStatus.includes('429')){
            this.get_schoolByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_schoolByAddrResStatus = '200 success';
            this.get_schoolByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_schoolByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_schoolByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
}
