import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import * as L from "leaflet";

@Component({
  selector: 'geolocation-demo',
  templateUrl: './geolocationdemo.component.html'
})
export class GeolocationDemoComponent implements OnInit {
  public activetab = "ipaddress";
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService) { };
  ngOnInit() {
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    };
    var identificationDetail;
    if (tabtype == 'ipaddress') {
      identificationDetail = $('#geoLocationSampleDemoipAddress').val();
    }
    else{
      identificationDetail = $('#geoLocationSampleDemomac').val();
    }
    if (identificationDetail != '') {
      this.sampleDemoGeoLocationGetLocation(this.activetab);
    }
  };
  sampleDemoGeoLocationGetLocation = function (tabname) {
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    };
    var identificationDetail;
    if (tabname == 'ipaddress') {
      identificationDetail = $('#geoLocationSampleDemoipAddress').val();
      if (identificationDetail == '') {
        $('#geoLocationipaddressResult').html('');
        $('#geoLocationDemoipaddressError').html('IP Address cannot be blank.');
        $('#geoLocationDemoipaddressError').show();
        return;
      }
      else {
        $('#geoLocationDemoipaddressError').hide();
      }
    }
    else if (tabname == 'wifi') {
      identificationDetail = $('#geoLocationSampleDemomac').val();
      if (identificationDetail == '') {
        $('#geoLocationwifiResult').html('');
        $('#geoLocationDemowifiError').html('WiFi MAC cannot be blank.');
        $('#geoLocationDemowifiError').show();
        return;
      }
      else {
        $('#geoLocationDemowifiError').hide();
      }
    }
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      type: tabname,
      identificationDetail: identificationDetail
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoLocationGetLocation', tmpData).subscribe(
      success => {
        var tmpHtml = '';
        if (success.ipInfo) {
          var latitude = success.geometry.coordinates[1];
          var longitude = success.geometry.coordinates[0];
          if (success.ipInfo.network.carrier.value) {
            tmpHtml += '<h2 class="zeromargintop">' + success.ipInfo.network.carrier.value + '</h2>';
          }
          tmpHtml += '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Connection Type</div><div class="resultVal">';
          if (success.ipInfo.network.connectionType) {
            tmpHtml += success.ipInfo.network.connectionType;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td><td><div class="resultKey">Line Speed</div><div class="resultVal">';
          if (success.ipInfo.network.lineSpeed) {
            tmpHtml += success.ipInfo.network.lineSpeed;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">IP Route Type</div><div class="resultVal">';
          if (success.ipInfo.network.ipRouteType) {
            tmpHtml += success.ipInfo.network.ipRouteType;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td><td><div class="resultKey">Organization</div><div class="resultVal">';
          if (success.ipInfo.network.organization) {
            tmpHtml += success.ipInfo.network.organization;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">Organization Type</div><div class="resultVal">';
          if (success.ipInfo.network.organizationType.value) {
            tmpHtml += success.ipInfo.network.organizationType.value;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td><td><div class="resultKey">Region</div><div class="resultVal">';
          if (success.ipInfo.place.region) {
            tmpHtml += success.ipInfo.place.region;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">City</div><div class="resultVal">';
          if (success.ipInfo.place.city.value) {
            tmpHtml += success.ipInfo.place.city.value;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td><td><div class="resultKey">State</div><div class="resultVal">';
          if (success.ipInfo.place.state.value) {
            tmpHtml += success.ipInfo.place.state.value;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">Postcode</div><div class="resultVal">';
          if (success.ipInfo.place.postCode) {
            tmpHtml += success.ipInfo.place.postCode;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td><td><div class="resultKey">Country</div><div class="resultVal">';
          if (success.ipInfo.place.country.value) {
            tmpHtml += success.ipInfo.place.country.value;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">Latitude</div><div class="resultVal">';
          if (latitude) {
            tmpHtml += latitude;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td><td><div class="resultKey">Longitude</div><div class="resultVal">';
          if (longitude) {
            tmpHtml += longitude;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          tmpHtml += '</table>';
          $('#geoLocationipaddressResult').html(tmpHtml);
          this.map.setView(L.latLng(latitude, longitude), 15);
          this.newMarker = L.marker([latitude, longitude], this.markerIcon).addTo(this.map);
        }
        else if (success.accuracy) {
          var latitude = success.geometry.coordinates[1];
          var longitude = success.geometry.coordinates[0];
          var tmpHtml = '';
          tmpHtml += '<h2 class="zeromargintop">Location Details</h2>';
          tmpHtml += '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Accuracy</div><div class="resultVal">';
          if (success.accuracy.value && success.accuracy.unit) {
            tmpHtml += success.accuracy.value + ' ' + success.accuracy.unit;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td><td><div class="resultKey">Country</div><div class="resultVal">';
          if (success.country && success.country.value) {
            tmpHtml += success.country.value;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">Latitude</div><div class="resultVal">';
          if (latitude) {
            tmpHtml += latitude;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td><td><div class="resultKey">Longitude</div><div class="resultVal">';
          if (longitude) {
            tmpHtml += longitude;
          }
          else {
            tmpHtml += '-';
          }
          tmpHtml += '</div></td></tr>';
          tmpHtml += '</table>';
          if (tabname == 'wifi') {
            $('#geoLocationwifiResult').html(tmpHtml);
          }
          if (latitude && longitude) {
            this.map.setView(L.latLng(latitude, longitude), 15);
            this.newMarker = L.marker([latitude, longitude], this.markerIcon).addTo(this.map);
          }
        }
        else {
          var errMsg = 'Failed to retrieve location.';
          if (tabname == 'ipaddress') {
            if (success.errors) {
              if (success.errors[0].errorCode == 'PB-14020-GEOLOCATION-0001') {
                errMsg = 'Invalid value for IP Address';
              }
            }
            $('#geoLocationipaddressResult').html('');
            $('#geoLocationDemoipaddressError').html(errMsg);
            $('#geoLocationDemoipaddressError').show();
          }
          else if (tabname == 'wifi') {
            if (success.errors) {
              if (success.errors[0].errorCode == 'PB-14020-GEOLOCATION-0002') {
                errMsg = 'Invalid value for WIFI MAC';
              }
            }
            $('#geoLocationwifiResult').html('');
            $('#geoLocationDemowifiError').html(errMsg);
            $('#geoLocationDemowifiError').show();
          }
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        var errorMsg = '';
        if (error.error) {
          errorMsg = error.error.errors[0].errorDescription;
        }
        else {
          errorMsg = error.statusText;
        }
        if (tabname == 'ipaddress') {
          $('#geoLocationDemoipaddressError').html(errorMsg);
          $('#geoLocationDemoipaddressError').show();
        }
        else if (tabname == 'wifi') {
          $('#geoLocationDemowifiError').html(errorMsg);
          $('#geoLocationDemowifiError').show();
        }
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoLocationDemoipaddressError').hide();
          $('#geoLocationDemowifiError').hide();
        }
      }
    );
  };
}
