import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResourceService } from '../service/resource.service';

@Component({
  selector: 'release',
  templateUrl: './releasenotes.component.html'
})
export class ReleaseComponent implements OnInit {
  public docsURL: any = '';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private resourceService: ResourceService) {
  };
  ngOnInit() {
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.docsURL = success.docsURL;
        }
      );
    }
    else{
      this.docsURL = docsURL;
    }
  };
}
