import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ResourceService } from '../service/resource.service';

@Component({
  selector: 'commerceapis',
  templateUrl: './commerceapis.component.html'
})
export class CommerceapisComponent implements OnInit {
  
  public searchParamLink = '';
  
  public mainCardObj = [
    [
      {
        cardApi: "autocomplete",
        cardIcon: "type-ahead-api",
        cardTitle: "Address Autocomplete Enterprise",
        cardText: "Capture addresses, reduce cart abandonment, increase purchase conversions and eliminate returns.",
        docLink:  "AddressAutocompleteEnterprise/AddressAutocompleteEnterprise.html"
      },
      {
        cardApi: "addressverification",
        cardIcon: "verify-address-api",
        cardTitle: "Address Verification",
        cardText: "Confirm that your customer's mailing address exists, and that mail and packages can be delivered to it.",
        docLink: "AddressVerification/Validate_Mailing_Address/AddressVerification_description.html"
      },
      {
        cardApi: "localtax",
        cardIcon: "local-tax-api-icon",
        cardTitle: "Local Tax (USA)",
        cardText: "Integrate local sales tax calculations into your shopping cart.",
        docLink: "LocalTax/localtax_intro.html"
      },
      {
        cardApi: "emailverification",
        cardIcon: "email-verify-api-icon",
        cardTitle: "Email Verification",
        cardText: "Correct and validate customer email addresses to avoid damaging your sender reputation by eliminating the invalid, toxic and undesirable.",
        docLink: "EmailVerification/validate_emailaddress/ValidateEmailAddress_intro.html"
      },
      {
        cardApi: "phoneverification",
        cardIcon: "phoneverification-api-icon",
        cardTitle: "Phone Verification",
        cardText: "Verify the customer telephone number information and identify alternate communications methods.",
        docLink: "PhoneVerificationv2/phoneverificationv2.html"
      }
    ],
    [
      {
        cardApi: "geolocation",
        cardIcon: "geo-location-api-icon",
        cardTitle: "IP Geolocation",
        cardText: "Find the geographic location of your customers devices.",
        docLink: "Geolocation/geolocation_intro.html"
      },
      {
        cardApi: "timezone",
        cardIcon: "time-zone-api-icon",
        cardTitle: "Time Zone",
        cardText: "For global trade, know the best time to interact with your customer.",
        docLink: "TimeZone/timezone_intro.html"
      },
      {
        cardApi: "demographics",
        cardIcon: "demo-graphics-api-icon",
        cardTitle: "Demographics",
        cardText: "Personalize your customer experience with consumer insights.",
        docLink: "Demographics/intro.html"
      },
      {
        cardApi: "findnearest",
        cardIcon: "routing-location-api-icon",
        cardTitle: "Find Nearest",
        cardText: "Embed a mapping and directions app that allows your customers to find your physical locations.",
        docLink: ""
      },
      {
        cardApi: "firmographics",
        cardIcon: "map-location-api-icon",
        cardTitle: "Firmographics",
        cardText: "Understand the purchasing behaviors of your business customers.",
        docLink: ""
      }
    ]
  ];
  public searchText: string = '';
  public cardObj = [];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private resourceService: ResourceService) {
  };
  ngOnInit() {
    window.scroll(0, 0);
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.searchParamLink = success.docsURL ;
          this.assignDocumentationLinkToCards(success.docsURL);
          this.cardObj = [...this.mainCardObj];
        }
      );
    }
    else{
      this.searchParamLink = docsURL;
      this.assignDocumentationLinkToCards(this.searchParamLink);
      this.cardObj = [...this.mainCardObj];
    }

  };
  onSearchUpdate =  () => {
    if(this.searchText === ''){
      this.cardObj = [...this.mainCardObj];
    }
    else{
      this.cardObj = [];
      let tmpArr1 = [];
      let tmpArr2 = [];
      for(let i=0; i<this.mainCardObj.length; i++){
        for(let j=0; j<this.mainCardObj[i].length; j++){
          if(this.mainCardObj[i][j].cardTitle.toLowerCase().includes(this.searchText.toLowerCase()) || this.mainCardObj[i][j].cardText.toLowerCase().includes(this.searchText.toLowerCase())){
            if(tmpArr1.length < 5){
              tmpArr1.push(this.mainCardObj[i][j]);
            }
            else{
              tmpArr2.push(this.mainCardObj[i][j]);
            }
          }
        }
      }
      if(tmpArr1.length){
        this.cardObj.push(tmpArr1);
      }
      if(tmpArr2.length){
        this.cardObj.push(tmpArr2);
      }
    }
  };

  assignDocumentationLinkToCards = (paramLink: string)=>{
    for(let rowCards of this.mainCardObj){
      for(let card of rowCards){
        card.docLink = paramLink + card.docLink;
      } 
    }

  }
}
