import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'localtax-api',
  templateUrl: './localtaxapi.component.html'
})
export class LocaltaxApiComponent implements OnInit {
  public ipdParamLink: any = '';
  public taxrateByAddrJson = '{ "preferences": { "fallbackToGeographic": "true", "useGeoTaxAuxiliaryFile": "Y", "matchMode": "EXACT", "latLongOffset": "40", "squeeze": "Y", "latLongFormat": "Decimal", "defaultBufferWidth": "50", "distanceUnits": "Feet", "outputCasing": "M", "returnCensusFields": "Y", "returnLatLongFields": "Y", "customPreferences": { "FIND_ADDR_POINT_INTERP": "true", "FIND_SEARCH_AREA": "1", "FIND_ADDRESS_RANGE": "true", "FIND_EXPANDED_SEARCH_RADIUS": "50", "FIND_ALTERNATE_LOOKUP": "2", "FIND_STREET_CENTROID": "true", "FIND_FIRST_LETTER_EXPANDED": "true" } }, "taxRateAddresses": [{ "mainAddressLine": "2001 Main St, Eagle Butte, SD 57625" }, { "mainAddressLine": "39 Sycamore Rd,Stafford 08050, USA" }] }';
  public taxrateByLocJson = '{"preferences": {"outputCasing":"M","defaultBufferWidth":"","distanceUnits":"METERS","returnCensusFields":"Y","returnLatLongFields":"Y"},"locations": [{"geometry": {"type": "point","coordinates": [-101.9835, 45.1134]}},{"geometry": {"type": "point","coordinates": [-101.2369835, 45.0001134]}}]}';
  public taxByAddrJson = '{"preferences": {"fallbackToGeographic": "true","useGeoTaxAuxiliaryFile": "Y","matchMode": "EXACT","latLongOffset": "40","squeeze": "Y","latLongFormat": "Decimal","defaultBufferWidth": "50","distanceUnits": "Feet","outputCasing": "M","returnCensusFields": "Y","returnLatLongFields": "Y","customPreferences": {"FIND_ADDR_POINT_INTERP": "true","FIND_SEARCH_AREA": "1","FIND_ADDRESS_RANGE": "true","FIND_EXPANDED_SEARCH_RADIUS": "50","FIND_ALTERNATE_LOOKUP": "2","FIND_STREET_CENTROID": "true","FIND_FIRST_LETTER_EXPANDED": "true"}},"taxAddresses": [{"mainAddressLine": "2001 Main St, Eagle Butte, SD 57625","purchaseAmount":"10"},{"mainAddressLine": "39 Sycamore Rd,Stafford 08050, USA","purchaseAmount":"10"}]}';
  public taxByLocJson = '{"preferences": {"outputCasing":"M","defaultBufferWidth":"","distanceUnits":"METERS","returnCensusFields":"Y","returnLatLongFields":"Y"},"locations": [{"geometry": {"type": "point","coordinates": [-101.9835, 45.1134]},"purchaseAmount": "100"},{"geometry": {"type": "point","coordinates": [-101.2369835, 45.0001134]},"purchaseAmount": "100"}]}';
  public ipdTaxByAddrJson = '{"preferences": {},"addresses": [{"objectId": "1","mainAddressLine": "4750 Walnut st, Boulder, CO","addressLastLine": "","placeName": "","areaName1": "","areaName2": "","areaName3": "","areaName4": "","postCode1": "","postCode2": "","country": "","addressNumber": "","streetName": "","unitType": "","unitValue": ""}, {"objectId": "2","mainAddressLine": "39 Sycamore Rd,Stafford 08050, USA","addressLastLine": "","placeName": "","areaName1": "","areaName2": "","areaName3": "","areaName4": "","postCode1": "","postCode2": "","country": "","addressNumber": "","streetName": "","unitType": "","unitValue": ""}]}';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'tax rate api, tax api, state tax api, sales tax api, us tax rates' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.ipdParamLink = success.docsURL + 'LocalTax/IPD_tax_add/query_paramters.html';
        }
      );
    }
    else {
      this.ipdParamLink = docsURL + 'LocalTax/IPD_tax_add/query_paramters.html';
    }
    this.formatJsonInputs();
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_taxrateByAddrRes':
        this.get_taxrateByAddrUri = "";
        this.get_taxrateByAddrRes = null;
        this.get_taxrateByAddrResStatus = "";
        this.get_taxrateByAddrResType = "";
        break;
      case 'post_taxrateByAddrRes':
        this.post_taxrateByAddrUri = "";
        this.post_taxrateByAddrRes = null;
        this.post_taxrateByAddrResStatus = "";
        this.post_taxrateByAddrResType = "";
        break;
      case 'get_taxrateByLocRes':
        this.get_taxrateByLocUri = "";
        this.get_taxrateByLocRes = null;
        this.get_taxrateByLocResStatus = "";
        this.get_taxrateByLocResType = "";
        break;
      case 'post_taxrateByLocRes':
        this.post_taxrateByLocUri = "";
        this.post_taxrateByLocRes = null;
        this.post_taxrateByLocResStatus = "";
        this.post_taxrateByLocResType = "";
        break;
      case 'get_taxByAddrRes':
        this.get_taxByAddrUri = "";
        this.get_taxByAddrRes = null;
        this.get_taxByAddrResStatus = "";
        this.get_taxByAddrResType = "";
        break;
      case 'post_taxByAddrRes':
        this.post_taxByAddrUri = "";
        this.post_taxByAddrRes = null;
        this.post_taxByAddrResStatus = "";
        this.post_taxByAddrResType = "";
        break;
      case 'get_taxByLocRes':
        this.get_taxByLocUri = "";
        this.get_taxByLocRes = null;
        this.get_taxByLocResStatus = "";
        this.get_taxByLocResType = "";
        break;
      case 'post_taxByLocRes':
        this.post_taxByLocUri = "";
        this.post_taxByLocRes = null;
        this.post_taxByLocResStatus = "";
        this.post_taxByLocResType = "";
        break;
      case 'get_ipdTaxrateByAddrRes':
        this.get_ipdTaxrateByAddrUri = "";
        this.get_ipdTaxrateByAddrRes = null;
        this.get_ipdTaxrateByAddrResStatus = "";
        this.get_ipdTaxrateByAddrResType = "";
        break;
      case 'post_ipdTaxrateByAddrRes':
        this.post_ipdTaxrateByAddrUri = "";
        this.post_ipdTaxrateByAddrRes = null;
        this.post_ipdTaxrateByAddrResStatus = "";
        this.post_ipdTaxrateByAddrResType = "";
        break;
    }
  };
  public geoTaxTryApiTaxrateByAddraddress = '2001 Main St, Eagle Butte, SD 57625';
  public geoTaxTryApiTaxrateByAddrtaxRateTypeId = 'General';
  public geoTaxTryApiTaxrateByAddrDataType = 'json';
  public get_taxrateByAddrUri = "";
  public get_taxrateByAddrRes = null;
  public get_taxrateByAddrResStatus = "";
  public get_taxrateByAddrResType = "";
  tryApiGeoTaxTaxrateByAddr = function (btnId) {
    var address = this.geoTaxTryApiTaxrateByAddraddress;
    var taxRateTypeId = this.geoTaxTryApiTaxrateByAddrtaxRateTypeId;
    var dataType = this.geoTaxTryApiTaxrateByAddrDataType;
    var tmpData = {
      address: address,
      taxRateTypeId: taxRateTypeId,
      dataType: dataType
    };
    var pathParam = ['taxRateTypeId'];
    if (address != '') {
      var methodName = '/tryApiGeoTaxTaxrateByAddr';
      let requestUri = this.liapiservice.getCompleteUri('localtax', 'Get Taxrate By Address', tmpData, pathParam);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_taxrateByAddrUri = requestUri;
          this.get_taxrateByAddrResType = dataType;
          if(this.get_taxrateByAddrResStatus.includes('429')){
            this.get_taxrateByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_taxrateByAddrResStatus = '200 success';
            this.get_taxrateByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_taxrateByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_taxrateByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoTaxTryApiPostTaxrateByAddrtaxRateTypeId = 'General';
  public post_taxrateByAddrUri = "";
  public post_taxrateByAddrRes = null;
  public post_taxrateByAddrResStatus = "";
  public post_taxrateByAddrResType = "";
  tryApiGeoTaxPostTaxrateByAddr = function (btnId) {
    var postData = this.taxrateByAddrJson;
    var taxRateTypeId = this.geoTaxTryApiPostTaxrateByAddrtaxRateTypeId;
    var tmpData = {
      data: postData,
      taxRateTypeId: taxRateTypeId
    };
    var pathParam = ['taxRateTypeId'];
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiGeoTaxPostTaxrateByAddr';
      let requestUri = this.liapiservice.getCompleteUriPostWithParam('localtax', 'Post Taxrate By Address', tmpData, pathParam);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCallWithAdditionalParams(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_taxrateByAddrUri = requestUri;
          this.post_taxrateByAddrResType = 'json';
          if(this.post_taxrateByAddrResStatus.includes('429')){
            this.post_taxrateByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_taxrateByAddrResStatus = '200 success';
            this.post_taxrateByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_taxrateByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_taxrateByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoTaxTryApiTaxrateByLoclatitude = 45.0001134;
  public geoTaxTryApiTaxrateByLoclongitude = -101.2369835;
  public geoTaxTryApiTaxrateByLoctaxRateTypeId = 'General';
  public geoTaxTryApiTaxrateByLocDataType = 'json';
  public get_taxrateByLocUri = "";
  public get_taxrateByLocRes = null;
  public get_taxrateByLocResStatus = "";
  public get_taxrateByLocResType = "";
  tryApiGeoTaxTaxrateByLoc = function (btnId) {
    var latitude: any = this.geoTaxTryApiTaxrateByLoclatitude;
    var longitude: any = this.geoTaxTryApiTaxrateByLoclongitude;
    var taxRateTypeId = this.geoTaxTryApiTaxrateByLoctaxRateTypeId;
    var dataType = this.geoTaxTryApiTaxrateByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      taxRateTypeId: taxRateTypeId,
      dataType: dataType
    };
    var pathParam = ['taxRateTypeId'];
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoTaxTaxrateByLoc';
        let requestUri = this.liapiservice.getCompleteUri('localtax', 'Get Taxrate By Location', tmpData, pathParam);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_taxrateByLocUri = requestUri;
            this.get_taxrateByLocResType = dataType;
            if(this.get_taxrateByLocResStatus.includes('429')){
              this.get_taxrateByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_taxrateByLocResStatus = '200 success';
              this.get_taxrateByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_taxrateByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_taxrateByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoTaxTryApiPostTaxrateByLoctaxRateTypeId = 'General';
  public post_taxrateByLocUri = "";
  public post_taxrateByLocRes = null;
  public post_taxrateByLocResStatus = "";
  public post_taxrateByLocResType = "";
  tryApiGeoTaxPostTaxrateByLoc = function (btnId) {
    var postData = this.taxrateByLocJson;
    var taxRateTypeId = this.geoTaxTryApiPostTaxrateByLoctaxRateTypeId;
    var tmpData = {
      data: postData,
      taxRateTypeId: taxRateTypeId
    };
    var pathParam = ['taxRateTypeId'];
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiGeoTaxPostTaxrateByLoc';
      let requestUri = this.liapiservice.getCompleteUriPostWithParam('localtax', 'Post Taxrate By Location', tmpData, pathParam);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCallWithAdditionalParams(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_taxrateByLocUri = requestUri;
          this.post_taxrateByLocResType = 'json';
          if(this.post_taxrateByLocResStatus.includes('429')){
            this.post_taxrateByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_taxrateByLocResStatus = '200 success';
            this.post_taxrateByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_taxrateByLocResStatus = `${error.status} ${error.statusText}`;
              this.post_taxrateByLocRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoTaxTryApiTaxByAddraddress = '2001 Main St, Eagle Butte, SD 57625';
  public geoTaxTryApiTaxByAddrpurchaseAmount = 100;
  public geoTaxTryApiTaxByAddrtaxRateTypeId = 'General';
  public geoTaxTryApiTaxByAddrDataType = 'json';
  public get_taxByAddrUri = "";
  public get_taxByAddrRes = null;
  public get_taxByAddrResStatus = "";
  public get_taxByAddrResType = "";
  tryApiGeoTaxTaxByAddr = function (btnId) {
    var address = this.geoTaxTryApiTaxByAddraddress;
    var purchaseAmount: any = this.geoTaxTryApiTaxByAddrpurchaseAmount;
    var taxRateTypeId = this.geoTaxTryApiTaxByAddrtaxRateTypeId;
    var dataType = this.geoTaxTryApiTaxByAddrDataType;
    var tmpData = {
      address: address,
      purchaseAmount: purchaseAmount,
      taxRateTypeId: taxRateTypeId,
      dataType: dataType
    };
    var pathParam = ['taxRateTypeId'];
    if (address != '' && purchaseAmount != '' && !isNaN(purchaseAmount)) {
      var methodName = '/tryApiGeoTaxTaxByAddr';
      let requestUri = this.liapiservice.getCompleteUri('localtax', 'Get Tax By Address', tmpData, pathParam);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_taxByAddrUri = requestUri;
          this.get_taxByAddrResType = dataType;
          if(this.get_taxByAddrResStatus.includes('429')){
            this.get_taxByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_taxByAddrResStatus = '200 success';
            this.get_taxByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_taxByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_taxByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoTaxTryApiPostTaxByAddrtaxRateTypeId = 'General';
  public post_taxByAddrUri = "";
  public post_taxByAddrRes = null;
  public post_taxByAddrResStatus = "";
  public post_taxByAddrResType = "";
  tryApiGeoTaxPostTaxByAddr = function (btnId) {
    var postData = this.taxByAddrJson;
    var taxRateTypeId = this.geoTaxTryApiPostTaxByAddrtaxRateTypeId;
    var tmpData = {
      data: postData,
      taxRateTypeId: taxRateTypeId
    };
    var pathParam = ['taxRateTypeId'];
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiGeoTaxPostTaxByAddr';
      let requestUri = this.liapiservice.getCompleteUriPostWithParam('localtax', 'Post Tax By Address', tmpData, pathParam);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCallWithAdditionalParams(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_taxByAddrUri = requestUri;
          this.post_taxByAddrResType = 'json';
          if(this.post_taxByAddrResStatus.includes('429')){
            this.post_taxByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_taxByAddrResStatus = '200 success';
            this.post_taxByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_taxByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_taxByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoTaxTryApiTaxByLoclatitude = 45.0001134;
  public geoTaxTryApiTaxByLoclongitude = -101.2369835;
  public geoTaxTryApiTaxByLocpurchaseAmount = 100;
  public geoTaxTryApiTaxByLoctaxRateTypeId = 'General';
  public geoTaxTryApiTaxByLocDataType = 'json';
  public get_taxByLocUri = "";
  public get_taxByLocRes = null;
  public get_taxByLocResStatus = "";
  public get_taxByLocResType = "";
  tryApiGeoTaxTaxByLoc = function (btnId) {
    var latitude: any = this.geoTaxTryApiTaxByLoclatitude;
    var longitude: any = this.geoTaxTryApiTaxByLoclongitude;
    var purchaseAmount: any = this.geoTaxTryApiTaxByLocpurchaseAmount;
    var taxRateTypeId = this.geoTaxTryApiTaxByLoctaxRateTypeId;
    var dataType = this.geoTaxTryApiTaxByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      purchaseAmount: purchaseAmount,
      taxRateTypeId: taxRateTypeId,
      dataType: dataType
    };
    var pathParam = ['taxRateTypeId'];
    if (latitude != '' && longitude != '' && purchaseAmount != '') {
      if (!isNaN(latitude) && !isNaN(longitude) && !isNaN(purchaseAmount)) {
        var methodName = '/tryApiGeoTaxTaxByLoc';
        let requestUri = this.liapiservice.getCompleteUri('localtax', 'Get Tax By Location', tmpData, pathParam);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_taxByLocUri = requestUri;
            this.get_taxByLocResType = dataType;
            if(this.get_taxByLocResStatus.includes('429')){
              this.get_taxByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_taxByLocResStatus = '200 success';
              this.get_taxByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_taxByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_taxByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoTaxTryApiPostTaxByLoctaxRateTypeId = 'General';
  public post_taxByLocUri = "";
  public post_taxByLocRes = null;
  public post_taxByLocResStatus = "";
  public post_taxByLocResType = "";
  tryApiGeoTaxPostTaxByLoc = function (btnId) {
    var postData = this.taxByLocJson;
    var taxRateTypeId = this.geoTaxTryApiPostTaxByLoctaxRateTypeId;
    var tmpData = {
      data: postData,
      taxRateTypeId: taxRateTypeId
    };
    var pathParam = ['taxRateTypeId'];
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiGeoTaxPostTaxByLoc';
      let requestUri = this.liapiservice.getCompleteUriPostWithParam('localtax', 'Post Tax By Location', tmpData, pathParam);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCallWithAdditionalParams(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_taxByLocUri = requestUri;
          this.post_taxByLocResType = 'json';
          if(this.post_taxByLocResStatus.includes('429')){
            this.post_taxByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_taxByLocResStatus = '200 success';
            this.post_taxByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_taxByLocResStatus = `${error.status} ${error.statusText}`;
              this.post_taxByLocRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoTaxTryApiIpdTaxByAddraddress = '39 Sycamore Rd,Stafford 08050, USA';
  public geoTaxTryApiIpdTaxByAddrreturnLatLongFields = 'N';
  public geoTaxTryApiIpdTaxByAddrlatLongFormat = 'Decimal';
  public geoTaxTryApiIpdTaxByAddrDataType = 'json';
  public get_ipdTaxrateByAddrUri = "";
  public get_ipdTaxrateByAddrRes = null;
  public get_ipdTaxrateByAddrResStatus = "";
  public get_ipdTaxrateByAddrResType = "";
  tryApiGeoTaxIpdTaxByAddr = function (btnId) {
    var address = this.geoTaxTryApiIpdTaxByAddraddress;
    var returnLatLongFields = this.geoTaxTryApiIpdTaxByAddrreturnLatLongFields;
    var latLongFormat = this.geoTaxTryApiIpdTaxByAddrlatLongFormat;
    var dataType = this.geoTaxTryApiIpdTaxByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (returnLatLongFields != '') {
      tmpData["returnLatLongFields"] = returnLatLongFields;
    }
    if (latLongFormat != '') {
      tmpData["latLongFormat"] = latLongFormat;
    }
    if (address != '') {
      var methodName = '/tryApiGeoTaxIpdTaxByAddr';
      let requestUri = this.liapiservice.getCompleteUri('localtax', 'Get IPD Taxrate By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_ipdTaxrateByAddrUri = requestUri;
          this.get_ipdTaxrateByAddrResType = dataType;
          if(this.get_ipdTaxrateByAddrResStatus.includes('429')){
            this.get_ipdTaxrateByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_ipdTaxrateByAddrResStatus = '200 success';
            this.get_ipdTaxrateByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_ipdTaxrateByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_ipdTaxrateByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_ipdTaxrateByAddrUri = "";
  public post_ipdTaxrateByAddrRes = null;
  public post_ipdTaxrateByAddrResStatus = "";
  public post_ipdTaxrateByAddrResType = "";
  tryApiGeoTaxPostIpdTaxByAddr = function (btnId) {
    var postData = this.ipdTaxByAddrJson;
    var methodName = '/tryApiGeoTaxPostIpdTaxByAddr';
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiGeoTaxPostIpdTaxByAddr';
      let requestUri = this.liapiservice.getCompleteUriPost('localtax', 'Post IPD Taxrate By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_ipdTaxrateByAddrUri = requestUri;
          this.post_ipdTaxrateByAddrResType = 'json';
          if(this.post_ipdTaxrateByAddrResStatus.includes('429')){
            this.post_ipdTaxrateByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_ipdTaxrateByAddrResStatus = '200 success';
            this.post_ipdTaxrateByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_ipdTaxrateByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_ipdTaxrateByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  formatJsonInputs = function () {
    this.taxrateByAddrJson = JSON.stringify(JSON.parse(this.taxrateByAddrJson), undefined, 4);
    this.taxrateByLocJson = JSON.stringify(JSON.parse(this.taxrateByLocJson), undefined, 4);
    this.taxByAddrJson = JSON.stringify(JSON.parse(this.taxByAddrJson), undefined, 4);
    this.taxByLocJson = JSON.stringify(JSON.parse(this.taxByLocJson), undefined, 4);
    this.ipdTaxByAddrJson = JSON.stringify(JSON.parse(this.ipdTaxByAddrJson), undefined, 4);
  };
}
