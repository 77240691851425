import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import {latLng, MapOptions, tileLayer, Map, point} from 'leaflet';
declare const Tangram: any;

@Component({
  selector: 'maps-api',
  templateUrl: './mapsapi.component.html'
})
export class MapsApiComponent implements OnInit {
  public docsURL: any = '';
  public requestUriMvt = '';
  public mapOptions: MapOptions;
  public map: Map;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'map apis, map api, tile developer api, base maps, maps api free' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.docsURL = success.docsURL;
        }
      );
    }
    else{
      this.docsURL = docsURL;
    }
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: latLng(40.780541431860314, -74.00390625),
      zoom: 12,
      layers: [
        tileLayer(
          mapFullURL+"bronze",
          {
            maxZoom: 18,
            attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>'
          })
      ],
    };
  }
  onMapReady(map: Map) {
    this.map = map;
    var mvtURL = 'yaml';
    let mvtEnv = localStorage.getItem("mvtEnv");
    if(!mvtEnv){
      this.resourceService.getMvtEnv().subscribe(
        success => {
          localStorage.setItem("mvtEnv", success.mvtEnv);
          this.loadMvtMap(success.mvtEnv, mvtURL);
        }
      );
    }
    else{
      this.loadMvtMap(mvtEnv, mvtURL);
    }
  };
  loadMvtMap = function(mvtEnv, mvtURL){
    if (mvtEnv == "qa") {
      mvtURL += '/qa';
    }
    mvtURL += '/bronze.yaml';
    var layer = Tangram.leafletLayer({ scene: mvtURL });
    layer.addTo(this.map);
    $('#collapse2').removeClass('show');
    $('#vectormapResult').hide();
  };
  clearResult = function (resultDivId) {
    $('#' + resultDivId).hide();
  };
  public geoMapTryApiRastertheme = 'bronze';
  public geoMapTryApiRasterX = 1206;
  public geoMapTryApiRasterY = 1539;
  public geoMapTryApiRasterZ = 12;
  tryApiGeoMapRaster = function (resultDivId, btnId) {
    var theme = this.geoMapTryApiRastertheme;
    var x: any = this.geoMapTryApiRasterX;
    var y: any = this.geoMapTryApiRasterY;
    var z: any = this.geoMapTryApiRasterZ;
    var tmpData = {
      x: x,
      y: y,
      z: z,
      theme: theme,
      api_key: '&lt;Your API Key&gt;'
    };
    var pathParam = ['z', 'x', 'y'];
    let requestUri = this.liapiservice.getCompleteUri('maps', 'Raster Base Map Tiles', tmpData, pathParam);
    if (x != '' && y != '' && z != '' && !isNaN(x) && !isNaN(y) && !isNaN(z) && x >= 0 && y >= 0 && z >= 0 && z <= 18) {
      let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
      var xhrUrl = mapFullURL + theme;
      xhrUrl = xhrUrl.replace("{z}", z).replace("{x}", x).replace("{y}", y);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (this.readyState == 1) {
          $('#' + btnId).html('Loading...');
          $('#' + btnId).prop('disabled', true);
        }
        if (this.readyState == 4) {
          $('#' + btnId).html('Try it');
          $('#' + btnId).prop('disabled', false);
          if (this.status == 200) {
            var tmpHtml = '<div><h5>Request URI</h5>';
            tmpHtml += '<div class="tryApiCodeResponse">' + requestUri + '</div></div>';
            tmpHtml += '<div><h5>Response Status</h5>';
            tmpHtml += '<div class="tryApiCodeResponse">' + xhr.status + ' OK</div></div>';
            tmpHtml += '<div><h5>Response Headers</h5>';
            tmpHtml += '<div class="tryApiCodeResponse">';
            tmpHtml += '<div>Content-Type: ' + xhr.getResponseHeader('Content-Type') + '</div>';
            tmpHtml += '</div></div>';
            tmpHtml += '<div><h5>Response Body</h5>';
            tmpHtml += '<div class="tryApiCodeResponse" id="tileGeoMaoRaster" style="display: inline-block;"></div></div>';
            $('#' + resultDivId).html(tmpHtml);
            $('#' + resultDivId).show();
            var img = document.createElement('img');
            var url = window['URL'] || window['webkitURL'];
            img.src = url.createObjectURL(this.response);
            $('#tileGeoMaoRaster').html(img);
          }
          else {
            var tmpHtml = '<div><h5>Request URI</h5>';
            tmpHtml += '<div class="tryApiCodeResponse">' + requestUri + '</div></div>';
            tmpHtml += '<div><h5>Response Status</h5>';
            tmpHtml += '<div class="tryApiCodeResponse">' + xhr.status + ' ' + xhr.statusText + '</div></div>';
            tmpHtml += '<div><h5>Response Headers</h5>';
            tmpHtml += '<div class="tryApiCodeResponse">';
            tmpHtml += '<div>Content-Type: ' + xhr.getResponseHeader('Content-Type') + '</div>';
            tmpHtml += '</div></div>';
            tmpHtml += '<div><h5>Response Body</h5>';
            tmpHtml += '<div class="tryApiCodeResponse"><pre><code class="prettyprint">' + xhr.statusText + '</code></pre></div></div>';
            $('#' + resultDivId).html(tmpHtml);
          }
        }
      }
      xhr.open('GET', xhrUrl, true);
      xhr.responseType = 'blob';
      xhr.send();
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoMapTryApiVectorX = 1206;
  public geoMapTryApiVectorY = 1539;
  public geoMapTryApiVectorZ = 12;
  tryApiGeoMapVector = function ($event) {
    $('#vectormapResult').show();
    var x: any = this.geoMapTryApiVectorX;
    var y: any = this.geoMapTryApiVectorY;
    var z: any = this.geoMapTryApiVectorZ;
    var tmpData = {
      x: x,
      y: y,
      z: z
    };
    var pathParam = ['z', 'x', 'y'];
    this.requestUriMvt = this.liapiservice.getCompleteUri('maps', 'Vector Base Map Tiles', tmpData, pathParam);
    if (x != '' && y != '' && z != '' && !isNaN(x) && !isNaN(y) && !isNaN(z) && x >= 0 && y >= 0 && z >= 0) {
      function tile2long(x, z) {
        return (x / Math.pow(2, z) * 360 - 180);
      }
      function tile2lat(y, z) {
        var n = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
        return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
      }
      var latitude = tile2lat(y, z);
      var longitude = tile2long(x, z);
      var center = latLng(latitude, longitude);
      var zoom = parseFloat(z);
      this.map.setView(center, zoom);
      this.map.dragging.disable();
      setTimeout(() => {
        this.map.panBy(point(128, 128), {animate: true});
      }, 1000);
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
}
