import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'addressing-api',
  templateUrl: './addressingapi.component.html'
})
export class AddressingApiComponent implements OnInit {
  public addressByBoundaryNameParamLink: any = '';
  public addressCountsByBoundaryNameParamLink: any = '';
  public poiBoundaryType = 'geometry';
  public poiCountType = 'geometry';
  public geometryByBoundJson = '{"preferences":{"maxCandidates":"20","page":"1"},"geometry":{"type":"Polygon","crs":{"type":"name","properties":{"name":"epsg:4326"}},"coordinates":[[-123.06002640606759,45.503667642589676],[-123.06054387066362,45.50590391214643],[-123.06157879985564,45.507777543396685],[-123.06179441010397,45.508321500856425],[-123.06179441010397,45.50886545831618],[-123.06153567780596,45.5099231533768],[-123.06110445730928,45.5107693094253],[-123.06080260296162,45.51164568533268],[-123.0604145045146,45.512189642792436],[-123.0602851383656,45.512642940675555],[-123.0601557722166,45.51273360025218],[-123.05998328401793,45.51318689813531],[-123.05972455171994,45.51345887686518],[-123.0598970399186,45.51164568533268],[-123.05972455171994,45.509439635634806],[-123.05925020917358,45.509318756199306],[-123.05890523277625,45.509107217187186],[-123.05868962252792,45.50883523845731],[-123.05864650047826,45.50838194057418],[-123.05890523277625,45.5079890824088],[-123.0595089414716,45.507717103678935],[-123.05942269737224,45.50590391214643],[-123.0598970399186,45.503848961742925],[-123.06002640606759,45.503667642589676]]}}';
  public traveldistance = '{"longitude":"-123.059909","latitude":"45.508550","travelDistance":"2000","travelDistanceUnit":"feet","travelMode":"driving"}';
  public traveltime = '{"longitude":"-123.059909","latitude":"45.508550","travelTime":"2","travelTimeUnit":"minutes","travelMode":"driving"}';
  public geometryAsTextByBoundJson = '{"preferences":{"maxCandidates":"20","page":"1"},"geometryAsText":"POLYGON(( -123.06002640606759 45.503667642589676, -123.06054387066362 45.50590391214643, -123.06157879985564 45.507777543396685, -123.06179441010397 45.508321500856425, -123.06179441010397 45.50886545831618, -123.06153567780596 45.5099231533768, -123.06110445730928 45.5107693094253, -123.06080260296162 45.51164568533268, -123.0604145045146 45.512189642792436, -123.0602851383656 45.512642940675555, -123.0601557722166 45.51273360025218, -123.05998328401793 45.51318689813531, -123.05972455171994 45.51345887686518, -123.0598970399186 45.51164568533268, -123.05972455171994 45.509439635634806, -123.05925020917358 45.509318756199306, -123.05890523277625 45.509107217187186, -123.05868962252792 45.50883523845731, -123.05864650047826 45.50838194057418, -123.05890523277625 45.5079890824088, -123.0595089414716 45.507717103678935, -123.05942269737224 45.50590391214643, -123.0598970399186 45.503848961742925, -123.06002640606759 45.503667642589676))"}';
  public geometryPoiCountJson = '{"geometry":{"type":"Polygon","crs":{"type":"name","properties":{"name":"epsg:4326"}},"coordinates":[[-123.06002640606759,45.503667642589676],[-123.06054387066362,45.50590391214643],[-123.06157879985564,45.507777543396685],[-123.06179441010397,45.508321500856425],[-123.06179441010397,45.50886545831618],[-123.06153567780596,45.5099231533768],[-123.06110445730928,45.5107693094253],[-123.06080260296162,45.51164568533268],[-123.0604145045146,45.512189642792436],[-123.0602851383656,45.512642940675555],[-123.0601557722166,45.51273360025218],[-123.05998328401793,45.51318689813531],[-123.05972455171994,45.51345887686518],[-123.0598970399186,45.51164568533268],[-123.05972455171994,45.509439635634806],[-123.05925020917358,45.509318756199306],[-123.05890523277625,45.509107217187186],[-123.05868962252792,45.50883523845731],[-123.05864650047826,45.50838194057418],[-123.05890523277625,45.5079890824088],[-123.0595089414716,45.507717103678935],[-123.05942269737224,45.50590391214643],[-123.0598970399186,45.503848961742925],[-123.06002640606759,45.503667642589676]]}}';
  public geometryAsTextPoiCountJson = '{"geometryAsText":"POLYGON(( -123.06002640606759 45.503667642589676, -123.06054387066362 45.50590391214643, -123.06157879985564 45.507777543396685, -123.06179441010397 45.508321500856425, -123.06179441010397 45.50886545831618, -123.06153567780596 45.5099231533768, -123.06110445730928 45.5107693094253, -123.06080260296162 45.51164568533268, -123.0604145045146 45.512189642792436, -123.0602851383656 45.512642940675555, -123.0601557722166 45.51273360025218, -123.05998328401793 45.51318689813531, -123.05972455171994 45.51345887686518, -123.0598970399186 45.51164568533268, -123.05972455171994 45.509439635634806, -123.05925020917358 45.509318756199306, -123.05890523277625 45.509107217187186, -123.05868962252792 45.50883523845731, -123.05864650047826 45.50838194057418, -123.05890523277625 45.5079890824088, -123.0595089414716 45.507717103678935, -123.05942269737224 45.50590391214643, -123.0598970399186 45.503848961742925, -123.06002640606759 45.503667642589676))"}';
  public traveldistanceCount = '{"longitude":"-123.059909","latitude":"45.508550","travelDistance":"2000","travelDistanceUnit":"feet","travelMode":"driving"}';
  public traveltimeCount = '{"longitude":"-123.059909","latitude":"45.508550","travelTime":"2","travelTimeUnit":"minutes","travelMode":"driving"}';
  public AddressingTryApiByBoundaryNameareaName1 = 'NY';
  public AddressingTryApiByBoundaryNameareaName2 = 'RENSSELAER';
  public AddressingTryApiByBoundaryNameareaName3 = 'TROY';
  public AddressingTryApiByBoundaryNameareaName4 = 'EASTSIDE';
  public AddressingTryApiByBoundaryNamepostcode = '';
  public AddressingTryApiByBoundaryNamecountry = 'USA';
  public AddressingTryApiByBoundaryNamemaxCandidates = '';
  public AddressingTryApiByBoundaryNamepage = '1';
  public AddressingTryApiByBoundaryNameDataType = 'json';
  public AddressingTryApiaddresscountByBoundaryNameareaName1 = 'NY';
  public AddressingTryApiaddresscountByBoundaryNameareaName2 = 'RENSSELAER';
  public AddressingTryApiaddresscountByBoundaryNameareaName3 = 'TROY';
  public AddressingTryApiaddresscountByBoundaryNameareaName4 = 'EASTSIDE';
  public AddressingTryApiaddresscountByBoundaryNamepostcode = '';
  public AddressingTryApiaddresscountByBoundaryNamecountry = 'USA';
  public AddressingTryApiaddresscountByBoundaryNameDataType = 'json';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'address list, bulk mail, property type, property geometry, genealogy, parent address' }
    );
    this.geometryByBoundJson = JSON.stringify(JSON.parse(this.geometryByBoundJson), undefined, 4);
    this.geometryAsTextByBoundJson = JSON.stringify(JSON.parse(this.geometryAsTextByBoundJson), undefined, 4);
    this.traveltime = JSON.stringify(JSON.parse(this.traveltime), undefined, 4);
    this.traveldistance = JSON.stringify(JSON.parse(this.traveldistance), undefined, 4);
    this.geometryPoiCountJson = JSON.stringify(JSON.parse(this.geometryPoiCountJson), undefined, 4);
    this.geometryAsTextPoiCountJson = JSON.stringify(JSON.parse(this.geometryAsTextPoiCountJson), undefined, 4);
    this.traveltimeCount = JSON.stringify(JSON.parse(this.traveltimeCount), undefined, 4);
    this.traveldistanceCount = JSON.stringify(JSON.parse(this.traveldistanceCount), undefined, 4);
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.setParamLink(success.docsURL);
        }
      );
    }
    else {
      this.setParamLink(docsURL);
    }
  };
  setParamLink = function (docsURL) {
    this.addressByBoundaryNameParamLink = docsURL + 'Addresses/get_By_boundary_name/query_param.html';
    this.addressCountsByBoundaryNameParamLink = docsURL + 'Addresses/get_counts_By_boundary_name/query_param.html';
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_byBoundaryRes':
        this.get_byBoundaryUri = "";
        this.get_byBoundaryRes = null;
        this.get_byBoundaryResStatus = "";
        this.get_byBoundaryResType = "";
        break;
      case 'post_byBoundaryRes':
        this.post_byBoundaryUri = "";
        this.post_byBoundaryRes = null;
        this.post_byBoundaryResStatus = "";
        this.post_byBoundaryResType = "";
        break;
      case 'post_countByBoundaryRes':
        this.post_countByBoundaryUri = "";
        this.post_countByBoundaryRes = null;
        this.post_countByBoundaryResStatus = "";
        this.post_countByBoundaryResType = "";
        break;
      case 'get_countByBoundaryRes':
        this.get_countByBoundaryUri = "";
        this.get_countByBoundaryRes = null;
        this.get_countByBoundaryResStatus = "";
        this.get_countByBoundaryResType = "";
        break;
    }
  };
  setPoiCountType = function (poiCountType) {
    this.poiCountType = poiCountType;
  };
  setPoiBoundaryType = function (poiBoundaryType) {
    this.poiBoundaryType = poiBoundaryType;
  };
  public get_byBoundaryUri = "";
  public get_byBoundaryRes = null;
  public get_byBoundaryResStatus = "";
  public get_byBoundaryResType = "";
  tryApiAddressingByBoundaryName = function (btnId) {
    var areaName1 = this.AddressingTryApiByBoundaryNameareaName1;
    var areaName2 = this.AddressingTryApiByBoundaryNameareaName2;
    var areaName3 = this.AddressingTryApiByBoundaryNameareaName3;
    var areaName4 = this.AddressingTryApiByBoundaryNameareaName4;
    var country = this.AddressingTryApiByBoundaryNamecountry;
    var postCode = this.AddressingTryApiByBoundaryNamepostcode;
    var maxCandidates = this.AddressingTryApiByBoundaryNamemaxCandidates;
    var page = this.AddressingTryApiByBoundaryNamepage;
    var dataType = this.AddressingTryApiByBoundaryNameDataType;
    var tmpData = {
      dataType: dataType
    };
    if (areaName1 != '') {
      tmpData['areaName1'] = areaName1;
    }
    if (areaName2 != '') {
      tmpData['areaName2'] = areaName2;
    }
    if (areaName3 != '') {
      tmpData['areaName3'] = areaName3;
    }
    if (areaName4 != '') {
      tmpData['areaName4'] = areaName4;
    }
    if (country != '') {
      tmpData['country'] = country;
    }
    if (postCode != '') {
      tmpData['postCode'] = postCode;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (page != '') {
      tmpData['page'] = page;
    }
    if (areaName1 != '' || postCode != '') {
      var methodName = '/tryApiAddressingByBoundaryName';
      let requestUri = this.liapiservice.getCompleteUri('addresses', 'Addresses By Boundary Name', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_byBoundaryUri = requestUri;
          this.get_byBoundaryResType = dataType;
          if(this.get_byBoundaryResStatus.includes('429')){
            this.get_byBoundaryUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_byBoundaryResStatus = '200 success';
            this.get_byBoundaryRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_byBoundaryResStatus = `${error.status} ${error.statusText}`;
              this.get_byBoundaryRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_byBoundaryUri = "";
  public post_byBoundaryRes = null;
  public post_byBoundaryResStatus = "";
  public post_byBoundaryResType = "";
  tryApiAddressByBoundary = function (btnId) {
    var postData = '';
    if (this.poiBoundaryType === 'geometry') {
      postData = this.geometryByBoundJson;
    }
    else if (this.poiBoundaryType === 'geometryastext') {
      postData = this.geometryAsTextByBoundJson;
    }
    else if (this.poiBoundaryType === 'traveltime') {
      postData = this.traveltime;
    }
    else if (this.poiBoundaryType === 'traveldistance') {
      postData = this.traveldistance;
    }
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiAddressByBoundary';
      let requestUri = this.liapiservice.getCompleteUriPost('addresses', 'Addresses By Boundary');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_byBoundaryUri = requestUri;
          this.post_byBoundaryResType = 'json';
          if(this.post_byBoundaryResStatus.includes('429')){
            this.post_byBoundaryUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_byBoundaryResStatus = '200 success';
            this.post_byBoundaryRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_byBoundaryResStatus = `${error.status} ${error.statusText}`;
              this.post_byBoundaryRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public post_countByBoundaryUri = "";
  public post_countByBoundaryRes = null;
  public post_countByBoundaryResStatus = "";
  public post_countByBoundaryResType = "";
  tryApiCountAddressByBoundary = function (btnId) {
    var postData = '';
    if (this.poiCountType === 'geometry') {
      postData = this.geometryPoiCountJson;
    }
    else if (this.poiCountType === 'geometryastext') {
      postData = this.geometryAsTextPoiCountJson;
    }
    else if (this.poiCountType === 'traveltime') {
      postData = this.traveltimeCount;
    }
    else if (this.poiCountType === 'traveldistance') {
      postData = this.traveldistanceCount;
    }
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiCountAddressByBoundary';
      let requestUri = this.liapiservice.getCompleteUriPost('addresses', 'Addresses Counts By Boundary');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_countByBoundaryUri = requestUri;
          this.post_countByBoundaryResType = 'json';
          if(this.post_countByBoundaryResStatus.includes('429')){
            this.post_countByBoundaryUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_countByBoundaryResStatus = '200 success';
            this.post_countByBoundaryRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_countByBoundaryResStatus = `${error.status} ${error.statusText}`;
              this.post_countByBoundaryRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public get_countByBoundaryUri = "";
  public get_countByBoundaryRes = null;
  public get_countByBoundaryResStatus = "";
  public get_countByBoundaryResType = "";
  tryApiAddressingcountByBoundaryName = function (btnId) {
    var areaName1 = this.AddressingTryApiaddresscountByBoundaryNameareaName1;
    var areaName2 = this.AddressingTryApiaddresscountByBoundaryNameareaName2;
    var areaName3 = this.AddressingTryApiaddresscountByBoundaryNameareaName3;
    var areaName4 = this.AddressingTryApiaddresscountByBoundaryNameareaName4;
    var country = this.AddressingTryApiaddresscountByBoundaryNamecountry;
    var postCode = this.AddressingTryApiaddresscountByBoundaryNamepostcode;
    var dataType = this.AddressingTryApiaddresscountByBoundaryNameDataType;
    var tmpData = {
      dataType: dataType
    };
    if (areaName1 != '') {
      tmpData['areaName1'] = areaName1;
    }
    if (areaName2 != '') {
      tmpData['areaName2'] = areaName2;
    }
    if (areaName3 != '') {
      tmpData['areaName3'] = areaName3;
    }
    if (areaName4 != '') {
      tmpData['areaName4'] = areaName4;
    }
    if (country != '') {
      tmpData['country'] = country;
    }
    if (postCode != '') {
      tmpData['postCode'] = postCode;
    }
    if (areaName1 != '' || postCode != '') {
      var methodName = '/tryApiAddressingcountByBoundaryName';
      let requestUri = this.liapiservice.getCompleteUri('addresses', 'Addresses Counts By Boundary Name', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_countByBoundaryUri = requestUri;
          this.get_countByBoundaryResType = dataType;
          if(this.get_countByBoundaryResStatus.includes('429')){
            this.get_countByBoundaryUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_countByBoundaryResStatus = '200 success';
            this.get_countByBoundaryRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_countByBoundaryResStatus = `${error.status} ${error.statusText}`;
              this.get_countByBoundaryRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
}
