import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import * as L from "leaflet";

@Component({
  selector: 'risks-demo',
  templateUrl: './risksdemo.component.html'
})
export class RisksDemoComponent implements OnInit {
  public docsURL: any = "";
  public activetab = "crime";
  public polyColor = {
    'High': '#CC3333',
    'Moderate': '#FC8D59',
    'Moderate to Minimal': '#F9EC73',
    'Minimal': '#66CC66',
    'Unknown': '#96E0DE',
    'Undetermined': '#96E0DE'
  };
  public polyOpacity = {
    'High': 0.25,
    'Moderate': 0.25,
    'Moderate to Minimal': 0.4,
    'Minimal': 0.25,
    'Unknown': 0.25,
    'Undetermined': 0.25
  };
  public polyColorCrime = {
    'Highest': '#CC3333',
    'High': '#FC8D59',
    'Above Average': '#F9EC73',
    'Below Average': '#66CC66',
    'Low': '#96E0DE',
    'Lowest': '#96E0DE'
  };
  public polyOpacityCrime = {
    'Highest': 0.25,
    'High': 0.25,
    'Above Average': 0.4,
    'Below Average': 0.25,
    'Low': 0.25,
    'Lowest': 0.25
  };
  public polyColorEarthquake = {
    'High': '#CC3333',
    'Medium': '#66CC66',
    'Low': '#96E0DE'
  };
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public geometryCrime: any = null;
  public crimeCategory;
  public geometryEarthquake: any = null;
  public riskLevel;
  public geometryFlood: any = null;
  public floodZoneRiskLevel;
  public polygon;
  public locationsFirestationArr = [];
  public newMarker;
  public resMarkers = [];
  public resMarkerDetail = [];
  public byAddrCountry: any = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public searchconCountryCode = 'USA';
  countries: any[];
  filteredCountries: any[];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService) { };
  ngOnInit() {
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.docsURL = success.docsURL;
        }
      );
    }
    else{
      this.docsURL = docsURL;
    }
  };
  ngDoCheck() {
    if (this.byAddrCountry.code) {
      this.searchconCountryCode = this.byAddrCountry.code;
    }
    else {
      this.searchconCountryCode = this.byAddrCountry.toString();
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
    this.map.on('click', (e: L.LeafletMouseEvent) => {
      this.mapclickHandler(e.latlng);
    });
  };
  mapclickHandler = function (latlng) {
    this.geoApisGetAddressGeocode(latlng.lat, latlng.lng);
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    this.removeAllResLayes();
    if (this.activetab == 'crime') {
      if (this.geometryCrime != null) {
        let geometry = this.geometryCrime;
        let locationsArr = [];
        for (let i = 0; i < geometry.coordinates.length; i++) {
          for (let j = 0; j < geometry.coordinates[i].length; j++) {
            locationsArr.push([geometry.coordinates[i][j][1], geometry.coordinates[i][j][0]]);
          }
        }
        let option: any = {
          color: this.polyColorCrime[this.crimeCategory],
          fillColor: this.polyColorCrime[this.crimeCategory],
          fillOpacity: this.polyOpacityCrime[this.crimeCategory]
        };
        this.polygon = L.geoJSON(geometry as any, option).addTo(this.map);
        this.map.fitBounds(locationsArr);
      }
    }
    if (this.activetab == 'earthquake') {
      if (this.geometryEarthquake != null) {
        let geometry = this.geometryEarthquake;
        let locationsArr = [];
        for (let i = 0; i < geometry.coordinates.length; i++) {
          for (let j = 0; j < geometry.coordinates[i].length; j++) {
            locationsArr.push([geometry.coordinates[i][j][1], geometry.coordinates[i][j][0]]);
          }
        }
        let option: any = {
          color: this.polyColorEarthquake[this.riskLevel],
          fillColor: this.polyColorEarthquake[this.riskLevel],
          fillOpacity: 0.25
        }
        this.polygon = L.geoJSON(geometry as any, option).addTo(this.map);
        this.map.fitBounds(locationsArr);
      }
    }
    if (this.activetab == 'flood') {
      if (this.geometryFlood != null) {
        let geometry = this.geometryFlood;
        let locationsArr = [];
        for (let i = 0; i < geometry.coordinates.length; i++) {
          for (let j = 0; j < geometry.coordinates[i].length; j++) {
            locationsArr.push([geometry.coordinates[i][j][1], geometry.coordinates[i][j][0]]);
          }
        }
        let option: any = {
          color: this.polyColor[this.floodZoneRiskLevel],
          fillColor: this.polyColor[this.floodZoneRiskLevel],
          fillOpacity: this.polyOpacity[this.floodZoneRiskLevel]
        }
        this.polygon = L.geoJSON(geometry as any, option).addTo(this.map);
        this.map.fitBounds(locationsArr);
      }
    }
    if (this.activetab == 'fireStation') {
      if (this.resMarkerDetail.length != 0) {
        this.resMarkers = [];
        for (let i = 0; i < this.resMarkerDetail.length; i++) {
          let resMarkerIcon = {
            icon: L.divIcon({
              iconSize: [56, 56],
              iconAnchor: [28, 56],
              popupAnchor: [0, -44],
              className: 'singlePoiIcon',
              html: this.resMarkerDetail[i].text
            })
          };
          let resMarker = L.marker([this.resMarkerDetail[i].lat, this.resMarkerDetail[i].lng], resMarkerIcon).addTo(this.map);
          this.resMarkers.push(resMarker);
        }
        this.map.fitBounds(this.locationsFirestationArr);
      }
    }
  };
  removeAllResLayes = function () {
    if (this.resMarkers.length != 0) {
      for (let i = 0; i < this.resMarkers.length; i++) {
        this.map.removeLayer(this.resMarkers[i]);
      }
    }
    if (this.polygon != undefined) {
      this.map.removeLayer(this.polygon);
    };
  };
  showSearchForm = function () {
    $('div.searchAddressForm table').show();
    $('div.searchAddressForm h4.searchResult').hide();
  };
  public searchedAddr = '';
  geoRiskDemoSearchAddress = function () {
    let address = $('.pb-geo-input-text').val().toString();
    var country = '';
    if (this.byAddrCountry != '') {
      if (this.byAddrCountry.code) {
        country = this.byAddrCountry.code;
      }
      else {
        country = this.byAddrCountry;
      }
    }
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    this.removeAllResLayes();
    this.locationsFirestationArr = [];
    $('#geoRiskDemoSearchError').hide();
    $('#geoRiskCrimeError').hide();
    $('#geoRiskCrimeResult').html('');
    $('#geoRiskEarthquakeError').hide();
    $('#geoRiskEarthquakeResult').html('');
    $('#geoRiskFloodError').hide();
    $('#geoRiskFloodResult').html('');
    $('#geoRiskFireError').hide();
    $('#geoRiskFireResult').html('');
    $('#geoRiskFireStationError').hide();
    $('#geoRiskFireStationResult').html('');
    $('#geoRiskDistanceToCoastError').hide();
    $('#geoRiskDistanceToCoastResult').html('');
    if (address != '') {
      this.searchedAddr = address;
      $('div.searchAddressForm table').hide();
      $('div.searchAddressForm h4.searchResult').show();
      this.geoApisGetLocationGeocode(address, country);
    }
    else {
      $('#geoRiskDemoSearchError').html('Address cannot be blank.');
      $('#geoRiskDemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var lat = success.candidates[0].geometry.coordinates[1];
          var lng = success.candidates[0].geometry.coordinates[0];
          this.locationsFirestationArr.push([lat, lng]);
          this.map.setView(L.latLng(lat, lng), 14);
          this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
          this.sampleDemoGeoRiskCrime(lat, lng);
          this.sampleDemoGeoRiskEarthquake(lat, lng);
          this.sampleDemoGeoRiskFlood(lat, lng);
          this.sampleDemoGeoRiskFire(lat, lng);
          this.sampleDemoGeoRiskFireStation(lat, lng);
          this.sampleDemoGeoRiskDisToCoast(lat, lng);
        }
        else {
          $('#geoRiskCrimeError').hide();
          $('#geoRiskCrimeResult').html('');
          $('#geoRiskEarthquakeError').hide();
          $('#geoRiskEarthquakeResult').html('');
          $('#geoRiskFloodError').hide();
          $('#geoRiskFloodResult').html('');
          $('#geoRiskFireError').hide();
          $('#geoRiskFireResult').html('');
          $('#geoRiskFireStationError').hide();
          $('#geoRiskFireStationResult').html('');
          $('#geoRiskDistanceToCoastError').hide();
          $('#geoRiskDistanceToCoastResult').html('');
          $('#geoRiskDemoSearchError').html('No information available for this address.');
          $('#geoRiskDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeoRiskCrime = function (lat, lng) {
    $('#geoRiskCrimeError').hide();
    $('#geoRiskCrimeResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng,
      type: 'all',
      includeGeometry: 'Y'
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRiskCrime', tmpData).subscribe(
      success => {
        if (success.boundaries && success.themes) {
          var tmpHtml = '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Crime Type</div><h4 class="zeromargintop">' + success.themes[0].crimeIndexTheme.indexVariable[0].name + '<span class="colorSquare" style="background-color:' + this.polyColorCrime[success.themes[0].crimeIndexTheme.indexVariable[0].category] + '"></span></h4></td><td><div class="resultKey">Risk Level</div><h4 class="zeromargintop">' + success.themes[0].crimeIndexTheme.indexVariable[0].category + '</h4></td><td><div class="resultKey">Index</div><h4 class="zeromargintop">' + success.themes[0].crimeIndexTheme.indexVariable[0].score + '</h4></td></tr>';
          for (var i = 1; i < success.themes[0].crimeIndexTheme.indexVariable.length; i++) {
            tmpHtml += '<tr><td><div>';
            tmpHtml += success.themes[0].crimeIndexTheme.indexVariable[i].name;
            tmpHtml += '</div></td>';
            tmpHtml += '<td>';
            tmpHtml += '<div>' + success.themes[0].crimeIndexTheme.indexVariable[i].category + '</div>';
            tmpHtml += '</td><td>';
            tmpHtml += '<div>' + success.themes[0].crimeIndexTheme.indexVariable[i].score + '</div>';
            tmpHtml += '</td></tr>';
          }
          tmpHtml += '</table>';
          $('#geoRiskCrimeResult').html(tmpHtml);
          this.geometryCrime = success.boundaries.boundary[0].geometry;
          this.crimeCategory = success.themes[0].crimeIndexTheme.indexVariable[0].category;
        }
        else {
          $('#geoRiskCrimeError').html('No information available for this address/location.');
          $('#geoRiskCrimeError').show();
        }
        $('.geoApisDemoMapLoader').hide();
        this.toogleDemoTab(this.activetab);
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoRiskCrimeError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoRiskCrimeError').html(error.statusText);
        }
        $('#geoRiskCrimeError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoRiskCrimeError').hide();
        }
      }
    );
  };
  sampleDemoGeoRiskEarthquake = function (lat, lng) {
    $('#geoRiskEarthquakeError').hide();
    $('#geoRiskEarthquakeResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng,
      richterValue: 'all',
      includeGeometry: 'Y'
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRiskEarthquake', tmpData).subscribe(
      success => {
        if (success.eventsCount && success.grid) {
          var tmpHtml = '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td colspan="2"><div class="resultKey">Risk Level</div><div class="h4">' + success.riskLevel + '<span class="colorSquare" style="background-color:' + this.polyColorEarthquake[success.riskLevel] + '"></span></div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">Richter Scale</div></td><td><div class="resultKey">No. of Events</div></td></tr>';
          for (var key in success.eventsCount) {
            if (success.eventsCount[key] != '' && key != 'r0_ge' && key != 'r1_ge' && key != 'r2_ge' && key != 'r3_ge' && key != 'r4_ge' && key != 'r5_ge' && key != 'r6_ge' && key != 'r7_ge' && key != 'total') {
              tmpHtml += '<tr><td>' + key + '</td><td>' + success.eventsCount[key] + '</td></tr>';
            }
          }
          tmpHtml += '<tr><th>Total</div></th><th>' + success.eventsCount.total + '</th></tr>';
          tmpHtml += '</table>';
          $('#geoRiskEarthquakeResult').html(tmpHtml);
          this.geometryEarthquake = success.grid.geometry;
          this.riskLevel = success.riskLevel;
        }
        else {
          $('#geoRiskEarthquakeError').html('No information available for this address/location.');
          $('#geoRiskEarthquakeError').show();
        }
        $('.geoApisDemoMapLoader').hide();
        this.toogleDemoTab(this.activetab);
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoRiskEarthquakeError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoRiskEarthquakeError').html(error.statusText);
        }
        $('#geoRiskEarthquakeError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoRiskEarthquakeError').hide();
        }
      }
    );
  };
  sampleDemoGeoRiskFlood = function (lat, lng) {
    $('#geoRiskFloodError').hide();
    $('#geoRiskFloodResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng,
      includeZoneDesc: 'Y',
      includeGeometry: 'Y'
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRiskFlood', tmpData).subscribe(
      success => {
        if (success.floodZone && success.boundary) {
          var tmpHtml = '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Risk Level</div><div class="h4">' + success.floodZone.riskLevel + '<span class="colorSquare" style="background-color:' + this.polyColor[success.floodZone.riskLevel] + '"></span></div></td><td><div class="resultKey">Code</div><div class="h4">' + success.floodZone.code + '</div></td></tr>';
          if (success.floodZone.baseFloodElevation.value != '') {
            tmpHtml += '<tr><td colspan="2"><div class="resultKey">Base Flood Elevation</div><div>' + parseInt(success.floodZone.baseFloodElevation.value) + ' ' + success.floodZone.baseFloodElevation.unit + '</div></td></tr>';
          }
          tmpHtml += '</table>';
          tmpHtml += '<p class="resultKey">' + success.floodZone.areaType + ' <a href="'+ this.docsURL +'Appendix/appendix_u_georisk_flood.html" rel="noopener noreferrer" target="_blank" style="margin-left:10px;">what&#39;s this?</a></p>';
          tmpHtml += '<div>' + success.floodZone.primaryZone.description + '</div>';
          document.getElementById('geoRiskFloodResult').innerHTML=decodeURI(encodeURI(tmpHtml));
          this.geometryFlood = success.boundary.geometry;
          this.floodZoneRiskLevel = success.floodZone.riskLevel;
        }
        else {
          $('#geoRiskFloodError').html('No information available for this address/location.');
          $('#geoRiskFloodError').show();
        }
        $('.geoApisDemoMapLoader').hide();
        this.toogleDemoTab(this.activetab);
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoRiskFloodError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoRiskFloodError').html(error.statusText);
        }
        $('#geoRiskFloodError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoRiskFloodError').hide();
        }
      }
    );
  };
  sampleDemoGeoRiskFire = function (lat, lng) {
    $('#geoRiskFireError').hide();
    $('#geoRiskFireResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRiskFire', tmpData).subscribe(
      success => {
        if (success) {
          var tmpHtml = '<table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Risk Type</div><div class="h4">' + success.noharmModel;
          tmpHtml += '</div></td><td><div class="resultKey">Risk Rating</div><div class="h4">' + success.risk50 + '</div></td></tr>';
          tmpHtml += '</table>';
          tmpHtml += '<p><span class="h4">' + success.riskDesc + '</span> <a href="'+ this.docsURL +'Risks/risks_fire_v2/byadd/resp_desc.html" rel="noopener noreferrer" target="_blank" style="margin-left:10px;">what&#39;s this?</a></p>';
          document.getElementById('geoRiskFireResult').innerHTML=decodeURI(encodeURI(tmpHtml));
        }
        else {
          $('#geoRiskFireError').html('No information available for this address/location.');
          $('#geoRiskFireError').show();
        }
        $('.geoApisDemoMapLoader').hide();
        this.toogleDemoTab(this.activetab);
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoRiskFireError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoRiskFireError').html(error.statusText);
        }
        $('#geoRiskFireError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoRiskFireError').hide();
        }
      }
    );
  };
  sampleDemoGeoRiskFireStation = function (lat, lng) {
    $('#geoRiskFireStationError').hide();
    $('#geoRiskFireStationResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng
    };
    this.resMarkerDetail = [];
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRiskFireStation', tmpData).subscribe(
      success => {
        if (success.fireStation) {
          var tmpHtml = '<div class="accordion" id="accordiondemo">';
          var markerCount = 1;
          for (var i = 0; i < success.fireStation.length; i++) {
            var latitude = success.fireStation[i].geometry.coordinates[1];//latitude
            var longitude = success.fireStation[i].geometry.coordinates[0];//longitude
            var markerName = markerCount + '. ' + success.fireStation[i].fireDepartment.name;
            var markerText = markerCount.toString();
            markerCount++;
            this.resMarkerDetail.push({ text: markerText, lat: latitude, lng: longitude })
            this.locationsFirestationArr.push([latitude, longitude]);
            tmpHtml += '<div class="card">';
            tmpHtml += '<div class="card-header" id="firestation_head' + i + '">';
            tmpHtml += '<h5 class="mb-0">';
            tmpHtml += '<button class="btn btn-acc" type="button" data-bs-toggle="collapse" data-bs-target="#firestation_content' + i + '" aria-controls="#firestation_content' + i + '"';
            if (i != 0) {
              tmpHtml += ' aria-expanded="false"';
            }
            else {
              tmpHtml += ' aria-expanded="true"';
            }
            tmpHtml += '>' + markerName + '</button></h5></div>';
            tmpHtml += '<div id="firestation_content' + i + '" class="collapse';
            if (i == 0) {
              tmpHtml += ' show';
            }
            tmpHtml += '" aria-labelledby="firestation_head' + i + '" data-parent="#accordiondemo"><div class="card-body">';
            tmpHtml += '<table class="table table-condensed table-unstyled">';
            for (var key in success.fireStation[i].contactDetails.address) {
              if (success.fireStation[i].contactDetails.address[key] != '') {
                tmpHtml += '<tr><td>' + key + '</td><td>' + success.fireStation[i].contactDetails.address[key] + '</td></tr>';
              }
            }
            tmpHtml += '<tr><td>phone</td><td>' + success.fireStation[i].contactDetails.phone + '</td></tr>';
            tmpHtml += '<tr><td>travel distance</td><td>' + success.fireStation[i].travelDistance.value + success.fireStation[i].travelDistance.unit + '</td></tr>';
            tmpHtml += '<tr><td>travel time</td><td>' + success.fireStation[i].travelTime.value + success.fireStation[i].travelTime.unit + '</td></tr>';
            tmpHtml += '</table>';
            tmpHtml += '</div></div></div>';
          }
          tmpHtml += '</div>';
          $('#geoRiskFireStationResult').html(tmpHtml);
        }
        else {
          $('#geoRiskFireStationError').html('No information available for this address/location.');
          $('#geoRiskFireStationError').show();
        }
        $('.geoApisDemoMapLoader').hide();
        this.toogleDemoTab(this.activetab);
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoRiskFireStationError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoRiskFireStationError').html(error.statusText);
        }
        $('#geoRiskFireStationError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoRiskFireStationError').hide();
        }
      }
    );
  };
  sampleDemoGeoRiskDisToCoast = function (lat, lng) {
    $('#geoRiskDistanceToCoastError').hide();
    $('#geoRiskDistanceToCoastResult').html('');
    var tmpData = {
      latitude: lat,
      longitude: lng
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRiskDisToFloodHazard', tmpData).subscribe(
      success => {
        if (success.waterBody && success.waterBody.length != 0) {
          var tmpHtml = '<div>';
          for (var i = 0; i < success.waterBody.length; i++) {
            tmpHtml += '<h2 class="zeromargintop">' + success.waterBody[i].name + '</h2>';
            tmpHtml += '<table class="table table-condensed table-unstyled">';
            tmpHtml += '<tr><td><div class="resultKey">Type</div><div class="resultVal">' + success.waterBody[i].type.value + '</div></td><td><div class="resultKey">Distance</div><div class="resultVal">' + success.waterBody[i].distance.value + ' ' + success.waterBody[i].distance.unit + '</div></td></tr>';
            tmpHtml += '</table>';
          }
          tmpHtml += '</div>';
          $('#geoRiskDistanceToCoastResult').html(tmpHtml);
        }
        else {
          $('#geoRiskDistanceToCoastError').html('No information available for this address/location.');
          $('#geoRiskDistanceToCoastError').show();
        }
        $('.geoApisDemoMapLoader').hide();
        this.toogleDemoTab(this.activetab);
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoRiskDistanceToCoastError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoRiskDistanceToCoastError').html(error.statusText);
        }
        $('#geoRiskDistanceToCoastError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoRiskDistanceToCoastError').hide();
        }
      }
    );
  };
  sampleDemoGeoMapShareLocation = function () {
    var that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        that.geoApisGetAddressGeocode(lat, lng);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  geoApisGetAddressGeocode = function (lat, lng) {
    $('.geoApisDemoMapLoader').show();
    $('#geoRiskDemoSearchError').hide();
    $('#geoRiskCrimeError').hide();
    $('#geoRiskCrimeResult').html('');
    $('#geoRiskEarthquakeError').hide();
    $('#geoRiskEarthquakeResult').html('');
    $('#geoRiskFloodError').hide();
    $('#geoRiskFloodResult').html('');
    $('#geoRiskFireError').hide();
    $('#geoRiskFireResult').html('');
    $('#geoRiskFireStationError').hide();
    $('#geoRiskFireStationResult').html('');
    $('#geoRiskDistanceToCoastError').hide();
    $('#geoRiskDistanceToCoastResult').html('');
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    }
    this.removeAllResLayes();
    this.locationsFirestationArr = [[lat, lng]];
    this.map.setView(L.latLng(lat, lng), 14);
    this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
    var tmpData = {
      dataType: 'json',
      x: lng,
      y: lat
    };
    this.liapiservice.geoApiSampleDemoGetCall('/tryApigeoCodeGetRevPre', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var tmpAddr = "";
          if (success.candidates[0].address.mainAddressLine) {
            tmpAddr += success.candidates[0].address.mainAddressLine;
          }
          if (success.candidates[0].address.mainAddressLine && success.candidates[0].address.addressLastLine) {
            tmpAddr += ', ';
          }
          if (success.candidates[0].address.addressLastLine) {
            tmpAddr += success.candidates[0].address.addressLastLine;
          }
          this.liapiservice.getCountryObj(success.candidates[0].address.country).subscribe(
            success => {
              this.byAddrCountry = success;
            }
          );
          $('.pb-geo-input-text').val(tmpAddr);
          if (tmpAddr != '') {
            this.searchedAddr = tmpAddr;
            $('div.searchAddressForm table').hide();
            $('div.searchAddressForm h4.searchResult').show();
          }
          else {
            this.showSearchForm();
          }
          this.sampleDemoGeoRiskCrime(lat, lng);
          this.sampleDemoGeoRiskEarthquake(lat, lng);
          this.sampleDemoGeoRiskFlood(lat, lng);
          this.sampleDemoGeoRiskFire(lat, lng);
          this.sampleDemoGeoRiskFireStation(lat, lng);
          this.sampleDemoGeoRiskDisToCoast(lat, lng);
        }
        else {
          $('.pb-geo-input-text').val('');
          this.showSearchForm();
          $('#geoRiskDemoSearchError').html('No information available for this location.');
          $('#geoRiskDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
}
