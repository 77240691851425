import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'typeahead-how',
  templateUrl: './typeaheadhow.component.html'
})
export class TypeaheadHowComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  };
  ngOnInit() { };
}
