import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import * as L from "leaflet";
import * as AppConfigs from '../app.constants';

@Component({
  selector: 'addresses-demo',
  templateUrl: './addressesdemo.component.html'
})
export class AddressesDemoComponent implements OnInit {
  public demoInfo = "Note: We are only showing 20 addressess for demo, there are more addresses in this boundary."
  public activetab = "byboundaryname";
  public boundType = "";
  public country = "USA";
  public areaName1 = "NY";
  public areaName2 = "RENSSELAER";
  public areaName3 = "TROY";
  public areaName4 = "EASTSIDE";
  public postcode = "12180";
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public polygon;
  public isByBoundaryReady = false;
  public isByBoundaryNameReady = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService) { };
  ngOnInit() {
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
  };
  public boundaryGeometry = '[[-123.06002640606759,45.503667642589676],[-123.06054387066362,45.50590391214643],[-123.06157879985564,45.507777543396685],[-123.06179441010397,45.508321500856425],[-123.06179441010397,45.50886545831618],[-123.06153567780596,45.5099231533768],[-123.06110445730928,45.5107693094253],[-123.06080260296162,45.51164568533268],[-123.0604145045146,45.512189642792436],[-123.0602851383656,45.512642940675555],[-123.0601557722166,45.51273360025218],[-123.05998328401793,45.51318689813531],[-123.05972455171994,45.51345887686518],[-123.0598970399186,45.51164568533268],[-123.05972455171994,45.509439635634806],[-123.05925020917358,45.509318756199306],[-123.05890523277625,45.509107217187186],[-123.05868962252792,45.50883523845731],[-123.05864650047826,45.50838194057418],[-123.05890523277625,45.5079890824088],[-123.0595089414716,45.507717103678935],[-123.05942269737224,45.50590391214643],[-123.0598970399186,45.503848961742925],[-123.06002640606759,45.503667642589676]]';
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    this.removeMarkerLayers();
    let boundaryGeometry = '';
    if (this.activetab == 'byboundary') {
      if (this.isByBoundaryReady) {
        boundaryGeometry = this.boundaryGeometry;
      }
    }
    else if (this.activetab == 'byboundaryname') {
      if (this.isByBoundaryNameReady) {
        boundaryGeometry = AppConfigs.addressesCoordinates[this.boundType];
      }
    }
    if (boundaryGeometry != '') {
      let geometryCoordArr = JSON.parse(boundaryGeometry);
      let locationsArr = [];
      for (let i = 0; i < geometryCoordArr.length; i++) {
        locationsArr.push([geometryCoordArr[i][1], geometryCoordArr[i][0]]);
      }
      let geometry: any = {
        type: "Polygon",
        coordinates: [geometryCoordArr]
      }
      this.polygon = L.geoJSON(geometry as any).addTo(this.map);
      this.map.fitBounds(locationsArr);
    }
  };
  removeMarkerLayers = function () {
    if (this.polygon != undefined) {
      this.map.removeLayer(this.polygon);
    };
  };
  getAddressByBoundary = function () {
    this.removeMarkerLayers();
    $('#byboundaryError').hide();
    $('#byboundaryResult').html('');
    this.isByBoundaryReady = true;
    let boundaryGeometry = this.boundaryGeometry;
    if (boundaryGeometry != '') {
      let geometryCoordArr = JSON.parse(boundaryGeometry);
      let locationsArr = [];
      for (let i = 0; i < geometryCoordArr.length; i++) {
        locationsArr.push([geometryCoordArr[i][1], geometryCoordArr[i][0]]);
      }
      let geometry: any = {
        type: "Polygon",
        coordinates: [geometryCoordArr]
      }
      this.polygon = L.geoJSON(geometry as any).addTo(this.map);
      this.map.fitBounds(locationsArr);
      if (geometryCoordArr[0]) {
        geometryCoordArr.push(geometryCoordArr[0]);
      }
      let postData = {
        geometry: {
          type: "Polygon",
          crs: {
            type: "name",
            properties: {
              name: "epsg:4326"
            }
          },
          coordinates: geometryCoordArr
        }
      };
      let tmpData = {
        data: JSON.stringify(postData)
      };
      $('.geoApisDemoMapLoader').show();
      this.liapiservice.geoApiSampleDemoPostCall('/tryApiAddressByBoundary', tmpData).subscribe(
        success => {
          let result = success;
          if (result.addressList) {
            let tmpHtml = '<div class="demoInstructions">' + this.demoInfo + '</div><table class="table table-condensed table-unstyled"><tbody>';
            for (let i = 0; i < result.addressList.length; i++) {
              tmpHtml += '<tr><td><div class="addresscount">' + (i + 1) + '</div></td><td>';
              tmpHtml += '<div>' + result.addressList[i].addressNumber + ' ' + result.addressList[i].streetName + '</div>';
              tmpHtml += '<div>' + result.addressList[i].areaName3 + ', ' + result.addressList[i].areaName1 + ', ' + result.addressList[i].postCode;
              if (result.addressList[i].postCodeExt) {
                tmpHtml += '-' + result.addressList[i].postCodeExt;
              }
              tmpHtml += '</div>';
              tmpHtml += '<div>' + result.addressList[i].country + '</div>';
              tmpHtml += '</td></tr>';
            }
            tmpHtml += '</tbody></table>';
            document.getElementById('byboundaryResult').innerHTML=decodeURI(encodeURI(tmpHtml));
          }
          else {
            $('#byboundaryError').html('No information available for this address/location.');
            $('#byboundaryError').show();
          }
          $('.geoApisDemoMapLoader').hide();
        },
        error => {
          if (error.status == 401) {
            window.location.href = "/signin";
          }
          if (error.error) {
            let errorObj = JSON.parse(error.error);
            $('#byboundaryError').html(errorObj.errors[0].errorDescription);
          }
          else {
            $('#byboundaryError').html(error.statusText);
          }
          $('#byboundaryError').show();
          $('.geoApisDemoMapLoader').hide();
          if (error.status == '429') {
            this.liapiservice.showPrimeInfo();
            $('#byboundaryError').hide();
          }
        }
      );
    }
    else {
      $('#byboundaryError').html('Geometry Coordinates are missing.');
      $('#byboundaryError').show();
    }
  };
  getAddressByBoundaryName = function () {
    this.removeMarkerLayers();
    $('#byboundarynameError').hide();
    $('#byboundarynameResult').html('');
    let tmpData = {
      dataType: 'json',
      country: this.country,
      areaName1: this.areaName1,
      areaName2: this.areaName2,
      areaName3: this.areaName3,
      areaName4: this.areaName4,
      postCode: this.postcode
    };
    let boundaryGeometry = this.getInputBoundary();
    if (boundaryGeometry == '') {
      $('#byboundarynameError').html('Either Zip or County or City/Town or Neighborhood is required.');
      $('#byboundarynameError').show();
      return;
    }
    $('.geoApisDemoMapLoader').show();
    let geometryCoordArr = JSON.parse(boundaryGeometry);
    let locationsArr = [];
    for (let i = 0; i < geometryCoordArr.length; i++) {
      locationsArr.push([geometryCoordArr[i][1], geometryCoordArr[i][0]]);
    }
    let geometry: any = {
      type: "Polygon",
      coordinates: [geometryCoordArr]
    }
    this.polygon = L.geoJSON(geometry as any).addTo(this.map);
    this.map.fitBounds(locationsArr);
    this.liapiservice.geoApiSampleDemoGetCall('/tryApiAddressingByBoundaryName', tmpData).subscribe(
      success => {
        if (success.addressList) {
          this.isByBoundaryNameReady = true;
          let tmpHtml = '<div class="demoInstructions">' + this.demoInfo + '</div><table class="table table-condensed table-unstyled"><tbody>';
          for (let i = 0; i < success.addressList.length; i++) {
            tmpHtml += '<tr><td><div class="addresscount">' + (i + 1) + '</div></td><td>';
            tmpHtml += '<div>' + success.addressList[i].addressNumber + ' ' + success.addressList[i].streetName + '</div>';
            tmpHtml += '<div>' + success.addressList[i].areaName3 + ', ' + success.addressList[i].areaName1 + ', ' + success.addressList[i].postCode;
            if (success.addressList[i].postCodeExt) {
              tmpHtml += '-' + success.addressList[i].postCodeExt;
            }
            tmpHtml += '</div>';
            tmpHtml += '<div>' + success.addressList[i].country + '</div>';
            tmpHtml += '</td></tr>';
          }
          tmpHtml += '</tbody></table>';
          document.getElementById('byboundarynameResult').innerHTML= decodeURI(encodeURI(tmpHtml));
        }
        else {
          $('#byboundarynameError').html('No information available for this address/location.');
          $('#byboundarynameError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#byboundarynameError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#byboundarynameError').html(error.statusText);
        }
        $('#byboundarynameError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#byboundarynameError').hide();
        }
      }
    );
  };
  getInputBoundary = function () {
    this.boundType = '';
    if (this.areaName2 != '') {
      this.boundType = "areaName2";
    }
    if (this.areaName3 != '') {
      this.boundType = "areaName3";
    }
    if (this.areaName4 != '') {
      this.boundType = "areaName4";
    }
    if (this.postcode != '') {
      this.boundType = "postcode";
    }
    if (this.boundType == '') {
      return this.boundType;
    }
    return AppConfigs.addressesCoordinates[this.boundType];
  };
}
