import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import $ from 'jquery';
import * as L from "leaflet";

@Component({
    selector: 'emailverification-demo',
    templateUrl: './emailverificationdemo.component.html'
})
export class EmailverificationDemoComponent implements OnInit {
    public captchaCheck: boolean = false;
    public isResReady: boolean = false;
    public resEmail: string;
    public isVerified: boolean;
    public resDelivery: string;
    public mapOptions;
    public map;
    public mapLayers: L.Layer[];
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService) { };
    ngOnInit() {
        let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
        this.initializeMapOptions(mapFullURL);
    };
    private initializeMapOptions(mapFullURL) {
        this.mapOptions = {
            center: L.latLng(39.828127, -98.579404),
            zoom: 4
        };
        this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
    }
    onMapReady(map: L.Map) {
        this.map = map;
    };
    public emailinput = '';
    verifyEmail = function () {
        if(!this.captchaCheck){
            this.isResReady = false;
            $('#emailError').html('Please select "I am not a robot".');
            $('#emailError').show();
            return;
        }
        $('#verifyBtn').prop('disabled', true);
        let email: any = this.emailinput;
        let postData = {
            "options": {
                "alias": "N"
            },
            "Input": {
                "Row": [
                    {
                        "atc": "a",
                        "bogus": "true",
                        "complain": "true",
                        "disposable": "true",
                        "emailAddress": email,
                        "emps": "true",
                        "fccwireless": "true",
                        "language": "true",
                        "role": "true",
                        "rtc": "true",
                        "rtc_timeout": "1200"
                    }
                ]
            }
        };
        let tmpData = {
            dataType: 'json',
            data: JSON.stringify(postData)
        };
        if(email != ''){
            this.liapiservice.geoApiSampleDemoPostCall('/tryApiValidateEmailAddressRest', tmpData).subscribe(
                success => {
                    $('#verifyBtn').prop('disabled', false);
                    $('#emailError').hide();
                    this.isResReady = true;
                    this.resEmail = success.Output[0].EMAIL;
                    if(success.Output[0].FINDING == 'V'){
                        this.isVerified = true;
                    }
                    else{
                        this.isVerified = false;
                    }
                    this.resDelivery = success.Output[0].COMMENT;
                },
                error => {
                    $('#verifyBtn').prop('disabled', false);
                    this.isResReady = false;
                    if (error.status == 401) {
                        window.location.href = "/signin";
                    }
                    if (error.error) {
                        $('#emailError').html(error.error.errors.errorDescription);
                    }
                    else {
                        $('#emailError').html(error.statusText);
                    }
                    $('#emailError').show();
                    if (error.status == '429') {
                      this.liapiservice.showPrimeInfo();
                      $('#emailError').hide();
                    }
                }
            );
        }
        else {
            $('#verifyBtn').prop('disabled', false);
            this.isResReady = false;
            $('#emailError').html('Invalid or missing email address.');
            $('#emailError').show();
        }
    };
}
