import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { finalize } from 'rxjs';
declare const vkbeautify: any;

@Component({
  selector: 'psap-api',
  templateUrl: './psapapi.component.html'
})
export class PsapApiComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'psap 911, 911 psap, psap call, fire, police, emergency services' }
    );
  };
  public geo911TryApiPSAPByAddraddress = '950 Josephine Street Denver CO 80204';
  public geo911TryApiPSAPByAddrDataType = 'json';
  public psapByAddrUri = "";
  public psapByAddrRes = null;
  public psapByAddrResStatus = "";
  public psapByAddrResType = "";
  tryApiGeo911PSAPByAddr = function (btnId) {
    var address = this.geo911TryApiPSAPByAddraddress;
    var dataType = this.geo911TryApiPSAPByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (address != '') {
      var methodName = '/tryApiGeo911PSAPByAddr';
      let requestUri = this.liapiservice.getCompleteUri('911', 'PSAP By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.psapByAddrUri = requestUri;
          this.psapByAddrResType = dataType;
          if(this.psapByAddrResStatus.includes('429')){
            this.psapByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.psapByAddrResStatus = '200 success';
          this.psapByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.psapByAddrResStatus = `${error.status} ${error.statusText}`;
            this.psapByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geo911TryApiPSAPByLoclatitude = 35.0118;
  public geo911TryApiPSAPByLoclongitude = -81.9571;
  public geo911TryApiPSAPByLocDataType = 'json';
  public psapByLocUri = "";
  public psapByLocRes = null;
  public psapByLocResStatus = "";
  public psapByLocResType = "";
  tryApiGeo911PSAPByLoc = function (btnId) {
    var latitude: any = this.geo911TryApiPSAPByLoclatitude;
    var longitude: any = this.geo911TryApiPSAPByLoclongitude;
    var dataType = this.geo911TryApiPSAPByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeo911PSAPByLoc';
        let requestUri = this.liapiservice.getCompleteUri('911', 'PSAP By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.psapByLocUri = requestUri;
            this.psapByLocResType = dataType;
            if(this.psapByLocResStatus.includes('429')){
              this.psapByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
        )).subscribe(
          success => {
            this.psapByLocResStatus = '200 success';
            this.psapByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.psapByLocResStatus = `${error.status} ${error.statusText}`;
              this.psapByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geo911TryApiAHJByAddraddress = '4591 West Russell Road , Las Vegas ,NV ,89118';
  public geo911TryApiAHJByAddrDataType = 'json';
  public ahjByAddrUri = "";
  public ahjByAddrRes = null;
  public ahjByAddrResStatus = "";
  public ahjByAddrResType = "";
  tryApiGeo911AHJByAddr = function (btnId) {
    var address = this.geo911TryApiAHJByAddraddress;
    var dataType = this.geo911TryApiAHJByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (address != '') {
      var methodName = '/tryApiGeo911AHJByAddr';
      let requestUri = this.liapiservice.getCompleteUri('911', 'AHJ & PSAP By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.ahjByAddrUri = requestUri;
          this.ahjByAddrResType = dataType;
          if(this.ahjByAddrResStatus.includes('429')){
            this.ahjByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
      )).subscribe(
        success => {
          this.ahjByAddrResStatus = '200 success';
          this.ahjByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.ahjByAddrResStatus = `${error.status} ${error.statusText}`;
            this.ahjByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geo911TryApiAHJByLoclatitude = 34.08088;
  public geo911TryApiAHJByLoclongitude = -117.719;
  public geo911TryApiAHJByLocDataType = 'json';
  public ahjByLocUri = "";
  public ahjByLocRes = null;
  public ahjByLocResStatus = "";
  public ahjByLocResType = "";
  tryApiGeo911AHJByLoc = function (btnId) {
    var latitude: any = this.geo911TryApiAHJByLoclatitude;
    var longitude: any = this.geo911TryApiAHJByLoclongitude;
    var dataType = this.geo911TryApiAHJByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeo911AHJByLoc';
        let requestUri = this.liapiservice.getCompleteUri('911', 'AHJ & PSAP By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.ahjByLocUri = requestUri;
            this.ahjByLocResType = dataType;
            if(this.ahjByLocResStatus.includes('429')){
              this.ahjByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
        )).subscribe(
          success => {
            this.ahjByLocResStatus = '200 success';
            this.ahjByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.ahjByLocResStatus = `${error.status} ${error.statusText}`;
              this.ahjByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  clearResult = function (result) {
    switch (result) {
      case 'psapByAddrRes':
        this.psapByAddrRes = null;
        this.psapByAddrResStatus = '';
        this.psapByAddrUri = '';
        this.psapByAddrResType = '';
        break;
      case 'psapByLocRes':
        this.psapByLocRes = null;
        this.psapByLocResStatus = '';
        this.psapByLocUri = '';
        this.psapByLocResType = '';
        break;
      case 'ahjByAddrRes':
        this.ahjByAddrRes = null;
        this.ahjByAddrResStatus = '';
        this.ahjByAddrUri = '';
        this.ahjByAddrResType = '';
        break;
      case 'ahjByLocRes':
        this.ahjByLocRes = null;
        this.ahjByLocResStatus = '';
        this.ahjByLocUri = '';
        this.ahjByLocResType = '';
        break;
    }
  };
}
