import { Component, OnInit } from "@angular/core";
import { Observable, debounceTime, distinctUntilChanged, switchMap, catchError, of } from "rxjs";
import { LiapiService } from '../../service/liapi.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'address-autocomplete-demoC',
    templateUrl: './address-autocomplete-demoC.html',
    styleUrls: ['./address-autocomplete-demoC.css']
})

export class AddressAutocompleteComponentC implements OnInit {

    public searchText: string = null;
    public showPostCodeResults: boolean = false;
    public _showResults: boolean = false;
    public loading: boolean = false;
    public loadingPostcode: boolean = false;
    public results: any = {};
    public postCodeResults: any = {};
    public postCodeResultsLength: number;
    public resultLength: number;
    public selectedIndex: number = -1;
    public postcodeSelectedIndex = -1;
    public requestObj: any = null;
    public transactionId: string = null;
    public transactionIdForPostcode: string = null;
    public generateNewTransactionID: boolean = true;
    public generateNewTransactionIDForPoscode: boolean = true;
    public minLength: number = 3;
    public maxCandidates: number = 10;
    public postCode: string = null;
    public city: string;
    public state: string;
    public postalVerified : string = "";
    public autocompletePostcode: boolean = true;
    public isStateEmpty : boolean = true;
    public filteredCountries: any[];
    public countries: any[];
    

    public countryInfo = {
        "name": "UNITED KINGDOM",
        "code": "GBR",
        "label": "UNITED KINGDOM (GBR)"
    };
    public matchOnAddressNumber: string = 'false';
    public searchOnAddressNumber: string = 'N';
    public returnAdminAreasOnly: string = 'N';
    public includeRangesDetails: string = 'N';
    public autoDetectLocation: string = 'false';
    public ipAddress: string;

    ngOnInit(){
        this.searchLocationsTmp.getTypeaheadCountries().subscribe(
          success => {
            this.countries = success;
          }
        );
    }

    constructor(private searchLocationsTmp: LiapiService) { }

    onQueryUpdate = (searchType: string, focusOut: boolean) => {
        if (this.generateNewTransactionID) {
            this.transactionId = uuidv4();
            this.generateNewTransactionID = false;
        }
        if (this.generateNewTransactionIDForPoscode) {
            this.transactionIdForPostcode = uuidv4();
            this.generateNewTransactionIDForPoscode = false;
        }
        let requestData = new Map();
        if (searchType === "address") {
            requestData.set("maxCandidates", this.maxCandidates);
            requestData.set("country", this.countryInfo.code);
            requestData.set("searchOnAddressNumber", this.searchOnAddressNumber);
            requestData.set("returnAdminAreasOnly", this.returnAdminAreasOnly);
        }
        else {
            requestData.set("maxCandidates", this.maxCandidates);
            requestData.set("country", this.countryInfo.code);
            requestData.set("searchOnAddressNumber", this.searchOnAddressNumber);
            requestData.set("returnAdminAreasOnly", this.returnAdminAreasOnly);
            requestData.set("returnAdminAreasOnly", "Y");
        }
        if ((this.searchText != null && this.searchText !== "" && this.searchText.length >= this.minLength) || (this.postCode != null && this.postCode !== "" && this.postCode.length >= this.minLength)) {
            if (searchType === "address") {
                requestData.set('searchText', this.postCode)
                requestData.set('transactionId', this.transactionId);
                this.loading = true;
            }
            else if (searchType != "address" && this.autocompletePostcode) {
                requestData.set('searchText', this.postCode);
                requestData.set('transactionId', this.transactionIdForPostcode);
                this.loadingPostcode = true;
            }
            requestData.set('clientIdentifier', "ECommerceDemo")
            if (this.requestObj != null) {
                this.requestObj.unsubscribe();
            }
            if (searchType === "address" || (searchType != "address" && this.autocompletePostcode)) {
                this.requestObj = this.searchLocationsTmp.getAddressAutocompleteResponse(requestData).subscribe(
                    (success: any) => {
                        if (success.location) {
                            if (success.location.length != 0) {
                                if (searchType === 'address') {
                                    this.results = success;
                                    this._showResults = true;
                                    this.resultLength = success.location.length;
                                    this.selectedIndex = 0;
                                    this.loading = false;
                                }
                                else if (searchType != "address" && this.autocompletePostcode) {
                                    this.postCodeResults = success;
                                    this.showPostCodeResults = true;
                                    this.postCodeResultsLength = success.location.length;
                                    this.postcodeSelectedIndex = 0;
                                    this.loadingPostcode = false;
                                }
                            }
                            else {
                                if (searchType === 'address') {
                                    this._showResults = false;
                                    this.loading = false;
                                }
                                else if (searchType != "address" && this.autocompletePostcode) {
                                    this.showPostCodeResults = false;
                                    this.loadingPostcode = false;
                                }
                            }
                        }
                        else {
                            if (searchType === 'address') {
                                this._showResults = false;
                                this.loading = false;           
                            }
                            else if (searchType != "address" && this.autocompletePostcode) {
                                this.showPostCodeResults = false;
                                this.loadingPostcode = false;
                            }
                        }
                    },
                    error => {

                        if (searchType === 'address') {
                            this._showResults = false;
                            this.loading = false
                        }
                        else if (searchType != "address" && this.autocompletePostcode) {
                            this.showPostCodeResults = false;
                            this.loadingPostcode = false;
                        }
                        if(error.status == '429'){
                            this.searchLocationsTmp.showPrimeInfo();
                        }
                    }
                );
            }


        }
        if ((this.searchText != null && this.searchText !== "" && this.searchText.length >= this.minLength) || (this.postCode != null && this.postCode !== "" || this.postCode.length >= this.minLength)) {
            if (searchType === 'address') {
                this._showResults = false;
            }
            else if (searchType != "address" && this.autocompletePostcode) {
                this.showPostCodeResults = false;
            }
        }

    };
    onFocusIn = ()=>{
        this.showPostCodeResults=false;
        this._showResults = false;
    }
    onResultClicked = (result: any, searchType: any) => {
        if (result.totalUnitCount > 1) {
            if (searchType === 'address') {
                this.results.location = [];
            }
            else if (searchType != "address" && this.autocompletePostcode) {
                this.postCodeResults.location = [];
            }
            let locArr = [];
            let addrObj = result.address;
            let geometryObj = result.geometry;
            let arrCount = 0;
            for (let i = 0; i < result.ranges.length; i++) {
                for (let j = 0; j < result.ranges[i].units.length; j++) {
                    locArr[arrCount] = {};
                    locArr[arrCount].totalUnitCount = 1;
                    locArr[arrCount].geometry = Object.assign({}, geometryObj);
                    locArr[arrCount].address = Object.assign({}, addrObj);
                    locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                    if(result.ranges[i].units[j].postalVerified !=null){
                        locArr[arrCount].address.postalVerified = result.ranges[i].units[j].postalVerified;
                    }
                    var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                    locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                    arrCount++;
                }
            }
            if (searchType === 'address') {
                this.results.location = locArr;
            }
            else if (searchType != "address" && this.autocompletePostcode) {
                this.postCodeResults.location = locArr;
            }
        }
        else {
            if (result.address.postalVerified != null && searchType === 'address') {
                this.postalVerified = result.address.postalVerified;
            }
            else if (result.ranges != null && result.ranges.length == 1 && result.ranges[0].units != null && result.ranges[0].units.length == 1 &&
                result.ranges[0].units[0].postalVerified != null && searchType === 'address') {
                this.postalVerified = result.ranges[0].units[0].postalVerified;
            }
            else if(searchType === 'address') {
                this.postalVerified = "Not Verfied";
            }
            let requestData = new Map();
            if (searchType === 'address') {
                this.searchText = result.address.mainAddressLine;
            }
            if (result.address.postCode != undefined) {
                this.postCode = result.address.postCode;
            }
            if (result.address.areaName3 != undefined) {
                this.city = result.address.areaName3;
            }
            if (result.address.areaName1 != undefined && result.address.areaName1 != "") {
                this.state = result.address.areaName1;
            }
            
            if (searchType === 'address') {
                this._showResults = false;
                requestData.set('transactionId', this.transactionId);
            }
            else if (searchType != "address" && this.autocompletePostcode) {
                this.showPostCodeResults = false;
                requestData.set('transactionId', this.transactionIdForPostcode);
            }
            requestData.set('selectedAddress', result.address.formattedAddress);
            requestData.set('country', this.countryInfo.code);
            this.searchLocationsTmp.selectAddress(requestData).subscribe((success) => {
                if (searchType === 'address') {
                    this.transactionId = null;
                    this.generateNewTransactionID = true;
                }
                else if (searchType != "address" && this.autocompletePostcode) {
                    this.transactionIdForPostcode = null;
                    this.generateNewTransactionIDForPoscode = true;
                    this.onQueryUpdate("address", false);
                }

            });
        }
    };
    onKeyDown = (event: any, searchType: string) => {
        if (document.getElementById('geoitemcontainer')) {
            if (event.keyCode === 38) {
                event.preventDefault();
                var indexval = searchType === 'address' ? this.selectedIndex : this.postcodeSelectedIndex;
                let currentIndex = searchType === 'address' ? this.selectedIndex : this.postcodeSelectedIndex;
                if (this.selectedIndex > 1) {
                    if (this.addCSS(currentIndex - 1)) {
                        if (searchType === 'address') {
                            this.selectedIndex = this.selectedIndex - 1;
                        }
                        else {
                            this.postcodeSelectedIndex = this.postcodeSelectedIndex - 1;
                        }
                        this.removeCSS(indexval);
                        document.getElementById('geoitemcontainer').scrollTop -= 40;
                    }
                }
            }
            if (event.keyCode === 40) {
                event.preventDefault();
                var indexval = searchType === 'address' ? this.selectedIndex : this.postcodeSelectedIndex;
                let currentIndex = searchType === 'address' ? this.selectedIndex : this.postcodeSelectedIndex;
                let resultsLength = searchType === 'address' ? this.resultLength : this.postCodeResultsLength;
                if (currentIndex < resultsLength) {
                    if (this.addCSS(currentIndex + 1)) {
                        if (searchType === 'address') {
                            this.selectedIndex = this.selectedIndex + 1;
                        }
                        else {
                            this.postcodeSelectedIndex = this.postcodeSelectedIndex + 1;
                        }
                        this.removeCSS(indexval + 1);
                        if ((indexval + 1) > 1) {
                            document.getElementById('geoitemcontainer').scrollTop += 40;
                        }
                    }
                }
            }
            if (event.keyCode === 13) {
                event.preventDefault();
                document.getElementById('geoitemcontainer').scrollTop = 0;
                var indexval = this.selectedIndex;
                if (indexval > 0) {
                    var result = searchType === 'address' ? this.results.location[indexval - 1] : this.postCodeResults.location[indexval - 1];
                    if (result.totalUnitCount > 1) {
                        if (searchType === 'address') {
                            this.results.location = [];
                        }
                        else {
                            this.postCodeResults.location = [];
                        }
                        var locArr = [];
                        var addrObj = result.address;
                        var geometryObj = result.geometry;
                        var arrCount = 0;
                        this.resultLength = result.totalUnitCount;
                        for (var i = 0; i < result.ranges.length; i++) {
                            for (var j = 0; j < result.ranges[i].units.length; j++) {
                                locArr[arrCount] = {};
                                locArr[arrCount].totalUnitCount = 1;
                                locArr[arrCount].address = Object.assign({}, addrObj);
                                locArr[arrCount].geometry = Object.assign({}, geometryObj);
                                locArr[arrCount].address.formattedAddress = result.ranges[i].units[j].formattedUnitAddress;
                                var addressArr = result.ranges[i].units[j].formattedUnitAddress.split(', ');
                                locArr[arrCount].address.mainAddressLine = addressArr[0] + ', ' + addressArr[1];
                                if (searchType === 'address') {
                                    this.removeCSS(this.selectedIndex);
                                    this.selectedIndex = 0;
                                }
                                else {
                                    this.removeCSS(this.postcodeSelectedIndex);
                                    this.postcodeSelectedIndex = 0;
                                }

                                arrCount++;
                            }
                        }
                        if (searchType === 'address') {
                            this.results.location = locArr;
                        }
                        else {
                            this.postCodeResults.location = locArr;
                        }

                    }
                    else {
                        this.removeCSS(this.selectedIndex);
                        this.selectedIndex = 0;
                        if (searchType === 'address') {
                            this.searchText = result.address.formattedAddress;
                            this._showResults = false;
                            this.removeCSS(this.selectedIndex);
                            this.selectedIndex = 0;

                        }
                        else {
                            this.postCode = result.address.postCode;
                            this.showPostCodeResults = false;
                            this.removeCSS(this.selectedIndex);
                            this.postcodeSelectedIndex = 0;
                        }

                    }
                }
            }
            if (event.keyCode === 27) {
                if (searchType === 'address') {
                    this._showResults = false;
                    this.removeCSS(this.selectedIndex);
                    this.selectedIndex = 0;

                }
                else {
                    this.showPostCodeResults = false;
                    this.removeCSS(this.postcodeSelectedIndex);
                    this.postcodeSelectedIndex = 0;

                }
                event.preventDefault();
            }
        }
    };
    removeCSS = (index: any) => {
        if (index > 0) {
            var elementId = 'geoitem' + index;
            document.getElementById(elementId).className = "pb-geo-item";
        }
    };
    addCSS = (index: any) => {
        var returnval = false;
        if (index > 0) {
            var elementId = 'geoitem' + index;
            document.getElementById(elementId).className = "pb-geo-item pb-geo-item-selected";
            return true;
        }
        return returnval;
    };
    filterCountry = function (event) {
        let filtered: any[] = [];
        let query = event.query;
        for (let i = 0; i < this.countries.length; i++) {
          let country = this.countries[i];
          if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
            filtered.push(country);
          }
        }
        this.filteredCountries = filtered;
      };

    
    checkForCountry = ($event)=>{
        if($event.code === "GBR"){
            this.isStateEmpty = true;
        }
        else if($event.code!= undefined){
            this.isStateEmpty = false;
        }
    }

    clearFields = () => {
        this.searchText = "";
        this.city = "";
        this.state = "";
        this.postCode = "";
        this.postalVerified = "";
        this.showPostCodeResults = false;
        this._showResults = false;
        this.loading = false;
        this.loadingPostcode = false;
        this.isStateEmpty = true;
        if(this.requestObj != null){
            this.requestObj.unsubscribe();
        }
        this.countryInfo = {
            "name": "UNITED KINGDOM",
            "code": "GBR",
            "label": "UNITED KINGDOM (GBR)"
        };
    }

}