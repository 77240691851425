import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService{
    constructor(private http: HttpClient){}
    getMeKeys = function(email){
        return this.http.get("/api/getMeKeys");
    };
    regenerateKeys = function(){
        return this.http.get('/api/regenerateKey');
    };
    getPlanDetails = function () {
        return this.http.get("/api/getPlanDetails");
    };
    updatePaymentInfo(tokenId : any){
        return this.http.get('/payment/update-cc/'+tokenId);
    };
    getUserStatus = function () {
        return this.http.get('/user/status');
    };
    countryCodeList = function(){
        return this.http.get("/assets/jsonData/country_code_list.json");
    };
    overallUsage = function () {
        return this.http.get("/api/overallUsage");
    };
    getProducts = function (userDetailsObj) {
        return this.http.get("/api/getProducts/"+userDetailsObj.developerId+"/"+userDetailsObj.applicationID+"/"+userDetailsObj.product);
    };
    apiTraffic = function (userDetailsObj) {
        return this.http.get("/api/apiTraffic/"+userDetailsObj.usageFromDate+"/"+userDetailsObj.userId+"/"+userDetailsObj.timeUnit+"/"+userDetailsObj.product+"/"+userDetailsObj.filterList);
    };
    getUserAccountDetailsById(){
        return this.http.get('/user/accountDetails');
    };
    verifyAddress(payload : any){
        var addrPayload = {
            data: '{"options":{"KeepMultimatch": "Y"},"Input": {"Row": ['+JSON.stringify(payload)+']}}',
            dataType: 'json',
            clientIdentifier: 'Payment'
        };
        return this.http.post('/tryApiValidateMailingAddressUSCANRest', addrPayload);
    };
    getUserInvoices(){
        return this.http.get('/user/invoices');
    };
    cancelSubscription(){
        return this.http.get('/user/cancelSubscription');
    };
    activateSubscription(){
        return this.http.get('/user/activateSubscription');
    };    
    getUserDeatails(){
        return this.http.get('/user/userDetails');
    };
}
