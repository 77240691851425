import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ResourceService{
    constructor(private http: HttpClient){}
    getSignupUrl = function () {
        return this.http.get("/api/getsignupurl");
    };
    getresources = function () {
        return this.http.get("/api/getresources");
    };
    getLiGitHubUrl = function () {
        return this.http.get("/api/getligithuburl");
    };
    getDocsUrl = function () {
        return this.http.get("/api/getdocumentationurl");
    };
    getDownloadUrl = function () {
        return this.http.get("/api/getdownloadurl");
    };
    getMvtEnv = function () {
        return this.http.get("/api/getmvtenv");
    };
    getRecurlyConfig = function () {
        return this.http.get("/api/recurlyconfig");
    };
    getStates = function (countryCode) {
        return this.http.get("/api/getStates/"+countryCode);
    };
    getCountries = function () {
        return this.http.get("/api/getCountries");
    };
}
