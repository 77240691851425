import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../service/user.service';

@Component({
    selector: 'add-product',
    templateUrl: './addproduct.component.html'
})
export class AddproductComponent implements OnInit {
    public showpage: boolean = false;
    public displayName: string = "";
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService) {
    };
    ngOnInit() {
        window.scroll(0, 0);
        let userDetails = null;
        this.userService.getUserDeatails().subscribe(
            success => {
                userDetails = success;
                this.showpage = true;
                this.displayName = userDetails.firstName + " " + userDetails.lastName;
            },
            error => {
                window.location.href = "/";
            }
        );
    };
    signup = function () {
        window.location.href = "/pricing";
    };
    signout = function () {
        window.location.href = "/signout";
    };
}
