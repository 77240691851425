import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as AppConfigs from '../app.constants';
declare const vkbeautify: any;
declare const ActiveXObject: any;

@Injectable()
export class LiapiService {
    public userEmailId: string = 'unauth';
    constructor(private http: HttpClient, private messageService: MessageService) {
        let userEmailId = JSON.parse(`[{"val": "${localStorage.getItem("clientIdentifier")}"}]`)[0].val;
        if (userEmailId !== "null") {
            this.userEmailId = userEmailId;
        }
    }
    getGeocodeCountries = function () {
        return this.http.get('/getGeocodeCountries');
    };
    getRoutingCountries = function () {
        return this.http.get('/getRoutingCountries');
    };
    getTypeaheadCountries = function () {
        return this.http.get('/getTypeaheadCountries');
    };
    getPoiTypes = function () {
        return this.http.get('/getPoiTypes');
    };
    getCountryObj = function (countrycode: string) {
        return this.http.get('/getCountryObj?countrycode=' + countrycode);
    };
    searchTypeaheadCountry = function (term: string) {
        if (term.length < 1) {
            return of([]);
        }
        else {
            return this.http.get('/getTypeaheadCountry?term=' + term).pipe(
                map(response => response)
            );
        }
    };
    poiAutocomplete = function (term: string, matchMode: string, zip: string, country: string, city: string, state: string, type: any) {
        if (term.length < 3) {
            return of([]);
        }
        else {
            let methodUrl = '/sampleDemoGeoEnrichPOIAuto?name=' + term + '&dataType=json';
            if (country != '') {
                methodUrl += '&country=' + country;
            }
            methodUrl += '&matchMode=' + matchMode;
            if (zip != '') {
                methodUrl += '&postcode1=' + zip;
            }
            if (city != '') {
                methodUrl += '&areaName3=' + city;
            }
            if (state != '') {
                methodUrl += '&areaName1=' + state;
            }
            if (type != '') {
                if (type.label) {
                    methodUrl += '&type=' + type.label;
                }
                else {
                    methodUrl += '&type=' + type;
                }
            }
            if (this.userEmailId != 'unauth') {
                methodUrl += '&clientIdentifier=LearnPageSampleDemo-' + this.userEmailId;
            }
            else {
                methodUrl += '&clientIdentifier=LearnPageSampleDemo';
            }
            return this.http.get(methodUrl).pipe(
                map(response => response)
            );
        }
    };
    geoApisTryApiGetCall = function (methodName, tmpData) {
        tmpData.clientIdentifier = 'LearnPageTryAPI';
        if (this.userEmailId != 'unauth') {
            tmpData.clientIdentifier = 'LearnPageTryAPI-' + this.userEmailId;
        }
        let requestHeader: HttpHeaders;
        let responseType = 'application/json';
        if (tmpData.dataType === 'json') {
            requestHeader = new HttpHeaders({
                ContentType: 'application/json'
            });
        }
        else if (tmpData.dataType === 'xml') {
            requestHeader = new HttpHeaders({
                ContentType: 'text/xml',
                Accept: 'text/xml'
            });
            responseType = 'text/xml';
        }
        let queryParams = new HttpParams({
            fromObject: tmpData
        });
        return this.http.get(methodName, { headers: requestHeader, responseType: responseType, params: queryParams });
    };
    geoAPIsTryApiBatchCall = function (methodName, postData) {
        var tmpData = {
            data: postData,
            clientIdentifier: 'LearnPageTryAPI'
        };
        if (this.userEmailId != 'unauth') {
            tmpData.clientIdentifier = 'LearnPageTryAPI-' + this.userEmailId;
        }
        const requestHeader = new HttpHeaders({
            ContentType: 'application/json'
        });
        return this.http.post(methodName, tmpData, { headers: requestHeader });
    };
    geoAPIsTryApiBatchLikeGetCall = function (methodName, tmpData) {
        tmpData.clientIdentifier = 'LearnPageTryAPI';
        if (this.userEmailId != 'unauth') {
            tmpData.clientIdentifier = 'LearnPageTryAPI-' + this.userEmailId;
        }
        let requestHeader: HttpHeaders;
        let responseType = 'application/json';
        if (tmpData.dataType === 'json') {
            requestHeader = new HttpHeaders({
                ContentType: 'application/json'
            });
        }
        else if (tmpData.dataType === 'xml') {
            requestHeader = new HttpHeaders({
                ContentType: 'text/xml',
                Accept: 'text/xml'
            });
            responseType = 'text/xml';
        }
        return this.http.post(methodName, tmpData, { headers: requestHeader, responseType: responseType });
    };
    geoAPIsTryApiBatchCallWithAdditionalParams = function (methodName, tmpData) {
        if (this.userEmailId != 'unauth') {
            tmpData.clientIdentifier = 'LearnPageTryAPI-' + this.userEmailId;
        }
        const requestHeader = new HttpHeaders({
            ContentType: 'application/json'
        });
        return this.http.post(methodName, tmpData, { headers: requestHeader });
    };
    showPrimeErr = function (errMsg) {
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: errMsg });
    };
    showPrimeInfo = function () {
        this.messageService.clear();
        this.messageService.add({ severity: 'info', summary: 'Information', detail: 'Please sign up for a Free Trial and use the Postman collection we provide to further try out our APIs.' });
    };
    getCompleteUri = function (apiName, methodName, tmpData, pathParam) {
        let requestUri = AppConfigs.BASE_API_PATH;
        for (var i = 0; i < AppConfigs.apisMethodList.length; i++) {
            if (apiName == AppConfigs.apisMethodList[i].apiName) {
                requestUri += AppConfigs.apisMethodList[i].apiUrl;
                for (var j = 0; j < AppConfigs.apisMethodList[i].methods.length; j++) {
                    if (methodName == AppConfigs.apisMethodList[i].methods[j].name) {
                        requestUri += AppConfigs.apisMethodList[i].methods[j].endPoint[0];
                    }
                }
            }
        }
        for (var key in tmpData) {
            if (key != 'dataType') {
                if (pathParam.includes(key)) {
                    let repStr = '{' + key + '}';
                    requestUri = requestUri.replace(repStr, tmpData[key]);
                }
                else {
                    if (requestUri.includes('?')) {
                        requestUri += '&';
                    }
                    else {
                        requestUri += '?';
                    }
                    requestUri += key + '=' + tmpData[key];
                }
            }
        }
        requestUri = this.safeTagsUriReplace(requestUri);
        return requestUri;
    };
    getCompleteUriPost = function (apiName, methodName) {
        let requestUri = AppConfigs.BASE_API_PATH;
        for (var i = 0; i < AppConfigs.apisMethodList.length; i++) {
            if (apiName == AppConfigs.apisMethodList[i].apiName) {
                requestUri += AppConfigs.apisMethodList[i].apiUrl;
                for (var j = 0; j < AppConfigs.apisMethodList[i].methods.length; j++) {
                    if (methodName == AppConfigs.apisMethodList[i].methods[j].name) {
                        requestUri += AppConfigs.apisMethodList[i].methods[j].endPoint[0];
                    }
                }
            }
        }
        requestUri = this.safeTagsUriReplace(requestUri);
        return requestUri;
    };
    getCompleteUriPostWithParam = function (apiName, methodName, tmpData, pathParam) {
        let requestUri = AppConfigs.BASE_API_PATH;
        for (var i = 0; i < AppConfigs.apisMethodList.length; i++) {
            if (apiName == AppConfigs.apisMethodList[i].apiName) {
                requestUri += AppConfigs.apisMethodList[i].apiUrl;
                for (var j = 0; j < AppConfigs.apisMethodList[i].methods.length; j++) {
                    if (methodName == AppConfigs.apisMethodList[i].methods[j].name) {
                        requestUri += AppConfigs.apisMethodList[i].methods[j].endPoint[0];
                    }
                }
            }
        }
        for (var j = 0; j < pathParam.length; j++) {
            let repStr = '{' + pathParam[j] + '}';
            requestUri = requestUri.replace(repStr, tmpData[pathParam[j]]);
        }
        requestUri = this.safeTagsUriReplace(requestUri);
        return requestUri;
    };
    getCompleteUriPostLikeGet = function (apiName, methodName, dataType) {
        let requestUri = AppConfigs.BASE_API_PATH;
        for (var i = 0; i < AppConfigs.apisMethodList.length; i++) {
            if (apiName == AppConfigs.apisMethodList[i].apiName) {
                requestUri += AppConfigs.apisMethodList[i].apiUrl;
                for (var j = 0; j < AppConfigs.apisMethodList[i].methods.length; j++) {
                    if (methodName == AppConfigs.apisMethodList[i].methods[j].name) {
                        if (dataType == 'json') {
                            requestUri += AppConfigs.apisMethodList[i].methods[j].endPoint[1];
                        }
                        else {
                            requestUri += AppConfigs.apisMethodList[i].methods[j].endPoint[0];
                        }
                    }
                }
            }
        }
        requestUri = this.safeTagsUriReplace(requestUri);
        return requestUri;
    };
    xmlToString = function (xmlData) {
        var xmlString;
        if ((<any>window).ActiveXObject) {
            var xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
            xmlDoc.async = "false";
            xmlDoc.load(xmlData);
            xmlString = xmlDoc.xml; //IE
        }
        else {
            xmlString = (new XMLSerializer()).serializeToString(xmlData); // code for Mozilla, Firefox, Opera, etc.
        }
        return xmlString;
    };
    safeTagsReplace = function (str) {
        str = str.replace(/&/g, '&amp;');
        str = str.replace(/</g, '&lt;');
        str = str.replace(/>/g, '&gt;');
        return str;
    };
    safeTagsUriReplace = function (str) {
        str = str.replace(/</g, '&lt;');
        str = str.replace(/>/g, '&gt;');
        return str;
    };
    geoApiSampleDemoGetCall = function (methodName, tmpData) {
        if (this.userEmailId != 'unauth') {
            tmpData.clientIdentifier = 'LearnPageSampleDemo-' + this.userEmailId;
        }
        else {
            tmpData.clientIdentifier = 'LearnPageSampleDemo';
        }
        let methodUrl = methodName;
        Object.keys(tmpData).forEach((key, index) => {
            if (index == 0) {
                methodUrl += "?";
            }
            else {
                methodUrl += "&";
            }
            methodUrl += key + "=" + tmpData[key];
        });
        return this.http.get(methodUrl);
    };
    geoApiSampleDemoPostCall = function (methodName, tmpData) {
        if (this.userEmailId != 'unauth') {
            tmpData.clientIdentifier = 'LearnPageSampleDemo-' + this.userEmailId;
        }
        else {
            tmpData.clientIdentifier = 'LearnPageSampleDemo';
        }
        let methodUrl = methodName;
        return this.http.post(methodUrl, tmpData);
    };
    getAddressAutocompleteResponse = function (requestData: any) {
        let requestHeader = new HttpHeaders();
        requestHeader.append('Content-Type', 'application/json');
        let queryParams = new HttpParams();
        for (let [key, value] of requestData) {
            queryParams = queryParams.append(key, value);
        }
        return this.http.get('/listLocations', { headers: requestHeader, params: queryParams });
    };
    selectAddress = function (requestData: any) {
        let requestHeader = new HttpHeaders();
        requestHeader.append('Content-Type', 'application/json');
        let queryParams = new HttpParams();
        for (let [key, value] of requestData) {
            queryParams = queryParams.append(key, value);
        }
        return this.http.get('/selectAddress', { headers: requestHeader, params: queryParams });
    };
    enableTryItBtn = function (btnId) {
        document.getElementById(btnId).removeAttribute('disabled');
        document.getElementById(btnId).innerHTML = 'Try it';
    };
    disableTryItBtn = function (btnId) {
        document.getElementById(btnId).setAttribute('disabled', 'true');
        document.getElementById(btnId).innerHTML = 'Loading...';
    };
    getFormattedResponse = function (dataType, response) {
        let formRes: any;
        if (response) {
            if (dataType === 'json') {
                formRes = vkbeautify.json(response);
            }
            else if (dataType === 'xml') {
                formRes = this.safeTagsReplace(vkbeautify.xml(response));
            }
            else if (dataType === 'csv') {
                formRes = response;
            }
        }
        return formRes;
    };
}
