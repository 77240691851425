import { Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ResourceService } from '../service/resource.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'gettingstarted',
  templateUrl: './gettingstarted.component.html'
})
export class GettingComponent implements OnInit {
  public docsURL: any = "";
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private resourceService: ResourceService, private metaTagService: Meta) {};
  ngOnInit() {
    window.scroll(0,0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'precisely api' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.docsURL = success.docsURL + 'Getting%20Started/making_first_call.html';
        }
      );
    }
    else{
      this.docsURL = docsURL + 'Getting%20Started/making_first_call.html';
    }
  };
}
