import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';
import $ from 'jquery';

@Component({
  selector: 'places-api',
  templateUrl: './placesapi.component.html'
})
export class PlacesApiComponent implements OnInit {
  public downloadableURL: any = "";
  public poiaddrParamLink = '';
  public poilocParamLink = '';
  public poiareaParamLink = '';
  public poiautoParamLink = '';
  public sicParamLink = '';
  public categoryParamLink = '';
  public poiaddrRadio = 'searchRadius';
  public poiaddrSearchRadius = '3218.688';
  public poiaddrSearchRadiusUnit = 'meters';
  public poiaddrTravelTime = '';
  public poiaddrTravelTimeUnit = '';
  public poiaddrTravelDistance = '';
  public poiaddrTravelDistanceUnit = '';
  public poiaddrTravelMode = '';
  public poilocRadio = 'searchRadius';
  public poilocSearchRadius = '3218.688';
  public poilocSearchRadiusUnit = 'meters';
  public poilocTravelTime = '';
  public poilocTravelTimeUnit = '';
  public poilocTravelDistance = '';
  public poilocTravelDistanceUnit = '';
  public poilocTravelMode = '';
  public poiAutoTab = 'location';
  public poiauto_autoDetectLocation: boolean = false;
  public poiautoRadio = 'searchRadius';
  public poiautoSearchRadius = '3218.688';
  public poiautoSearchRadiusUnit = 'meters';
  public poiautoTravelTime = '';
  public poiautoTravelTimeUnit = '';
  public poiautoTravelDistance = '';
  public poiautoTravelDistanceUnit = '';
  public poiautoTravelMode = '';
  public poiautoCountry = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public poiareaCountry = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public poiaddrCountry = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public poiautoType = '';
  public poiareaType = '';
  public poiaddrType = '';
  public poilocType = '';
  public geometryPoiCountJson = '{"name": "starbucks", "matchMode": "Specific", "specificMatchOn": "NAME", "type": "eating and drinking places", "categoryCode": "1607", "sicCode": "6571", "fuzzyOnName": "N", "geometry": {"type":"Polygon", "crs": {"type": "name", "properties": {"name": "epsg:4326"}},"coordinates": [[-87.38259721457948, 40.50315209303844], [-87.39186330973128, 40.51004951521943], [-87.39186330973128, 40.57902373702927], [-87.40112940488306, 40.57902373702927], [-87.40112940488306, 40.613510847934194], [-87.41039550003488, 40.613510847934194], [-87.41039550003488, 40.641100536658136], [-87.42892769033847, 40.64799795883912], [-89.3748076722164, 42.1378411499318], [-89.3748076722164, 42.15163599429376], [-89.3655415770646, 42.15163599429376], [-86.22433532060451, 41.42740666529038], [-86.1872709399973, 41.34463759911857], [-86.1872709399973, 41.33774017693758], [-87.36406502427587, 40.523844359581396], [-87.35479892912407, 40.523844359581396], [-87.35479892912407, 40.51694693740041], [-87.38259721457948, 40.50315209303844]]}}';
  public geometryAsTextPoiCountJson = '{"name": "starbucks", "matchMode": "Specific", "specificMatchOn": "NAME", "type":"eating and drinking places", "categoryCode":"1607", "sicCode":"6571", "fuzzyOnName":"N", "geometryAsText":"POLYGON((-87.38259721457948 40.50315209303844,-87.39186330973128 40.51004951521943,-87.39186330973128 40.57902373702927,-87.40112940488306 40.57902373702927,-87.40112940488306 40.613510847934194,-87.41039550003488 40.613510847934194,-87.41039550003488 40.641100536658136,-87.42892769033847 40.64799795883912,-89.3748076722164 42.1378411499318,-89.3748076722164 42.15163599429376,-89.3655415770646 42.15163599429376,-86.22433532060451 41.42740666529038,-86.1872709399973 41.34463759911857,-86.1872709399973 41.33774017693758,-87.36406502427587 40.523844359581396,-87.35479892912407 40.523844359581396,-87.35479892912407 40.51694693740041,-87.38259721457948 40.50315209303844))"}';
  public geometryByBoundJson = '{"name": "starbucks", "matchMode": "Specific", "specificMatchOn": "NAME", "type": "eating and drinking places", "categoryCode": "1607", "sicCode": "6571", "maxCandidates": "20", "fuzzyOnName": "N", "page": "1", "geometry": {"type":"Polygon", "crs": {"type": "name", "properties": {"name": "epsg:4326"}},"coordinates": [[-87.38259721457948, 40.50315209303844], [-87.39186330973128, 40.51004951521943], [-87.39186330973128, 40.57902373702927], [-87.40112940488306, 40.57902373702927], [-87.40112940488306, 40.613510847934194], [-87.41039550003488, 40.613510847934194], [-87.41039550003488, 40.641100536658136], [-87.42892769033847, 40.64799795883912], [-89.3748076722164, 42.1378411499318], [-89.3748076722164, 42.15163599429376], [-89.3655415770646, 42.15163599429376], [-86.22433532060451, 41.42740666529038], [-86.1872709399973, 41.34463759911857], [-86.1872709399973, 41.33774017693758], [-87.36406502427587, 40.523844359581396], [-87.35479892912407, 40.523844359581396], [-87.35479892912407, 40.51694693740041], [-87.38259721457948, 40.50315209303844]]}}';
  public geometryAsTextByBoundJson = '{"name": "starbucks", "matchMode": "Specific", "specificMatchOn": "NAME", "type":"eating and drinking places", "categoryCode":"1607", "sicCode":"6571", "maxCandidates":"20", "fuzzyOnName":"N", "page":"1", "geometryAsText":"POLYGON((-87.38259721457948 40.50315209303844,-87.39186330973128 40.51004951521943,-87.39186330973128 40.57902373702927,-87.40112940488306 40.57902373702927,-87.40112940488306 40.613510847934194,-87.41039550003488 40.613510847934194,-87.41039550003488 40.641100536658136,-87.42892769033847 40.64799795883912,-89.3748076722164 42.1378411499318,-89.3748076722164 42.15163599429376,-89.3655415770646 42.15163599429376,-86.22433532060451 41.42740666529038,-86.1872709399973 41.34463759911857,-86.1872709399973 41.33774017693758,-87.36406502427587 40.523844359581396,-87.35479892912407 40.523844359581396,-87.35479892912407 40.51694693740041,-87.38259721457948 40.50315209303844))"}';
  public poiCountType = 'geometry';
  public poiBoundaryType = 'geometry';
  countries: any[];
  filteredCountries: any[];
  pois: any[];
  filteredPoi: any[];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    this.liapiservice.getPoiTypes().subscribe(
      success => {
        this.pois = success;
      }
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'point of interest api, place search, business location, industry code, autocomplete, company information, point of interest by address' }
    );
    this.geometryByBoundJson = JSON.stringify(JSON.parse(this.geometryByBoundJson), undefined, 4);
    this.geometryAsTextByBoundJson = JSON.stringify(JSON.parse(this.geometryAsTextByBoundJson), undefined, 4);
    this.geometryPoiCountJson = JSON.stringify(JSON.parse(this.geometryPoiCountJson), undefined, 4);
    this.geometryAsTextPoiCountJson = JSON.stringify(JSON.parse(this.geometryAsTextPoiCountJson), undefined, 4);
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.setParamLink(success.docsURL);
        }
      );
    }
    else {
      this.setParamLink(docsURL);
    }
    let downloadableURL = localStorage.getItem("downloadableURL");
    if (!downloadableURL || downloadableURL == "https://learnpages-assets-qa.s3.amazonaws.com/downloads/9.5/") {
      this.resourceService.getDownloadUrl().subscribe(
        success => {
          this.downloadableURL = success.downloadableURL;
        }
      );
    }
    else {
      this.downloadableURL = downloadableURL;
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  filterPoi = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.pois.length; i++) {
      let poi = this.pois[i];
      if (poi.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(poi);
      }
    }
    this.filteredPoi = filtered;
  };
  setParamLink = function (docsURL) {
    this.poiaddrParamLink = docsURL + 'Places/POI_by_Address/query_parameters.html';
    this.poilocParamLink = docsURL + 'Places/POI_by_Location/query_parameters.html';
    this.poiareaParamLink = docsURL + 'Places/POI_by_Area/query_parameters.html';
    this.poiautoParamLink = docsURL + 'Places/POI_AutoComplete/List_POIs/query_parameters.html';
    this.sicParamLink = docsURL + 'Places/Metadata/sic_code/query_parameters.html';
    this.categoryParamLink = docsURL + 'Places/Metadata/category_code/query_parameters.html';
  };
  changePoiaddrRadio = function () {
    if (this.poiaddrRadio == 'searchRadius') {
      this.poiaddrSearchRadius = '3218.688';
      this.poiaddrSearchRadiusUnit = 'meters';
      this.poiaddrTravelTime = '';
      this.poiaddrTravelTimeUnit = '';
      this.poiaddrTravelDistance = '';
      this.poiaddrTravelDistanceUnit = '';
      this.poiaddrTravelMode = '';
    }
    if (this.poiaddrRadio == 'travelTime') {
      this.poiaddrSearchRadius = '';
      this.poiaddrSearchRadiusUnit = '';
      this.poiaddrTravelTime = '150';
      this.poiaddrTravelTimeUnit = 'minutes';
      this.poiaddrTravelDistance = '';
      this.poiaddrTravelDistanceUnit = '';
      this.poiaddrTravelMode = 'driving';
    }
    if (this.poiaddrRadio == 'travelDistance') {
      this.poiaddrSearchRadius = '';
      this.poiaddrSearchRadiusUnit = '';
      this.poiaddrTravelTime = '';
      this.poiaddrTravelTimeUnit = '';
      this.poiaddrTravelDistance = '528000';
      this.poiaddrTravelDistanceUnit = 'feet';
      this.poiaddrTravelMode = 'driving';
    }
  };
  changePoilocRadio = function () {
    if (this.poilocRadio == 'searchRadius') {
      this.poilocSearchRadius = '3218.688';
      this.poilocSearchRadiusUnit = 'meters';
      this.poilocTravelTime = '';
      this.poilocTravelTimeUnit = '';
      this.poilocTravelDistance = '';
      this.poilocTravelDistanceUnit = '';
      this.poilocTravelMode = '';
    }
    if (this.poilocRadio == 'travelTime') {
      this.poilocSearchRadius = '';
      this.poilocSearchRadiusUnit = '';
      this.poilocTravelTime = '150';
      this.poilocTravelTimeUnit = 'minutes';
      this.poilocTravelDistance = '';
      this.poilocTravelDistanceUnit = '';
      this.poilocTravelMode = 'driving';
    }
    if (this.poilocRadio == 'travelDistance') {
      this.poilocSearchRadius = '';
      this.poilocSearchRadiusUnit = '';
      this.poilocTravelTime = '';
      this.poilocTravelTimeUnit = '';
      this.poilocTravelDistance = '528000';
      this.poilocTravelDistanceUnit = 'feet';
      this.poilocTravelMode = 'driving';
    }
  };
  changePoiautoRadio = function () {
    if (this.poiautoRadio == 'searchRadius') {
      this.poiautoSearchRadius = '3218.688';
      this.poiautoSearchRadiusUnit = 'meters';
      this.poiautoTravelTime = '';
      this.poiautoTravelTimeUnit = '';
      this.poiautoTravelDistance = '';
      this.poiautoTravelDistanceUnit = '';
      this.poiautoTravelMode = '';
    }
    if (this.poiautoRadio == 'travelTime') {
      this.poiautoSearchRadius = '';
      this.poiautoSearchRadiusUnit = '';
      this.poiautoTravelTime = '150';
      this.poiautoTravelTimeUnit = 'minutes';
      this.poiautoTravelDistance = '';
      this.poiautoTravelDistanceUnit = '';
      this.poiautoTravelMode = 'driving';
    }
    if (this.poiautoRadio == 'travelDistance') {
      this.poiautoSearchRadius = '';
      this.poiautoSearchRadiusUnit = '';
      this.poiautoTravelTime = '';
      this.poiautoTravelTimeUnit = '';
      this.poiautoTravelDistance = '528000';
      this.poiautoTravelDistanceUnit = 'feet';
      this.poiautoTravelMode = 'driving';
    }
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_poiByAddrRes':
        this.get_poiByAddrUri = "";
        this.get_poiByAddrRes = null;
        this.get_poiByAddrResStatus = "";
        this.get_poiByAddrResType = "";
        break;
      case 'get_poiByLocRes':
        this.get_poiByLocUri = "";
        this.get_poiByLocRes = null;
        this.get_poiByLocResStatus = "";
        this.get_poiByLocResType = "";
        break;
      case 'get_poiByAreaRes':
        this.get_poiByAreaUri = "";
        this.get_poiByAreaRes = null;
        this.get_poiByAreaResStatus = "";
        this.get_poiByAreaResType = "";
        break;
      case 'post_poiByBoundRes':
        this.post_poiByBoundUri = "";
        this.post_poiByBoundRes = null;
        this.post_poiByBoundResStatus = "";
        this.post_poiByBoundResType = "";
        break;
      case 'get_poiAutoRes':
        this.get_poiAutoUri = "";
        this.get_poiAutoRes = null;
        this.get_poiAutoResStatus = "";
        this.get_poiAutoResType = "";
        break;
      case 'get_poiByIdRes':
        this.get_poiByIdUri = "";
        this.get_poiByIdRes = null;
        this.get_poiByIdResStatus = "";
        this.get_poiByIdResType = "";
        break;
      case 'post_poiCountRes':
        this.post_poiCountUri = "";
        this.post_poiCountRes = null;
        this.post_poiCountResStatus = "";
        this.post_poiCountResType = "";
        break;
      case 'get_sicRes':
        this.get_sicUri = "";
        this.get_sicRes = null;
        this.get_sicResStatus = "";
        this.get_sicResType = "";
        break;
      case 'get_categoryRes':
        this.get_categoryUri = "";
        this.get_categoryRes = null;
        this.get_categoryResStatus = "";
        this.get_categoryResType = "";
        break;
    }
  };
  toogleOptionalContainer = function (optConHan, optCon) {
    var domEle = $('#' + optConHan);
    var domEleNext = $('#' + optCon);
    if (domEleNext.prop('style')['display'] == 'none') {
      domEleNext.show(200);
      domEle.removeClass('plus');
      domEle.addClass('minus');
    }
    else {
      domEleNext.hide(200);
      domEle.removeClass('minus');
      domEle.addClass('plus');
    }
  };
  clearOptionalFields = function (nbsOtpPrmFrm) {
    switch (nbsOtpPrmFrm) {
      case 'poiaddrOptPrmFrm':
        this.poiaddrTravelMode = '';
        this.geoEnrichTryApiPOIByAddrmaxCandidates = '';
        this.geoEnrichTryApiPOIByAddrsortBy = '';
        this.geoEnrichTryApiPOIByAddrfuzzyOnName = '';
        this.geoEnrichTryApiPOIByAddrpage = '';
        break;
      case 'poilocOptPrmFrm':
        this.poilocTravelMode = '';
        this.geoEnrichTryApiPOIByLocmaxCandidates = '';
        this.geoEnrichTryApiPOIByLocsortBy = '';
        this.geoEnrichTryApiPOIByLocfuzzyOnName = '';
        this.geoEnrichTryApiPOIByLocpage = '';
        break;
      case 'poiareaOptPrmFrm':
        this.geoEnrichTryApiPOIByAreamaxCandidates = '';
        this.geoEnrichTryApiPOIByAreafuzzyOnName = '';
        this.geoEnrichTryApiPOIByAreapage = '';
        break;
      case 'poiautoOptPrmFrm':
        this.geoEnrichTryApiPOIAutomatchMode = '';
        this.geoEnrichTryApiPOIAutospecificMatchOn = '';
        this.poiautoType = '';
        this.geoEnrichTryApiPOIAutocategoryCode = '';
        this.geoEnrichTryApiPOIAutosicCode = '';
        this.geoEnrichTryApiPOIAutomaxCandidates = '';
        this.geoEnrichTryApiPOIAutosearchOnNameOnly = '';
        this.geoEnrichTryApiPOIAutosortBy = '';
        break;
    }
  };
  setPoiCountType = function (poiCountType) {
    this.poiCountType = poiCountType;
  };
  setPoiBoundaryType = function (poiBoundaryType) {
    this.poiBoundaryType = poiBoundaryType;
  };
  public geoEnrichTryApiPOIByAddraddress = '2935 Broadbridge Ave, Stratford, CT';
  public geoEnrichTryApiPOIByAddrname = '';
  public geoEnrichTryApiPOIByAddrmatchMode = 'Specific';
  public geoEnrichTryApiPOIByAddrspecificMatchOn = 'NAME';
  public geoEnrichTryApiPOIByAddrcategoryCode = '';
  public geoEnrichTryApiPOIByAddrsicCode = '';
  public geoEnrichTryApiPOIByAddrmaxCandidates = 20;
  public geoEnrichTryApiPOIByAddrsortBy = 'relevance';
  public geoEnrichTryApiPOIByAddrfuzzyOnName = 'Y';
  public geoEnrichTryApiPOIByAddrpage = 1;
  public geoEnrichTryApiPOIByAddrDataType = 'json';
  public get_poiByAddrUri = "";
  public get_poiByAddrRes = null;
  public get_poiByAddrResStatus = "";
  public get_poiByAddrResType = "";
  tryApiGeoEnrichPOIByAddr = function (btnId) {
    var address = this.geoEnrichTryApiPOIByAddraddress;
    var name = this.geoEnrichTryApiPOIByAddrname;
    var matchMode = this.geoEnrichTryApiPOIByAddrmatchMode;

    var specificMatchOn = this.geoEnrichTryApiPOIByAddrspecificMatchOn;
    if (matchMode != 'Specific') {
      specificMatchOn = "";
    }

    var categoryCode = this.geoEnrichTryApiPOIByAddrcategoryCode;
    var sicCode = this.geoEnrichTryApiPOIByAddrsicCode;
    var maxCandidates = this.geoEnrichTryApiPOIByAddrmaxCandidates;
    var sortBy = this.geoEnrichTryApiPOIByAddrsortBy;
    var fuzzyOnName = this.geoEnrichTryApiPOIByAddrfuzzyOnName;
    var page = this.geoEnrichTryApiPOIByAddrpage;
    var dataType = this.geoEnrichTryApiPOIByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (this.poiaddrCountry != '') {
      if (this.poiaddrCountry.code) {
        tmpData['country'] = this.poiaddrCountry.code;
      }
      else {
        tmpData['country'] = this.poiaddrCountry;
      }
    }
    if (name != '') {
      tmpData['name'] = name;
    }
    if (matchMode != '') {
      tmpData['matchMode'] = matchMode;
    }
    if (specificMatchOn != '') {
      tmpData['specificMatchOn'] = specificMatchOn;
    }
    if (this.poiaddrType != '') {
      if (this.poiaddrType.label) {
        tmpData['type'] = this.poiaddrType.label;
      }
      else {
        tmpData['type'] = this.poiaddrType;
      }
    }
    if (categoryCode != '') {
      tmpData['categoryCode'] = categoryCode;
    }
    if (sicCode != '') {
      tmpData['sicCode'] = sicCode;
    }
    if (this.poiaddrSearchRadius != '') {
      tmpData['searchRadius'] = this.poiaddrSearchRadius;
    }
    if (this.poiaddrSearchRadiusUnit != '') {
      tmpData['searchRadiusUnit'] = this.poiaddrSearchRadiusUnit;
    }
    if (this.poiaddrTravelTime != '') {
      tmpData['travelTime'] = this.poiaddrTravelTime;
    }
    if (this.poiaddrTravelTimeUnit != '') {
      tmpData['travelTimeUnit'] = this.poiaddrTravelTimeUnit;
    }
    if (this.poiaddrTravelDistance != '') {
      tmpData['travelDistance'] = this.poiaddrTravelDistance;
    }
    if (this.poiaddrTravelDistanceUnit != '') {
      tmpData['travelDistanceUnit'] = this.poiaddrTravelDistanceUnit;
    }
    if (this.poiaddrTravelMode != '') {
      tmpData['travelMode'] = this.poiaddrTravelMode;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (sortBy != '') {
      tmpData['sortBy'] = sortBy;
    }
    if (fuzzyOnName != '') {
      tmpData['fuzzyOnName'] = fuzzyOnName;
    }
    if (page != '') {
      tmpData['page'] = page;
    }
    if (address != '') {
      var methodName = '/tryApiGeoEnrichPOIByAddr';
      let requestUri = this.liapiservice.getCompleteUri('places', 'Points Of Interest By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_poiByAddrUri = requestUri;
          this.get_poiByAddrResType = dataType;
          if(this.get_poiByAddrResStatus.includes('429')){
            this.get_poiByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_poiByAddrResStatus = '200 success';
            this.get_poiByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_poiByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_poiByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  }
  public geoEnrichTryApiPOIByLoclatitude = 40.020087;
  public geoEnrichTryApiPOIByLoclongitude = -105.261963;
  public geoEnrichTryApiPOIByLocname = '';
  public geoEnrichTryApiPOIByLocmatchMode = 'Specific';
  public geoEnrichTryApiPOIByLocspecificMatchOn = 'NAME';
  public geoEnrichTryApiPOIByLoccategoryCode = '';
  public geoEnrichTryApiPOIByLocsicCode = '';
  public geoEnrichTryApiPOIByLocmaxCandidates = 20;
  public geoEnrichTryApiPOIByLocsortBy = 'relevance';
  public geoEnrichTryApiPOIByLocfuzzyOnName = 'Y';
  public geoEnrichTryApiPOIByLocpage = 1;
  public geoEnrichTryApiPOIByLocDataType = 'json';
  public get_poiByLocUri = "";
  public get_poiByLocRes = null;
  public get_poiByLocResStatus = "";
  public get_poiByLocResType = "";
  tryApiGeoEnrichPOIByLoc = function (btnId) {
    var latitude: any = this.geoEnrichTryApiPOIByLoclatitude;
    var longitude: any = this.geoEnrichTryApiPOIByLoclongitude;
    var name = this.geoEnrichTryApiPOIByLocname;
    var matchMode = this.geoEnrichTryApiPOIByLocmatchMode;
    var specificMatchOn = this.geoEnrichTryApiPOIByLocspecificMatchOn;
    if (matchMode != 'Specific') {
      specificMatchOn = ""
    }
    var categoryCode = this.geoEnrichTryApiPOIByLoccategoryCode;
    var sicCode = this.geoEnrichTryApiPOIByLocsicCode;
    var maxCandidates = this.geoEnrichTryApiPOIByLocmaxCandidates;
    var sortBy = this.geoEnrichTryApiPOIByLocsortBy;
    var fuzzyOnName = this.geoEnrichTryApiPOIByLocfuzzyOnName;
    var page = this.geoEnrichTryApiPOIByLocpage;
    var dataType = this.geoEnrichTryApiPOIByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (name != '') {
      tmpData['name'] = name;
    }
    if (matchMode != '') {
      tmpData['matchMode'] = matchMode;
    }
    if (specificMatchOn != '') {
      tmpData['specificMatchOn'] = specificMatchOn;
    }
    if (this.poilocType != '') {
      if (this.poilocType.label) {
        tmpData['type'] = this.poilocType.label;
      }
      else {
        tmpData['type'] = this.poilocType;
      }
    }
    if (categoryCode != '') {
      tmpData['categoryCode'] = categoryCode;
    }
    if (sicCode != '') {
      tmpData['sicCode'] = sicCode;
    }
    if (this.poilocSearchRadius != '') {
      tmpData['searchRadius'] = this.poilocSearchRadius;
    }
    if (this.poilocSearchRadiusUnit != '') {
      tmpData['searchRadiusUnit'] = this.poilocSearchRadiusUnit;
    }
    if (this.poilocTravelTime != '') {
      tmpData['travelTime'] = this.poilocTravelTime;
    }
    if (this.poilocTravelTimeUnit != '') {
      tmpData['travelTimeUnit'] = this.poilocTravelTimeUnit;
    }
    if (this.poilocTravelDistance != '') {
      tmpData['travelDistance'] = this.poilocTravelDistance;
    }
    if (this.poilocTravelDistanceUnit != '') {
      tmpData['travelDistanceUnit'] = this.poilocTravelDistanceUnit;
    }
    if (this.poilocTravelMode != '') {
      tmpData['travelMode'] = this.poilocTravelMode;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (sortBy != '') {
      tmpData['sortBy'] = sortBy;
    }
    if (fuzzyOnName != '') {
      tmpData['fuzzyOnName'] = fuzzyOnName;
    }
    if (page != '') {
      tmpData['page'] = page;
    }
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoEnrichPOIByLoc';
        let requestUri = this.liapiservice.getCompleteUri('places', 'Points Of Interest By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_poiByLocUri = requestUri;
            this.get_poiByLocResType = dataType;
            if(this.get_poiByLocResStatus.includes('429')){
              this.get_poiByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_poiByLocResStatus = '200 success';
              this.get_poiByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_poiByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_poiByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  }
  public geoEnrichTryApiPOIByAreaareaName1 = 'NY';
  public geoEnrichTryApiPOIByAreaareaName3 = 'Troy';
  public geoEnrichTryApiPOIByAreapostcode1 = '';
  public geoEnrichTryApiPOIByAreapostcode2 = '';
  public geoEnrichTryApiPOIByAreaname = '';
  public geoEnrichTryApiPOIByAreamatchMode = 'Specific';
  public geoEnrichTryApiPOIByAreaspecificMatchOn = 'NAME';
  public geoEnrichTryApiPOIByAreatype = '';
  public geoEnrichTryApiPOIByAreacategoryCode = '';
  public geoEnrichTryApiPOIByAreasicCode = '';
  public geoEnrichTryApiPOIByAreamaxCandidates = 20;
  public geoEnrichTryApiPOIByAreafuzzyOnName = 'Y';
  public geoEnrichTryApiPOIByAreapage = 1;
  public geoEnrichTryApiPOIByAreaDataType = 'json';
  public get_poiByAreaUri = "";
  public get_poiByAreaRes = null;
  public get_poiByAreaResStatus = "";
  public get_poiByAreaResType = "";
  tryApiGeoEnrichPOIByArea = function (btnId) {
    var country = '';
    if (this.poiareaCountry != '') {
      if (this.poiareaCountry.code) {
        country = this.poiareaCountry.code;
      }
      else {
        country = this.poiareaCountry;
      }
    }
    var areaName1 = this.geoEnrichTryApiPOIByAreaareaName1;
    var areaName3 = this.geoEnrichTryApiPOIByAreaareaName3;
    var postcode1 = this.geoEnrichTryApiPOIByAreapostcode1;
    var postcode2 = this.geoEnrichTryApiPOIByAreapostcode2;
    var name = this.geoEnrichTryApiPOIByAreaname;
    var matchMode = this.geoEnrichTryApiPOIByAreamatchMode;
    var specificMatchOn = this.geoEnrichTryApiPOIByAreaspecificMatchOn;
    if (matchMode != 'Specific') {
      specificMatchOn = ""
    }
    var type = this.geoEnrichTryApiPOIByAreatype;
    var categoryCode = this.geoEnrichTryApiPOIByAreacategoryCode;
    var sicCode = this.geoEnrichTryApiPOIByAreasicCode;
    var maxCandidates = this.geoEnrichTryApiPOIByAreamaxCandidates;
    var fuzzyOnName = this.geoEnrichTryApiPOIByAreafuzzyOnName;
    var page = this.geoEnrichTryApiPOIByAreapage;
    var dataType = this.geoEnrichTryApiPOIByAreaDataType;
    var tmpData = {
      country: country,
      dataType: dataType
    };
    if (areaName1 != '') {
      tmpData['areaName1'] = areaName1;
    }
    if (areaName3 != '') {
      tmpData['areaName3'] = areaName3;
    }
    if (postcode1 != '') {
      tmpData['postcode1'] = postcode1;
    }
    if (postcode2 != '') {
      tmpData['postcode2'] = postcode2;
    }
    if (name != '') {
      tmpData['name'] = name;
    }
    if (matchMode != '') {
      tmpData['matchMode'] = matchMode;
    }
    if (specificMatchOn != '') {
      tmpData['specificMatchOn'] = specificMatchOn;
    }
    if (this.poiareaType != '') {
      if (this.poiareaType.label) {
        tmpData['type'] = this.poiareaType.label;
      }
      else {
        tmpData['type'] = this.poiareaType;
      }
    }
    if (categoryCode != '') {
      tmpData['categoryCode'] = categoryCode;
    }
    if (sicCode != '') {
      tmpData['sicCode'] = sicCode;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (fuzzyOnName != '') {
      tmpData['fuzzyOnName'] = fuzzyOnName;
    }
    if (page != '') {
      tmpData['page'] = page;
    }
    if (country != '') {
      var methodName = '/tryApiGeoEnrichPOIByArea';
      let requestUri = this.liapiservice.getCompleteUri('places', 'Points Of Interest By Area', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_poiByAreaUri = requestUri;
          this.get_poiByAreaResType = dataType;
          if(this.get_poiByAreaResStatus.includes('429')){
            this.get_poiByAreaUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_poiByAreaResStatus = '200 success';
            this.get_poiByAreaRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_poiByAreaResStatus = `${error.status} ${error.statusText}`;
              this.get_poiByAreaRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_poiByBoundUri = "";
  public post_poiByBoundRes = null;
  public post_poiByBoundResStatus = "";
  public post_poiByBoundResType = "";
  tryApiGeoEnrichPOIByBoundary = function (btnId) {
    var postData = '';
    if (this.poiBoundaryType === 'geometry') {
      postData = this.geometryByBoundJson;
    }
    else if (this.poiBoundaryType === 'geometryastext') {
      postData = this.geometryAsTextByBoundJson;
    }
    var methodName = '/tryApiGeoEnrichPOIByBoundary';
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiGeoEnrichPOIByBoundary';
      let requestUri = this.liapiservice.getCompleteUriPost('places', 'Points Of Interest By Boundary');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_poiByBoundUri = requestUri;
          this.post_poiByBoundResType = 'json';
          if(this.post_poiByBoundResStatus.includes('429')){
            this.post_poiByBoundUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_poiByBoundResStatus = '200 success';
            this.post_poiByBoundRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_poiByBoundResStatus = `${error.status} ${error.statusText}`;
              this.post_poiByBoundRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoEnrichTryApiPOIAutoname = 'starbucks';
  public geoEnrichTryApiPOIAutomatchMode = 'Specific';
  public geoEnrichTryApiPOIAutospecificMatchOn = 'NAME';
  public geoEnrichTryApiPOIAutocategoryCode = '';
  public geoEnrichTryApiPOIAutosicCode = '';
  public geoEnrichTryApiPOIAutomaxCandidates = 20;
  public geoEnrichTryApiPOIAutosearchOnNameOnly = 'N';
  public geoEnrichTryApiPOIAutosortBy = 'relevance';
  public geoEnrichTryApiPOIAutoDataType = 'json';
  public geoEnrichTryApiPOIAutolatitude = 40.761819;
  public geoEnrichTryApiPOIAutolongitude = -73.997533;
  public geoEnrichTryApiPOIAutoipAddress = '54.86.242.73';
  public geoEnrichTryApiPOIAutoareaName1 = '';
  public geoEnrichTryApiPOIAutoareaName3 = '';
  public geoEnrichTryApiPOIAutopostcode1 = '';
  public geoEnrichTryApiPOIAutopostcode2 = '';
  public get_poiAutoUri = "";
  public get_poiAutoRes = null;
  public get_poiAutoResStatus = "";
  public get_poiAutoResType = "";
  tryApiGeoEnrichPOIAuto = function (btnId) {
    var searchText = this.geoEnrichTryApiPOIAutoname;
    var matchMode = this.geoEnrichTryApiPOIAutomatchMode;
    var specificMatchOn = this.geoEnrichTryApiPOIAutospecificMatchOn;
    if (matchMode != 'Specific') {
      specificMatchOn = ""
    }
    var categoryCode = this.geoEnrichTryApiPOIAutocategoryCode;
    var sicCode = this.geoEnrichTryApiPOIAutosicCode;
    var maxCandidates = this.geoEnrichTryApiPOIAutomaxCandidates;
    var searchOnNameOnly = this.geoEnrichTryApiPOIAutosearchOnNameOnly;
    var sortBy = this.geoEnrichTryApiPOIAutosortBy;
    var dataType = this.geoEnrichTryApiPOIAutoDataType;
    var callApiFlag = false;
    var tmpData = {
      searchText: searchText,
      dataType: dataType
    };
    if (searchText != '') {
      if (this.poiAutoTab == 'location') {
        var latitude: any = this.geoEnrichTryApiPOIAutolatitude;
        var longitude: any = this.geoEnrichTryApiPOIAutolongitude;
        if (latitude != '' && longitude != '') {
          if (!isNaN(latitude) && !isNaN(longitude)) {
            tmpData['latitude'] = latitude;
            tmpData['longitude'] = longitude;
            if (this.poiautoSearchRadius != '') {
              tmpData['searchRadius'] = this.poiautoSearchRadius;
            }
            if (this.poiautoSearchRadiusUnit != '') {
              tmpData['searchRadiusUnit'] = this.poiautoSearchRadiusUnit;
            }
            if (this.poiautoTravelTime != '') {
              tmpData['travelTime'] = this.poiautoTravelTime;
            }
            if (this.poiautoTravelTimeUnit != '') {
              tmpData['travelTimeUnit'] = this.poiautoTravelTimeUnit;
            }
            if (this.poiautoTravelDistance != '') {
              tmpData['travelDistance'] = this.poiautoTravelDistance;
            }
            if (this.poiautoTravelDistanceUnit != '') {
              tmpData['travelDistanceUnit'] = this.poiautoTravelDistanceUnit;
            }
            if (this.poiautoTravelMode != '') {
              tmpData['travelMode'] = this.poiautoTravelMode;
            }
            callApiFlag = true;
          }
          else {
            this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
          }
        }
        else {
          this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
        }
      }
      else if (this.poiAutoTab == 'ipaddress') {
        var ipAddress = this.geoEnrichTryApiPOIAutoipAddress;
        if (ipAddress != '') {
          tmpData['ipAddress'] = ipAddress;
          if (this.poiauto_autoDetectLocation) {
            tmpData['autoDetectLocation'] = this.poiauto_autoDetectLocation;
          }
          if (this.poiautoSearchRadius != '') {
            tmpData['searchRadius'] = this.poiautoSearchRadius;
          }
          if (this.poiautoSearchRadiusUnit != '') {
            tmpData['searchRadiusUnit'] = this.poiautoSearchRadiusUnit;
          }
          if (this.poiautoTravelTime != '') {
            tmpData['travelTime'] = this.poiautoTravelTime;
          }
          if (this.poiautoTravelTimeUnit != '') {
            tmpData['travelTimeUnit'] = this.poiautoTravelTimeUnit;
          }
          if (this.poiautoTravelDistance != '') {
            tmpData['travelDistance'] = this.poiautoTravelDistance;
          }
          if (this.poiautoTravelDistanceUnit != '') {
            tmpData['travelDistanceUnit'] = this.poiautoTravelDistanceUnit;
          }
          if (this.poiautoTravelMode != '') {
            tmpData['travelMode'] = this.poiautoTravelMode;
          }
          callApiFlag = true;
        }
        else {
          this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
        }
      }
      else if (this.poiAutoTab == 'country') {
        var areaName1 = this.geoEnrichTryApiPOIAutoareaName1;
        var areaName3 = this.geoEnrichTryApiPOIAutoareaName3;
        var postcode1 = this.geoEnrichTryApiPOIAutopostcode1;
        var postcode2 = this.geoEnrichTryApiPOIAutopostcode2;
        if (this.poiautoCountry != '') {
          if (this.poiautoCountry.code) {
            tmpData['country'] = this.poiautoCountry.code;
          }
          else {
            tmpData['country'] = this.poiautoCountry;
          }
          if (areaName1 != '') {
            tmpData['areaName1'] = areaName1;
          }
          if (areaName3 != '') {
            tmpData['areaName3'] = areaName3;
          }
          if (postcode1 != '') {
            tmpData['postcode1'] = postcode1;
          }
          if (postcode2 != '') {
            tmpData['postcode2'] = postcode2;
          }
          callApiFlag = true;
        }
        else {
          this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
        }
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
    if (this.poiautoType != '') {
      if (this.poiautoType.label) {
        tmpData['type'] = this.poiautoType.label;
      }
      else {
        tmpData['type'] = this.poiautoType;
      }
    }
    if (matchMode != '') {
      tmpData['matchMode'] = matchMode;
    }
    if (specificMatchOn != '') {
      tmpData['specificMatchOn'] = specificMatchOn;
    }
    if (categoryCode != '') {
      tmpData['categoryCode'] = categoryCode;
    }
    if (sicCode != '') {
      tmpData['sicCode'] = sicCode;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (searchOnNameOnly != '') {
      tmpData['searchOnNameOnly'] = searchOnNameOnly;
    }
    if (sortBy != '') {
      tmpData['sortBy'] = sortBy;
    }
    if (callApiFlag) {
      this.tryApiGeoEnrichPOIAutoAjax(tmpData, btnId, dataType);
    }
  };
  tryApiGeoEnrichPOIAutoAjax = function (tmpData, btnId, dataType) {
    var methodName = '/tryApiGeoEnrichPOIAuto';
    let requestUri = this.liapiservice.getCompleteUri('places', 'Points Of Interest Autocomplete', tmpData, []);
    this.liapiservice.disableTryItBtn(btnId);
    this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
      finalize(() => {
        this.liapiservice.enableTryItBtn(btnId);
        this.get_poiAutoUri = requestUri;
        this.get_poiAutoResType = dataType;
        if(this.get_poiAutoResStatus.includes('429')){
          this.get_poiAutoUri = '';
          this.liapiservice.showPrimeInfo();
        }
      }
      )).subscribe(
        success => {
          this.get_poiAutoResStatus = '200 success';
          this.get_poiAutoRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_poiAutoResStatus = `${error.status} ${error.statusText}`;
            this.get_poiAutoRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
  };
  public geoEnrichTryApiPOIByIdid = 1218000262;
  public geoEnrichTryApiPOIByIdDataType = 'json';
  public get_poiByIdUri = "";
  public get_poiByIdRes = null;
  public get_poiByIdResStatus = "";
  public get_poiByIdResType = "";
  tryApiGeoEnrichPOIById = function (btnId) {
    var id = this.geoEnrichTryApiPOIByIdid;
    var dataType = this.geoEnrichTryApiPOIByIdDataType;
    var tmpData = {
      id: id,
      dataType: dataType
    };
    var pathParam = ['id'];
    if (id != '') {
      var methodName = '/tryApiGeoEnrichPOIById';
      let requestUri = this.liapiservice.getCompleteUri('places', 'Points Of Interest Details By Id', tmpData, pathParam);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_poiByIdUri = requestUri;
          this.get_poiByIdResType = dataType;
          if(this.get_poiByIdResStatus.includes('429')){
            this.get_poiByIdUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_poiByIdResStatus = '200 success';
            this.get_poiByIdRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_poiByIdResStatus = `${error.status} ${error.statusText}`;
              this.get_poiByIdRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_poiCountUri = "";
  public post_poiCountRes = null;
  public post_poiCountResStatus = "";
  public post_poiCountResType = "";
  tryApiGeoEnrichPOICount = function (btnId) {
    var postData = '';
    if (this.poiCountType === 'geometry') {
      postData = this.geometryPoiCountJson;
    }
    else if (this.poiCountType === 'geometryastext') {
      postData = this.geometryAsTextPoiCountJson;
    }
    var methodName = '/tryApiGeoEnrichPOICount';
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiGeoEnrichPOICount';
      let requestUri = this.liapiservice.getCompleteUriPost('places', 'Points Of Interest Count');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_poiCountUri = requestUri;
          this.post_poiCountResType = 'json';
          if(this.post_poiCountResStatus.includes('429')){
            this.post_poiCountUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_poiCountResStatus = '200 success';
            this.post_poiCountRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_poiCountResStatus = `${error.status} ${error.statusText}`;
              this.post_poiCountRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoEnrichTryApiSICsicCode = 5812;
  public geoEnrichTryApiSIClevel = 1;
  public geoEnrichTryApiSICDataType = 'json';
  public get_sicUri = "";
  public get_sicRes = null;
  public get_sicResStatus = "";
  public get_sicResType = "";
  tryApiGeoEnrichSIC = function (btnId) {
    var sicCode = this.geoEnrichTryApiSICsicCode;
    var level = this.geoEnrichTryApiSIClevel;
    var dataType = this.geoEnrichTryApiSICDataType;
    var tmpData = {
      dataType: dataType
    };
    if (sicCode != '') {
      tmpData['sicCode'] = sicCode;
    }
    if (level != '') {
      tmpData['level'] = level;
    }
    var methodName = '/tryApiGeoEnrichSIC';
    let requestUri = this.liapiservice.getCompleteUri('places', 'SIC Metadata', tmpData, []);
    this.liapiservice.disableTryItBtn(btnId);
    this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
      finalize(() => {
        this.liapiservice.enableTryItBtn(btnId);
        this.get_sicUri = requestUri;
        this.get_sicResType = dataType;
        if(this.get_sicResStatus.includes('429')){
          this.get_sicUri = '';
          this.liapiservice.showPrimeInfo();
        }
      }
      )).subscribe(
        success => {
          this.get_sicResStatus = '200 success';
          this.get_sicRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_sicResStatus = `${error.status} ${error.statusText}`;
            this.get_sicRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
  };
  public geoEnrichTryApiCategorycategoryCode = 1607;
  public geoEnrichTryApiCategorylevel = 1;
  public geoEnrichTryApiCategoryDataType = 'json';
  public get_categoryUri = "";
  public get_categoryRes = null;
  public get_categoryResStatus = "";
  public get_categoryResType = "";
  tryApiGeoEnrichCategory = function (btnId) {
    var categoryCode = this.geoEnrichTryApiCategorycategoryCode;
    var level = this.geoEnrichTryApiCategorylevel;
    var dataType = this.geoEnrichTryApiCategoryDataType;
    var tmpData = {
      dataType: dataType
    };
    if (categoryCode != '') {
      tmpData['categoryCode'] = categoryCode;
    }
    if (level != '') {
      tmpData['level'] = level;
    }
    var methodName = '/tryApiGeoEnrichCategory';
    let requestUri = this.liapiservice.getCompleteUri('places', 'Category Code Metadata', tmpData, []);
    this.liapiservice.disableTryItBtn(btnId);
    this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
      finalize(() => {
        this.liapiservice.enableTryItBtn(btnId);
        this.get_categoryUri = requestUri;
        this.get_categoryResType = dataType;
        if(this.get_categoryResStatus.includes('429')){
          this.get_categoryUri = '';
          this.liapiservice.showPrimeInfo();
        }
      }
      )).subscribe(
        success => {
          this.get_categoryResStatus = '200 success';
          this.get_categoryRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_categoryResStatus = `${error.status} ${error.statusText}`;
            this.get_categoryRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
  };
  downloadFile = function (event, fileName) {
    event.preventDefault();
    let downloadUrl = this.downloadableURL + fileName;
    let a = document.createElement('A') as HTMLAnchorElement;
    a.href = decodeURI(encodeURI(downloadUrl));
    a.download = decodeURI(encodeURI(fileName));
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
}
