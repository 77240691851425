import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'property-api',
  templateUrl: './propertyapi.component.html'
})
export class PropertyApiComponent implements OnInit {
  public propParamLink: any = '';
  public byAddressJson = '{"preferences": {"attributes": "businessName, taxAddress, vacancy, owners, appraisedValueImp, assessedValueImp, buildgType, basementType, effectiveBuiltYear, exteriorWalls, garageType, lastUpdated, marketValueImp, absenteeOwner, parkingSqFt, replacementCost, roofShapeType, buildgStyle, basementSqFt, roofCoverType"}, "addresses": [{"mainAddressLine": "165 Acadiana Ln", "addressLastLine": "", "placeName": "", "areaName1": "NC", "areaName2": "", "areaName3": "Vilas", "areaName4": "", "postCode": "28692", "postCodeExt": "", "country": "USA", "addressNumber": "", "streetName": "", "unitType": "", "unitValue": "" }, {"mainAddressLine": "2877 SHORE DR", "addressLastLine": "", "placeName": "", "areaName1": "NY", "areaName2": "", "areaName3": "MERRICK", "areaName4": "", "postCode": "11566", "postCodeExt": "", "country": "usa", "addressNumber": "", "streetName": "", "unitType": "", "unitValue": ""}]}';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'property api, real estate apis, api real estate, residential property, commercial property, property exterior interior, property tax, property type, property owner, property details, deliverability, property value, property insurance, property risk, property underwriting' }
    );
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.propParamLink = success.docsURL + 'PropertyInformation/ByAddressv2/query_param.html';
        }
      );
    }
    else {
      this.propParamLink = docsURL + 'PropertyInformation/ByAddressv2/query_param.html';
    }
    this.formatJsonInputs();
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_propertyByAddrRes':
        this.get_propertyByAddrUri = "";
        this.get_propertyByAddrRes = null;
        this.get_propertyByAddrResStatus = "";
        this.get_propertyByAddrResType = "";
        break;
      case 'post_propertyByAddrRes':
        this.post_propertyByAddrUri = "";
        this.post_propertyByAddrRes = null;
        this.post_propertyByAddrResStatus = "";
        this.post_propertyByAddrResType = "";
        break;
        case 'get_parcelByAddrRes':
        this.get_parcelByAddrUri = "";
        this.get_parcelByAddrRes = null;
        this.get_parcelByAddrResStatus = "";
        this.get_parcelByAddrResType = "";
        break;case 'get_parcelByLocRes':
        this.get_parcelByLocUri = "";
        this.get_parcelByLocRes = null;
        this.get_parcelByLocResStatus = "";
        this.get_parcelByLocResType = "";
        break;case 'get_parcelyByPreciselyIDRes':
        this.get_parcelByPreciselyIDUri = "";
        this.get_parcelByPreciselyIDRes = null;
        this.get_parcelByPreciselyIDResStatus = "";
        this.get_parcelByPreciselyIDResType = "";
        break;
    }
  };
  public geoPropertyTryApiByAddraddress = '15525 COUNTRY EDGE DR OKLAHOMA CITY, OK 73170-9399';
  public geoParcelTryApiByAddraddress = '127 BRYAN ST, PRATTVILLE, AL';
  public geoParcelTrylongitude ='-97.370789';
  public geoParcelTryApilatitude ='32.749619';
  public geoParcelTryApiByPreciselyId='P0000M3JBK13'; 
  public geoPropertyTryApiByAddrattributes = 'all';
  public geoPropertyTryApiByAddrDataType = 'json';
  public geoParcelTryApiByAddrDataType = 'json';
  public geoParcelTryApiByLocDataType = 'json';
  public geoParcelTryApiByPreciselyIDDataType = 'json';
  

  public get_propertyByAddrUri = "";
  public get_propertyByAddrRes = null;
  public get_propertyByAddrResStatus = "";
  public get_propertyByAddrResType = "";
  tryApiGeoPropertyByAddr = function (btnId) {
    var address = this.geoPropertyTryApiByAddraddress;
    var attributes = this.geoPropertyTryApiByAddrattributes;
    var dataType = this.geoPropertyTryApiByAddrDataType;
    var tmpData = {
      address: address,
      attributes: attributes,
      dataType: dataType
    };
    if (address != '' && attributes != '') {
      var methodName = '/tryApiGeoPropertyByAddr';
      let requestUri = this.liapiservice.getCompleteUri('property', 'Get Property Attributes By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_propertyByAddrUri = requestUri;
          this.get_propertyByAddrResType = dataType;
          if(this.get_propertyByAddrResStatus.includes('429')){
            this.get_propertyByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_propertyByAddrResStatus = '200 success';
            this.get_propertyByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_propertyByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_propertyByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_propertyByAddrUri = "";
  public post_propertyByAddrRes = null;
  public post_propertyByAddrResStatus = "";
  public post_propertyByAddrResType = "";
  tryApiPostGeoPropertyByAddr = function (btnId) {
    var postData = this.byAddressJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiPostGeoPropertyByAddr';
      let requestUri = this.liapiservice.getCompleteUriPost('property', 'Post Property Attributes By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_propertyByAddrUri = requestUri;
          this.post_propertyByAddrResType = 'json';
          if(this.post_propertyByAddrResStatus.includes('429')){
            this.post_propertyByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_propertyByAddrResStatus = '200 success';
            this.post_propertyByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_propertyByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_propertyByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  tryApiGeoParcelByAddr = function (btnId) {
    var address = this.geoParcelTryApiByAddraddress;
    var dataType = this.geoParcelTryApiByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
  if (address != '') {
    var methodName = '/tryApiGeoParcelByAddr';
    let requestUri = this.liapiservice.getCompleteUri('property', 'Get Parcel Boundary By Address', tmpData, []);
    this.liapiservice.disableTryItBtn(btnId);
    this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
      finalize(() => {
        this.liapiservice.enableTryItBtn(btnId);
        this.get_parcelByAddrUri = requestUri;
        this.get_parcelByAddrResType = dataType;
        if(this.get_parcelByAddrResStatus.includes('429')){
          this.get_parcelByAddrUri = '';
          this.liapiservice.showPrimeInfo();
        }
      }
      )).subscribe(
        success => {
          this.get_parcelByAddrResStatus = '200 success';
          this.get_parcelByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_parcelByAddrResStatus = `${error.status} ${error.statusText}`;
            this.get_parcelByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
          }
        }
      );
  }
  else {
    this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
  }
};tryApiGeoParcelByLoc = function (btnId) {

 var longitude= this.geoParcelTrylongitude;
  var latitude= this.geoParcelTryApilatitude;

  var dataType = this.geoParcelTryApiByLocDataType;
  var tmpData = {
    longitude: longitude,
    latitude : latitude,
    dataType: dataType
  };
if (longitude != '' && latitude!='') {
  var methodName = '/tryApiGeoParcelByLoc';
  let requestUri = this.liapiservice.getCompleteUri('property', 'Get Parcel Boundary By Location', tmpData, []);
  this.liapiservice.disableTryItBtn(btnId);
  this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
    finalize(() => {
      this.liapiservice.enableTryItBtn(btnId);
      this.get_parcelByLocUri = requestUri;
      this.get_parcelByLocResType = dataType;
      if(this.get_parcelByLocResStatus.includes('429')){
        this.get_parcelByLocUri = '';
        this.liapiservice.showPrimeInfo();
      }
    }
    )).subscribe(
      success => {
        this.get_parcelByLocResStatus = '200 success';
        this.get_parcelByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
      },
      error => {
        if (error.status && error.statusText) {
          this.get_parcelByLocResStatus = `${error.status} ${error.statusText}`;
          this.get_parcelByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
        }
      }
    );
}
else {
  this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
}
};tryApiGeoParcelByPreciselyID = function (btnId) {

  var preciselyID= this.geoParcelTryApiByPreciselyId;
  
   var dataType = this.geoParcelTryApiByPreciselyIDDataType;
   var tmpData = {
    preciselyID: preciselyID,
     dataType: dataType
   };
 if (preciselyID != '') {
   var methodName = '/tryApiGeoParcelByPreciselyID';
   let requestUri = this.liapiservice.getCompleteUri('property', 'Get Parcel Boundary By PreciselyID', tmpData, []);
   this.liapiservice.disableTryItBtn(btnId);
   this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
     finalize(() => {
       this.liapiservice.enableTryItBtn(btnId);
       this.get_parcelByPreciselyIDUri = requestUri;
       this.get_parcelByPreciselyIDResType = dataType;
       if(this.get_parcelByPreciselyIDResStatus.includes('429')){
         this.get_parcelByPreciselyIDUri = '';
         this.liapiservice.showPrimeInfo();
       }
     }
     )).subscribe(
       success => {
         this.get_parcelByPreciselyIDResStatus = '200 success';
         this.get_parcelByPreciselyID = this.liapiservice.getFormattedResponse(dataType, success);
       },
       error => {
         if (error.status && error.statusText) {
           this.get_parcelByPreciselyIDResStatus = `${error.status} ${error.statusText}`;
           this.get_parcelByPreciselyIDRes = this.liapiservice.getFormattedResponse(dataType, error.error);
         }
       }
     );
 }
 else {
   this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
 }
 };
  formatJsonInputs = function () {
    this.byAddressJson = JSON.stringify(JSON.parse(this.byAddressJson), undefined, 4);
  };
}
