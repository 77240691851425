import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';
import $ from 'jquery';

@Component({
  selector: 'zones-api',
  templateUrl: './zonesapi.component.html'
})
export class ZonesApiComponent implements OnInit {
  public downloadableURL = '';
  public tbbtParamLink = '';
  public tbbdParamLink = '';
  public bbbaParamLink = '';
  public bbblParamLink = '';
  public pbbaParamLink = '';
  public pbblParamLink = '';
  public tbbtRadio = 'point';
  public tbbtPoint = '-73.99753,40.76181,epsg:4326';
  public tbbtAddress = '';
  public tbbtCountry = '';
  public tbbdRadio = 'point';
  public tbbdPoint = '-73.99753,40.76181,epsg:4326';
  public tbbdAddress = '';
  public tbbdCountry = '';
  public bbbaCountry = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public tbbt_majorRoads: boolean = true;
  public tbbt_returnHoles: boolean = false;
  public tbbt_returnIslands: boolean = false;
  public tbbd_majorRoads: boolean = true;
  public tbbd_returnHoles: boolean = false;
  public tbbd_returnIslands: boolean = false;
  public poiByAddrJson = '{"preferences": {"categoryCode": "18180301010", "sicCode": "82310300", "naicsCode": "511210"},"addresses": [{"objectId": "1", "mainAddressLine": "609 Cayuga St, Storm Lake, IA"}, {"objectId": "2", "mainAddressLine": "Empire State Building, 350 5th Ave, New York"}]}';
  public poiByLocJson = '{"preferences": {"categoryCode": "17010702020", "sicCode":"57340000", "naicsCode":"511210"}, "locations": [{"geometry": {"type": "point", "coordinates": [-73.70515, 42.682658]}, "objectId": "1"}, {"geometry": {"type": "point", "coordinates": [-149.908997, 61.214249]}, "objectId": "2"}]}';
  countries: any[];
  filteredCountries: any[];
  routeCountries: any[];
  filteredRouteCountries: any[];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.liapiservice.getGeocodeCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    this.liapiservice.getRoutingCountries().subscribe(
      success => {
        this.routeCountries = success;
      }
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'boundaries' }
    );
    this.formatJsonInputs();
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.setParamLink(success.docsURL);
        }
      );
    }
    else{
      this.setParamLink(docsURL);
    }
    let downloadableURL = localStorage.getItem("downloadableURL");
    if(!downloadableURL || downloadableURL == "https://learnpages-assets-qa.s3.amazonaws.com/downloads/9.5/"){
        this.resourceService.getDownloadUrl().subscribe(
            success => {
                this.downloadableURL = success.downloadableURL;
            }
        );
    }
    else{
        this.downloadableURL = downloadableURL;
    }
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  filterRouteCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredRouteCountries = filtered;
  };
  setParamLink = function (docsURL) {
    this.tbbtParamLink = docsURL + 'Zones/TravelBoundary/bytime/query_param.html';
    this.tbbdParamLink = docsURL + 'Zones/TravelBoundary/bydistance/query_param.html';
    this.bbbaParamLink = docsURL + 'Zones/BasicBoundary/ByAddress/query_parameters.html';
    this.bbblParamLink = docsURL + 'Zones/BasicBoundary/ByLocation/query_parameters.html';
    this.pbbaParamLink = docsURL + 'Zones/POIBoundary/ByAddress/query_parameters.html';
    this.pbblParamLink = docsURL + 'Zones/POIBoundary/ByLocation/query_parameters.html';
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_tbByTimeRes':
        this.get_tbByTimeUri = "";
        this.get_tbByTimeRes = null;
        this.get_tbByTimeResStatus = "";
        this.get_tbByTimeResType = "";
        break;
      case 'get_tbByDisRes':
        this.get_tbByDisUri = "";
        this.get_tbByDisRes = null;
        this.get_tbByDisResStatus = "";
        this.get_tbByDisResType = "";
        break;
      case 'get_bbByAddrRes':
        this.get_bbByAddrUri = "";
        this.get_bbByAddrRes = null;
        this.get_bbByAddrResStatus = "";
        this.get_bbByAddrResType = "";
        break;
      case 'get_bbByLocRes':
        this.get_bbByLocUri = "";
        this.get_bbByLocRes = null;
        this.get_bbByLocResStatus = "";
        this.get_bbByLocResType = "";
        break;
      case 'get_poiByAddrRes':
        this.get_poiByAddrUri = "";
        this.get_poiByAddrRes = null;
        this.get_poiByAddrResStatus = "";
        this.get_poiByAddrResType = "";
        break;
      case 'post_poiByAddrRes':
        this.post_poiByAddrUri = "";
        this.post_poiByAddrRes = null;
        this.post_poiByAddrResStatus = "";
        this.post_poiByAddrResType = "";
        break;
      case 'get_poiByLocRes':
        this.get_poiByLocUri = "";
        this.get_poiByLocRes = null;
        this.get_poiByLocResStatus = "";
        this.get_poiByLocResType = "";
        break;
      case 'post_poiByLocRes':
        this.post_poiByLocUri = "";
        this.post_poiByLocRes = null;
        this.post_poiByLocResStatus = "";
        this.post_poiByLocResType = "";
        break;
    }
  };
  changeTbbtRadio = function () {
    if (this.tbbtRadio == 'point') {
      this.tbbtPoint = '-73.99753,40.76181,epsg:4326';
      this.tbbtAddress = '';
      this.tbbtCountry = '';
    }
    if (this.tbbtRadio == 'address') {
      this.tbbtPoint = '';
      this.tbbtAddress = '4750 Walnut St, Boulder, CO';
      this.tbbtCountry = {
        "name": "UNITED STATES OF AMERICA",
        "code": "USA",
        "label": "UNITED STATES OF AMERICA (USA)"
      };
    }
  };
  changeTbbdRadio = function () {
    if (this.tbbdRadio == 'point') {
      this.tbbdPoint = '-73.99753,40.76181,epsg:4326';
      this.tbbdAddress = '';
      this.tbbdCountry = '';
    }
    if (this.tbbdRadio == 'address') {
      this.tbbdPoint = '';
      this.tbbdAddress = '4750 Walnut St, Boulder, CO';
      this.tbbdCountry = {
        "name": "UNITED STATES OF AMERICA",
        "code": "USA",
        "label": "UNITED STATES OF AMERICA (USA)"
      };
    }
  };
  toogleOptionalContainer = function (optConHan, optCon) {
    var domEle = $('#' + optConHan);
    var domEleNext = $('#' + optCon);
    if (domEleNext.prop('style')['display'] == 'none') {
      domEleNext.show(200);
      domEle.removeClass('plus');
      domEle.addClass('minus');
    }
    else {
      domEleNext.hide(200);
      domEle.removeClass('minus');
      domEle.addClass('plus');
    }
  };
  clearOptionalFields = function (nbsOtpPrmFrm) {
    switch(nbsOtpPrmFrm){
      case 'tbbtOtpPrmFrm':
        this.geoZoneTryApiByTimemaxOffroadDistance = '';
        this.geoZoneTryApiByTimemaxOffroadDistanceUnit = '';
        this.geoZoneTryApiByTimedestinationSrs = '';
        this.geoZoneTryApiByTimesimplificationFactor = '';
        this.geoZoneTryApiByTimebandingStyle = '';
        this.geoZoneTryApiByTimehistoricTrafficTimeBucket = '';
        break;
      case 'tbbdOtpPrmFrm':
        this.geoZoneTryApiByDistancemaxOffroadDistance = '';
        this.geoZoneTryApiByDistancemaxOffroadDistanceUnit = '';
        this.geoZoneTryApiByDistancedestinationSrs = '';
        this.geoZoneTryApiByDistancesimplificationFactor = '';
        this.geoZoneTryApiByDistancebandingStyle = '';
        this.geoZoneTryApiByDistancehistoricTrafficTimeBucket = '';
        break;
    }
  };
  formatJsonInputs = function () {
    this.poiByAddrJson = JSON.stringify(JSON.parse(this.poiByAddrJson), undefined, 4);
    this.poiByLocJson = JSON.stringify(JSON.parse(this.poiByLocJson), undefined, 4);
  };
  public geoZoneTryApiByTimecosts = 5;
  public geoZoneTryApiByTimecostUnit = 'min';
  public geoZoneTryApiByTimedb = 'driving';
  public geoZoneTryApiByTimedefaultAmbientSpeed = '';
  public geoZoneTryApiByTimeambientSpeedUnit = 'MPH';
  public geoZoneTryApiByTimemaxOffroadDistance = 1;
  public geoZoneTryApiByTimemaxOffroadDistanceUnit = 'mi';
  public geoZoneTryApiByTimedestinationSrs = 'epsg:4326';
  public geoZoneTryApiByTimesimplificationFactor = 0.5;
  public geoZoneTryApiByTimebandingStyle = 'Donut';
  public geoZoneTryApiByTimehistoricTrafficTimeBucket = 'None';
  public get_tbByTimeUri = "";
  public get_tbByTimeRes = null;
  public get_tbByTimeResStatus = "";
  public get_tbByTimeResType = "";
  tryApiGeoZoneByTime = function (btnId) {
    var point = this.tbbtPoint;
    var address = this.tbbtAddress;
    var costs = this.geoZoneTryApiByTimecosts;
    var costUnit = this.geoZoneTryApiByTimecostUnit;
    var db = this.geoZoneTryApiByTimedb;
    var tmpData = {};
    if (point != '' && costs != '') {
      tmpData = {
        point: point,
        costs: costs,
        costUnit: costUnit,
        db: db,
        dataType: 'json'
      };
      this.tryApiGeoZoneByTimeAjax(tmpData, btnId);
    }
    else if (address != '' && costs != '') {
      tmpData = {
        address: address,
        costs: costs,
        costUnit: costUnit,
        db: db,
        dataType: 'json'
      };
      if (this.tbbtCountry != '') {
        if (this.tbbtCountry.code) {
          tmpData['country'] = this.tbbtCountry.code;
        }
        else {
          tmpData['country'] = this.tbbtCountry;
        }
      }
      this.tryApiGeoZoneByTimeAjax(tmpData, btnId);
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  tryApiGeoZoneByTimeAjax = function (tmpData, btnId) {
    var defaultAmbientSpeed = this.geoZoneTryApiByTimedefaultAmbientSpeed;
    if (defaultAmbientSpeed != '') {
      tmpData['defaultAmbientSpeed'] = defaultAmbientSpeed;
    }
    var ambientSpeedUnit = this.geoZoneTryApiByTimeambientSpeedUnit;
    if (ambientSpeedUnit != '') {
      tmpData['ambientSpeedUnit'] = ambientSpeedUnit;
    }
    var maxOffroadDistance = this.geoZoneTryApiByTimemaxOffroadDistance;
    if (maxOffroadDistance != '') {
      tmpData['maxOffroadDistance'] = maxOffroadDistance;
    }
    var maxOffroadDistanceUnit = this.geoZoneTryApiByTimemaxOffroadDistanceUnit;
    if (maxOffroadDistanceUnit != '') {
      tmpData['maxOffroadDistanceUnit'] = maxOffroadDistanceUnit;
    }
    var destinationSrs = this.geoZoneTryApiByTimedestinationSrs;
    if (destinationSrs != '') {
      tmpData['destinationSrs'] = destinationSrs;
    }
    tmpData['majorRoads'] = this.tbbt_majorRoads;
    tmpData['returnHoles'] = this.tbbt_returnHoles;
    tmpData['returnIslands'] = this.tbbt_returnIslands;
    var simplificationFactor = this.geoZoneTryApiByTimesimplificationFactor;
    if (simplificationFactor != '') {
      tmpData['simplificationFactor'] = simplificationFactor;
    }
    var bandingStyle = this.geoZoneTryApiByTimebandingStyle;
    if (bandingStyle != '') {
      tmpData['bandingStyle'] = bandingStyle;
    }
    var historicTrafficTimeBucket = this.geoZoneTryApiByTimehistoricTrafficTimeBucket;
    if (historicTrafficTimeBucket != '') {
      tmpData['historicTrafficTimeBucket'] = historicTrafficTimeBucket;
    }
    var methodName = '/tryApiGeoZoneByTime';
    let requestUri = this.liapiservice.getCompleteUri('zones', 'Travel Boundary By Time', tmpData, []);
    this.liapiservice.disableTryItBtn(btnId);
    this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
      finalize(() => {
        this.liapiservice.enableTryItBtn(btnId);
        this.get_tbByTimeUri = requestUri;
        this.get_tbByTimeResType = 'json';
        if(this.get_tbByTimeResStatus.includes('429')){
          this.get_tbByTimeUri = '';
          this.liapiservice.showPrimeInfo();
        }
      }
      )).subscribe(
        success => {
          this.get_tbByTimeResStatus = '200 success';
          this.get_tbByTimeRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_tbByTimeResStatus = `${error.status} ${error.statusText}`;
            this.get_tbByTimeRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
  };
  public geoZoneTryApiByDistancecosts = 500;
  public geoZoneTryApiByDistancecostUnit = 'm';
  public geoZoneTryApiByDistancedb = 'driving';
  public geoZoneTryApiByDistancedefaultAmbientSpeed = '';
  public geoZoneTryApiByDistanceambientSpeedUnit = 'MPH';
  public geoZoneTryApiByDistancemaxOffroadDistance = 1;
  public geoZoneTryApiByDistancemaxOffroadDistanceUnit = 'mi';
  public geoZoneTryApiByDistancedestinationSrs = 'epsg:4326';
  public geoZoneTryApiByDistancesimplificationFactor = 0.5;
  public geoZoneTryApiByDistancebandingStyle = 'Donut';
  public geoZoneTryApiByDistancehistoricTrafficTimeBucket = 'None';
  public get_tbByDisUri = "";
  public get_tbByDisRes = null;
  public get_tbByDisResStatus = "";
  public get_tbByDisResType = "";
  tryApiGeoZoneByDistance = function (btnId) {
    var point = this.tbbdPoint;
    var address = this.tbbdAddress;
    var costs = this.geoZoneTryApiByDistancecosts;
    var costUnit = this.geoZoneTryApiByDistancecostUnit;
    var db = this.geoZoneTryApiByDistancedb;
    var tmpData = {};
    if (point != '' && costs != '') {
      tmpData = {
        point: point,
        costs: costs,
        costUnit: costUnit,
        db: db,
        dataType: 'json'
      };
      this.tryApiGeoZoneByDistanceAjax(tmpData, btnId);
    }
    else if (address != '' && costs != '') {
      tmpData = {
        address: address,
        costs: costs,
        costUnit: costUnit,
        db: db,
        dataType: 'json'
      };
      if (this.tbbdCountry != '') {
        if (this.tbbdCountry.code) {
          tmpData['country'] = this.tbbdCountry.code;
        }
        else {
          tmpData['country'] = this.tbbdCountry;
        }
      }
      this.tryApiGeoZoneByDistanceAjax(tmpData, btnId);
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  tryApiGeoZoneByDistanceAjax = function (tmpData, btnId) {
    var defaultAmbientSpeed = this.geoZoneTryApiByDistancedefaultAmbientSpeed;
    if (defaultAmbientSpeed != '') {
      tmpData['defaultAmbientSpeed'] = defaultAmbientSpeed;
    }
    var ambientSpeedUnit = this.geoZoneTryApiByDistanceambientSpeedUnit;
    if (ambientSpeedUnit != '') {
      tmpData['ambientSpeedUnit'] = ambientSpeedUnit;
    }
    var maxOffroadDistance = this.geoZoneTryApiByDistancemaxOffroadDistance;
    if (maxOffroadDistance != '') {
      tmpData['maxOffroadDistance'] = maxOffroadDistance;
    }
    var maxOffroadDistanceUnit = this.geoZoneTryApiByDistancemaxOffroadDistanceUnit;
    if (maxOffroadDistanceUnit != '') {
      tmpData['maxOffroadDistanceUnit'] = maxOffroadDistanceUnit;
    }
    var destinationSrs = this.geoZoneTryApiByDistancedestinationSrs;
    if (destinationSrs != '') {
      tmpData['destinationSrs'] = destinationSrs;
    }
    tmpData['majorRoads'] = this.tbbd_majorRoads;
    tmpData['returnHoles'] = this.tbbd_returnHoles;
    tmpData['returnIslands'] = this.tbbd_returnIslands;
    var simplificationFactor = this.geoZoneTryApiByDistancesimplificationFactor;
    if (simplificationFactor != '') {
      tmpData['simplificationFactor'] = simplificationFactor;
    }
    var bandingStyle = this.geoZoneTryApiByDistancebandingStyle;
    if (bandingStyle != '') {
      tmpData['bandingStyle'] = bandingStyle;
    }
    var historicTrafficTimeBucket = this.geoZoneTryApiByDistancehistoricTrafficTimeBucket;
    if (historicTrafficTimeBucket != '') {
      tmpData['historicTrafficTimeBucket'] = historicTrafficTimeBucket;
    }
    var methodName = '/tryApiGeoZoneByDistance';
    let requestUri = this.liapiservice.getCompleteUri('zones', 'Travel Boundary By Distance', tmpData, []);
    this.liapiservice.disableTryItBtn(btnId);
    this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
      finalize(() => {
        this.liapiservice.enableTryItBtn(btnId);
        this.get_tbByDisUri = requestUri;
        this.get_tbByDisResType = 'json';
        if(this.get_tbByDisResStatus.includes('429')){
          this.get_tbByDisUri = '';
          this.liapiservice.showPrimeInfo();
        }
      }
      )).subscribe(
        success => {
          this.get_tbByDisResStatus = '200 success';
          this.get_tbByDisRes = this.liapiservice.getFormattedResponse('json', success);
        },
        error => {
          if (error.status && error.statusText) {
            this.get_tbByDisResStatus = `${error.status} ${error.statusText}`;
            this.get_tbByDisRes = this.liapiservice.getFormattedResponse('json', error.error);
          }
        }
      );
  };
  public geoZoneTryApiBasicBoundaryByAddraddress = '4750 Walnut St., Boulder CO, 80301';
  public geoZoneTryApiBasicBoundaryByAddrdistance = 500;
  public geoZoneTryApiBasicBoundaryByAddrdistanceUnit = 'feet';
  public geoZoneTryApiBasicBoundaryByAddrresolution = 4;
  public geoZoneTryApiBasicBoundaryByAddrresponseSrs = 'epsg:4326';
  public geoZoneTryApiBasicBoundaryByAddrDataType = 'json';
  public get_bbByAddrUri = "";
  public get_bbByAddrRes = null;
  public get_bbByAddrResStatus = "";
  public get_bbByAddrResType = "";
  tryApiGeoZoneBasicBoundaryByAddr = function (btnId) {
    var address = this.geoZoneTryApiBasicBoundaryByAddraddress;
    var distance: any = this.geoZoneTryApiBasicBoundaryByAddrdistance;
    var distanceUnit = this.geoZoneTryApiBasicBoundaryByAddrdistanceUnit;
    var resolution = this.geoZoneTryApiBasicBoundaryByAddrresolution;
    var responseSrs = this.geoZoneTryApiBasicBoundaryByAddrresponseSrs;
    var dataType = this.geoZoneTryApiBasicBoundaryByAddrDataType;
    var tmpData = {
      address: address,
      distance: distance,
      dataType: dataType
    };
    if (this.bbbaCountry != '') {
      if (this.bbbaCountry.code) {
        tmpData['country'] = this.bbbaCountry.code;
      }
      else {
        tmpData['country'] = this.bbbaCountry;
      }
    }
    if (distanceUnit != '') {
      tmpData['distanceUnit'] = distanceUnit;
    }
    if (resolution != '') {
      tmpData['resolution'] = resolution;
    }
    if (responseSrs != '') {
      tmpData['responseSrs'] = responseSrs;
    }
    if (address != '' && distance != '' && !isNaN(distance)) {
      var methodName = '/tryApiGeoZoneBasicBoundaryByAddr';
      let requestUri = this.liapiservice.getCompleteUri('zones', 'Basic Boundary By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_bbByAddrUri = requestUri;
          this.get_bbByAddrResType = dataType;
          if(this.get_bbByAddrResStatus.includes('429')){
            this.get_bbByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_bbByAddrResStatus = '200 success';
            this.get_bbByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_bbByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_bbByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoZoneTryApiBasicBoundaryByLoclatitude = 35.0118;
  public geoZoneTryApiBasicBoundaryByLoclongitude = -81.9571;
  public geoZoneTryApiBasicBoundaryByLocsrsName = 'epsg:4326';
  public geoZoneTryApiBasicBoundaryByLocdistance = 500;
  public geoZoneTryApiBasicBoundaryByLocdistanceUnit = 'feet';
  public geoZoneTryApiBasicBoundaryByLocresolution = 4;
  public geoZoneTryApiBasicBoundaryByLocresponseSrs = 'epsg:4326';
  public geoZoneTryApiBasicBoundaryByLocDataType = 'json';
  public get_bbByLocUri = "";
  public get_bbByLocRes = null;
  public get_bbByLocResStatus = "";
  public get_bbByLocResType = "";
  tryApiGeoZoneBasicBoundaryByLoc = function (btnId) {
    var latitude: any = this.geoZoneTryApiBasicBoundaryByLoclatitude;
    var longitude: any = this.geoZoneTryApiBasicBoundaryByLoclongitude;
    var srsName = this.geoZoneTryApiBasicBoundaryByLocsrsName;
    var distance: any = this.geoZoneTryApiBasicBoundaryByLocdistance;
    var distanceUnit = this.geoZoneTryApiBasicBoundaryByLocdistanceUnit;
    var resolution = this.geoZoneTryApiBasicBoundaryByLocresolution;
    var responseSrs = this.geoZoneTryApiBasicBoundaryByLocresponseSrs;
    var dataType = this.geoZoneTryApiBasicBoundaryByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      distance: distance,
      dataType: dataType
    };
    if (srsName != '') {
      tmpData['srsName'] = srsName;
    }
    if (distanceUnit != '') {
      tmpData['distanceUnit'] = distanceUnit;
    }
    if (resolution != '') {
      tmpData['resolution'] = resolution;
    }
    if (responseSrs != '') {
      tmpData['responseSrs'] = responseSrs;
    }
    if (latitude != '' && longitude != '' && distance != '' && !isNaN(latitude) && !isNaN(longitude) && !isNaN(distance)) {
      var methodName = '/tryApiGeoZoneBasicBoundaryByLoc';
      let requestUri = this.liapiservice.getCompleteUri('zones', 'Basic Boundary By Location', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_bbByLocUri = requestUri;
          this.get_bbByLocResType = dataType;
          if(this.get_bbByLocResStatus.includes('429')){
            this.get_bbByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_bbByLocResStatus = '200 success';
            this.get_bbByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_bbByLocResStatus = `${error.status} ${error.statusText}`;
              this.get_bbByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoZoneTryApiPoiBoundaryByAddraddress = '609 Cayuga St, Storm Lake, IA';
  public geoZoneTryApiPoiBoundaryByAddrcategoryCode = '';
  public geoZoneTryApiPoiBoundaryByAddrsicCode = '';
  public geoZoneTryApiPoiBoundaryByAddrnaicsCode = '';
  public geoZoneTryApiPoiBoundaryByAddrDataType = 'json';
  public get_poiByAddrUri = "";
  public get_poiByAddrRes = null;
  public get_poiByAddrResStatus = "";
  public get_poiByAddrResType = "";
  tryApiGeoZonePoiBoundaryByAddr = function (btnId) {
    var address = this.geoZoneTryApiPoiBoundaryByAddraddress;
    var categoryCode = this.geoZoneTryApiPoiBoundaryByAddrcategoryCode;
    var sicCode = this.geoZoneTryApiPoiBoundaryByAddrsicCode;
    var naicsCode = this.geoZoneTryApiPoiBoundaryByAddrnaicsCode;
    var dataType = this.geoZoneTryApiPoiBoundaryByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (categoryCode != '') {
      tmpData['categoryCode'] = categoryCode;
    }
    if (sicCode != '') {
      tmpData['sicCode'] = sicCode;
    }
    if (naicsCode != '') {
      tmpData['naicsCode'] = naicsCode;
    }
    if (address != '') {
      var methodName = '/tryApiGeoZonePoiBoundaryByAddr';
      let requestUri = this.liapiservice.getCompleteUri('zones', 'Points Of Interest Boundary By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_poiByAddrUri = requestUri;
          this.get_poiByAddrResType = dataType;
          if(this.get_poiByAddrResStatus.includes('429')){
            this.get_poiByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_poiByAddrResStatus = '200 success';
            this.get_poiByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_poiByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_poiByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_poiByAddrUri = "";
  public post_poiByAddrRes = null;
  public post_poiByAddrResStatus = "";
  public post_poiByAddrResType = "";
  tryApiGeoZonePostPoiBoundaryByAddr = function (btnId) {
    var postData = this.poiByAddrJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiGeoZonePostPoiBoundaryByAddr';
      let requestUri = this.liapiservice.getCompleteUriPost('zones', 'Post Points Of Interest Boundary By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_poiByAddrUri = requestUri;
          this.post_poiByAddrResType = 'json';
          if(this.post_poiByAddrResStatus.includes('429')){
            this.post_poiByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_poiByAddrResStatus = '200 success';
            this.post_poiByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_poiByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_poiByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoZoneTryApiPoiBoundaryByLoclatitude = 42.682658;
  public geoZoneTryApiPoiBoundaryByLoclongitude = -73.70515;
  public geoZoneTryApiPoiBoundaryByLoccategoryCode = '';
  public geoZoneTryApiPoiBoundaryByLocsicCode = '';
  public geoZoneTryApiPoiBoundaryByLocnaicsCode = '';
  public geoZoneTryApiPoiBoundaryByLocDataType = 'json';
  public get_poiByLocUri = "";
  public get_poiByLocRes = null;
  public get_poiByLocResStatus = "";
  public get_poiByLocResType = "";
  tryApiGeoZonePoiBoundaryByLoc = function (btnId) {
    var latitude: any = this.geoZoneTryApiPoiBoundaryByLoclatitude;
    var longitude: any = this.geoZoneTryApiPoiBoundaryByLoclongitude;
    var categoryCode = this.geoZoneTryApiPoiBoundaryByLoccategoryCode;
    var sicCode = this.geoZoneTryApiPoiBoundaryByLocsicCode;
    var naicsCode = this.geoZoneTryApiPoiBoundaryByLocnaicsCode;
    var dataType = this.geoZoneTryApiPoiBoundaryByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (categoryCode != '') {
      tmpData['categoryCode'] = categoryCode;
    }
    if (sicCode != '') {
      tmpData['sicCode'] = sicCode;
    }
    if (naicsCode != '') {
      tmpData['naicsCode'] = naicsCode;
    }
    if (latitude != '' && longitude != '' && !isNaN(latitude) && !isNaN(longitude)) {
      var methodName = '/tryApiGeoZonePoiBoundaryByLoc';
      let requestUri = this.liapiservice.getCompleteUri('zones', 'Points Of Interest Boundary By Location', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_poiByLocUri = requestUri;
          this.get_poiByLocResType = dataType;
          if(this.get_poiByLocResStatus.includes('429')){
            this.get_poiByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_poiByLocResStatus = '200 success';
            this.get_poiByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_poiByLocResStatus = `${error.status} ${error.statusText}`;
              this.get_poiByLocrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_poiByLocUri = "";
  public post_poiByLocRes = null;
  public post_poiByLocResStatus = "";
  public post_poiByLocResType = "";
  tryApiGeoZonePostPoiBoundaryByLoc = function (btnId) {
    var postData = this.poiByLocJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/tryApiGeoZonePostPoiBoundaryByLoc';
      let requestUri = this.liapiservice.getCompleteUriPost('zones', 'Post Points Of Interest Boundary By Location');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_poiByLocUri = requestUri;
          this.post_poiByLocResType = 'json';
          if(this.post_poiByLocResStatus.includes('429')){
            this.post_poiByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_poiByLocResStatus = '200 success';
            this.post_poiByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_poiByLocResStatus = `${error.status} ${error.statusText}`;
              this.post_poiByLocRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  downloadFile = function (event, fileName) {
    event.preventDefault();
    let downloadUrl = this.downloadableURL + fileName;
    let a = document.createElement('A') as HTMLAnchorElement;
    a.href = decodeURI(encodeURI(downloadUrl));
    a.download = decodeURI(encodeURI(fileName));
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
}
