import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';

@Component({
  selector: 'localtax-demo',
  templateUrl: './localtaxdemo.component.html'
})
export class LocaltaxDemoComponent implements OnInit {
  public searchedAddr = "";
  public searchAddress = "";
  public activetab = "general";
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService) {};
  ngOnInit() {
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
  };
  showSearchForm = function () {
    this.searchedAddr = "";
  };
  geoTaxDemoSearchAddress = function () {
    this.searchedAddr = this.searchAddress;
    var country = 'USA';
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    };
    $('#geoTaxgeneralError').hide();
    $('#geoTaxgeneralResult').html('');
    $('#geoTaxautoError').hide();
    $('#geoTaxautoResult').html('');
    $('#geoTaxipdError').hide();
    $('#geoTaxipdResult').html('');
    $('#geoTaxDemoSearchError').hide();
    if (this.searchAddress != '') {
      this.geoApisGetLocationGeocode(this.searchAddress, country);
    }
    else {
      $('#geoTaxDemoSearchError').html('Address cannot be blank.');
      $('#geoTaxDemoSearchError').show();
    }
  };
  geoApisGetLocationGeocode = function (address, country) {
    $('.geoApisDemoMapLoader').show();
    var tmpData = {
      mainAddress: address,
      country: country
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoRouteGeoCode', tmpData).subscribe(
      success => {
        if (success.candidates.length != 0) {
          var lat = success.candidates[0].geometry.coordinates[1];
          var lng = success.candidates[0].geometry.coordinates[0];
          this.map.setView(L.latLng(lat, lng), 14);
          this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
          this.sampleDemoGeoTaxTaxRateByLocation(lat, lng, 'General');
          this.sampleDemoGeoTaxTaxRateByLocation(lat, lng, 'Auto');
          this.sampleDemoGeoTaxIpdTaxRateByaddress(address);
        }
        else {
          $('#geoTaxgeneralError').hide();
          $('#geoTaxgeneralResult').html('');
          $('#geoTaxautoError').hide();
          $('#geoTaxautoResult').html('');
          $('#geoTaxipdError').hide();
          $('#geoTaxipdResult').html('');
          $('#geoTaxDemoSearchError').html('No information available for this address.');
          $('#geoTaxDemoSearchError').show();
          $('.geoApisDemoMapLoader').hide();
        }
      },
      error => {
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('.geoApisDemoMapLoader').hide();
        }
      }
    );
  };
  sampleDemoGeoTaxTaxRateByLocation = function(lat, lng, taxRateTypeId){
    var resultEleId = '#geoTax'+taxRateTypeId.toLowerCase()+'Result';
    var resultErrId = '#geoTax'+taxRateTypeId.toLowerCase()+'Error';
    $(resultErrId).hide();
    $(resultEleId).html('');
    var tmpData = {
      latitude: lat,
      longitude: lng,
      taxRateTypeId: taxRateTypeId
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoTaxTaxRateByLocation', tmpData).subscribe(
      success => {
        if(success.salesTax){
          var spds = new Array();
          for(var k=0; k<success.jurisdiction.spds.length; k++){
            spds[success.jurisdiction.spds[k].districtNumber] = success.jurisdiction.spds[k].districtName;
          }
          var tmpHtml = '<h2 class="zeromargintop">'+taxRateTypeId+' Sales Tax</h2><table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Sales Tax</div><div class="resultVal">'+(success.salesTax['totalTaxRate']*100).toFixed(2)+'%</div></td>';
          tmpHtml += '<td><div class="resultKey">State Tax</div><div class="resultVal">'+(success.salesTax['stateTaxRate']*100).toFixed(2)+'%</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">County Tax</div><div class="resultVal">'+(success.salesTax['countyTaxRate']*100).toFixed(2)+'%</div></td>';
          tmpHtml += '<td><div class="resultKey">Municipal Tax</div><div class="resultVal">'+(success.salesTax['municipalTaxRate']*100).toFixed(2)+'%</div></td></tr>';
          if(success.salesTax.spdsTax.length){
            tmpHtml += '<tr><td colspan="2"><h3 class="mb-0 mt-0">Special Purpose Districts Tax</h3></td></tr>';
            for(var j=0; j<success.salesTax.spdsTax.length; j++){
              var districtNumber = success.salesTax.spdsTax[j].districtNumber;
              var name = spds[districtNumber];
              var value = (success.salesTax.spdsTax[j].taxRate*100).toFixed(2);
              tmpHtml += '<tr><td><div>'+name+'</div></td><td><div>'+value+'%</div></td></tr>';
            }
          }
          tmpHtml += '</table>';
          tmpHtml += '<h2 class="zeromargintop">'+taxRateTypeId+' Use Tax</h2><table class="table table-condensed table-unstyled">';
          tmpHtml += '<tr><td><div class="resultKey">Use Tax</div><div class="resultVal">'+(success.useTax['totalTaxRate']*100).toFixed(2)+'%</div></td>';
          tmpHtml += '<td><div class="resultKey">State Tax</div><div class="resultVal">'+(success.useTax['stateTaxRate']*100).toFixed(2)+'%</div></td></tr>';
          tmpHtml += '<tr><td><div class="resultKey">County Tax</div><div class="resultVal">'+(success.useTax['countyTaxRate']*100).toFixed(2)+'%</div></td>';
          tmpHtml += '<td><div class="resultKey">Municipal Tax</div><div class="resultVal">'+(success.useTax['municipalTaxRate']*100).toFixed(2)+'%</div></td></tr>';
          if(success.useTax.spdsTax.length){
            tmpHtml += '<tr><td colspan="2"><h3 class="mb-0 mt-0">Special Purpose Districts Tax</h3></td></tr>';
            for(var j=0; j<success.useTax.spdsTax.length; j++){
              var districtNumber = success.useTax.spdsTax[j].districtNumber;
              var name = spds[districtNumber];
              var value = (success.useTax.spdsTax[j].taxRate*100).toFixed(2);
              tmpHtml += '<tr><td><div>'+name+'</div></td><td><div>'+value+'%</div></td></tr>';
            }
          }
          tmpHtml += '</table>';
          $(resultEleId).html(tmpHtml);
        }
        else{
          $(resultErrId).html('No information available for this address.');
          $(resultErrId).show();						
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $(resultErrId).html(error.error.errors[0].errorDescription);
        }
        else {
          $(resultErrId).html(error.statusText);
        }
        $(resultErrId).show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $(resultErrId).hide();
        }
      }
    );
  };
  sampleDemoGeoTaxIpdTaxRateByaddress = function(address){
    $('#geoTaxipdError').hide();
    $('#geoTaxipdResult').html('');
    var tmpData = {
      address: address
    };
    this.liapiservice.geoApiSampleDemoGetCall('/sampleDemoGeoIpdTaxByAddress', tmpData).subscribe(
      success => {
        if(success.ipds){
          var tmpHtml = '';
          for(var i=0; i<success.ipds.length; i++){
            tmpHtml += '<h2 class="zeromargintop">'+success.ipds[i].districtName+'</h2><table class="table table-condensed table-unstyled">';
            tmpHtml += '<tr><td><div class="resultKey">District Type</div><div class="resultVal">'+success.ipds[i].districtType.description+'</div></td>';
            if(success.ipds[i].rates){
              tmpHtml += '<td><div class="resultKey">'+success.ipds[i].rates[0].name+' Taxrate</div><div class="resultVal">'+success.ipds[i].rates[0].value+'%</div></td>';
            }
            tmpHtml += '</tr></table>';
          }
          $('#geoTaxipdResult').html(tmpHtml);
        }
        else{
          $('#geoTaxipdError').html('Tax details not available for this address.');
          $('#geoTaxipdError').show();
        }
        $('.geoApisDemoMapLoader').hide();
      },
      error => {
        if (error.status == 401) {
          window.location.href = "/signin";
        }
        if (error.error) {
          $('#geoTaxipdError').html(error.error.errors[0].errorDescription);
        }
        else {
          $('#geoTaxipdError').html(error.statusText);
        }
        $('#geoTaxipdError').show();
        $('.geoApisDemoMapLoader').hide();
        if (error.status == '429') {
          this.liapiservice.showPrimeInfo();
          $('#geoTaxipdError').hide();
        }
      }
    );
  };
}
