import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import $ from 'jquery';

$.ajax({
  url: "/api/getresources",
  method: "GET",
  async: false
}).done(function (response) {
  let mapFullURL = 'https://api.precisely.com/maps/v1/tile/osm/{z}/{x}/{y}.png?api_key=' + response.mapApiStr + '&theme=';
  localStorage.setItem("mapFullURL", mapFullURL);
});


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private router : Router, private metaTagService: Meta) {
    this.injectChatBotScript();
  }
  ngOnInit(): void {
    this.metaTagService.addTags([
      {
        name: 'keywords',
        content: 'precisely login, precisely app, precisely api, precisely geocoding, autocomplete api design, address autocomplete api free, developer apis, address validation api free, free api for developers, free apis for developers, paid apis, location api, free address validation api, geocoding api free, apis online, api guide',
      },
      { charset: 'UTF-8' }
    ]);
  }

  injectChatBotScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    // language=javascript
    script.innerHTML = `'use strict';

    !(function () {
      var t = (window.driftt = window.drift = window.driftt || []);
      if (!t.init) {
        if (t.invoked) return void (window.console && console.error && console.error('Drift snippet included twice.'));
        (t.invoked = !0),
          (t.methods = ['identify', 'config', 'track', 'reset', 'debug', 'show', 'ping', 'page', 'hide', 'off', 'on']),
          (t.factory = function (e) {
            return function () {
              var n = Array.prototype.slice.call(arguments);
              return n.unshift(e), t.push(n), t;
            };
          }),
          t.methods.forEach(function (e) {
            t[e] = t.factory(e);
          }),
          (t.load = function (t) {
            var e = 3e5,
              n = Math.ceil(new Date() / e) * e,
              o = document.createElement('script');
            (o.type = 'text/javascript'),
              (o.async = !0),
              (o.crossorigin = 'anonymous'),
              (o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js');
            var i = document.getElementsByTagName('script')[0];
            i.parentNode.insertBefore(o, i);
          });
      }
    })();
    drift.SNIPPET_VERSION = '0.3.1';
    drift.load('bkrne6cztd8c');`;

    document.head.appendChild(script);
  }
}
