import { Component, Input } from '@angular/core';

@Component({
    selector: 'breadcrumbs',
    templateUrl: './breadcrumbs.component.html'
})

export class BreadcrumbsComponent {
    @Input() apiName: string = "";
    @Input() pageType: string = "";

    constructor() { };
    loadApi = function(){
        window.location.href = this.pageType+'/'+this.apiName;
    };
}
