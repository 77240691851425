import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import { ResourceService } from '../service/resource.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'risks-api',
  templateUrl: './risksapi.component.html'
})
export class RisksApiComponent implements OnInit {
  public crbaParamLink = '';
  public crblParamLink = '';
  public erbaParamLink = '';
  public erblParamLink = '';
  public ehParamLink = '';
  public frbaParamLink = '';
  public frblParamLink = '';
  public fhParamLink = '';
  public fsbaParamLink = '';
  public fsblParamLink = '';
  public dcbaParamLink = '';
  public dcblParamLink = '';
  public fsbaRadio: any = 'travelTime';
  public fsbaTravelTime: any = '150';
  public fsbaTravelTimeUnit: any = 'minutes';
  public fsbaTravelDistance: any = '';
  public fsbaTravelDistanceUnit: any = '';
  public fsblRadio: any = 'travelTime';
  public fsblTravelTime: any = '150';
  public fsblTravelTimeUnit: any = 'minutes';
  public fsblTravelDistance: any = '';
  public fsblTravelDistanceUnit: any = '';
  public crimeRiskByAddrJson = '{"preferences": {"type": "all", "includeGeometry": "N"}, "addresses": [{"objectId":"1", "mainAddressLine": "1926 Gumtree Rd, Forest, VA 24551, USA","addressLastLine": "","placeName": "", "areaName1": "", "areaName2": "", "areaName3": "", "areaName4": "", "postCode": "", "postCodeExt": "", "country": "", "addressNumber": "", "streetName": "", "unitType": "", "unitValue": ""},{"objectId":"2", "mainAddressLine": "229.7 Parks Hwy, Denali Natl Park, AK 99755, United States", "addressLastLine": "", "placeName": "", "areaName1": "", "areaName2": "", "areaName3": "", "areaName4": "", "postCode": "", "postCodeExt": "", "country": "", "addressNumber": "", "streetName": "", "unitType": "", "unitValue": ""}]}';
  public crimeRiskByLocJson = '{"preferences":{"type":"all", "includeGeometry":"N"},"locations":[{"geometry":{"type":"point", "coordinates":[-79.2965203,37.3692212]}, "objectId":"100"},{"geometry":{"type":"point", "coordinates":[-109.212223,37.097559]}, "objectId":"1"}]}';
  public earthquakeRiskByAddrJson = '{"preferences": {"richterValue": "all", "includeGeometry": "N"}, "addresses": [{"objectId":"1", "mainAddressLine": "1926 Gumtree Rd, Forest, VA 24551, USA","addressLastLine": "","placeName": "", "areaName1": "", "areaName2": "", "areaName3": "", "areaName4": "", "postCode": "", "postCodeExt": "", "country": "", "addressNumber": "", "streetName": "", "unitType": "", "unitValue": ""},{"objectId":"2", "mainAddressLine": "229.7 Parks Hwy, Denali Natl Park, AK 99755, United States", "addressLastLine": "", "placeName": "", "areaName1": "", "areaName2": "", "areaName3": "", "areaName4": "", "postCode": "", "postCodeExt": "", "country": "", "addressNumber": "", "streetName": "", "unitType": "", "unitValue": ""}]}';
  public earthquakeRiskByLocJson = '{"preferences":{"richterValue":"all", "includeGeometry":"N"},"locations":[{"geometry":{"type":"point", "coordinates":[-79.2965203,37.3692212]}, "objectId":"100"},{"geometry":{"type":"point", "coordinates":[-109.212223,37.097559]}, "objectId":"1"}]}';
  public floodRiskByAddrJson = '{"preferences":{"includeGeometry":"N","includeZoneDesc":"Y"},"addresses":[{"objectId":"1","mainAddressLine": "1805 33rd Street","addressLastLine": "","placeName": "","areaName1": "CO","areaName2": "","areaName3": "Boulder","areaName4": "","postCode": "80301","postCodeExt": "","country": "USA","addressNumber": "","streetName": "","unitType": "","unitValue": ""},{"objectId":"2","mainAddressLine": "210 Back Fork Street,Webster Springs,WV,26288","addressLastLine": "","placeName": "","areaName1": "","areaName2": "","areaName3": "","areaName4": "","postCode": "","postCodeExt": "","country": "USA","addressNumber": "","streetName": "","unitType": "","unitValue": ""}]}';
  public floodRiskByLocJson = '{"preferences":{"includeGeometry": "N","includeZoneDesc": "Y"},"locations":[{"geometry":{"type": "point","coordinates": [-119.759098,41.909106]},"objectId": "1"},{"geometry":{"type": "point","coordinates":[-109.212223,37.097559]},"objectId": "2"}]}';
  public fireRiskByAddrJson = '{"preferences": { "includeGeometry": "N" },"addresses": [{"objectId":"1","mainAddressLine": "2550 Riverside Dr, Susanville, CA 96130, USA","addressLastLine": "","placeName": "","areaName1": "","areaName2": "","areaName3": "","areaName4": "","postCode": "","postCodeExt": "","country": "","addressNumber": "","streetName": "","unitType": "","unitValue": ""},{"objectId":"2","mainAddressLine": "229.7 Parks Hwy, Denali Natl Park, AK 99755, United States","addressLastLine": "","placeName": "","areaName1": "","areaName2": "","areaName3": "","areaName4": "","postCode": "","postCodeExt": "","country": "","addressNumber": "","streetName": "","unitType": "","unitValue": ""}]}';
  public fireRiskByLocJson = '{"preferences": { "includeGeometry": "N" },"locations":[{"geometry": {"type": "point","coordinates": [-123.977847,40.45393]},"objectId": "1"},{"geometry": {"type": "point","coordinates": [-120.522737,34.885387]},"objectId": "2"}]}';
  public disToFloodHazardByAddrJson = '{"preferences": {"maxCandidates":"3","waterBodyType":"All","searchDistance":"20","searchDistanceUnit":"miles" },"addresses": [{"objectId":"1","mainAddressLine": "2550 Riverside Dr, Susanville, CA 96130, USA"},{"objectId":"2","mainAddressLine": "97 Hammond Street,Bangor,ME,04401"}]}';
  public disToFloodHazardByLocJson = '{"preferences":{"maxCandidates":"1","waterBodyType":"All","searchDistance":"20","searchDistanceUnit":"miles"},"locations":[{"objectId":"1","geometry":{"type":"point","coordinates":[-73.087749,41.603221]}},{"objectId":"2","geometry":{"type":"point","coordinates":[-82.670278,28.9235985]}}]}';
  public crimeRiskByAddrtype = [{ name: 'all' }];
  public crimeRiskByLoctype = [{ name: 'all' }];
  public riskTypes = [{ name: 'all' }, { name: 'arson' }, { name: 'assault' }, { name: 'burglary' }, { name: 'larceny' }, { name: 'motorvehicletheft' }, { name: 'murder' }, { name: 'property' }, { name: 'rape' }, { name: 'robbery' }, { name: 'violent' }];
  public earthquakeByAddrrichterValue = [{ name: 'all' }];
  public earthquakeByLocrichterValue = [{ name: 'all' }];
  public richterTypes = [{ name: 'all' }, { name: 'R0' }, { name: 'R1' }, { name: 'R2' }, { name: 'R3' }, { name: 'R4' }, { name: 'R5' }, { name: 'R6' }, { name: 'R7' }, { name: 'R0_GE' }, { name: 'R1_GE' }, { name: 'R2_GE' }, { name: 'R3_GE' }, { name: 'R4_GE' }, { name: 'R5_GE' }, { name: 'R6_GE' }, { name: 'R7_GE' }];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService, private metaTagService: Meta) { };
  ngOnInit() {
    window.scroll(0, 0);
    this.metaTagService.updateTag(
      { name: 'keywords', content: 'earthquake risk by address, flood hazard, risk analysis, risk intelligence, property risk, insurance risk, insurance underwriting, fire station location' }
    );
    this.formatJsonInputs();
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if (docsURL == "null") {
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.setParamLink(success.docsURL);
        }
      );
    }
    else {
      this.setParamLink(docsURL);
    }
  };
  setParamLink = function (docsURL) {
    this.crbaParamLink = docsURL + 'Risks/risks_crime/byadd/query_param.html';
    this.crblParamLink = docsURL + 'Risks/risks_crime/byloc/query_param.html';
    this.erbaParamLink = docsURL + 'Risks/risks_earthquake/byadd/query_param.html';
    this.erblParamLink = docsURL + 'Risks/risks_earthquake/byloc/query_param.html';
    this.ehParamLink = docsURL + 'Risks/risks_earthquake_history/query_param.html';
    this.frbaParamLink = docsURL + 'Risks/risks_flood/byadd/query_param.html';
    this.frblParamLink = docsURL + 'Risks/risks_flood/byloc/query_param.html';
    this.fhParamLink = docsURL + 'Risks/risks_fire_history/query_param.html';
    this.fsbaParamLink = docsURL + 'Risks/risks_firestation/byadd/query_param.html';
    this.fsblParamLink = docsURL + 'Risks/risks_firestation/byloc/query_param.html';
    this.dcbaParamLink = docsURL + 'Risks/risks_distancetocoast/byaddress/query_param.html';
    this.dcblParamLink = docsURL + 'Risks/risks_distancetocoast/bypoint/query_param.html';
  };
  clearResult = function (result) {
    switch (result) {
      case 'get_crimeByAddrRes':
        this.get_crimeByAddrUri = "";
        this.get_crimeByAddrRes = null;
        this.get_crimeByAddrResStatus = "";
        this.get_crimeByAddrResType = "";
        break;
      case 'post_crimeByAddrRes':
        this.post_crimeByAddrUri = "";
        this.post_crimeByAddrRes = null;
        this.post_crimeByAddrResStatus = "";
        this.post_crimeByAddrResType = "";
        break;
      case 'get_crimeByLocRes':
        this.get_crimeByLocUri = "";
        this.get_crimeByLocRes = null;
        this.get_crimeByLocResStatus = "";
        this.get_crimeByLocResType = "";
        break;
      case 'post_crimeByLocRes':
        this.post_crimeByLocUri = "";
        this.post_crimeByLocRes = null;
        this.post_crimeByLocResStatus = "";
        this.post_crimeByLocResType = "";
        break;
      case 'get_quakeByAddrRes':
        this.get_quakeByAddrUri = "";
        this.get_quakeByAddrRes = null;
        this.get_quakeByAddrResStatus = "";
        this.get_quakeByAddrResType = "";
        break;
      case 'post_quakeByAddrRes':
        this.post_quakeByAddrUri = "";
        this.post_quakeByAddrRes = null;
        this.post_quakeByAddrResStatus = "";
        this.post_quakeByAddrResType = "";
        break;
      case 'get_quakeByLocRes':
        this.get_quakeByLocUri = "";
        this.get_quakeByLocRes = null;
        this.get_quakeByLocResStatus = "";
        this.get_quakeByLocResType = "";
        break;
      case 'post_quakeByLocRes':
        this.post_quakeByLocUri = "";
        this.post_quakeByLocRes = null;
        this.post_quakeByLocResStatus = "";
        this.post_quakeByLocResType = "";
        break;
      case 'get_quakeHisRes':
        this.get_quakeHisUri = "";
        this.get_quakeHisRes = null;
        this.get_quakeHisResStatus = "";
        this.get_quakeHisResType = "";
        break;
      case 'get_floodByAddrRes':
        this.get_floodByAddrUri = "";
        this.get_floodByAddrRes = null;
        this.get_floodByAddrResStatus = "";
        this.get_floodByAddrResType = "";
        break;
      case 'post_floodByAddrRes':
        this.post_floodByAddrUri = "";
        this.post_floodByAddrRes = null;
        this.post_floodByAddrResStatus = "";
        this.post_floodByAddrResType = "";
        break;
      case 'get_floodByLocRes':
        this.get_floodByLocUri = "";
        this.get_floodByLocRes = null;
        this.get_floodByLocResStatus = "";
        this.get_floodByLocResType = "";
        break;
      case 'post_floodByLocRes':
        this.post_floodByLocUri = "";
        this.post_floodByLocRes = null;
        this.post_floodByLocResStatus = "";
        this.post_floodByLocResType = "";
        break;
      case 'get_fireByAddrRes':
        this.get_fireByAddrUri = "";
        this.get_fireByAddrRes = null;
        this.get_fireByAddrResStatus = "";
        this.get_fireByAddrResType = "";
        break;
      case 'post_fireByAddrRes':
        this.post_fireByAddrUri = "";
        this.post_fireByAddrRes = null;
        this.post_fireByAddrResStatus = "";
        this.post_fireByAddrResType = "";
        break;
      case 'get_fireByLocRes':
        this.get_fireByLocUri = "";
        this.get_fireByLocRes = null;
        this.get_fireByLocResStatus = "";
        this.get_fireByLocResType = "";
        break;
      case 'post_fireByLocRes':
        this.post_fireByLocUri = "";
        this.post_fireByLocRes = null;
        this.post_fireByLocResStatus = "";
        this.post_fireByLocResType = "";
        break;
      case 'get_fireHisRes':
        this.get_fireHisUri = "";
        this.get_fireHisRes = null;
        this.get_fireHisResStatus = "";
        this.get_fireHisResType = "";
        break;
      case 'get_stationByAddrRes':
        this.get_stationByAddrUri = "";
        this.get_stationByAddrRes = null;
        this.get_stationByAddrResStatus = "";
        this.get_stationByAddrResType = "";
        break;
      case 'get_stationByLocRes':
        this.get_stationByLocUri = "";
        this.get_stationByLocRes = null;
        this.get_stationByLocResStatus = "";
        this.get_stationByLocResType = "";
        break;
      case 'get_dtfhByAddrRes':
        this.get_dtfhByAddrUri = "";
        this.get_dtfhByAddrRes = null;
        this.get_dtfhByAddrResStatus = "";
        this.get_dtfhByAddrResType = "";
        break;
      case 'post_dtfhByAddrRes':
        this.post_dtfhByAddrUri = "";
        this.post_dtfhByAddrRes = null;
        this.post_dtfhByAddrResStatus = "";
        this.post_dtfhByAddrResType = "";
        break;
      case 'get_dtfhByLocRes':
        this.get_dtfhByLocUri = "";
        this.get_dtfhByLocRes = null;
        this.get_dtfhByLocResStatus = "";
        this.get_dtfhByLocResType = "";
        break;
      case 'post_dtfhByLocRes':
        this.post_dtfhByLocUri = "";
        this.post_dtfhByLocRes = null;
        this.post_dtfhByLocResStatus = "";
        this.post_dtfhByLocResType = "";
        break;
    }
  };
  changeFsbaRadio = function () {
    if (this.fsbaRadio == 'travelTime') {
      this.fsbaTravelTime = '150';
      this.fsbaTravelTimeUnit = 'minutes';
      this.fsbaTravelDistance = '';
      this.fsbaTravelDistanceUnit = '';
    }
    if (this.fsbaRadio == 'travelDistance') {
      this.fsbaTravelTime = '';
      this.fsbaTravelTimeUnit = '';
      this.fsbaTravelDistance = '100';
      this.fsbaTravelDistanceUnit = 'Miles';
    }
  };
  changeFsblRadio = function () {
    if (this.fsblRadio == 'travelTime') {
      this.fsblTravelTime = '150';
      this.fsblTravelTimeUnit = 'minutes';
      this.fsblTravelDistance = '';
      this.fsblTravelDistanceUnit = '';
    }
    if (this.fsblRadio == 'travelDistance') {
      this.fsblTravelTime = '';
      this.fsblTravelTimeUnit = '';
      this.fsblTravelDistance = '100';
      this.fsblTravelDistanceUnit = 'Miles';
    }
  };
  public geoRiskTryApiCrimeRiskByAddraddress = '121 W Main St, Durant, OK 74701, USA';
  public geoRiskTryApiCrimeRiskByAddrincludeGeometry = 'N';
  public geoRiskTryApiCrimeRiskByAddrDataType = 'json';
  public get_crimeByAddrUri = "";
  public get_crimeByAddrRes = null;
  public get_crimeByAddrResStatus = "";
  public get_crimeByAddrResType = "";
  tryApiGeoRiskCrimeRiskByAddr = function (btnId) {
    var address = this.geoRiskTryApiCrimeRiskByAddraddress;
    var typeArr = [];
    for (var item of this.crimeRiskByAddrtype) {
      typeArr.push(item.name);
    }
    var type = typeArr.join(',');
    var includeGeometry = this.geoRiskTryApiCrimeRiskByAddrincludeGeometry;
    var dataType = this.geoRiskTryApiCrimeRiskByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (type != '') {
      tmpData['type'] = type;
    }
    tmpData['includeGeometry'] = includeGeometry;
    if (address != '') {
      var methodName = '/tryApiGeoRiskCrimeRiskByAddr';
      let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Crime Risk By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_crimeByAddrUri = requestUri;
          this.get_crimeByAddrResType = dataType;
          if(this.get_crimeByAddrResStatus.includes('429')){
            this.get_crimeByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_crimeByAddrResStatus = '200 success';
            this.get_crimeByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_crimeByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_crimeByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_crimeByAddrUri = "";
  public post_crimeByAddrRes = null;
  public post_crimeByAddrResStatus = "";
  public post_crimeByAddrResType = "";
  geoRiskTryApiPostCrimeRiskByAddr = function (btnId) {
    var postData = this.crimeRiskByAddrJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostCrimeRiskByAddr';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Crime Risk By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_crimeByAddrUri = requestUri;
          this.post_crimeByAddrResType = 'json';
          if(this.post_crimeByAddrResStatus.includes('429')){
            this.post_crimeByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_crimeByAddrResStatus = '200 success';
            this.post_crimeByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_crimeByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_crimeByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoRiskTryApiCrimeRiskByLoclatitude = 35.0118;
  public geoRiskTryApiCrimeRiskByLoclongitude = -81.9571;
  public geoRiskTryApiCrimeRiskByLocincludeGeometry = 'N';
  public geoRiskTryApiCrimeRiskByLocDataType = 'json';
  public get_crimeByLocUri = "";
  public get_crimeByLocRes = null;
  public get_crimeByLocResStatus = "";
  public get_crimeByLocResType = "";
  tryApiGeoRiskCrimeRiskByLoc = function (btnId) {
    var latitude: any = this.geoRiskTryApiCrimeRiskByLoclatitude;
    var longitude: any = this.geoRiskTryApiCrimeRiskByLoclongitude;
    var typeArr = [];
    for (var item of this.crimeRiskByLoctype) {
      typeArr.push(item.name);
    }
    var type = typeArr.join(',');
    var dataType = this.geoRiskTryApiCrimeRiskByLocDataType;
    var includeGeometry = this.geoRiskTryApiCrimeRiskByLocincludeGeometry;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (type != '') {
      tmpData['type'] = type;
    }
    tmpData['includeGeometry'] = includeGeometry;
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoRiskCrimeRiskByLoc';
        let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Crime Risk By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_crimeByLocUri = requestUri;
            this.get_crimeByLocResType = dataType;
            if(this.get_crimeByLocResStatus.includes('429')){
              this.get_crimeByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_crimeByLocResStatus = '200 success';
              this.get_crimeByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_crimeByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_crimeByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_crimeByLocUri = "";
  public post_crimeByLocRes = null;
  public post_crimeByLocResStatus = "";
  public post_crimeByLocResType = "";
  geoRiskTryApiPostCrimeRiskByLoc = function (btnId) {
    var postData = this.crimeRiskByLocJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostCrimeRiskByLoc';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Crime Risk By Location');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_crimeByLocUri = requestUri;
          this.post_crimeByLocResType = 'json';
          if(this.post_crimeByLocResStatus.includes('429')){
            this.post_crimeByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_crimeByLocResStatus = '200 success';
            this.post_crimeByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_crimeByLocResStatus = `${error.status} ${error.statusText}`;
              this.post_crimeByLocRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoRiskTryApiEarthquakeByAddraddress = '121 W Main St, Durant, OK 74701, USA';
  public geoRiskTryApiEarthquakeByAddrincludeGeometry = 'N';
  public geoRiskTryApiEarthquakeByAddrDataType = 'json';
  public get_quakeByAddrUri = "";
  public get_quakeByAddrRes = null;
  public get_quakeByAddrResStatus = "";
  public get_quakeByAddrResType = "";
  tryApiGeoRiskEarthquakeByAddr = function (btnId) {
    var address = this.geoRiskTryApiEarthquakeByAddraddress;
    var richterArr = [];
    for (var item of this.earthquakeByAddrrichterValue) {
      richterArr.push(item.name);
    }
    var richterValue = richterArr.join(',');
    var includeGeometry = this.geoRiskTryApiEarthquakeByAddrincludeGeometry;
    var dataType = this.geoRiskTryApiEarthquakeByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (richterValue != '') {
      tmpData['richterValue'] = richterValue;
    }
    tmpData['includeGeometry'] = includeGeometry;
    if (address != '') {
      var methodName = '/tryApiGeoRiskEarthquakeByAddr';
      let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Earthquake Risk By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_quakeByAddrUri = requestUri;
          this.get_quakeByAddrResType = dataType;
          if(this.get_quakeByAddrResStatus.includes('429')){
            this.get_quakeByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_quakeByAddrResStatus = '200 success';
            this.get_quakeByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_quakeByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_quakeByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_quakeByAddrUri = "";
  public post_quakeByAddrRes = null;
  public post_quakeByAddrResStatus = "";
  public post_quakeByAddrResType = "";
  geoRiskTryApiPostEarthquakeRiskByAddr = function (btnId) {
    var postData = this.earthquakeRiskByAddrJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostEarthquakeRiskByAddr';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Earthquake Risk By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_quakeByAddrUri = requestUri;
          this.post_quakeByAddrResType = 'json';
          if(this.post_quakeByAddrResStatus.includes('429')){
            this.post_quakeByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_quakeByAddrResStatus = '200 success';
            this.post_quakeByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_quakeByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_quakeByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoRiskTryApiEarthquakeByLoclatitude = 37.097559;
  public geoRiskTryApiEarthquakeByLoclongitude = -109.212223;
  public geoRiskTryApiEarthquakeByLocincludeGeometry = 'N';
  public geoRiskTryApiEarthquakeByLocDataType = 'json';
  public get_quakeByLocUri = "";
  public get_quakeByLocRes = null;
  public get_quakeByLocResStatus = "";
  public get_quakeByLocResType = "";
  tryApiGeoRiskEarthquakeByLoc = function (btnId) {
    var latitude: any = this.geoRiskTryApiEarthquakeByLoclatitude;
    var longitude: any = this.geoRiskTryApiEarthquakeByLoclongitude;
    var richterArr = [];
    for (var item of this.earthquakeByLocrichterValue) {
      richterArr.push(item.name);
    }
    var richterValue = richterArr.join(',');
    var includeGeometry = this.geoRiskTryApiEarthquakeByLocincludeGeometry;
    var dataType = this.geoRiskTryApiEarthquakeByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (richterValue != '') {
      tmpData['richterValue'] = richterValue;
    }
    tmpData['includeGeometry'] = includeGeometry;
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoRiskEarthquakeByLoc';
        let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Earthquake Risk By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_quakeByLocUri = requestUri;
            this.get_quakeByLocResType = dataType;
            if(this.get_quakeByLocResStatus.includes('429')){
              this.get_quakeByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_quakeByLocResStatus = '200 success';
              this.get_quakeByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_quakeByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_quakeByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_quakeByLocUri = "";
  public post_quakeByLocRes = null;
  public post_quakeByLocResStatus = "";
  public post_quakeByLocResType = "";
  geoRiskTryApiPostEarthquakeRiskByLoc = function (btnId) {
    var postData = this.earthquakeRiskByLocJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostEarthquakeRiskByLoc';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Earthquake Risk By Location');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_quakeByLocUri = requestUri;
          this.post_quakeByLocResType = 'json';
          if(this.post_quakeByLocResStatus.includes('429')){
            this.post_quakeByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_quakeByLocResStatus = '200 success';
            this.post_quakeByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_quakeByLocResStatus = `${error.status} ${error.statusText}`;
              this.post_quakeByLocRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoRiskTryApiEarthquakeHistorypostCode = '92561';
  public geoRiskTryApiEarthquakeHistorystartDate = '';
  public geoRiskTryApiEarthquakeHistoryendDate = '';
  public geoRiskTryApiEarthquakeHistoryminMagnitude = 1;
  public geoRiskTryApiEarthquakeHistorymaxMagnitude = 3;
  public geoRiskTryApiEarthquakeHistorymaxCandidates = 5;
  public geoRiskTryApiEarthquakeHistoryDataType = 'json';
  public get_quakeHisUri = "";
  public get_quakeHisRes = null;
  public get_quakeHisResStatus = "";
  public get_quakeHisResType = "";
  tryApiGeoRiskEarthquakeHistory = function (btnId) {
    var postCode: any = this.geoRiskTryApiEarthquakeHistorypostCode;
    var startDate = this.geoRiskTryApiEarthquakeHistorystartDate;
    var endDate = this.geoRiskTryApiEarthquakeHistoryendDate;
    var minMagnitude = this.geoRiskTryApiEarthquakeHistoryminMagnitude;
    var maxMagnitude = this.geoRiskTryApiEarthquakeHistorymaxMagnitude;
    var maxCandidates = this.geoRiskTryApiEarthquakeHistorymaxCandidates;
    var dataType = this.geoRiskTryApiEarthquakeHistoryDataType;
    var tmpData = {
      postCode: postCode,
      dataType: dataType
    };
    if (startDate != '') {
      tmpData['startDate'] = startDate;
    }
    if (endDate != '') {
      tmpData['endDate'] = endDate;
    }
    if (minMagnitude != '') {
      tmpData['minMagnitude'] = minMagnitude;
    }
    if (maxMagnitude != '') {
      tmpData['maxMagnitude'] = maxMagnitude;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (postCode != '' && !isNaN(postCode)) {
      var methodName = '/tryApiGeoRiskEarthquakeHistory';
      let requestUri = this.liapiservice.getCompleteUri('risks', 'Earthquake History', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_quakeHisUri = requestUri;
          this.get_quakeHisResType = dataType;
          if(this.get_quakeHisResStatus.includes('429')){
            this.get_quakeHisUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_quakeHisResStatus = '200 success';
            this.get_quakeHisRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_quakeHisResStatus = `${error.status} ${error.statusText}`;
              this.get_quakeHisRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoRiskTryApiFloodRiskByAddraddress = '121 W Main St, Durant, OK 74701, USA';
  public geoRiskTryApiFloodRiskByAddrincludeZoneDesc = 'Y';
  public geoRiskTryApiFloodRiskByAddrincludeGeometry = 'N';
  public geoRiskTryApiFloodRiskByAddrDataType = 'json';
  public get_floodByAddrUri = "";
  public get_floodByAddrRes = null;
  public get_floodByAddrResStatus = "";
  public get_floodByAddrResType = "";
  tryApiGeoRiskFloodRiskByAddr = function (btnId) {
    var address = this.geoRiskTryApiFloodRiskByAddraddress;
    var includeZoneDesc = this.geoRiskTryApiFloodRiskByAddrincludeZoneDesc;
    var includeGeometry = this.geoRiskTryApiFloodRiskByAddrincludeGeometry;
    var dataType = this.geoRiskTryApiFloodRiskByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (includeZoneDesc != '') {
      tmpData['includeZoneDesc'] = includeZoneDesc;
    }
    tmpData['includeGeometry'] = includeGeometry;
    if (address != '') {
      var methodName = '/tryApiGeoRiskFloodRiskByAddr';
      let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Flood Risk By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_floodByAddrUri = requestUri;
          this.get_floodByAddrResType = dataType;
          if(this.get_floodByAddrResStatus.includes('429')){
            this.get_floodByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_floodByAddrResStatus = '200 success';
            this.get_floodByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_floodByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_floodByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_floodByAddrUri = "";
  public post_floodByAddrRes = null;
  public post_floodByAddrResStatus = "";
  public post_floodByAddrResType = "";
  geoRiskTryApiPostFloodRiskByAddr = function (btnId) {
    var postData = this.floodRiskByAddrJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostFloodRiskByAddr';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Flood Risk By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_floodByAddrUri = requestUri;
          this.post_floodByAddrResType = 'json';
          if(this.post_floodByAddrResStatus.includes('429')){
            this.post_floodByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_floodByAddrResStatus = '200 success';
            this.post_floodByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_floodByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_floodByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoRiskTryApiFloodRiskByLoclatitude = 35.0118;
  public geoRiskTryApiFloodRiskByLoclongitude = -81.9571;
  public geoRiskTryApiFloodRiskByLocincludeZoneDesc = 'Y';
  public geoRiskTryApiFloodRiskByLocincludeGeometry = 'N';
  public geoRiskTryApiFloodRiskByLocDataType = 'json';
  public get_floodByLocUri = "";
  public get_floodByLocRes = null;
  public get_floodByLocResStatus = "";
  public get_floodByLocResType = "";
  tryApiGeoRiskFloodRiskByLoc = function (btnId) {
    var latitude: any = this.geoRiskTryApiFloodRiskByLoclatitude;
    var longitude: any = this.geoRiskTryApiFloodRiskByLoclongitude;
    var includeZoneDesc = this.geoRiskTryApiFloodRiskByLocincludeZoneDesc;
    var includeGeometry = this.geoRiskTryApiFloodRiskByLocincludeGeometry;
    var dataType = this.geoRiskTryApiFloodRiskByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (includeZoneDesc != '') {
      tmpData['includeZoneDesc'] = includeZoneDesc;
    }
    tmpData['includeGeometry'] = includeGeometry;
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoRiskFloodRiskByLoc';
        let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Flood Risk By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_floodByLocUri = requestUri;
            this.get_floodByLocResType = dataType;
            if(this.get_floodByLocResStatus.includes('429')){
              this.get_floodByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_floodByLocResStatus = '200 success';
              this.get_floodByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_floodByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_floodByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_floodByLocUri = "";
  public post_floodByLocRes = null;
  public post_floodByLocResStatus = "";
  public post_floodByLocResType = "";
  geoRiskTryApiPostFloodRiskByLoc = function (btnId) {
    var postData = this.floodRiskByLocJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostFloodRiskByLoc';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Flood Risk By Location');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_floodByLocUri = requestUri;
          this.post_floodByLocResType = 'json';
          if(this.post_floodByLocResStatus.includes('429')){
            this.post_floodByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_floodByLocResStatus = '200 success';
            this.post_floodByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_floodByLocResStatus = `${error.status} ${error.statusText}`;
              this.post_floodByLocRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoRiskTryApiFireRiskByAddraddress = '229.7 Parks Hwy, Denali Natl Park, AK 99755, United States';
  public geoRiskTryApiFireRiskByAddrincludeGeometry = 'N';
  public geoRiskTryApiFireRiskByAddrDataType = 'json';
  public get_fireByAddrUri = "";
  public get_fireByAddrRes = null;
  public get_fireByAddrResStatus = "";
  public get_fireByAddrResType = "";
  tryApiGeoRiskFireRiskByAddr = function (btnId) {
    var address = this.geoRiskTryApiFireRiskByAddraddress;
    var includeGeometry = this.geoRiskTryApiFireRiskByAddrincludeGeometry;
    var dataType = this.geoRiskTryApiFireRiskByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    tmpData['includeGeometry'] = includeGeometry;
    if (address != '') {
      var methodName = '/tryApiGeoRiskFireRiskByAddr';
      let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Fire Risk By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_fireByAddrUri = requestUri;
          this.get_fireByAddrResType = dataType;
          if(this.get_fireByAddrResStatus.includes('429')){
            this.get_fireByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_fireByAddrResStatus = '200 success';
            this.get_fireByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_fireByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_fireByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_fireByAddrUri = "";
  public post_fireByAddrRes = null;
  public post_fireByAddrResStatus = "";
  public post_fireByAddrResType = "";
  geoRiskTryApiPostFireRiskByAddr = function (btnId) {
    var postData = this.fireRiskByAddrJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostFireRiskByAddr';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Fire Risk By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_fireByAddrUri = requestUri;
          this.post_fireByAddrResType = 'json';
          if(this.post_fireByAddrResStatus.includes('429')){
            this.post_fireByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_fireByAddrResStatus = '200 success';
            this.post_fireByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_fireByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_fireByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoRiskTryApiFireRiskByLoclatitude = 40.45393;
  public geoRiskTryApiFireRiskByLoclongitude = -123.977847;
  public geoRiskTryApiFireRiskByLocincludeGeometry = 'N';
  public geoRiskTryApiFireRiskByLocDataType = 'json';
  public get_fireByLocUri = "";
  public get_fireByLocRes = null;
  public get_fireByLocResStatus = "";
  public get_fireByLocResType = "";
  tryApiGeoRiskFireRiskByLoc = function (btnId) {
    var latitude: any = this.geoRiskTryApiFireRiskByLoclatitude;
    var longitude: any = this.geoRiskTryApiFireRiskByLoclongitude;
    var includeGeometry = this.geoRiskTryApiFireRiskByLocincludeGeometry;
    var dataType = this.geoRiskTryApiFireRiskByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    tmpData['includeGeometry'] = includeGeometry;
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoRiskFireRiskByLoc';
        let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Fire Risk By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_fireByLocUri = requestUri;
            this.get_fireByLocResType = dataType;
            if(this.get_fireByLocResStatus.includes('429')){
              this.get_fireByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_fireByLocResStatus = '200 success';
              this.get_fireByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_fireByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_fireByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_fireByLocUri = "";
  public post_fireByLocRes = null;
  public post_fireByLocResStatus = "";
  public post_fireByLocResType = "";
  geoRiskTryApiPostFireRiskByLoc = function (btnId) {
    var postData = this.fireRiskByLocJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostFireRiskByLoc';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Fire Risk By Location');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_fireByLocUri = requestUri;
          this.post_fireByLocResType = 'json';
          if(this.post_fireByLocResStatus.includes('429')){
            this.post_fireByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_fireByLocResStatus = '200 success';
            this.post_fireByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_fireByLocResStatus = `${error.status} ${error.statusText}`;
              this.post_fireByLocRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoRiskTryApiFireRiskHistorypostCode = '92561';
  public geoRiskTryApiFireRiskHistorystartDate = '';
  public geoRiskTryApiFireRiskHistoryendDate = '';
  public geoRiskTryApiFireRiskHistorymaxCandidates = 5;
  public geoRiskTryApiFireRiskHistoryDataType = 'json';
  public get_fireHisUri = "";
  public get_fireHisRes = null;
  public get_fireHisResStatus = "";
  public get_fireHisResType = "";
  tryApiGeoRiskFireRiskHistory = function (btnId) {
    var postCode: any = this.geoRiskTryApiFireRiskHistorypostCode;
    var startDate = this.geoRiskTryApiFireRiskHistorystartDate;
    var endDate = this.geoRiskTryApiFireRiskHistoryendDate;
    var maxCandidates = this.geoRiskTryApiFireRiskHistorymaxCandidates;
    var dataType = this.geoRiskTryApiFireRiskHistoryDataType;
    var tmpData = {
      postCode: postCode,
      dataType: dataType
    };
    if (startDate != '') {
      tmpData['startDate'] = startDate;
    }
    if (endDate != '') {
      tmpData['endDate'] = endDate;
    }
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (postCode != '' && !isNaN(postCode)) {
      var methodName = '/tryApiGeoRiskFireRiskHistory';
      let requestUri = this.liapiservice.getCompleteUri('risks', 'Fire History', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_fireHisUri = requestUri;
          this.get_fireHisResType = dataType;
          if(this.get_fireHisResStatus.includes('429')){
            this.get_fireHisUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_fireHisResStatus = '200 success';
            this.get_fireHisRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_fireHisResStatus = `${error.status} ${error.statusText}`;
              this.get_fireHisRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoRiskTryApiFireStationByAddraddress = '121 W Main St, Durant, OK 74701, USA';
  public geoRiskTryApiFireStationByAddrmaxCandidates = 3;
  public geoRiskTryApiFireStationByAddrsortBy = 'time';
  public geoRiskTryApiFireStationByAddrhistoricTrafficTimeBucket = 'None';
  public geoRiskTryApiFireStationByAddrDataType = 'json';
  public get_stationByAddrUri = "";
  public get_stationByAddrRes = null;
  public get_stationByAddrResStatus = "";
  public get_stationByAddrResType = "";
  tryApiGeoRiskFireStationByAddr = function (btnId) {
    var address = this.geoRiskTryApiFireStationByAddraddress;
    var maxCandidates = this.geoRiskTryApiFireStationByAddrmaxCandidates;
    var sortBy = this.geoRiskTryApiFireStationByAddrsortBy;
    var historicTrafficTimeBucket = this.geoRiskTryApiFireStationByAddrhistoricTrafficTimeBucket;
    var dataType = this.geoRiskTryApiFireStationByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    };
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (this.fsbaTravelTime != '') {
      tmpData['travelTime'] = this.fsbaTravelTime;
    }
    if (this.fsbaTravelTimeUnit != '') {
      tmpData['travelTimeUnit'] = this.fsbaTravelTimeUnit;
    }
    if (this.fsbaTravelDistance != '') {
      tmpData['travelDistance'] = this.fsbaTravelDistance;
    }
    if (this.fsbaTravelDistanceUnit != '') {
      tmpData['travelDistanceUnit'] = this.fsbaTravelDistanceUnit;
    }
    if (sortBy != '') {
      tmpData['sortBy'] = sortBy;
    }
    if (historicTrafficTimeBucket != '') {
      tmpData['historicTrafficTimeBucket'] = historicTrafficTimeBucket;
    }
    if (address != '') {
      var methodName = '/tryApiGeoRiskFireStationByAddr';
      let requestUri = this.liapiservice.getCompleteUri('risks', 'Fire Station By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_stationByAddrUri = requestUri;
          this.get_stationByAddrResType = dataType;
          if(this.get_stationByAddrResStatus.includes('429')){
            this.get_stationByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_stationByAddrResStatus = '200 success';
            this.get_stationByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_stationByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_stationByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoRiskTryApiFireStationByLoclatitude = 35.0118;
  public geoRiskTryApiFireStationByLoclongitude = -81.9571;
  public geoRiskTryApiFireStationByLocmaxCandidates = 3;
  public geoRiskTryApiFireStationByLocsortBy = 'time';
  public geoRiskTryApiFireStationByLochistoricTrafficTimeBucket = 'None';
  public geoRiskTryApiFireStationByLocDataType = 'json';
  public get_stationByLocUri = "";
  public get_stationByLocRes = null;
  public get_stationByLocResStatus = "";
  public get_stationByLocResType = "";
  tryApiGeoRiskFireStationByLoc = function (btnId) {
    var latitude: any = this.geoRiskTryApiFireStationByLoclatitude;
    var longitude: any = this.geoRiskTryApiFireStationByLoclongitude;
    var maxCandidates = this.geoRiskTryApiFireStationByLocmaxCandidates;
    var sortBy = this.geoRiskTryApiFireStationByLocsortBy;
    var historicTrafficTimeBucket = this.geoRiskTryApiFireStationByLochistoricTrafficTimeBucket;
    var dataType = this.geoRiskTryApiFireStationByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (this.fsblTravelTime != '') {
      tmpData['travelTime'] = this.fsblTravelTime;
    }
    if (this.fsblTravelTimeUnit != '') {
      tmpData['travelTimeUnit'] = this.fsblTravelTimeUnit;
    }
    if (this.fsblTravelDistance != '') {
      tmpData['travelDistance'] = this.fsblTravelDistance;
    }
    if (this.fsblTravelDistanceUnit != '') {
      tmpData['travelDistanceUnit'] = this.fsblTravelDistanceUnit;
    }
    if (sortBy != '') {
      tmpData['sortBy'] = sortBy;
    }
    if (historicTrafficTimeBucket != '') {
      tmpData['historicTrafficTimeBucket'] = historicTrafficTimeBucket;
    }
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoRiskFireStationByLoc';
        let requestUri = this.liapiservice.getCompleteUri('risks', 'Fire Station By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_stationByLocUri = requestUri;
            this.get_stationByLocResType = dataType;
            if(this.get_stationByLocResStatus.includes('429')){
              this.get_stationByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_stationByLocResStatus = '200 success';
              this.get_stationByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_stationByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_stationByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public geoRiskTryApiDisToFloodHazardByAddraddress = '2550 Riverside Dr, Susanville, CA 96130, USA';
  public geoRiskTryApiDisToFloodHazardByAddrmaxCandidates = 1;
  public geoRiskTryApiDisToFloodHazardByAddrwaterBodyType = 'all';
  public geoRiskTryApiDisToFloodHazardByAddrsearchDistance = 20;
  public geoRiskTryApiDisToFloodHazardByAddrsearchDistanceUnit = 'miles';
  public geoRiskTryApiDisToFloodHazardByAddrDataType = 'json';
  public get_dtfhByAddrUri = "";
  public get_dtfhByAddrRes = null;
  public get_dtfhByAddrResStatus = "";
  public get_dtfhByAddrResType = "";
  tryApiGeoRiskDisToFloodHazardByAddr = function (btnId) {
    var address = this.geoRiskTryApiDisToFloodHazardByAddraddress;
    var maxCandidates = this.geoRiskTryApiDisToFloodHazardByAddrmaxCandidates;
    var waterBodyType = this.geoRiskTryApiDisToFloodHazardByAddrwaterBodyType;
    var searchDistance = this.geoRiskTryApiDisToFloodHazardByAddrsearchDistance;
    var searchDistanceUnit = this.geoRiskTryApiDisToFloodHazardByAddrsearchDistanceUnit;
    var dataType = this.geoRiskTryApiDisToFloodHazardByAddrDataType;
    var tmpData = {
      address: address,
      dataType: dataType
    }; (address);
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (waterBodyType != '') {
      tmpData['waterBodyType'] = waterBodyType;
    }
    if (searchDistance != '') {
      tmpData['searchDistance'] = searchDistance;
    }
    if (searchDistanceUnit != '') {
      tmpData['searchDistanceUnit'] = searchDistanceUnit;
    }
    if (address != '') {
      var methodName = '/tryApiGeoRiskDisToFloodHazardByAddr';
      let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Distance To Flood Hazard By Address', tmpData, []);
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.get_dtfhByAddrUri = requestUri;
          this.get_dtfhByAddrResType = dataType;
          if(this.get_dtfhByAddrResStatus.includes('429')){
            this.get_dtfhByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.get_dtfhByAddrResStatus = '200 success';
            this.get_dtfhByAddrRes = this.liapiservice.getFormattedResponse(dataType, success);
          },
          error => {
            if (error.status && error.statusText) {
              this.get_dtfhByAddrResStatus = `${error.status} ${error.statusText}`;
              this.get_dtfhByAddrRes = this.liapiservice.getFormattedResponse(dataType, error.error);
            }
          }
        );
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_dtfhByAddrUri = "";
  public post_dtfhByAddrRes = null;
  public post_dtfhByAddrResStatus = "";
  public post_dtfhByAddrResType = "";
  geoRiskTryApiPostDisToFloodHazardByAddr = function (btnId) {
    var postData = this.disToFloodHazardByAddrJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostDisToFloodHazardByAddr';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Distance To Flood Hazard By Address');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_dtfhByAddrUri = requestUri;
          this.post_dtfhByAddrResType = 'json';
          if(this.post_dtfhByAddrResStatus.includes('429')){
            this.post_dtfhByAddrUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_dtfhByAddrResStatus = '200 success';
            this.post_dtfhByAddrRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_dtfhByAddrResStatus = `${error.status} ${error.statusText}`;
              this.post_dtfhByAddrRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  public geoRiskTryApiDisToFloodHazardByLoclatitude = 38.8976;
  public geoRiskTryApiDisToFloodHazardByLoclongitude = -77.0365;
  public geoRiskTryApiDisToFloodHazardByLocmaxCandidates = 1;
  public geoRiskTryApiDisToFloodHazardByLocwaterBodyType = 'all';
  public geoRiskTryApiDisToFloodHazardByLocsearchDistance = 20;
  public geoRiskTryApiDisToFloodHazardByLocsearchDistanceUnit = 'miles';
  public geoRiskTryApiDisToFloodHazardByLocDataType = 'json';
  public get_dtfhByLocUri = "";
  public get_dtfhByLocRes = null;
  public get_dtfhByLocResStatus = "";
  public get_dtfhByLocResType = "";
  tryApiGeoRiskDisToFloodHazardByLoc = function (btnId) {
    var latitude: any = this.geoRiskTryApiDisToFloodHazardByLoclatitude;
    var longitude: any = this.geoRiskTryApiDisToFloodHazardByLoclongitude;
    var maxCandidates = this.geoRiskTryApiDisToFloodHazardByLocmaxCandidates;
    var waterBodyType = this.geoRiskTryApiDisToFloodHazardByLocwaterBodyType;
    var searchDistance = this.geoRiskTryApiDisToFloodHazardByLocsearchDistance;
    var searchDistanceUnit = this.geoRiskTryApiDisToFloodHazardByLocsearchDistanceUnit;
    var dataType = this.geoRiskTryApiDisToFloodHazardByLocDataType;
    var tmpData = {
      latitude: latitude,
      longitude: longitude,
      dataType: dataType
    };
    if (maxCandidates != '') {
      tmpData['maxCandidates'] = maxCandidates;
    }
    if (waterBodyType != '') {
      tmpData['waterBodyType'] = waterBodyType;
    }
    if (searchDistance != '') {
      tmpData['searchDistance'] = searchDistance;
    }
    if (searchDistanceUnit != '') {
      tmpData['searchDistanceUnit'] = searchDistanceUnit;
    }
    if (latitude != '' && longitude != '') {
      if (!isNaN(latitude) && !isNaN(longitude)) {
        var methodName = '/tryApiGeoRiskDisToFloodHazardByLoc';
        let requestUri = this.liapiservice.getCompleteUri('risks', 'Get Distance To Flood Hazard By Location', tmpData, []);
        this.liapiservice.disableTryItBtn(btnId);
        this.liapiservice.geoApisTryApiGetCall(methodName, tmpData).pipe(
          finalize(() => {
            this.liapiservice.enableTryItBtn(btnId);
            this.get_dtfhByLocUri = requestUri;
            this.get_dtfhByLocResType = dataType;
            if(this.get_dtfhByLocResStatus.includes('429')){
              this.get_dtfhByLocUri = '';
              this.liapiservice.showPrimeInfo();
            }
          }
          )).subscribe(
            success => {
              this.get_dtfhByLocResStatus = '200 success';
              this.get_dtfhByLocRes = this.liapiservice.getFormattedResponse(dataType, success);
            },
            error => {
              if (error.status && error.statusText) {
                this.get_dtfhByLocResStatus = `${error.status} ${error.statusText}`;
                this.get_dtfhByLocRes = this.liapiservice.getFormattedResponse(dataType, error.error);
              }
            }
          );
      }
      else {
        this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
      }
    }
    else {
      this.liapiservice.showPrimeErr('Parameters are incorrect or missing.');
    }
  };
  public post_dtfhByLocUri = "";
  public post_dtfhByLocRes = null;
  public post_dtfhByLocResStatus = "";
  public post_dtfhByLocResType = "";
  geoRiskTryApiPostDisToFloodHazardByLoc = function (btnId) {
    var postData = this.disToFloodHazardByLocJson;
    if (!postData) {
      this.liapiservice.showPrimeErr('Request cannot be blank.');
    }
    else {
      var methodName = '/geoRiskTryApiPostDisToFloodHazardByLoc';
      let requestUri = this.liapiservice.getCompleteUriPost('risks', 'Post Distance To Flood Hazard By Location');
      this.liapiservice.disableTryItBtn(btnId);
      this.liapiservice.geoAPIsTryApiBatchCall(methodName, postData).pipe(
        finalize(() => {
          this.liapiservice.enableTryItBtn(btnId);
          this.post_dtfhByLocUri = requestUri;
          this.post_dtfhByLocResType = 'json';
          if(this.post_dtfhByLocResStatus.includes('429')){
            this.post_dtfhByLocUri = '';
            this.liapiservice.showPrimeInfo();
          }
        }
        )).subscribe(
          success => {
            this.post_dtfhByLocResStatus = '200 success';
            this.post_dtfhByLocRes = this.liapiservice.getFormattedResponse('json', success);
          },
          error => {
            if (error.status && error.statusText) {
              this.post_dtfhByLocResStatus = `${error.status} ${error.statusText}`;
              this.post_dtfhByLocRes = this.liapiservice.getFormattedResponse('json', error.error);
            }
          }
        );
    }
  };
  formatJsonInputs = function () {
    this.crimeRiskByAddrJson = JSON.stringify(JSON.parse(this.crimeRiskByAddrJson), undefined, 4);
    this.crimeRiskByLocJson = JSON.stringify(JSON.parse(this.crimeRiskByLocJson), undefined, 4);
    this.earthquakeRiskByAddrJson = JSON.stringify(JSON.parse(this.earthquakeRiskByAddrJson), undefined, 4);
    this.earthquakeRiskByLocJson = JSON.stringify(JSON.parse(this.earthquakeRiskByLocJson), undefined, 4);
    this.floodRiskByAddrJson = JSON.stringify(JSON.parse(this.floodRiskByAddrJson), undefined, 4);
    this.floodRiskByLocJson = JSON.stringify(JSON.parse(this.floodRiskByLocJson), undefined, 4);
    this.fireRiskByAddrJson = JSON.stringify(JSON.parse(this.fireRiskByAddrJson), undefined, 4);
    this.fireRiskByLocJson = JSON.stringify(JSON.parse(this.fireRiskByLocJson), undefined, 4);
    this.disToFloodHazardByAddrJson = JSON.stringify(JSON.parse(this.disToFloodHazardByAddrJson), undefined, 4);
    this.disToFloodHazardByLocJson = JSON.stringify(JSON.parse(this.disToFloodHazardByLocJson), undefined, 4);
  };
}
