import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LiapiService } from '../service/liapi.service';
import $ from 'jquery';
import * as L from "leaflet";
import { ResourceService } from '../service/resource.service';

@Component({
  selector: 'typeahead-demo',
  templateUrl: './typeaheaddemo.component.html'
})
export class TypeaheadDemoComponent implements OnInit {
  public searchconCountry = {
    "name": "UNITED STATES OF AMERICA",
    "code": "USA",
    "label": "UNITED STATES OF AMERICA (USA)"
  };
  public searchconCountryCode = 'USA';
  public searchIpAddress = '';
  public autoDetectLocation = true;
  public returnAdminAreasOnly = 'N';
  public returnAdminAreasOnlyIp = 'N';
  public searchOnAddressNumber ='N';
  public isadminDisabled = false;
  public isaddressDisabled =false;
  public isadminDisabledIp = false;
  public isaddressDisabledIp =false;
  public geoSearchSampleDemoAdminAreasOnly = false;
  public geoSearchSampleDemoAdminAreasOnlyIp = false;
  public geoSearchSampleDemosearchOnAddressNumber =false;
  public geoSearchSampleDemosearchOnAddressNumberIp =false;
  public bycountrylocobj = null;
  public byiplocobj = null;
  public searchRadius = 52800;
  public searchRadiusUnit = 'feet';
  public mapOptions;
  public map;
  public mapLayers: L.Layer[];
  public markerIcon = {
    icon: L.icon({
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -40],
      iconUrl: '/assets/images/defaultmarker.svg'
    })
  };
  public newMarker;
  public activetab = 'locations';
  countries: any[];
  filteredCountries: any[];
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private liapiservice: LiapiService, private resourceService: ResourceService) { };
  ngOnInit() {
    this.liapiservice.getTypeaheadCountries().subscribe(
      success => {
        this.countries = success;
      }
    );
    let mapFullURL = JSON.parse(`[{"val": "${localStorage.getItem("mapFullURL")}"}]`)[0].val;
    this.initializeMapOptions(mapFullURL);
  };
  filterCountry = function (event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      let country = this.countries[i];
      if (country.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(country);
      }
    }
    this.filteredCountries = filtered;
  };
  changeSearchCheckbox = function () {
    if(this.geoSearchSampleDemoAdminAreasOnly){
      this.returnAdminAreasOnly = 'Y';
      this.isaddressDisabled=true;
    }
    else{
      this.returnAdminAreasOnly = 'N';
      this.isaddressDisabled=false;
    }
  };
  changeSearchCheckboxIp = function () {
    if(this.geoSearchSampleDemoAdminAreasOnlyIp){
      this.returnAdminAreasOnlyIp = 'Y';
      this.isaddressDisabledIp=true;
    }
    else{
      this.returnAdminAreasOnlyIp = 'N';
      this.isaddressDisabledIp=false;

    }
  };
  changeSearchAddressNumber = function () {

    if(this.geoSearchSampleDemosearchOnAddressNumber){
      this.searchOnAddressNumber = 'Y';
      this.isadminDisabled=true;



    }
    else{
      this.searchOnAddressNumber = 'N';
      this.isadminDisabled=false;

    }
  };

  changeSearchAddressNumberIp = function () {
    if(this.geoSearchSampleDemosearchOnAddressNumberIp){
      this.searchOnAddressNumber = 'Y';
      this.isadminDisabledIp=true;

    }
    else{
      this.searchOnAddressNumber = 'N';
      this.isadminDisabledIp=false;

    }
  };


  ngDoCheck() {
    if (this.searchconCountry.code) {
      this.searchconCountryCode = this.searchconCountry.code;
    }
    else {
      this.searchconCountryCode = this.searchconCountry.toString();
    }
  };
  private initializeMapOptions(mapFullURL) {
    this.mapOptions = {
      center: L.latLng(39.828127, -98.579404),
      zoom: 4
    };
    this.mapLayers = [L.tileLayer(mapFullURL + "bronze", { maxZoom: 18, attribution: '<a rel="noopener noreferrer" target="_blank" href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>' })];
  }
  onMapReady(map: L.Map) {
    this.map = map;
  };
  toogleDemoTab = function (tabtype) {
    this.activetab = tabtype;
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    };
    if(tabtype == 'locations'){
      if(this.bycountrylocobj != null){
        var lat = this.bycountrylocobj.lat;
        var lng = this.bycountrylocobj.lng;
        this.map.setView(L.latLng(lat, lng), 14);
        this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
      }
    }
    else if(tabtype == 'ipaddress'){
      if(this.byiplocobj != null){
        var lat = this.byiplocobj.lat;
        var lng = this.byiplocobj.lng;
        this.map.setView(L.latLng(lat, lng), 14);
        this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
      }
    }
  };
  handleGeosearchData = function (data) {
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    };
    var lat = data.geometry.coordinates[1];
    var lng = data.geometry.coordinates[0];
    this.bycountrylocobj = {lat: lat, lng: lng};
    this.map.setView(L.latLng(lat, lng), 14);
    this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
    setTimeout(() => {
      $('#countryDemoGeosearch .pb-geo-input-text').val(data.address.mainAddressLine);
      $('#geoSearchSampleDemoCity').val(data.address.areaName3);
      $('#geoSearchSampleDemoState').val(data.address.areaName1);
      $('#geoSearchSampleDemoPostalcode').val(data.address.postCode);
    }, 100);
  };
  handleGeosearchDataIp = function (data) {
    if (this.newMarker != undefined) {
      this.map.removeLayer(this.newMarker);
    };
    var lat = data.geometry.coordinates[1];
    var lng = data.geometry.coordinates[0];
    this.byiplocobj = {lat: lat, lng: lng};
    this.map.setView(L.latLng(lat, lng), 14);
    this.newMarker = L.marker([lat, lng], this.markerIcon).addTo(this.map);
    setTimeout(() => {
      $('#ipDemoGeosearch .pb-geo-input-text').val(data.address.mainAddressLine);
      $('#geoSearchSampleDemoCityIp').val(data.address.areaName3);
      $('#geoSearchSampleDemoStateIp').val(data.address.areaName1);
      $('#geoSearchSampleDemoPostalcodeIp').val(data.address.postCode);
      $('#geoSearchSampleDemoCountryIp').val(data.address.country);
    }, 100);
  };
  toogleIpCheckbox = function(){
    this.searchIpAddress = '';
  };
}
