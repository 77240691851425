import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserService } from '../service/user.service';
import { ResourceService } from '../service/resource.service';

@Component({
  selector: 'api-keys',
  templateUrl: './apikeys.component.html'
})
export class ApikeysComponent implements OnInit {
  public showpage: boolean = false;
  public liveAPIKey: string = "";
  public liveAPISecret: string = "";
  public updating: boolean = true;
  public userInfo: any;
  public userEntitlement: any = [];
  public isValidEntitlement: boolean = true;
  public docsURL: any = "";
  public showKeymsgModal: boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private messageService: MessageService, private userService: UserService, private resourceService: ResourceService) {
  };
  ngOnInit() {
    window.scroll(0, 0);
    let docsURL = JSON.parse(`[{"val": "${localStorage.getItem("docsURL")}"}]`)[0].val;
    if(docsURL == "null"){
      this.resourceService.getDocsUrl().subscribe(
        success => {
          this.docsURL = success.docsURL + "Appendix/appendix_mapping_of_old_urls_with_precisely_urls.html";
        }
      );
    }
    else{
      this.docsURL = docsURL + "Appendix/appendix_mapping_of_old_urls_with_precisely_urls.html";
    }
    this.userService.getUserDeatails().subscribe(
      success => {
        this.userInfo = success;
        for (let i = 0; i < this.userInfo.entitlements.length; i++) {
          if (this.userInfo.entitlements[i].product == 'LBS') {
            this.userEntitlement[0] = this.userInfo.entitlements[i];
            if(this.userInfo.entitlements[i].status == 'SUSPENDED' || this.userInfo.entitlements[i].status == 'CANCELLED'){
              this.isValidEntitlement = false;
            }
          }
        }
        if (this.userEntitlement.length == 0) {
          window.location.href = "/subscribeproduct";
          return;
        }
        else{
          this.showpage = true;
          for (var i = 0; i < this.userEntitlement.length; i++) {
            if (this.userEntitlement[i].product == 'LBS') {
              this.userService.getMeKeys(this.userInfo.email).subscribe(
                success => {
                  this.liveAPIKey = success.clientId;
                  this.liveAPISecret = success.secret;
                  this.updating = false;
                },
                error => {
                  if(error.error.error == 'Cookie tempered'){
                    window.location.href = '/signout';
                  }
                }
              );
            }
          }
        }
      },
      error => {
        window.location.href = "/";
      }
    );
  };
  copyMe = function (copyString: string) {
    let dummyBox = document.getElementById('dummyBox') as HTMLInputElement;
    dummyBox.value = copyString;
    dummyBox.select();
    document.execCommand('copy');
    window.focus();
    this.messageService.clear();
    this.messageService.add({ severity: 'info', summary: 'Information', detail: 'Text has been copied to clipboard. Use Ctrl+V to paste.' });
  };
  RegenerateMyKey = function () {
    // const payload = {
    //   product: 'software-apis',
    //   keyType: 'livekey'
    // };
    this.showKeymsgModal = false;
    this.messageService.add({ severity: 'info', summary: 'Updating..', detail: 'Creating new keys. Please wait..' });
    this.updating = true;
    this.userService.regenerateKeys().subscribe(
      success => {
        this.updating = false;
        this.res = success;
        this.messageService.clear();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'New keys created.' });
        this.liveAPIKey = this.res.key;
        this.liveAPISecret = this.res.secret;
      },
      error => {
        if(error.error.error == 'Cookie tempered'){
          window.location.href = '/signout';
          return;
        }
        this.updating = false;
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Couldn't create new key. Please try again." });
      }
    );
  };
}
