import { Component, Input } from '@angular/core';

@Component({
    selector: 'api-response',
    templateUrl: './apiresponse.component.html'
})
export class ApiresponseComponent {
    @Input() requestUri: string;
    @Input() requestBody: any;
    @Input() responseBody: any;
    @Input() responseStatus: string;
    @Input() responseType: string;

    constructor() { };

    copyRes = function () {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.responseBody;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    };
}
